import React from 'react';
import classes from './IncrementControl.module.css';

const incrementControl = (props) => (
    <div className={classes.Container} style={props.style}>
        <button 
            className={[classes.MinusButton, classes["Danger"]].join(' ')}
            onClick={props.removed}
            disabled={props.minusDisabled}/>
        <div className={classes.Value} >{props.value}</div>
        <button 
            className={[classes.PlusButton, classes["Success"]].join(' ')}
            onClick={props.added}
            disabled={props.plusDisabled}/> 
    </div>   
);
 
export default incrementControl;
