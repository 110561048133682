import React from 'react';
import Display from '../Display/Display';
import classes from './Dashboard.module.css';
import { useTranslation } from 'react-i18next';
import { useSNA19Context } from '../../../context/SNA19-context';

const Dashboard = () => {
    const { t } = useTranslation();
    const { 
        spacecraftSpeed, 
        flightData: {
            spacecraftFlightDistance: distance, 
            startPointTimerValue: start, 
            finishPointTimerValue: finish,
            planetOrbitDistance,
            destinationPlanetOrbitalSpeed,
            correctSpeed
        }, 
        isStarcraftAnimationRunning,
        isTimerOn,
        canStart,
        timerAcc,
    } = useSNA19Context();
    
    const traveledDistance = (distance / (finish - start)) * timerAcc;
    const totalTime = planetOrbitDistance / destinationPlanetOrbitalSpeed / 3600;
    const traveledTime = +((totalTime / (finish - start)) * timerAcc).toFixed(2);
    
    const DISPLAY_SETTINGS = [
        {header: 'v', value: isStarcraftAnimationRunning ? spacecraftSpeed : !isTimerOn && canStart ? spacecraftSpeed : 0, footer: 'speed'},
        {header: 'S', value: Math.round(traveledDistance < distance ? traveledDistance : spacecraftSpeed !== correctSpeed ? traveledDistance || 0 : distance), footer: 'distance'},
        {header: 't', value: traveledTime || 0, footer: 'time'}
    ];
    
    return (
        <article className={classes.wrapper}>
            {DISPLAY_SETTINGS.map(s => (
                <Display 
                    key={s.header} 
                    header={s.header}
                    value={s.value}
                    footer={t(`SNA19.dashboard.${s.footer}`)}/>
            ))}
        </article>
    );
}
 
export default Dashboard;
