export const DATA = [
    {
        footballField: 'https://dartef.s3.eu-north-1.amazonaws.com/SNA22/football-fields/4_NED_MEX_2014.png',
        overlay: 'https://dartef.s3.eu-north-1.amazonaws.com/SNA22/overlays/1_overlay_v2.png',
        mascot: {
            image: 'https://dartef.s3.eu-north-1.amazonaws.com/SNA22/mascots/bacon_mascot.png',
            position: {
                left: 5.8,
                top: 15
            },
            size: {
                width: 6,
                height: 5.6,
                divShadowHeight: 110,
                divShadowRight: 45
            },
        },
        correctAngle: 4
    },
    {
        footballField: 'https://dartef.s3.eu-north-1.amazonaws.com/SNA22/football-fields/2_NED_ARG_1998.png',
        overlay: 'https://dartef.s3.eu-north-1.amazonaws.com/SNA22/overlays/2_overlay_v2.png',
        mascot: {
            image: 'https://dartef.s3.eu-north-1.amazonaws.com/SNA22/mascots/syringe_mascot_flipped.png',
            position: {
                left: 26,
                top: 7
            },
            size: {
                width: 6,
                height: 6,
                divShadowHeight: 120,
                divShadowRight: 32
            },
        },
        correctAngle: 17
    },
    {
        footballField: 'https://dartef.s3.eu-north-1.amazonaws.com/SNA22/football-fields/3_NED_CHI_2014.png',
        overlay: 'https://dartef.s3.eu-north-1.amazonaws.com/SNA22/overlays/3_overlay_v2.png',
        mascot: {
            image: 'https://dartef.s3.eu-north-1.amazonaws.com/SNA22/mascots/beverage_can_mascot.png',
            position: {
                left: 29.8,
                top: 22
            },
            size: {
                width: 9,
                height: 9,
                divShadowHeight: 140,
                divShadowRight: 55
            },
        },
        correctAngle: 2
    },
    {
        footballField: 'https://dartef.s3.eu-north-1.amazonaws.com/SNA22/football-fields/4_NED_MEX_2014.png',
        overlay: 'https://dartef.s3.eu-north-1.amazonaws.com/SNA22/overlays/4_overlay_v4.png',
        mascot: {
            image: 'https://dartef.s3.eu-north-1.amazonaws.com/SNA22/mascots/beverage_can_mascot.png',
            position: {
                left: 28,
                top: 19
            },
            size: {
                width: 6,
                height: 5.6,
                divShadowHeight: 110,
                divShadowRight: 55
            },
        },
        correctAngle: 4
    },
    {
        footballField: 'https://dartef.s3.eu-north-1.amazonaws.com/SNA22/football-fields/5_NED_MEX_2014.png',
        overlay: 'https://dartef.s3.eu-north-1.amazonaws.com/SNA22/overlays/5_overlay_v2.png',
        mascot: {
            image: 'https://dartef.s3.eu-north-1.amazonaws.com/SNA22/mascots/water_glass_mascot.png',
            position: {
                left: 18,
                top: 24
            },
            size: {
                width: 10,
                height: 10,
                divShadowHeight: 110,
                divShadowRight: 55
            },
        },
        correctAngle: 41
    },
    {
        footballField: 'https://dartef.s3.eu-north-1.amazonaws.com/SNA22/football-fields/6_ARG_CH_2014.png',
        overlay: 'https://dartef.s3.eu-north-1.amazonaws.com/SNA22/overlays/6_overlay_v2.png',
        mascot: {
            image: 'https://dartef.s3.eu-north-1.amazonaws.com/SNA22/mascots/water_glass_mascot.png',
            position: {
                left: 15,
                top: 16
            },
            size: {
                width: 9,
                height: 9,
                divShadowHeight: 110,
                divShadowRight: 55
            },
        },
        correctAngle: 1
    },
    {
        footballField: 'https://dartef.s3.eu-north-1.amazonaws.com/SNA22/football-fields/7_BRA_ARG_1990.png',
        overlay: 'https://dartef.s3.eu-north-1.amazonaws.com/SNA22/overlays/7_overlay_v2.png',
        mascot: {
            image: 'https://dartef.s3.eu-north-1.amazonaws.com/SNA22/mascots/banana_mascot.png',
            position: {
                left: 44,
                top: 19
            },
            size: {
                width: 8,
                height: 8,
                divShadowHeight: 110,
                divShadowRight: 45,
            },
        },
        correctAngle: 17
    },
    {
        footballField: 'https://dartef.s3.eu-north-1.amazonaws.com/SNA22/football-fields/8_FRA_AUS_2018.png',
        overlay: 'https://dartef.s3.eu-north-1.amazonaws.com/SNA22/overlays/8_overlay_v2.png',
        mascot: {
            image: 'https://dartef.s3.eu-north-1.amazonaws.com/SNA22/mascots/water_glass_mascot.png',
            position: {
                left: 48,
                top: 28
            },
            size: {
                width: 9,
                height: 9,
                divShadowHeight: 110,
                divShadowRight: 55,
            },
        },
        correctAngle: 139
    },
    {
        footballField: 'https://dartef.s3.eu-north-1.amazonaws.com/SNA22/football-fields/9_DE_RS_2010.png',
        overlay: 'https://dartef.s3.eu-north-1.amazonaws.com/SNA22/overlays/9_overlay_v3.png',
        mascot: {
            image: 'https://dartef.s3.eu-north-1.amazonaws.com/SNA22/mascots/beverage_can_mascot.png',
            position: {
                left: 11,
                top: 15
            },
            size: {
                width: 9,
                height: 9,
                divShadowHeight: 110,
                divShadowRight: 55,
            },
        },
        correctAngle: 18
    },
    {
        footballField: 'https://dartef.s3.eu-north-1.amazonaws.com/SNA22/football-fields/10_ARG_ICE_2018.png',
        overlay: 'https://dartef.s3.eu-north-1.amazonaws.com/SNA22/overlays/10_overlay_v2.png',
        mascot: {
            image: 'https://dartef.s3.eu-north-1.amazonaws.com/SNA22/mascots/syringe_mascot_flipped.png',
            position: {
                left: 27.3,
                top: 6
            },
            size: {
                width: 5,
                height: 5,
                divShadowHeight: 110,
                divShadowRight: 32,
            },
        },
        correctAngle: 7
    },
    {
        footballField: 'https://dartef.s3.eu-north-1.amazonaws.com/SNA22/football-fields/11_ALG_SI_2010.png',
        overlay: 'https://dartef.s3.eu-north-1.amazonaws.com/SNA22/overlays/11_overlay_v2.png',
        mascot: {
            image: 'https://dartef.s3.eu-north-1.amazonaws.com/SNA22/mascots/banana_mascot.png',
            position: {
                left: 9,
                top: 7
            },
            size: {
                width: 5,
                height: 5,
                divShadowHeight: 110,
                divShadowRight: 45,
            },
        },
        correctAngle: 25
    },
    {
        footballField: 'https://dartef.s3.eu-north-1.amazonaws.com/SNA22/football-fields/12_CMR_ROU_1990.png',
        overlay: 'https://dartef.s3.eu-north-1.amazonaws.com/SNA22/overlays/12_overlay_v2.png',
        mascot: {
            image: 'https://dartef.s3.eu-north-1.amazonaws.com/SNA22/mascots/bacon_mascot_flipped.png',
            position: {
                left: 25.3,
                top: 20
            },
            size: {
                width: 10,
                height: 10,
                divShadowHeight: 110,
                divShadowRight: 45,
            },
        },
        correctAngle: 18
    },
];
