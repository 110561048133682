const SNA26_reducer = (state, action) => {
    if (action.type === 'TIMELINE') {
        return { 
            ...state, 
            frequency: action.payload.frequency,
            percentage: action.payload.percentage
        };
    }
    if (action.type === 'START') {
        return { 
            ...state, 
            isPlaying: state.isPlaying ? false : true,
        };
    }
    if (action.type === 'RADIO') {
        return { 
            ...state, 
            track: action.payload,
            isPlaying: false,
            frequency: 0,
            percentage: 0
        };
    }
    if (action.type === 'END') {
        return { 
            ...state, 
            isPlaying: false,
        };
    }
}

export default SNA26_reducer;
