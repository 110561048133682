import { alphabet, getArrayOfUniques } from "../utils/helpers";

const checkExpressionValidity = (expression) => {
    for (const operator of ['*', '/', ':', '+', '-']) {
        if (expression.indexOf(operator) > -1 && expression.length > 2) 
            return true;
    }
    return false;
}

const checkFieldsNotEmpty = (arr) => {
    let areNotEmpty = true;
    for (const el of arr) {
        if (typeof el.value === 'string') areNotEmpty = el.value.trim() !== '' && areNotEmpty; 
    }
    return areNotEmpty;
}

const getIndex = (arr, type) => arr.findIndex(object => {
    return object.type === type;
  });

const SNA24_reducer = (state, action) => {
    if (action.type === 'CHANGE_COMPUTER') {
        return { ...state, computerIndex: action.payload };
    }
    if (action.type === 'SET_EXPRESSION') {
        const variables = action.payload.indexOf('sqrt') === -1 ? action.payload : action.payload.replace('sqrt', '');
        const allExpressionVariables = variables
            .split('')
            .filter(el => alphabet.indexOf(el) > -1)
            .map(el => {
                const i = getIndex(state.expressionVariables, el);
                if (i === -1) return {type: el, value: ''}
                return {type: el, value: state.expressionVariables[i].value};  
            });
        const uniqueExpressionVariables = getArrayOfUniques(allExpressionVariables, 'type');
        return { 
            ...state, 
            expression: action.payload.replace(':', '/'), 
            expressionVariables: uniqueExpressionVariables,
            expressionIsValid: checkExpressionValidity(action.payload)  
        };
    }
    if (action.type === 'ERROR') {
        return { ...state,  calculationError: true };
    }
    if (action.type === 'ZERO_ERROR') {
        return { ...state,  zeroError: true, isCalculated: true };
    }
    if (action.type === 'UPDATE_INPUT') {
        const { index, value } = action.payload;
        const updatedExpressionVariables = [...state.expressionVariables];
        const updatedVariable = { ...updatedExpressionVariables[index], value: value };
        updatedExpressionVariables[index] = updatedVariable;
        return { 
            ...state,
            expressionVariables: updatedExpressionVariables,
            fieldsAreValid: checkFieldsNotEmpty(updatedExpressionVariables)
        };
    }
    if (action.type === 'INCREASE' || action.type === 'DECREASE') {
        const modifier = action.type === 'INCREASE' ? 1 : -1;
        const updatedExpressionVariables = [...state.expressionVariables];
        const updatedVariable = { ...updatedExpressionVariables[action.payload], value: +updatedExpressionVariables[action.payload].value + modifier }; 
        updatedExpressionVariables[action.payload] = updatedVariable;
        return { 
            ...state, 
            expressionVariables: updatedExpressionVariables,
            fieldsAreValid: checkFieldsNotEmpty(updatedExpressionVariables)
        };
    }
    if (action.type === 'SET_TIME') {
        return { ...state,  calculationTime: action.payload };
    }
    if (action.type === 'CALCULATE') {
        return { ...state,  isCalculated: true };
    }
    if (action.type === 'RESET') {
        return { 
            ...state, 
            calculationError: false,
            zeroError: false,
            calculationTime: null, 
            isCalculated: false, 
            expressionIsValid: state.expressionIsValid ? true : false
        };
    }
}

export default SNA24_reducer;
