import React, { useState, useCallback, useEffect } from 'react';
import classes from './Content.module.css';
import GraphingCalculator from '../GraphingCalculator/GraphingCalculator';
import Controls from '../Controls/Controls';
import Notification from '../../UI/Notification/Notification';
import { useSNA25Context } from '../../../context/SNA25-context';
import { useTranslation } from 'react-i18next';
import { round } from '../../../utils/helpers';
import SideDrawer from '../../UI/SideDrawer/SideDrawer';
import QuickStart from '../QuickStart/QuickStart';
import useToggle from '../../../hooks/useToggle';

const DEVIATION_THRESHOLD = 3;

const Content = () => {
    const [isOpen, setIsOpen] = useToggle(false);
    const [notifications, setNotifications] = useState([]);
    const { 
        currentData: { parabola: { vertex: {correctParabolaX, correctParabolaY} } }, 
        crossMarkCoords, 
        crossMarkIsValid,
        formData, 
        submitterName 
    } = useSNA25Context();
    const { i18n } = useTranslation();

    const getNotification = useCallback((identifier) => {
        if (identifier === 'SUCCESS_1') {
            return {
                id: 1,
                type: 'Success',
                title: i18n.t('SNA25.notifications.titles.success'),
                description: i18n.t('SNA25.notifications.descriptions.success-1')
            }
        }
        if (identifier === 'SUCCESS_2') {
            return {
                id: 2,
                type: 'Success',
                title: i18n.t('SNA25.notifications.titles.success'),
                description: i18n.t('SNA25.notifications.descriptions.success-2')
            }
        }
        if (identifier === 'DANGER_1') {
            return {
                id: 3,
                type: 'Danger',
                title: i18n.t('SNA25.notifications.titles.fail'),
                description: i18n.t('SNA25.notifications.descriptions.fail-1')
            }
        }
        if (identifier === 'DANGER_2') {
            return {
                id: 4,
                type: 'Danger',
                title: i18n.t('SNA25.notifications.titles.fail'),
                description: i18n.t('SNA25.notifications.descriptions.fail-2')
            }
        }
        if (identifier === 'WARNING-1') {
            return {
                id: 5,
                type: 'Warning',
                title: i18n.t('SNA25.notifications.titles.warning'),
                description: i18n.t('SNA25.notifications.descriptions.warning-1')
            }
        }
        if (identifier === 'WARNING-2') {
            return {
                id: 6,
                type: 'Warning',
                title: i18n.t('SNA25.notifications.titles.warning'),
                description: i18n.t('SNA25.notifications.descriptions.warning-2')
            }
        }
        if (identifier === 'WARNING-3') {
            return {
                id: 7,
                type: 'Warning',
                title: i18n.t('SNA25.notifications.titles.warning'),
                description: i18n.t('SNA25.notifications.descriptions.warning-3')
            }
        }
    }, [i18n]);

    const deleteNotificationHandler = useCallback(() => {
        setNotifications([]);
    }, []);

    useEffect(() => {
        if (!formData) return;
        const { userParabolaX, userParabolaY, userDeviationX, userDeviationY } = formData;
        const {x: crossMarkX, y: crossMarkY } = crossMarkCoords;

        const parabolaCoordsAreValid = userParabolaX === correctParabolaX && userParabolaY === correctParabolaY;
        const correctDeviationX = round((crossMarkX * 100) / correctParabolaX - 100, 2);
        const correctDeviationY = round((crossMarkY * 100) / correctParabolaY - 100, 2);
    
        const deviationIsCorrect = correctDeviationX === userDeviationX && correctDeviationY === userDeviationY;

        const deviationIsValidAndLessThanThreshold = 
            deviationIsCorrect && 
            (Math.abs(correctDeviationX) <= DEVIATION_THRESHOLD &&  
            Math.abs(correctDeviationY) <= DEVIATION_THRESHOLD);

        const deviationIsValidAndMoreThanThreshold = 
            deviationIsCorrect && 
            (Math.abs(correctDeviationX) > DEVIATION_THRESHOLD || 
            Math.abs(correctDeviationY) > DEVIATION_THRESHOLD);

        // 1. correct user parabola vertex coordinates, correct deviation (less than 3%), submited with CORRECT button => success notification
        if (parabolaCoordsAreValid && deviationIsValidAndLessThanThreshold && submitterName === 'correct') {
            setNotifications([getNotification('SUCCESS_1')]);
        }
        // 2. correct user parabola vertex coordinates, correct deviation (more than 3%), submited with DEFECT button => success notification
        if (parabolaCoordsAreValid && deviationIsValidAndMoreThanThreshold && submitterName === 'defect') {
            setNotifications([getNotification('SUCCESS_2')]);
        }
        // 3. correct user parabola vertex coordinates, wrong deviation => danger notification
        if (parabolaCoordsAreValid && !deviationIsCorrect) {
            setNotifications([getNotification('DANGER_1')]);
        }
        // 4. wrong user parabola vertex coordinates => danger notification
        if (!parabolaCoordsAreValid) {
            setNotifications([getNotification('DANGER_2')]);
        }
        // 5. correct user parabola vertex coordinates, correct deviation (less than 3%), submited with DEFECT button => warning notification
        if (parabolaCoordsAreValid && deviationIsValidAndLessThanThreshold && submitterName === 'defect') {
            setNotifications([getNotification('WARNING-1')]);
        }
        // 6. correct user parabola vertex coordinates, correct deviation (more than 3%), submited with CORRECT button => warning notification
        if (parabolaCoordsAreValid && deviationIsValidAndMoreThanThreshold && submitterName === 'correct') {
            setNotifications([getNotification('WARNING-2')]);
        }
        // 7. correct user parabola vertex coordinates, crossmark in wrong place (+/- some value) => warning notification
        if (parabolaCoordsAreValid && !crossMarkIsValid) {
            setNotifications([getNotification('WARNING-3')]);
        }
        
    }, [correctParabolaX, correctParabolaY, crossMarkCoords, crossMarkIsValid, formData, submitterName, getNotification]);

    const openSideDrawerHandler = () => {
        setIsOpen(true);
    }

    const closeSideDrawerHandler = () => {
        setIsOpen(false);
    }

    return (
        <main className={classes.wrapper}>
            <section className={classes.gc}>
                <GraphingCalculator />
            </section>
            <section className={classes.controls}>
                <Controls />
            </section>
            {!!notifications.length && <Notification 
                notifications={notifications}
                notificationDelay={10000}
                deleteNotification={deleteNotificationHandler}
            />}
            <SideDrawer
                isSideDrawerOpen={isOpen}
                openSideDrawer={openSideDrawerHandler}
                closeSideDrawer={closeSideDrawerHandler}
            >
                <QuickStart />
            </SideDrawer>
        </main>
    );
}
 
export default Content;
