import React from 'react';
import ToggleSwitch from '../../UI/ToggleSwitch/ToggleSwitch';
import classes from './SpeedControl.module.css';
import { GiTurtle, GiRabbit } from 'react-icons/gi';

const SpeedControl = (props) => {
    const {isToggled, isRunning} = props;
    return (
        <div className={classes.wrapper}>
            <GiRabbit />
            <ToggleSwitch 
                sliderColor='SliderWhite'
                sliderType='Rounded'
                disabled={!isRunning}
                isToggled={isToggled}
                onToggle={() => props.toggle()}
            />
            <GiTurtle />
        </div>
    );
}
 
export default SpeedControl;
