import React from 'react';
import classes from './Triangle.module.css';
import { target } from '../../../data/SNA2data';

const TriangleB = ({ transparent, index, triangle }) => (
    <div>
        {transparent && index !== null && <img className={classes.Triangle} alt="triangle" src={triangle}/>}
        {!transparent && <img className={classes.Triangle} alt="triangle" src={target}/>}
    </div>  
);

export default TriangleB;
