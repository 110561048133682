import React from 'react';
import classes from './SignButton.module.css';

const SignButton = (props) => {
    return (
        <button
            className={`${classes[props.sign]} ${classes[props.btnType]}`}
            onClick={props.clicked}
            disabled={props.disabled}
        />
    );
}

export default SignButton;
