import React, { Fragment, useState } from 'react';
import classes from './Keypad.module.css';
import { FaKeyboard, FaCaretUp } from "react-icons/fa";

const Keypad = (props) => {
    const [open, setOpen] = useState(false);

    const handleKeypadClick = () => {
        setOpen(prev => !prev);
    }

    return (
        <Fragment>
            <div className={`${classes.wrapper} ${classes[open ? 'open' : 'close']}`}>
                <div 
                    className={classes['keypad-btn']}
                    role="button"
                    onClick={handleKeypadClick}
                >
                    <FaKeyboard />
                    <FaCaretUp />
                </div>
                {props.children}
            </div>
        </Fragment>
    );
}
 
export default Keypad;