import React, { Component } from 'react';
import classes from './SNA15.module.css';
import WaterTank from '../../components/SNA15/WaterTank/WaterTank';
import ConveyorBelt from '../../components/SNA15/ConveyorBelt/ConveyorBelt';
import Digits from '../../components/SNA15/InputPanels/Digits/Digits';
import ParametersWithImages from '../../components/SNA15/InputPanels/Parameters/ParametersWithImages';
import ParametersWithSymbols from '../../components/SNA15/InputPanels/Parameters/ParametersWithSymbols';
import a_w from '../../assets/icons/SNA15/WhiteIcons/a.png';
import a2_w from '../../assets/icons/SNA15/WhiteIcons/a2.png';
import a3_w from '../../assets/icons/SNA15/WhiteIcons/a3.png';
import b_w from '../../assets/icons/SNA15/WhiteIcons/b.png';
import b2_w from '../../assets/icons/SNA15/WhiteIcons/b2.png';
import b3_w from '../../assets/icons/SNA15/WhiteIcons/b3.png';
import c_w from '../../assets/icons/SNA15/WhiteIcons/c.png';
import c2_w from '../../assets/icons/SNA15/WhiteIcons/c2.png';
import c3_w from '../../assets/icons/SNA15/WhiteIcons/c3.png';
import a_b from '../../assets/icons/SNA15/BlackIcons/a.png';
import a2_b from '../../assets/icons/SNA15/BlackIcons/a2.png';
import a3_b from '../../assets/icons/SNA15/BlackIcons/a3.png';
import b_b from '../../assets/icons/SNA15/BlackIcons/b.png';
import b2_b from '../../assets/icons/SNA15/BlackIcons/b2.png';
import b3_b from '../../assets/icons/SNA15/BlackIcons/b3.png';
import c_b from '../../assets/icons/SNA15/BlackIcons/c.png';
import c2_b from '../../assets/icons/SNA15/BlackIcons/c2.png';
import c3_b from '../../assets/icons/SNA15/BlackIcons/c3.png';
import ExBuilder from '../../components/SNA15/ExpressionBuilder/ExBuilderA';

const TIMER_DELAY = 40;
const CUBE_LEFT_POSITION = -10; // e.g. left: -10vw
const CUBE_RIGHT_POSITION = 140; // e.g. left: 140vw
const BELT_LEFT_POSITION = -13; // e.g. background-position: -13vw
const BELT_RIGHT_POSITION = 0; // e.g. background-position: 0vw
const DISTANCE_BETWEEN_CUBES = 30;

const POLYNOMIAL = {
    coeficient: '',
    variable_1: {
        image: null,
        type: '',
        value: 0
    },
    variable_2: {
        type: '',
        value: 0
    }
};

const IMAGES = [
    {
        type: 'a1',
        white: a_w,
        black: a_b
    },
    {
        type: 'a2',
        white: a2_w,
        black: a2_b
    },
    {
        type: 'a3',
        white: a3_w,
        black: a3_b
    },
    {
        type: 'b1',
        white: b_w,
        black: b_b
    },
    {
        type: 'b2',
        white: b2_w,
        black: b2_b
    },
    {
        type: 'b3',
        white: b3_w,
        black: b3_b
    },
    {
        type: 'c1',
        white: c_w,
        black: c_b
    },
    {
        type: 'c2',
        white: c2_w,
        black: c2_b
    },
    {
        type: 'c3',
        white: c3_w,
        black: c3_b
    },
];

class SNA15a extends Component {
    state = {
        image: null,
        weeks: {
            firstWeek: {
                p1: {
                    coeficient: '',
                    variable_1: {
                        image: null,
                        type: '',
                        value: 0
                    },
                    variable_2: {
                        type: '',
                        value: 0
                    }
                },
            },
            secondWeek: {},
            thirdWeek: {},
        },
        inputPanels: {
            isCoeficientsPanelHidden: true,
            isVariables_1_panelHidden: true,
            isVariables_2_panelHidden: true,
        },
        totalWaterVolume: 0,
        waterVolumesPerBox: [],
        cubePositions: [],
        plantsAreas: [],
        waterSpray: 'Off',
        conveyorBeltPosition: BELT_RIGHT_POSITION,
        isAnimationRunning: false,
        isDataReseted: true,
        canStart: false,
        polynomialId: null, // temp id for passing data between siblings
        weekId: null,       // temp id for passing data between siblings
        variablesArray: [],
    }

    componentWillUnmount() {
        clearInterval(this.animation);
    }

    handleStartClick = () => {
        this.state.isAnimationRunning ? this.stopTimer() : this.startTimer();
    }

    handleResetClick = () => {
        clearInterval(this.animation);
        this.setState({
            weeks: {
                firstWeek: {
                    p1: {
                        coeficient: '',
                        variable_1: {
                            image: null,
                            type: '',
                            value: 0
                        },
                        variable_2: {
                            type: '',
                            value: 0
                        }
                    },
                },
                secondWeek: {},
                thirdWeek: {},
            },
            inputPanels: {
                isCoeficientsPanelHidden: true,
                isVariables_1_panelHidden: true,
                isVariables_2_panelHidden: true,
            },
            totalWaterVolume: 0,
            cubePositions: [],
            plantsAreas: [],
            waterSpray: 'Off',
            conveyorBeltPosition: BELT_RIGHT_POSITION,
            isAnimationRunning: false,
            isDataReseted: true,
            canStart: false,
            variablesArray: []
        });
    }

    startTimer = () => {
        this.setState({
            isAnimationRunning: true,
            isDataReseted: false,
        });
        this.animation = setInterval(() => {
            this.animateBoxes();
            this.animateConveyorBelt();
        }, TIMER_DELAY);
    }

    stopTimer = () => {
        clearInterval(this.animation);
        this.setState({ isAnimationRunning: false });
    }

    addPolynomialHandler = (id) => {
        const updatedWeeks = { ...this.state.weeks };
        // Add empty polynomial template to existing polynomial(s)
        const updatedPolynomials = Object.assign({}, updatedWeeks[id], { [`p${Object.keys(updatedWeeks[id]).length + 1}`]: JSON.parse(JSON.stringify(POLYNOMIAL)) });
        updatedWeeks[id] = updatedPolynomials;
        this.setState({ weeks: updatedWeeks, canStart: false });
    }

    deletePolynomialHandler = (id) => {
        const updatedWeeks = { ...this.state.weeks };
        const updatedPolynomials = { ...updatedWeeks[id] };
        delete updatedPolynomials[`p${Object.keys(updatedWeeks[id]).length}`];
        updatedWeeks[id] = updatedPolynomials;
        this.setState({ weeks: updatedWeeks }, () => {
            this.calculateAmountOfBoxes();
            this.calculateWaterVolume();
            this.checkStartStatus();
        });
    }

    addWeekHandler = (id) => {
        const updatedWeeks = { ...this.state.weeks };
        const updatedPolynomials = Object.assign({}, { [`p${Object.keys(updatedWeeks[id]).length + 1}`]: JSON.parse(JSON.stringify(POLYNOMIAL)) });
        updatedWeeks[id] = updatedPolynomials;
        this.setState({ weeks: updatedWeeks, canStart: false });
    }

    deleteWeekHandler = (id) => {
        const updatedWeeks = { ...this.state.weeks };
        updatedWeeks[id] = {};
        this.setState({ weeks: updatedWeeks }, () => {
            this.calculateAmountOfBoxes();
            this.calculateWaterVolume();
            this.checkStartStatus();
        });
    }

    closeDigitsPanelHandler = () => {
        this.setState({
            inputPanels: {
                ...this.state.inputPanels,
                isCoeficientsPanelHidden: true,
            },
        });
    }

    closeVariables_1_PanelHandler = () => {
        this.setState({
            inputPanels: {
                ...this.state.inputPanels,
                isVariables_1_panelHidden: true,
            },
        });
    }

    closeVariables_2_PanelHandler = () => {
        this.setState({
            inputPanels: {
                ...this.state.inputPanels,
                isVariables_2_panelHidden: true,
            },
        });
    }

    openCoeficientsPanelHandler = (weekId, polynomialId) => {
        this.setState({
            inputPanels: {
                ...this.state.inputPanels,
                isCoeficientsPanelHidden: false,
                isVariables_1_panelHidden: true,
                isVariables_2_panelHidden: true,
            },
            polynomialId,
            weekId
        });
    }

    openVariables_1_PanelHandler = (weekId, polynomialId) => {
        this.setState({
            inputPanels: {
                ...this.state.inputPanels,
                isCoeficientsPanelHidden: true,
                isVariables_1_panelHidden: false,
                isVariables_2_panelHidden: true,
            },
            polynomialId,
            weekId
        });
    }

    openVariables_2_PanelHandler = (weekId, polynomialId) => {
        this.setState({
            inputPanels: {
                ...this.state.inputPanels,
                isCoeficientsPanelHidden: true,
                isVariables_1_panelHidden: true,
                isVariables_2_panelHidden: false,
            },
            polynomialId,
            weekId
        });
    }

    // Sets the polynomials variables in weeks
    setPolynomialValues() {
        const updatedWeeks = { ...this.state.weeks };
        for (const key of Object.keys(updatedWeeks)) {
            for (const polynomial of Object.values(updatedWeeks[key])) {
                const { variable_1: { type: type_1, value: value_1 }, variable_2: { type: type_2, value: value_2 } } = polynomial;
                for (const v of this.state.variablesArray) {
                    const { type: type_v, value: value_v } = v;
                    if (type_v === type_1.slice(0, -1) && value_1 !== value_v) {
                        polynomial.variable_1.value = value_v;
                    }
                    if (type_v === type_2 && value_2 !== value_v) {
                        polynomial.variable_2.value = value_v;
                    }
                }
            }
        }
        this.setState({ weeks: updatedWeeks }, () => {
            this.calculateWaterVolume();
            this.calculateLeavesAreas();
        });
    }

    // Sets the variables for increment controls
    setVariables() {
        const [variablesArray, tempVariablesArray] = [[], []];
        for (const key of Object.keys(this.state.weeks)) {
            for (const value of Object.values(this.state.weeks[key])) {
                const { variable_1: { type: type_1, value: value_1 }, variable_2: { type: type_2, value: value_2 } } = value;
                if (type_1 !== '' && !tempVariablesArray.includes(type_1.slice(0, -1))) variablesArray.push({type: type_1.slice(0, -1), value: value_1});
                if (type_2 !== '' && !tempVariablesArray.includes(type_2)) variablesArray.push({type: type_2, value: value_2});
                tempVariablesArray.push(type_1.slice(0, -1), type_2);
            }
        }
        this.setState({ variablesArray }, () => this.setPolynomialValues());
    }

    setCoeficientValueHandler = (value, weekId, polynomialId) => {
        const updatedWeeks = { ...this.state.weeks };
        const updatedPolynomials = { ...updatedWeeks[weekId] };
        const updatedPolynomial = { ...updatedPolynomials[polynomialId] };
        updatedPolynomial.coeficient = value;
        updatedPolynomials[polynomialId] = updatedPolynomial;
        updatedWeeks[weekId] = updatedPolynomials;
        this.setState({
            inputPanels: {
                ...this.state.inputPanels,
                isCoeficientsPanelHidden: true
            },
            weeks: updatedWeeks,
        }, () => {
                this.calculateWaterVolume();
                this.calculateAmountOfBoxes();
        });
    }

    setVariable_1_ValueHandler = (value, weekId, polynomialId) => {
        const updatedWeeks = { ...this.state.weeks };
        const updatedPolynomials = { ...updatedWeeks[weekId] };
        const updatedPolynomial = { ...updatedPolynomials[polynomialId] };
        updatedPolynomial.variable_1.image = value.black;
        updatedPolynomial.variable_1.type = value.type;
        updatedPolynomials[polynomialId] = updatedPolynomial;
        updatedWeeks[weekId] = updatedPolynomials;
        this.setState({
            inputPanels: {
                ...this.state.inputPanels,
                isVariables_1_panelHidden: true
            },
            weeks: updatedWeeks,
        }, () => this.setVariables());
    }

    setVariable_2_ValueHandler = (value, weekId, polynomialId) => {
        const updatedWeeks = { ...this.state.weeks };
        const updatedPolynomials = { ...updatedWeeks[weekId] };
        const updatedPolynomial = { ...updatedPolynomials[polynomialId] };
        updatedPolynomial.variable_2.type = value;
        updatedPolynomials[polynomialId] = updatedPolynomial;
        updatedWeeks[weekId] = updatedPolynomials;
        this.setState({
            inputPanels: {
                ...this.state.inputPanels,
                isVariables_2_panelHidden: true
            },
            weeks: updatedWeeks,
        }, () => this.setVariables());
    }

    incrementVariableHandler = ({ type, value }) => {
        const updatedWeeks = { ...this.state.weeks };
        for (const key of Object.keys(updatedWeeks)) {
            for (const polynomial of Object.values(updatedWeeks[key])) {
                if (polynomial.variable_1.type.slice(0, -1) === type) {
                    polynomial.variable_1.value = value + 1;
                }
                if (polynomial.variable_2.type === type) {
                    polynomial.variable_2.value = value + 1;
                }
            }
        }
        this.setState({ weeks: updatedWeeks }, () => this.setVariables());
    }

    decrementVariableHandler = ({ type, value }) => {
        const updatedWeeks = { ...this.state.weeks };
        for (const key of Object.keys(updatedWeeks)) {
            for (const polynomial of Object.values(updatedWeeks[key])) {
                if (polynomial.variable_1.type.slice(0, -1) === type) {
                    polynomial.variable_1.value = value - 1
                }
                if (polynomial.variable_2.type === type) {
                    polynomial.variable_2.value = value - 1
                }
            }
        }
        this.setState({ weeks: updatedWeeks }, () => this.setVariables());
    }

    calculateAmountOfBoxes() {
        let cubePositions = [];
        for (const week of Object.values(this.state.weeks)) {
            for (const polynomial of Object.values(week)) {
                if (polynomial.coeficient !== '') cubePositions = [...cubePositions, ...Array.from({length: polynomial.coeficient}, () => CUBE_RIGHT_POSITION)]
            }
        }
        this.setState({ cubePositions });
    }

    calculateWaterVolume() {
        let totalWaterVolume = 0;
        let waterVolumesPerBox = [];
        let canStart = true;
        for (const week of Object.values(this.state.weeks)) {
            for (const polynomial of Object.values(week)) {
                const { coeficient, variable_1: { type: type_1, value: value_1 }, variable_2: { type: type_2, value: value_2 } } = polynomial;
                canStart = canStart && coeficient && type_1 && value_1 && type_2 && value_2;
                const waterVolume = Math.pow(value_1, Number(type_1.slice(-1))) * value_2 / 1000;
                totalWaterVolume = totalWaterVolume + coeficient * waterVolume;
                waterVolumesPerBox = [...waterVolumesPerBox, ...Array.from({length: coeficient}, () => waterVolume)];
            }
        }
        this.setState({ totalWaterVolume, waterVolumesPerBox, canStart });
    }

    calculateLeavesAreas() {
        let plantsAreas = [];
        for (const week of Object.values(this.state.weeks)) {
            for (const polynomial of Object.values(week)) {
                const { coeficient, variable_1: { type: type_1, value: value_1 } } = polynomial;
                const plantsArea = Math.pow(value_1, Number(type_1.slice(-1)));
                plantsAreas = [...plantsAreas, ...Array.from({length: coeficient}, () => plantsArea)];
            }
        }
        this.setState({ plantsAreas });
    }

    checkStartStatus() {
        let canStart = true;
        for (const week of Object.values(this.state.weeks)) {
            for (const polynomial of Object.values(week)) {
                const { coeficient, variable_1: { type: type_1, value: value_1 }, variable_2: { type: type_2, value: value_2 } } = polynomial;
                canStart = canStart && coeficient && type_1 && value_1 && type_2 && value_2;
            }
        }
        this.setState({ canStart });
    }

    animateBoxes = () => {
        const { cubePositions: positions, waterVolumesPerBox: volumes } = this.state;
        const updatedPositions = [...positions];
        for (const [i, position] of positions.entries()) {
            if (position >= CUBE_LEFT_POSITION && i === 0) {
                if (position === 43) {
                    this.waterPlants(i);
                    volumes[i] && this.setState({ waterSpray: 'On' });
                }
                if (position === 30 && volumes[i] !== 0) {
                    this.setState({ waterSpray: 'Off' });
                }
                updatedPositions[i] -= 0.5;
            }
            if (position >= CUBE_LEFT_POSITION && i > 0 && (position - positions[i - 1] >= DISTANCE_BETWEEN_CUBES || positions[i - 1] <= CUBE_LEFT_POSITION)) {
                if (position === 43) {
                    this.waterPlants(i);
                    volumes[i] && this.setState({ waterSpray: 'On' });
                }
                if (position === 30 && volumes[i] !== 0) {
                    this.setState({ waterSpray: 'Off' });
                }
                updatedPositions[i] -= 0.5;
            }
            if (positions[positions.length - 1] <= CUBE_LEFT_POSITION) {
                this.stopTimer();
                this.setState({ canStart: false });
            }
        }
        this.setState({ cubePositions: updatedPositions });
    }

    animateConveyorBelt = () => {
        this.state.conveyorBeltPosition <= BELT_LEFT_POSITION ?
            this.setState({ conveyorBeltPosition: BELT_RIGHT_POSITION }) :
            this.setState({ conveyorBeltPosition: this.state.conveyorBeltPosition - 0.5 });
    }

    waterPlants = (i) => {
        this.setState({ totalWaterVolume: this.state.totalWaterVolume - this.state.waterVolumesPerBox[i] });
    }

    render() {
        const {
            weeks,
            variablesArray,
            totalWaterVolume,
            cubePositions,
            conveyorBeltPosition,
            isAnimationRunning,
            waterVolumesPerBox,
            plantsAreas,
            waterSpray,
            isDataReseted,
            canStart,
            weekId,
            polynomialId
        } = this.state;

        const { isCoeficientsPanelHidden, isVariables_1_panelHidden, isVariables_2_panelHidden } = this.state.inputPanels;

        const weeksArray = [];
        for (const key of Object.keys(weeks)) {
            weeksArray.push({
                id: key,
                values: weeks[key]
            });
        }

        return (
            <div className={classes.SNA15}>
                <ConveyorBelt
                    waterSpray={waterSpray}
                    waterVolumesPerBox={waterVolumesPerBox}
                    plantsAreas={plantsAreas}
                    cubePositions={cubePositions}
                    conveyorBeltPosition={conveyorBeltPosition} />
                <section className={classes.Controls}>
                    <ExBuilder
                        weeks={weeksArray}
                        variablesArray={variablesArray}
                        isAnimationRunning={isAnimationRunning}
                        isDataReseted={isDataReseted}
                        canStart={canStart}
                        start={this.handleStartClick}
                        reset={this.handleResetClick}
                        addPolynomial={(id) => this.addPolynomialHandler(id)}
                        deletePolynomial={(id) => this.deletePolynomialHandler(id)}
                        addWeek={(id) => this.addWeekHandler(id)}
                        deleteWeek={(id) => this.deleteWeekHandler(id)}
                        openCoeficientsPanel={(weekId, polynomialId) => this.openCoeficientsPanelHandler(weekId, polynomialId)}
                        openVariables_1_Panel={(weekId, polynomialId) => this.openVariables_1_PanelHandler(weekId, polynomialId)}
                        openVariables_2_Panel={(weekId, polynomialId) => this.openVariables_2_PanelHandler(weekId, polynomialId)} 
                        incrementValue={(obj) => this.incrementVariableHandler(obj)}
                        decrementValue={(obj) => this.decrementVariableHandler(obj)}/>
                    <div className={classes.InputPanels}>
                        {isCoeficientsPanelHidden ||
                            <Digits
                                setInputBlockDigitValue={(value) => this.setCoeficientValueHandler(value, weekId, polynomialId)}
                                closeDigitsPanel={this.closeDigitsPanelHandler} />
                        }
                        {isVariables_1_panelHidden || <ParametersWithImages
                            values={IMAGES}
                            setInputBlockValue={(value) => this.setVariable_1_ValueHandler(value, weekId, polynomialId)}
                            closeVariables_1_Panel={this.closeVariables_1_PanelHandler} />}
                        {isVariables_2_panelHidden || <ParametersWithSymbols
                            values={['x', 'y', 'z']}
                            setInputBlockValue={(value) => this.setVariable_2_ValueHandler(value, weekId, polynomialId)}
                            closeVariables_2_Panel={this.closeVariables_2_PanelHandler} />}
                    </div> 
                    <WaterTank waterVolume={totalWaterVolume} />
                </section>
            </div>
        );
    }
}

export default SNA15a;
