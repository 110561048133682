import React, { useContext, useReducer } from 'react';
import reducer from '../reducers/SNA19-reducer';
import { randomNum } from '../utils/helpers';
import { PLANET_TRAJECTORIES } from '../data/SNA19data';

const SNA19Context = React.createContext();

export const SNA19Provider = (props) => {
    const initialState = {
        spacecraftSpeed: 0,
        trajectoryIndex: '',
        flightData: '',
        isTimerOn: true,
        isDataReseted: true,
        canStart: false,
        isStarcraftAnimationRunning: false,
        isAnimationSpeedSlowed: false,
        timerAcc: 0,
    };
    const [state, dispatch] = useReducer(reducer, initialState);
    
    const handleStartClick = () => {
        dispatch({ type: 'START' });
    }

    const handleResetClick = () => {
        dispatch({ type: 'RESET' });
    }

    const handleNewVariantClick = () => {
        let random = randomNum(0, PLANET_TRAJECTORIES.length - 1);
        while (random === state.trajectoryIndex) random = randomNum(0, PLANET_TRAJECTORIES.length - 1);
        const flightData =  PLANET_TRAJECTORIES[random];
        dispatch({ 
            type: 'NEW_VARIANT',  
            payload: {flightData, random}
        });
    }

    const setSpacecraftSpeed = (speed) => {
        dispatch({ type: 'SET_SPEED', payload: speed });
    }

    const stopSpacecraft = () => {
        dispatch({ type: 'STOP_SPACECRAFT' }); 
    }

    const adjustAnimationSpeed = () => {
        dispatch({ type: 'ADJUST_SPEED' });
    }

    const setTimerAcc = (delta) => {
        dispatch({ type: 'ADD_DELTA', payload: delta });
    }

    const stopAnimation = () => {
        dispatch({ type: 'STOP_ANIMATION' }); 
    }

    const disableStart = () => {
        dispatch({ type: 'DISABLE_START' }); 
    }

    return (
        <SNA19Context.Provider 
            value={{ 
                ...state, 
                handleStartClick, 
                handleResetClick, 
                handleNewVariantClick, 
                setSpacecraftSpeed,
                stopSpacecraft,
                adjustAnimationSpeed,
                setTimerAcc,
                stopAnimation,
                disableStart
            }}
        >
            {props.children}
        </SNA19Context.Provider>
    );
}

export const useSNA19Context = () => {
    return useContext(SNA19Context);
};
