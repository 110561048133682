import React, { Component } from 'react';
import Input from '../../../../components/UI/Input/Input';
import classes from './LocationForm.module.css';
import Button from '../../../../components/UI/Button/Button';
import { withTranslation } from 'react-i18next';

class EditLocationForm extends Component {
    state = {
        form: {
            location: {
                elementType: 'input',
                elementConfig: {
                    type: 'text',
                },
                value: '',
                validation: {
                    required: true,
                },
                valid: true,
                touched: false,
            },
            population: {
                elementType: 'input',
                elementConfig: {
                    type: 'number',
                },
                value: '',
                validation: {
                    required: true,
                    minPopulation: 1,
                    maxPopulation: 1_500_000_000,
                },
                valid: true,
                touched: false,
            }
        },
        formIsValid: true,
    }

    componentDidUpdate(prevProps) {
        if (this.props.selectedLocation !== prevProps.selectedLocation) {
          this.populateInputs();
        }
    }

    populateInputs = () => {
        const updatedForm = {...this.state.form};
        for(let key in updatedForm) {
            if (key === 'location') updatedForm[key].value = this.props.selectedLocation.location;
            if (key === 'population') updatedForm[key].value = this.props.selectedLocation.population;
        }
    }

    dataHandler = (event) => {
        event.preventDefault();
        const updatedLocation = {...this.props.selectedLocation}
        for (let key in this.state.form) {
            updatedLocation[key] = this.state.form[key].value;
        }
        this.props.formDataCallback(updatedLocation);
    }

    checkValidity = (value, rules) => {
        let isValid = true;
        if (rules.required) {
            isValid = value.trim() !== '' && isValid;
        }
        if (rules.maxPopulation) {
            isValid = (value >= rules.minPopulation && value <= rules.maxPopulation) && isValid;
        }
        return isValid;
    }

    inputChangedHandler = (event, id) => {
        const updatedForm = {...this.state.form};
        const updatedItem = {...updatedForm[id]}; 
        updatedItem.value = event.target.value;  
        updatedItem.valid = this.checkValidity(updatedItem.value, updatedItem.validation); 
        updatedItem.touched = true;
        updatedForm[id] = updatedItem;

        let formIsValid = true;
        for(let id in updatedForm) {
            formIsValid = updatedForm[id].valid && formIsValid;
        }
        this.setState({ form: updatedForm, formIsValid });
    }

    render() { 
        const { formIsValid } = this.state;
        const { t } = this.props;
        const formElementsArray = [];
        for(let key in this.state.form) {
            formElementsArray.push({
                id: key,
                config: this.state.form[key]
            });
        }
        return (
            <form className={classes.LocationForm} onSubmit={this.dataHandler}>
                <div className={classes.Inputs}>
                    {formElementsArray.map((formElement, index) => (
                        <Input
                            labelTextColor='White'
                            key={formElement.id}
                            elementType={formElement.config.elementType}
                            elementConfig={formElement.config.elementConfig}
                            placeholder={t(`SNA12.locationForm.inputs.input-${index + 1}.placeholder`)}
                            value={formElement.config.value}
                            label={t(`SNA12.locationForm.inputs.input-${index + 1}.label`)}
                            invalid={!formElement.config.valid}
                            validation={formElement.config.validation}
                            errorMessage={
                                index === 1 && t('SNA12.error', 
                                    {
                                        minValue: formElement.config.validation.minPopulation, 
                                        maxValue: formElement.config.validation.maxPopulation
                                    }
                                )
                            }
                            touched={formElement.config.touched}
                            changed={(event) => this.inputChangedHandler(event, formElement.id)}/>
                        ))}
                </div>
                <Button 
                    btnType="Success" 
                    style={{width: '100%'}}
                    disabled={!formIsValid}
                >
                    {t('buttons.submit')}
                </Button>
            </form>
        );
    }
}
 
export default withTranslation() (EditLocationForm);
