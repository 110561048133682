import React, { useEffect } from 'react';
import classes from './Info.module.css';
import { useSNA24Context } from '../../../context/SNA24-context';
import { create, all } from 'mathjs';
import { useTranslation, Trans } from 'react-i18next';
import { data } from '../../../data/SNA24data';
import { average } from '../../../utils/helpers';

const MATH = create(all);
const PARSER = MATH.parser();

const Info = () => {
    const { 
        computerIndex,
        expression, 
        isCalculated, 
        expressionVariables, 
        setCalculationTime, 
        calculationTime,
        setCalculationError,
        calculationError,
        setZeroDivisionError,
        zeroError
    } = useSNA24Context();
    const { t } = useTranslation();

    useEffect(()=> {
        if (calculationTime === null && isCalculated && !calculationError && !zeroError) {
            expressionVariables.forEach(({type, value}) => {
                PARSER.evaluate(`${type} = ${value}`)
            });
            const timeValues = [];
            let value;
            try {
                for (let i = 0; i < 10; i++) {
                    const start = performance.now();
                    value = PARSER.evaluate(expression);
                    const end = performance.now();
                    const time = end - start;
                    time && timeValues.push(time);
                }
                (Math.abs(value) === Infinity || isNaN(value)) ? setZeroDivisionError() : setCalculationTime(average(timeValues));
            }
            catch (e) {
                setCalculationError();
            }
        }
    },[
        isCalculated, 
        expression, 
        expressionVariables, 
        setCalculationTime, 
        calculationTime, 
        setCalculationError, 
        setZeroDivisionError,
        calculationError,
        zeroError
    ]);

    const { decade, coeficient } = data[computerIndex];
    
    return (
        <div className={classes.time}>
            <Trans>
                <p style={{'--play-state': isCalculated && ! calculationError && !zeroError ? 'running' : 'paused'}}>
                    {t('SNA24.info.p', { decade, ms: calculationTime !== null ? (calculationTime / coeficient).toFixed(5) : '___' })}
                </p>
            </Trans>
        </div>
    );
}
 
export default Info;
