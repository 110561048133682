import React, { useState } from 'react';
import Input from '../../UI/Input/Input';
import Button from '../../UI/Button/Button';
import { useTranslation } from 'react-i18next';
import { useSNA22Context } from '../../../context/SNA22-context';

const Form = () => {
    const { t } = useTranslation();
    const { setMascotAngle, canShow } = useSNA22Context();

    const [angle, setAngle] = useState({
        elementType: 'input',
        elementConfig: {
            type: 'number',
        },
        value: '',
        validation: {
            minAngle: 0,
            maxAngle: 180
        },
        valid: false,
        touched: false,
    });

    const inputChangedHandler = (event) => {
        setAngle({
            ...angle, 
            value: event.target.value,
            touched: true,
            valid: checkValidity(event.target.value)
        });
    }

    const checkValidity = (value) => {
        let isValid = true;
        isValid = value.trim() !== '' && isValid;
        isValid = (value >= angle.validation.minAngle && value <= angle.validation.maxAngle) && isValid;
        return isValid;
    }

    const formSubmissionHandler = (event) => {
        event.preventDefault();
        setMascotAngle(+angle.value);
    }

    const { elementType, elementConfig, value, validation: { minAngle, maxAngle }, valid, touched } = angle;
    
    return (  
        <form onSubmit={formSubmissionHandler}>
            <Input
                elementType={elementType}
                elementConfig={elementConfig}
                placeholder={t('SNA22.form.placeholder')}
                value={value}
                invalid={!valid}
                touched={touched}
                label={t('SNA22.form.label')}
                errorMessage={t('SNA22.form.error', {minValue: minAngle, maxValue: maxAngle})}
                disabled={canShow}
                changed={inputChangedHandler}
            />
            <Button 
                btnType='Success'
                disabled={!valid || canShow}
            >
                {t('buttons.submit')}
            </Button>
        </form>
    );
}
 
export default Form;
