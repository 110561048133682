import React from 'react';
import classes from './DisplayWithRadioButtons.module.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const displayWithRadioButtons = (props) => (
    <div className={classes.Display} style={props.style}>
        <FontAwesomeIcon className={classes.DisplayIcon} style={{ color: 'rgb(52, 58, 63)' }} icon={props.icon} size="2x" />
        <div className={classes.DisplayValue}>{props.value}</div>
        <div className={classes.SpeedControls}>
            <div className={classes.RadioButtons} onChange={event => props.setStopwatchSpeed(event)}>
                <input type="radio" value="0.75" name="acc"/> 0.25
                <input type="radio" value="0.5" name="acc"/> 0.5
                <input type="radio" value="0" defaultChecked name="acc"/> 1
            </div>
            <div className={classes.DisplayUnit}>{props.unit}</div>
        </div>
    </div>
);

export default displayWithRadioButtons;