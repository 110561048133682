import React, { Component } from 'react';
import IncrementControl from '../../../../components/UI/IncrementControl/IncrementControl';
import Form from '../Form/Form';
import classes from './FormBuilder.module.css';
import AnimatedButton from '../../../../components/UI/Button/AnimatedButton';
import Button from '../../../../components/UI/Button/Button';
import ToggleSwitch from '../../../../components/UI/ToggleSwitch/ToggleSwitch';
import { withTranslation } from 'react-i18next';

class FormBuilder extends Component {
    state = {
        inputValue: 2,
        isToggled: true
    }

    addInputHandler = () => {
        this.setState({inputValue: this.state.inputValue + 1});
    }

    removeInputHandler = () => {
        this.setState({inputValue: this.state.inputValue - 1});
    }

    toggle() {
        this.setState({ isToggled: !this.state.isToggled})      
    }

    render() { 
        const { t } = this.props;
        return (
            <div className={classes.FormBuilder}>
                <div style={{display: "flex", alignItems: "center", justifyContent: "center"}}>
                    <img src="/images/lines_off.png" alt="lines_off" style={{width: "30px", height: "30px", border: "1px solid #aaa"}}/>
                    <ToggleSwitch
                        onToggle={() => {this.props.onToggle(); this.toggle()}}
                        isToggled={this.state.isToggled}
                        sliderColor="SliderWhite"
                        sliderType="Rounded"/>
                    <img src="/images/graphs_on.png" alt="graphs_on" style={{width: "30px", height: "30px", border: "1px solid #aaa"}}/>
                </div>
                <IncrementControl 
                    added={this.addInputHandler}
                    removed={this.removeInputHandler}
                    value={this.state.inputValue}
                    minusDisabled={this.state.inputValue <= 2 ? true : false}
                    plusDisabled={this.state.inputValue >= 10 ? true : false}/>
                <Form 
                    functionAmount={this.state.inputValue}
                    canStart={this.props.canStart} 
                    isDataReseted={this.props.isDataReseted}
                    inputDisabled={this.props.inputDisabled}
                    formDataCallback={(functionsData, pointsData) => this.props.formDataCallback(functionsData, pointsData)}/>
                <div className={classes.Btn}>
                    <AnimatedButton
                        btnType="Danger"
                        style={{width: '50%', marginRight: '5px'}}
                        disabled={!this.props.canStart}
                        clicked={() => this.props.start()}
                    >
                        {this.props.isAnimationRunning ? t('buttons.pause') : t('buttons.start')}
                    </AnimatedButton>
                    <Button 
                        btnType="Primary"
                        style={{width: '50%', marginLeft: '5px'}}
                        clicked={() => this.props.reset()}
                    >
                        {t('buttons.reset')}
                    </Button>
                </div>
            </div>
        );
    }
}
 
export default withTranslation() (FormBuilder);
