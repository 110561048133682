import React, { Fragment, useState, useEffect, useCallback } from 'react';
import classes from './Content.module.css';
import Accordion from '../../UI/Accordion/Accordion';
import GraphingCalculator from '../GraphingCalculator/GraphingCalculator';
import Controls from '../Controls/Controls';
import { useTranslation } from 'react-i18next';
import Dashboard from '../Dashboard/Dashboard';
import Chart from '../Chart/Chart';
import { useSNA19Context } from '../../../context/SNA19-context';
import Notification from '../../UI/Notification/Notification';
import positive_notification from '../../../assets/audio/positive_notification.wav';
import negative_notification from '../../../assets/audio/negative_notification.wav';
import SideDrawer from '../../UI/SideDrawer/SideDrawer';
import QuickStart from '../QuickStart/QuickStart';
import useToggle from '../../../hooks/useToggle';

const successSound = new Audio(positive_notification);
const failSound = new Audio(negative_notification);

const Content = () => {
    const [isOpen, setIsOpen] = useToggle(false);
    const {t} = useTranslation();
    const [distanceAxisData, setDistanceAxisData] = useState([[0, 0]]);
    const [speedAxisData, setSpeedAxisData] = useState([[0, 0]]);
    const [distanceDrawingData, setDistanceDrawingData] = useState([[0, 0]]);
    const [speedDrawingData, setSpeedDrawingData] = useState([[0, 0]]);
    const [notifications, setNotifications] = useState([]);

    const { 
        flightData: {
            spacecraftFlightDistance: distance, 
            startPointTimerValue: start, 
            finishPointTimerValue: finish,
            planetOrbitDistance,
            destinationPlanetOrbitalSpeed,
            chartValues,
            correctSpeed
        },
        isStarcraftAnimationRunning,
        spacecraftSpeed,
        timerAcc,
        isTimerOn,
        stopAnimation,
        disableStart,
        canStart
    } = useSNA19Context();

    const getNotification = useCallback((identifier) => {
        if (identifier === 'SUCCESS') {
            return {
                id: 1,
                type: 'Success',
                title: t('SNA19.notifications.titles.success'),
                description: t('SNA19.notifications.descriptions.success')
            }
        }
        if (identifier === 'DANGER') {
            return {
                id: 2,
                type: 'Danger',
                title: t('SNA19.notifications.titles.fail'),
                description: t('SNA19.notifications.descriptions.fail')
            }
        }
    }, [t]);

    const deleteNotificationHandler = useCallback(() => {
        setNotifications([]);
    }, []);

    const playSound = (identifier) => {
        identifier === 'SUCCESS' ? successSound.play() : failSound.play();
    }
    
    useEffect(() => {
        setDistanceAxisData([[0, 0], [chartValues?.tMax, chartValues?.sMax]]);
        setSpeedAxisData([[0, 0], [chartValues?.tMax, chartValues?.vMax]]);
    }, [chartValues]);

    useEffect(() => {
        const totalTime = planetOrbitDistance / destinationPlanetOrbitalSpeed / 3600;
        const traveledTime = +((totalTime / (finish - start)) * timerAcc).toFixed(2);
        const traveledDistance = isStarcraftAnimationRunning && (distance / (finish - start)) * timerAcc;
        if (isStarcraftAnimationRunning) {
            setDistanceDrawingData([[0, 0], [traveledTime, traveledDistance], [0, 0]]);
            setSpeedDrawingData([[0, spacecraftSpeed], [traveledTime, spacecraftSpeed]]);
        }
        if (!isStarcraftAnimationRunning && isTimerOn) {
            setDistanceDrawingData([[0, 0], [totalTime, distance], [traveledTime, distance]]);
            setSpeedDrawingData([[0, spacecraftSpeed], [totalTime, spacecraftSpeed], [totalTime, 0], [traveledTime, 0]]);
        }
        if ((traveledTime > chartValues?.tMax || traveledDistance > chartValues?.sMax) && isTimerOn) {
            stopAnimation();
        }
        if (traveledTime >= totalTime && correctSpeed === spacecraftSpeed && !notifications.length && canStart) {
            disableStart();
            setNotifications([getNotification('SUCCESS')]);
            playSound('SUCCESS');
        }
        if (traveledTime >= totalTime && correctSpeed !== spacecraftSpeed && !notifications.length && canStart) {
            disableStart();
            setNotifications([getNotification('DANGER')]);
            playSound('DANGER');
        }
    }, [isStarcraftAnimationRunning, 
        spacecraftSpeed,
        timerAcc, 
        destinationPlanetOrbitalSpeed, 
        planetOrbitDistance, 
        distance, 
        start, 
        finish,
        isTimerOn, 
        chartValues, 
        stopAnimation,
        correctSpeed, 
        canStart,
        disableStart,
        notifications,
        getNotification]
    );

    const openSideDrawerHandler = () => {
        setIsOpen(true);
    }

    const closeSideDrawerHandler = () => {
        setIsOpen(false);
    }

    return (
        <Fragment>
            <main className={classes.wrapper}>
                <section className={classes.indicators}>
                    <Accordion 
                        title={t('SNA19.accordions.a1')}
                        showContentOutside={spacecraftSpeed}
                    >
                        <Chart 
                            labels={{x: 't', y: 'S'}}
                            axisData={distanceAxisData}
                            drawingData={distanceDrawingData} 
                        />
                    </Accordion>
                    <Accordion 
                        title={t('SNA19.accordions.a2')}
                        showContentOutside={spacecraftSpeed}
                    >
                        <Chart 
                            labels={{x: 't', y: 'v'}}
                            axisData={speedAxisData}
                            drawingData={speedDrawingData} 
                        />
                    </Accordion>
                    <Dashboard />
                </section>
                <GraphingCalculator />
                <Controls />
            </main>
            <Notification 
                notifications={notifications}
                notificationDelay={10000}
                deleteNotification={deleteNotificationHandler}/>
            <SideDrawer
                isSideDrawerOpen={isOpen}
                openSideDrawer={openSideDrawerHandler}
                closeSideDrawer={closeSideDrawerHandler}
            >
                <QuickStart />
            </SideDrawer>
        </Fragment>
    );
}
 
export default Content;
