import React, { Fragment } from 'react';
import Accordion from '../../UI/Accordion/Accordion';
import classes from './QuickStart.module.css';

const QuickStartC = () => {
    const exp = <span className={classes.expression}>y=2.5x-12.5</span>

    return (  
        <Fragment>
            <Accordion title={'Mida me teeme selles programmis?'}>
                <p className={classes.paragraph}>Tänapäeva teatri- või kontserdilaval on palju erinevaid valgusteid. Paljud neist peavad töötama kindlate algoritmide järgi. See programm näitab sulle, kuidas juhitakse selliseid valgustusi. Saad kasutada oma teadmisi sirge võrrandi leidmisel.</p>
            </Accordion>
            <Accordion title={'Kelle tööd see programm tutvustab?'}>
                <p className={classes.paragraph}>Inimesi, kes töötavad teatri-, kontserdi- ja näitustesaalides valgustitega kutsutakse valgusinsenerideks või valgustehnikuteks. Insenerid tihti loovad uusi seadmeid ja uusi algoritme. Nendel on tavaliselt kõrgharidus. Tehnikud tavaliselt kasutavad, häälestavad ja hooldavad olemasolevaid seadmeid ja seadistavad neid etendusteks. Tehnikutel võib olla nii kõrg- kui ka keskeriharidus.</p>
            </Accordion>
            <Accordion title={'Kuidas programmi kasutada?'}>
            <div className={classes.step}>
                    <span className={classes.numeration}>1.</span>
                    <p className={classes.paragraph}>Leia õpetaja ülesande järgi leitud sirge võrrand programmi (näiteks {exp}). Seejärel vajuta "OK" ja "START".</p>
                </div>
                <div className={classes.step}>
                    <span className={classes.numeration}>2.</span>
                    <p className={classes.paragraph}>Vaata, kas valgustuse käitumine vastab õpetaja poolt antud algoritmile. Uue funktsiooni sisestamiseks vajuta "RESET".</p>
                </div>
            </Accordion>
        </Fragment>
    );
}
 
export default QuickStartC;
