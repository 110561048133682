const SNA25_reducer = (state, action) => {
    if (action.type === 'NEW_TASK') {
        return { ...state, currentData: action.payload.currentData };
    }
    if (action.type === 'TOGGLE') {
        return { 
            ...state,
            isParabolaHidden: state.isParabolaHidden ? false : true
        };
    }
    if (action.type === 'COORDS') {
        return { ...state, crossMarkCoords: action.payload.coords, crossMarkIsValid: action.payload.valid };
    }
    if (action.type === 'FORM') {
        return { 
            ...state, 
            formData: action.payload.data, 
            submitterName: action.payload.submitterName,
            isDataReseted: false
        };
    }
    if (action.type === 'RESET') {
        return { 
            ...state, 
            crossMarkCoords: { x: null, y: null },
            crossMarkIsValid: false,
            formData: null,
            submitterName: '',
            isDataReseted: true,
            isParabolaHidden: true
        };
    }
}

export default SNA25_reducer;
