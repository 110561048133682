import React from 'react';
import ToggleSwitch from '../../UI/ToggleSwitch/ToggleSwitch';
import Form from '../../../containers/common_containers/SNA-2-7-8/Form/Form';
import Button from '../../UI/Button/Button';
import classes from './Controls.module.css';
import { useTranslation } from "react-i18next";
import { IoDocuments, IoPlay, IoPause, IoReload } from "react-icons/io5";
import { randomNum } from '../../../utils/helpers';

const Controls = (props) => {
    const { t } = useTranslation();
    
    const handleNewVariantClick = (min, max) => {
        let random = randomNum(min, max);
        while (props.index === random) {
            random = randomNum(min, max);
        }
        props.setTriangle(random);
    }

    return (
        <div className={classes.Controls}>
            <div className={classes.Control} style={props.style}>
                <img src="/images/knee/knee_50x50.png" alt="knee_skin" style={{ margin: "0 10px", border: "1px solid #ccc", borderRadius: "5px" }} />
                <ToggleSwitch
                    isToggled={props.transparent}
                    onToggle={() => props.changeImage()} />
                <img src="/images/knee/knee_transparent_50x50.png" alt="knee_transparent" style={{ margin: "0 10px", border: "1px solid #ccc", borderRadius: "5px" }} />
            </div>
            <div className={classes.Control}>
                <Button 
                    btnType='Info'
                    disabled={props.isNewTaskDisabled}
                    clicked={() => handleNewVariantClick(0, props.randomMax - 1)}
                >
                    <IoDocuments/> {t('buttons.new-variant')}
                </Button>
            </div>
            <div className={classes.Control}>
                <Form
                    formDataCallback={hypotenuse => props.formDataCallback(hypotenuse)}
                    inputDisabled={props.isDataReseted}
                    hypotenuse={props.hypotenuse}
                    minValue={props.minValue}
                    maxValue={props.maxValue}
                    index={props.index}
                    canStart={props.canStart}
                    beforeInputText={props.beforeInputText} />
            </div>
            <div className={classes.Control}>
                <Button
                    clicked={() => props.start()}
                    disabled={props.index === null || !props.isDataReseted || !props.canStart}
                    btnType="Danger"
                    style={{ margin: "10px" }}
                >
                    {props.isAnimationRunning ? <IoPause/> : <IoPlay/>} {props.isAnimationRunning ? t('buttons.pause') : t('buttons.start')}
                </Button>
                <Button
                    clicked={() => props.reset()}
                    btnType="Primary"
                    style={{ margin: "10px"}} 
                >
                    <IoReload/> {t('buttons.reset')}
                </Button>
            </div>
        </div>
    );
}

export default Controls;
