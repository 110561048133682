import React from 'react';
import classes from './Vehicle.module.css';

const vehicle = React.forwardRef((props, ref) => {
    return (
        <div 
            className={classes.Vehicle} 
            style={props.position} 
            ref={ref} />
    )
});

export default vehicle;
