import React, { Component, Fragment } from 'react';
import Input from '../../../../components/UI/Input/Input';
import Button from '../../../../components/UI/Button/Button';
import { create, all } from 'mathjs';
import { withTranslation } from 'react-i18next';

const MATH = create(all);
const PARSER = MATH.parser();
const REGEX_FLOAT = /[+-]?\d+(\.\d+)?/g;

const REGEX_LINEAR = /^y=[+-]?([0-9]*[.])?[0-9]+x[+-]([0-9]*[.])?[0-9]+$/;       // y=kx+b
const REGEX_LINEAR_SHORT = /^y=[+-]?([0-9]*[.])?[0-9]+x$/;                       // y=kx
//const REGEX_X_FUNCTION = /^x=[+-]?([0-9]*[.])?[0-9]+$/;                        // x=k
const REGEX_Y_FUNCTION = /^y=[+-]?([0-9]*[.])?[0-9]+$/;                          // y=k
const REGEX_X_Y = /^y=[+-]?x$/;                                                  // y=x
const REGEX_X_Y_F = /^y=[+-]?x[+-]?([0-9]*[.])?[0-9]+$/;                         // y=x+b
const REGEX_POINT = /^[(][+-]?([0-9]*[.])?[0-9]+[,][+-]?([0-9]*[.])?[0-9]+[)]$/; // (x,y)

class Form extends Component {
    state = {
        functions: [
            {
                elementType: 'input',
                elementConfig: {
                    type: 'text',
                    placeholder: 'y=ax+b'
                },
                value: '', 
                validation: {
                    required: true,
                    linearFunction: 1,
                    linearFunctionShort: 1,
                    xFunction: 1,
                    yFunction: 1,
                },
                valid: false, 
                touched: false
            },
            {
                elementType: 'input',
                elementConfig: {
                    type: 'text',
                    placeholder: 'y=ax+b'
                },
                value: '',
                validation: {
                    required: true,
                    linearFunction: 1,
                    linearFunctionShort: 1,
                    xFunction: 1,
                    yFunction: 1,
                },
                valid: false,
                touched: false
            },
        ],
        points: [
            {
                elementType: 'input',
                elementConfig: {
                    type: 'text',
                    placeholder: '(x,y)'
                },
                value: '',
                label: 'Start', 
                validation: {
                    required: true,
                    point: 1,
                    belongsToFunction: false,
                    errorMessage: '(x,y)',
                },
                valid: false, 
                touched: false
            },
            {
                elementType: 'input',
                elementConfig: {
                    type: 'text',
                    placeholder: '(x,y)'
                },
                value: '', 
                label: 'Stop', 
                validation: {
                    required: true,
                    point: 1,
                    belongsToFunction: false,
                    errorMessage: '(x,y)',
                },
                valid: false, 
                touched: false
            },
        ],
        formIsValid: false
    }

    componentDidUpdate() {
        if (this.props.functionAmount > this.state.functions.length) {
            this.addFunction();
        }  
        if (this.props.functionAmount < this.state.functions.length) {
            this.removeFunction();
        } 
    }

    addFunction() {
        this.setState(({ functions }) => ({ 
            functions: [
                ...functions, 
                {
                    elementType: 'input',
                    elementConfig: {
                        type: 'text',
                        placeholder: 'y=ax+b'
                    },
                    value: '',
                    validation: {
                        required: true,
                        linearFunction: 1,
                        linearFunctionShort: 1,
                        xFunction: 1,
                        yFunction: 1,
                    },
                    valid: false,
                    touched: false
                }
            ],
            formIsValid: false,
            pointsIsValid: false,
            functionsIsValid: false,
        }));
    }

    removeFunction() {
        let updatedFunctions = this.state.functions;
        updatedFunctions.pop();
        let functionsIsValid = true;
        updatedFunctions.forEach(func => functionsIsValid = func.valid && functionsIsValid);
        this.setState({functions: updatedFunctions, functionsIsValid}, () => this.checkFormValidity(this.state.functions.length - 1, 'func'))
    }

    checkValidity = (value, rules) => {
        let isValid = false;
        if (rules.required) {
            isValid = value.trim() !== '';
        }
       
        if (rules.linearFunction) {
            isValid = REGEX_LINEAR.test(value.trim()) || 
                        REGEX_LINEAR_SHORT.test(value.trim()) ||  
                        REGEX_Y_FUNCTION.test(value.trim()) || 
                        REGEX_X_Y_F.test(value.trim()) ||
                        REGEX_X_Y.test(value.trim());
        }
        if (rules.point) {
            isValid = REGEX_POINT.test(value.trim());
        }
        return isValid;
    }

    // Checks if point belongs to the function and overall form validity
    checkFormValidity(index, str) {
        if(((index === 0 || index === 1) && str === 'point') || ((index === 0 || index === this.state.functions.length - 1) && str === 'func')) {
            if(this.state.functions[index === 0 ? 0 : this.state.functions.length - 1].valid && this.state.points[index === 0 ? 0 : 1].valid) {
                const coordinatesArray = this.state.points[index === 0 ? 0 : 1].value.slice(1, -1).split(','); // string type
                // Check if point belongs to function
                // x = b 
                if(this.state.functions[index === 0 ? 0 : this.state.functions.length - 1].value.indexOf('y') === -1) {
                    const x = parseFloat(this.state.functions[index === 0 ? 0 : this.state.functions.length - 1].value.match(REGEX_FLOAT));
                    const updatedPoints = [
                        ...this.state.points
                    ];
                    const updatedPoint = {
                        ...updatedPoints[index === 0 ? 0 : 1]
                    };
                    updatedPoint.validation.belongsToFunction = x === parseFloat(coordinatesArray[0]);
                    this.setState({points: updatedPoints});
                }       
                // y = b
                if(this.state.functions[index === 0 ? 0 : this.state.functions.length - 1].value.indexOf('x') === -1) {
                    const y = parseFloat(this.state.functions[index === 0 ? 0 : this.state.functions.length - 1].value.match(REGEX_FLOAT));
                    const updatedPoints = [
                        ...this.state.points
                    ];
                    const updatedPoint = {
                        ...updatedPoints[index === 0 ? 0 : 1]
                    };
                    updatedPoint.validation.belongsToFunction = y === parseFloat(coordinatesArray[1]);
                    this.setState({points: updatedPoints});
                }
                // y = kx + b
                if(this.state.functions[index === 0 ? 0 : this.state.functions.length - 1].value.indexOf('x') !== -1 && this.state.functions[index === 0 ? 0 : this.state.functions.length - 1].value.indexOf('y') !== -1) {
                    PARSER.evaluate('x = ' + parseFloat(coordinatesArray[0]));
                    
                    if (parseFloat(this.state.functions[index === 0 ? 0 : this.state.functions.length - 1].value.match(REGEX_FLOAT)) !== 0) { // for case if user enter y = 0x (mathjs throws exception)
                        PARSER.evaluate(this.state.functions[index === 0 ? 0 : this.state.functions.length - 1].value);
                        const y = PARSER.evaluate('y');
                        const updatedPoints = [
                         ...this.state.points
                        ];
                        const updatedPoint = {
                            ...updatedPoints[index === 0 ? 0 : 1]
                        };
                        updatedPoint.validation.belongsToFunction = Number(y.toPrecision(4)) === parseFloat(coordinatesArray[1]);  // toPrecision for avoiding values like 1.999999999 from Math.js
                        this.setState({points: updatedPoints});
                    } 
                    else if (parseFloat(this.state.functions[index === 0 ? 0 : this.state.functions.length - 1].value.match(REGEX_FLOAT)) === 0) { // if user enter y = 0x or y = 0x + b function
                        const func = this.state.functions[index === 0 ? 0 : this.state.functions.length - 1].value;
                        let modifiedFunction =  null;
                        
                        if (func.includes('0x+')) {
                            modifiedFunction = func.split('0x+').join('');
                        }
                        else if (func.includes('0x-')) {
                            modifiedFunction = func.split('0x').join('');
                        }
                        else if (func.includes('0x')) {
                            modifiedFunction = func.slice(0, -1);
                        }

                        const updatedFunctions = [...this.state.functions];
                        const updatedFunction = {...updatedFunctions[index === 0 ? 0 : this.state.functions.length - 1]};
                        updatedFunction.value = modifiedFunction;
                        updatedFunctions[index === 0 ? 0 : this.state.functions.length - 1] = updatedFunction;

                        const y = parseFloat(modifiedFunction.match(REGEX_FLOAT));
                        const updatedPoints = [
                            ...this.state.points
                        ];
                        const updatedPoint = {
                            ...updatedPoints[index === 0 ? 0 : 1]
                        };
                        updatedPoint.validation.belongsToFunction = y === parseFloat(coordinatesArray[1]);
                        
                        this.setState({points: updatedPoints, functions: updatedFunctions });
                    } 
                }
            }
        }
        this.setState({formIsValid: this.state.functionsIsValid && this.state.points[0].valid && this.state.points[1].valid && this.state.points[0].validation.belongsToFunction && this.state.points[1].validation.belongsToFunction});
    }

    functionInputChangedHandler = (event, index) => {
        const updatedFunctions = [
            ...this.state.functions
        ];
        const updatedFunction = {
            ...updatedFunctions[index]
        };
        updatedFunction.value = event.target.value;
        updatedFunction.valid = this.checkValidity(updatedFunction.value, updatedFunction.validation);
        updatedFunction.touched = true;
        updatedFunctions[index] = updatedFunction;

        let functionsIsValid = true;
        updatedFunctions.forEach(func => functionsIsValid = func.valid && functionsIsValid);

        this.setState({functions: updatedFunctions, functionsIsValid}, () => this.checkFormValidity(index, 'func'));
    }

    pointInputChangedHandler = (event, index) => {
        const updatedPoints = [
            ...this.state.points
        ];
        const updatedPoint = {
            ...updatedPoints[index]
        };
        updatedPoint.value = event.target.value;
        updatedPoint.valid = this.checkValidity(updatedPoint.value, updatedPoint.validation);
        updatedPoint.touched = true;
        updatedPoints[index] = updatedPoint;

        let pointsIsValid = true;
        updatedPoints.forEach(point => pointsIsValid = point.valid && pointsIsValid);
        this.setState({points: updatedPoints, pointsIsValid}, () => this.checkFormValidity(index, 'point'));
    }

    dataHandler = (event) => {
        event.preventDefault();
        const functionsData = [
            ...this.state.functions
        ];
        const pointsData = [
            ...this.state.points
        ];
        this.props.formDataCallback(functionsData, pointsData);
    }

    render() { 
        const { t } = this.props;
        return (
            <Fragment>
                <form onSubmit={this.dataHandler}>
                    {this.state.functions.map((func, index) => (
                        <Input 
                            key={index}
                            elementType={func.elementType}
                            elementConfig={func.elementConfig}
                            value={func.value}
                            invalid={!func.valid}
                            validation={func.validation}
                            errorMessage={t('SNA3.form.function-input-error')}
                            touched={func.touched}
                            disabled={this.props.inputDisabled}
                            changed={(event) => this.functionInputChangedHandler(event, index)}/>
                    ))}
                    {this.state.points.map((point, index) => (
                        <Input 
                            key={index}
                            elementType={point.elementType}
                            elementConfig={point.elementConfig}
                            value={point.value}
                            label={index === 0 ? t('SNA3.form.point-label-1') : t('SNA3.form.point-label-2')}
                            invalid={!point.valid}
                            belongsToFunction={!point.validation.belongsToFunction}
                            notBelongToFunctionErrorMessage={index === 0 ? t('SNA3.form.start-point-input-error') : t('SNA3.form.stop-point-input-error')}
                            validation={point.validation}
                            touched={point.touched}
                            disabled={this.props.inputDisabled}
                            changed={(event) => this.pointInputChangedHandler(event, index)}/>
                    ))}
                <Button 
                    btnType='Success'
                    style={{width: '100%', marginBottom: '15px'}}
                    disabled={!(this.state.formIsValid && !this.props.canStart && this.props.isDataReseted)}
                >
                    {t('buttons.submit')}
                </Button>
                </form>
            </Fragment>
        );
    }
}
 
export default withTranslation() (Form);
