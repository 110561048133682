import React from 'react';
import classes from './WaterTank.module.css';
import { useTranslation } from 'react-i18next';

const WaterTank = ({waterVolume, waterIsVisible = true}) => {
    const { t } = useTranslation();
    return (
        <div 
            className={classes.WaterTank}
            style={{'--waterVolume': waterIsVisible ? waterVolume : 0}}
        >
            {`${(waterVolume).toFixed(3)} ${t('SNA15.units.liter')}`}
        </div>
    );
}
 
export default WaterTank;
