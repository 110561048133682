import React from 'react';
import classes from './AmountControl.module.css';
import { FaPlus, FaMinus } from 'react-icons/fa';

const AmountControl = ({ 
    change, 
    increase,
    decrease,
    minusDisabled, 
    plussDisabled, 
    value,
    column = false
}) => {
    return (  
        <div className={`${classes.wrapper} ${column && classes.column}`}>
            <button 
                className={classes.minus}
                onClick={decrease} 
                disabled={minusDisabled}
            >
                <FaMinus />
            </button>
            <input
                type='number' 
                value={value}
                onChange={change}
            />
            <button 
                className={classes.plus}
                onClick={increase} 
                disabled={plussDisabled}
            >
                <FaPlus />
            </button>
        </div>
    );
}
 
export default AmountControl;
