import React, { Component } from 'react';
import classes from '../SNA6.module.css';
import Controls from '../../../components/SNA6/common_components/Controls/Controls';
import Form from './Form/Form';
import Des from 'desmos';
import { calcAppendScript } from '../../../components/calcAppendScript';
import GraphingCalculator from '../../../components/SNA6/common_components/GraphingCalculator/GraphingCalculator';
import { create, all } from 'mathjs';
import { withTranslation } from 'react-i18next';

let calculator;
const ASTEROID_INITIAL_POSITION = -200;  // x coordinate

const ASTEROID_SPEED = 20; // Need to fix, not speed but derivative accuracy coefficient
const SPEED_PROPORTION = 13.78;  // Need to fix, not speed but derivative accuracy coefficient

const TIMER_DELAY = 25;
const HOUR_DISTANCE = 36000; // km per 1 hour

const MATH = create(all);
const PARSER = MATH.parser();

class SNA6a extends Component {
    state = {
        settings: {
            autosize: true,  
            settingsMenu: false, 
            zoomButtons: false, 
            lockViewport: false,
            expressionsCollapsed: true,
            projectorMode: true, 
            expressions: false,
        },
        mathBounds: {
            left: -350,
            right: 350,
            bottom: -50,
            top: 650
        },
        stopWatch: {
            isTimerOn: false,
        },
        explorer: {
            explorerStartPoint: null,
            explorerDeltaPoint: null,
            explorerFinishPoint: 100,
            explorerPathParabola: 'f(x)=x^2', 
            explorerPathLength: 0,
            explorerSpeed: 0, 
            speedIsCorrect: false
        },
        asteroid: {
            asteroidStartPoint: ASTEROID_INITIAL_POSITION,
            asteroidDeltaPoint: ASTEROID_INITIAL_POSITION,
            asteroidFinishPoint: 100,
            asteroidPathParabola: 'g(x)=-0.015x^2+600',
            asteroidPathLength: null
        },
        earthCircleSettings: { // (x-center_x)^2+(y-center_y)^2=radius^2
            center_x: 0,              // moves circle left/right           
            center_y: 5,              // moves circle up/down
            radius: 5                 // circle radius
        },
        canStart: false,
        isDataReseted: true,
        isCoordsDataReseted: true,
    }

    componentDidMount() {
        calcAppendScript();
        const elt = document.getElementById('graphCalcId1');
        calculator = Des.GraphingCalculator(elt, this.state.settings);
        calculator.setMathBounds(this.state.mathBounds);
        this.setArcsLengthValues();
    }

    setArcsLengthValues() {
        this.setInitialCalcValues();

        const arcsLengthValues = {};
        calculator.observe('expressionAnalysis', () => {
            const expressions = calculator.getState().expressions.list;
            let analysis = null;
            for (let i = 0; i < expressions.length; i++) {
                if (expressions[i].id.startsWith('l')) {
                    analysis = calculator.expressionAnalysis[expressions[i].id];
                    const key = expressions[i].id.split('l_{').join('').slice(0, -1);
                    arcsLengthValues[key] = analysis.evaluation.value;
                }
            } 
            this.setState({ 
                explorer: { 
                    ...this.state.explorer, 
                    explorerPathLength: arcsLengthValues.explorer
                }, 
                asteroid: { 
                    ...this.state.asteroid, 
                    asteroidPathLength: arcsLengthValues.asteroid
                },
            });
        });
    }

    setInitialCalcValues() {
        calculator.setExpression({ 
            id: 'explorer', 
            latex: 'f(x)=0.1x^2+2x+2',   
        });
        
        calculator.setExpression({ 
            id: 'asteroid', 
            latex: this.state.asteroid.asteroidPathParabola,   
        });

        calculator.setExpression({ 
            id: 'l_{explorer}', 
            latex: 'l=\\int_{a}^{b}\\sqrt{1+\\left(\\frac{d}{dx}\\left(f\\left(x\\right)\\right)\\right)^{2}}dx', 
        });
        calculator.setExpression({ 
            id: 'a',                                    
            latex: `a=${0}`, 
        });
        calculator.setExpression({ 
            id: 'b', 
            latex: `b=${0}`, 
        });

        calculator.setExpression({ 
            id: 'l_{asteroid}', 
            latex: 'l=\\int_{c}^{d}\\sqrt{1+\\left(\\frac{d}{dx}\\left(g\\left(x\\right)\\right)\\right)^{2}}dx', 
        });
        calculator.setExpression({ 
            id: 'c',                                    
            latex: `c=${this.state.asteroid.asteroidStartPoint}`, 
        });
        calculator.setExpression({ 
            id: 'd', 
            latex: `d=${this.state.asteroid.asteroidStartPoint}`, 
        });
    }

    // When form data recieved
    changeCalcValues() {
        calculator.setExpression({ 
            id: 'explorer', 
            latex: this.state.explorer.explorerPathParabola,   
        });
        calculator.setExpression({ 
            id: 'a',                                    
            latex: `a=${this.state.explorer.explorerStartPoint}`, 
        });
        calculator.setExpression({ 
            id: 'b', 
            latex: `b=${this.state.explorer.explorerFinishPoint}`, 
        });
        calculator.setExpression({ 
            id: 'd', 
            latex: `d=${this.state.explorer.explorerFinishPoint}`, 
        });
    }

    getParabolaData({explorerPath}) {
        this.setState({
            explorer: { 
                ...this.state.explorer, 
                explorerPathParabola: explorerPath, 
            },
        });
    }

    getCoordsAndTrajectoryData({startPoint, explorerPath, finishPoint}) {
        this.setState({
            explorer: { 
                ...this.state.explorer, 
                explorerStartPoint: startPoint, 
                explorerDeltaPoint: startPoint, 
                explorerPathParabola: explorerPath, 
                explorerFinishPoint: finishPoint,
            },
            isCoordsDataReseted: false
        }, () => this.changeCalcValues());
    }

    getFormData = ({speed}) => {
        const hours = Math.trunc(this.state.asteroid.asteroidPathLength * 1000 / HOUR_DISTANCE);
        const minutes = Math.trunc(((this.state.asteroid.asteroidPathLength * 1000 / HOUR_DISTANCE) % 1).toFixed(2).slice(2) / 99 * 60);
        const distance = +(this.state.explorer.explorerPathLength * 1000).toFixed(0);
        const correctSpeed = +(distance / (hours * 3600 + minutes * 60)).toFixed(1);
        this.setState({ 
            explorer: { 
                ...this.state.explorer, 
                explorerSpeed: speed,
                speedIsCorrect: correctSpeed === speed
            },
            canStart: true,
            isDataReseted: false, 
        });
    }

    handleStartClick = () => {
        this.state.stopWatch.isTimerOn ? this.stopTimer() : this.startTimer();
        this.getValueOfNextPoint();
    }

    handleResetClick = () => {
        clearInterval(this.timer);
        this.setInitialCalcValues();
        this.setState({ 
            stopWatch: { ...this.state.stopWatch, isTimerOn: false },
            explorer: {
                ...this.state.explorer, 
                explorerStartPoint: null,
                explorerDeltaPoint: null,
                explorerFinishPoint: 100,
                explorerPathParabola: null, 
                explorerPathLength: 0,
                explorerSpeed: 0,
                 
                },
            asteroid: {
                ...this.state.asteroid, 
                asteroidStartPoint: ASTEROID_INITIAL_POSITION,
                asteroidDeltaPoint: ASTEROID_INITIAL_POSITION,
                asteroidFinishPoint: 100,
                asteroidPathLength: null 
            },
            canStart: false,
            isDataReseted: true,
            isCoordsDataReseted: true,
        });
    }

    getValueOfNextPoint() {
        const { explorerDeltaPoint, explorerPathParabola, explorerSpeed } = this.state.explorer;
        const { asteroidDeltaPoint, asteroidPathParabola } = this.state.asteroid;
        
        PARSER.set('d', MATH.derivative(explorerPathParabola, 'x').evaluate({x: explorerDeltaPoint}));
        const explorerDeltaX = PARSER.evaluate(`1/sqrt(1+d^2)*${explorerSpeed * 2 / SPEED_PROPORTION}`);
        PARSER.set('d', MATH.derivative(asteroidPathParabola, 'x').evaluate({x: asteroidDeltaPoint}));
        const asteroidDeltaX = PARSER.evaluate(`1/sqrt(1+d^2)*${ASTEROID_SPEED / SPEED_PROPORTION}`);
        this.setState({ 
            explorer: { ...this.state.explorer, explorerDeltaPoint: (explorerDeltaPoint + explorerDeltaX) },
            asteroid: { ...this.state.asteroid, asteroidDeltaPoint: (asteroidDeltaPoint + asteroidDeltaX) }
        });
    }

    startTimer = () => {
        this.setState({ stopWatch: { ...this.state.stopWatch, isTimerOn: true } });

        this.timer = setInterval(() => {
            this.getValueOfNextPoint();
            this.checkBounds();
        }, TIMER_DELAY);
    }

    checkBounds() {
        const { explorerDeltaPoint, explorerFinishPoint, speedIsCorrect } = this.state.explorer;
        const { asteroidDeltaPoint } = this.state.asteroid;
        if ((explorerDeltaPoint >= explorerFinishPoint || asteroidDeltaPoint >= explorerFinishPoint) && speedIsCorrect) {
            // for small visual correction of positions when explorer and asteroid stops at intersection point
            this.setState({
                explorer: {
                    ...this.state.explorer,
                    explorerDeltaPoint: explorerFinishPoint
                },
                asteroid: {
                    ...this.state.asteroid,
                    asteroidDeltaPoint: explorerFinishPoint
                },
                canStart: false,
            });
            this.stopTimer();
        }
    }

    stopTimer = () => {
        this.setState({ stopWatch: {...this.state.stopWatch, isTimerOn: false } });
        clearInterval(this.timer);
    }

    componentWillUnmount() {
        calculator.destroy();
        clearInterval(this.timer);
    }

    render() { 
        const { t } = this.props;
        const { isTimerOn } = this.state.stopWatch;
        const { settings, mathBounds, isDataReseted, isCoordsDataReseted, canStart } = this.state;
        const { explorerPathParabola, explorerDeltaPoint, explorerFinishPoint, explorerStartPoint, explorerPathLength, speedIsCorrect } = this.state.explorer;
        const { asteroidPathParabola, asteroidDeltaPoint, asteroidStartPoint, asteroidPathLength } = this.state.asteroid;
        const { center_x, center_y, radius } = this.state.earthCircleSettings;
        const hours = explorerPathLength === 0 ? `_____` : Math.trunc(asteroidPathLength * 1000 / HOUR_DISTANCE);
        const minutes = explorerPathLength === 0 ? `_____` : Math.trunc(((asteroidPathLength * 1000 / HOUR_DISTANCE) % 1).toFixed(2).slice(2)/99*60);
        const distance = explorerPathLength === 0 ? `_____` : (explorerPathLength * 1000).toFixed(0);
        const landing = (explorerDeltaPoint < explorerFinishPoint && asteroidDeltaPoint < explorerFinishPoint) ? 
            <span>____</span> : (explorerDeltaPoint >= explorerFinishPoint || asteroidDeltaPoint >= explorerFinishPoint) && speedIsCorrect ? 
            <span className={classes.Success}>{t('SNA6.info.p3.part-2.success')}</span> : <span className={classes.Fail}>{t('SNA6.info.p3.part-2.fail')}</span>;
        
        return (
            <div className={classes.SNA6}>
                <div>
                    <GraphingCalculator 
                        settings={settings} 
                        mathBounds={mathBounds}
                        explorerPathParabola={explorerPathParabola}
                        asteroidPathParabola={asteroidPathParabola}
                        explorerDeltaPoint={explorerDeltaPoint}
                        asteroidDeltaPoint={asteroidDeltaPoint}
                        explorerFinishPoint={explorerFinishPoint}
                        asteroidStartPoint={asteroidStartPoint}
                        explorerStartPoint={explorerStartPoint}
                        asteroidPathLength={(asteroidPathLength * 1000).toFixed(0)}
                        explorerPathLength={(explorerPathLength * 1000).toFixed(0)}
                        isDataReseted={isDataReseted}
                        h={center_x}
                        k={center_y}
                        r={radius}/>
                    <div className={classes.Info}>
                        <p>{t('SNA6.info.p1', {hours, minutes})}</p>
                        <p>{t('SNA6.info.p2', {distance})}</p>
                        <p>{t('SNA6.info.p3.part-1') + ' '}<span>{landing}</span></p>
                    </div>
                </div>
                <Controls
                    isTimerOn={isTimerOn}
                    isDataReseted={isDataReseted}
                    isCoordsDataReseted={isCoordsDataReseted}
                    canStart={canStart}
                    start={this.handleStartClick}
                    reset={this.handleResetClick}>
                    <Form 
                        isDataReseted={isDataReseted}
                        isCoordsDataReseted={isCoordsDataReseted}
                        parabolaData={parabola => this.getParabolaData(parabola)}
                        coordsAndTrajectoryData={coordsAndTrajectoryData => this.getCoordsAndTrajectoryData(coordsAndTrajectoryData)}
                        formData={formData => this.getFormData(formData)}/>
                </Controls>
            </div>
        );
    }
}
 
export default withTranslation() (SNA6a);
