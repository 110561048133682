import React, { Component } from 'react';
import Button from '../../../../components/UI/Button/Button';
import Input from '../../../../components/UI/Input/Input';
import { withTranslation } from 'react-i18next';

const REGEX_POINT = /^[(][+-]?([0-9]*[.])?[0-9]+[,][+-]?([0-9]*[.])?[0-9]+[)]$/; // (x,y)

class Form extends Component {
    state = {
        points: [
            {
                elementType: 'input',
                elementConfig: {
                    type: 'text',
                    placeholder: '(x,y)'
                },
                value: '',
                validation: {
                    required: true,
                    errorMessage: '(x,y)'
                },
                valid: false,
                touched: false
            },
            {
                elementType: 'input',
                elementConfig: {
                    type: 'text',
                    placeholder: '(x,y)'
                },
                value: '',
                validation: {
                    required: true,
                    errorMessage: '(x,y)'
                },
                valid: false,
                touched: false
            },
        ],
        formIsValid: false    
    }

    componentDidUpdate() {
        if (this.props.pointAmount > this.state.points.length) {
            this.addPoint();
        }  
        if (this.props.pointAmount < this.state.points.length) {
            this.removePoint();
        } 
    }

    addPoint() {
        this.setState(({ points }) => ({ 
            points: [
                ...points, 
                {
                    elementType: 'input',
                    elementConfig: {
                        type: 'text',
                        placeholder: '(x,y)'
                    },
                    value: '',
                    validation: {
                        required: true,
                        errorMessage: '(x,y)'
                    },
                    valid: false,
                    touched: false
                }
            ],
            formIsValid: false,
        }));
    }

    removePoint() {
        const updatedPoints = this.state.points;
        updatedPoints.pop();
        let formIsValid = true;
        updatedPoints.forEach(point => formIsValid = point.valid && formIsValid);
        this.setState({points: updatedPoints, formIsValid})
    }

    checkValidity = (value, rules) => {
        let isValid = false;
        if (rules.required) {
            isValid = value.trim() !== '';
        }
        isValid = REGEX_POINT.test(value.trim());
        
        return isValid;
    }

    inputChangedHandler = (event, index) => {
        const updatedPoints = [
            ...this.state.points
        ];
        const updatedPoint = {
            ...updatedPoints[index]
        };
        updatedPoint.value = event.target.value;
        updatedPoint.valid = this.checkValidity(updatedPoint.value, updatedPoint.validation);
        updatedPoint.touched = true;
        updatedPoints[index] = updatedPoint;

        let formIsValid = true;
        updatedPoints.forEach(point => formIsValid = point.valid && formIsValid);
        this.setState({points: updatedPoints, formIsValid});
        
    }

    dataHandler = (event) => {
        event.preventDefault();
        const pointsData = [
            ...this.state.points
        ];
        this.props.formDataCallback(pointsData);
    }

    render() { 
        const { t } = this.props;
        return (
            <form onSubmit={this.dataHandler}>
                {this.state.points.map((point, index) => (
                        <Input 
                            key={index}
                            elementType={point.elementType}
                            elementConfig={point.elementConfig}
                            value={point.value}
                            invalid={!point.valid}
                            validation={point.validation}
                            touched={point.touched}
                            disabled={this.props.inputDisabled}
                            changed={(event) => this.inputChangedHandler(event, index)}/>
                    ))}
                <Button 
                    btnType='Success'
                    style={{width: '100%', marginBottom: '15px'}}
                    disabled={!(this.state.formIsValid && !this.props.canStart && this.props.isDataReseted)}
                >
                    {t('buttons.submit')}
                </Button>
            </form>
        );
    }
}
 
export default withTranslation() (Form);
