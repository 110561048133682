import React, { useEffect, useState, Fragment } from 'react';
import classes from './ProgressBar.module.css';

const ProgressBar = (props) => {
    const [offset, setOffset] = useState(0);
    const {
        size,
        progress,
        strokeWidth,
        firstCircleStroke,
        secondCircleStroke,
        circleFillColor
    } = props;

    const center = size / 2;
    const radius = size / 2 - strokeWidth / 2;
    const circumference = 2 * Math.PI * radius;

    useEffect(() => {
        const progressOffset = ((props.maxValue - progress) / props.maxValue) * circumference;
        setOffset(progressOffset);
    }, [circumference, progress, props.maxValue]);

    return (
        <Fragment>
            <svg className={classes.ProgressBar} style={props.style} width={size} height={size} >
                <circle
                    className={classes.FirstCircleBackground}
                    stroke={firstCircleStroke}
                    style={{ fill: circleFillColor }}
                    cx={center}
                    cy={center}
                    r={radius}
                    strokeWidth={strokeWidth} />
                <circle
                    className={classes.SecondCircleBackground}
                    stroke={secondCircleStroke}
                    cx={center}
                    cy={center}
                    r={radius}
                    strokeWidth={strokeWidth}
                    strokeDasharray={circumference}
                    strokeDashoffset={offset} />
                <text
                    className={classes.ProgressValue}
                    dominantBaseline='middle'
                    x={center}
                    y={center}>
                    {(progress * (-1)).toFixed(1) + props.units}
                </text>
            </svg>
        </Fragment>
    );
}

export default ProgressBar;