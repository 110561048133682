import React, { Fragment } from 'react';
import Accordion from '../UI/Accordion/Accordion';
import classes from './QuickStart.module.css';
import button from '../../assets/SNA26/show-list.png';

const QuickStartB = () => {
    const exp1 = <span className={classes.expression}>y=4x+50</span>;
    const exp2 = <span className={classes.expression}>y=-0.25x+9</span>;

    const open = <img className={classes['show-list-img']} src={button} alt="show-list" />;
    const close = <img className={classes['hide-list-img']} src={button} alt="hide-list" />;

    return (
        <Fragment>
            <Accordion title={'Mida me teeme selles programmis?'}>
                <p className={classes.paragraph}>Isejuhtiva auto algoritmid suures osas põhinevad matemaatilistel funktsioonidel. Nende abil auto koostab oma teekonda. See programm näitab sulle, kuidas saab panna autot sõitma mööda sirget joont. Saad kasutada oma teadmisi sirge võrrandist.</p>
            </Accordion>
            <Accordion title={'Kelle tööd see programm tutvustab?'}>
                <p className={classes.paragraph}>Isejuhtivate sõidukite algoritme loovad arvuti- ja elektroonikainsenerid, programmeerijad, andmeanalüütikud ja teised spetsialistid. Valdaval osal nendest on kõrgharidus ja isegi doktorikraad.</p>
            </Accordion>
            <Accordion title={'Kuidas programmi kasutada?'}>
                <div className={classes.step}>
                    <span className={classes.numeration}>1.</span>
                    <p className={classes.paragraph}>Vajuta koordinaattasandi vasakus nurgas noolekest {open}, seejärel nuppu "+" ja <strong>"Image"</strong>, ja lisa koordinaattasandile pilt kaardiga (kaardi saad õpetajalt). Kui kaart on üles laetud, sulge aken vajutades nuppu {close}. Tee kaardi suurus parajaks (reguleerides pilti nurgast).</p>
                </div>
                <div className={classes.step}>
                    <span className={classes.numeration}>2.</span>
                    <p className={classes.paragraph}>Sisesta järgmiste sirgete võrrandid: {exp1} ja {exp2}. <strong>"START"</strong> punktiks pane: (-13,-2) ja <strong>"STOP"</strong> punktiks pane (12,6). Vajuta <strong>"OK"</strong> ja seejärel <strong>"START"</strong>, ning uuri auto teekonda. Kas see teekond sobib sinu pildiga?</p>
                </div>
                <div className={classes.step}>
                    <span className={classes.numeration}>3.</span>
                    <p className={classes.paragraph}>Muuda võrrandeid, et need paremini sobiksid sinu pildiga.</p>
                </div>
            </Accordion>
        </Fragment>
    );
}
 
export default QuickStartB;
