import lens1 from '../assets/SNA25/lenses/1.png';
import lens2 from '../assets/SNA25/lenses/2.png';
import lens3 from '../assets/SNA25/lenses/3.png';
import lens5 from '../assets/SNA25/lenses/5.png';
import lens6 from '../assets/SNA25/lenses/6.png';
import lens7 from '../assets/SNA25/lenses/7.png';
import lens8 from '../assets/SNA25/lenses/8.png';
import lens9 from '../assets/SNA25/lenses/9.png';
import lens11 from '../assets/SNA25/lenses/11.png';
import lens13 from '../assets/SNA25/lenses/13.png';
import parabola1 from '../assets/SNA25/parabolas/par1.png';
import parabola2 from '../assets/SNA25/parabolas/par2.png';
import parabola3 from '../assets/SNA25/parabolas/par3.png';
import parabola5 from '../assets/SNA25/parabolas/par5.png';
import parabola6 from '../assets/SNA25/parabolas/par6.png';
import parabola7 from '../assets/SNA25/parabolas/par7.png';
import parabola8 from '../assets/SNA25/parabolas/par8.png';
import parabola9 from '../assets/SNA25/parabolas/par9.png';
import parabola11 from '../assets/SNA25/parabolas/par11.png';
import parabola13 from '../assets/SNA25/parabolas/par13.png';

export const data = [
    {
        id: 0,
        parabola: {
            src: parabola1,
            latex: 'y=-1.3x^{2}+13x-25',
            vertex: {correctParabolaX: 5, correctParabolaY: 7.5}
        },
        lens: {
            src: lens1,
            width: '0.814',
            height: '0.517',
            center: {x: 5.011, y: 7.341},
        },
        mathBounds: {
            left: 4,
            right: 6,
            bottom: 6,
            top: 8
        }
    },
    {
        id: 1,
        parabola: {
            src: parabola2,
            latex: 'y=-0.12x^{2}+2.6x-25',
            vertex: {correctParabolaX: 10.83, correctParabolaY: -10.92}
        },
        lens: {
            src: lens2,
            width: '10',
            height: '6.3',
            center: {x: 11.01, y: -13.01},
        },
        mathBounds: {
            left: 0,
            right: 20,
            bottom: -22,
            top: -2
        }
    },
    {
        id: 2,
        parabola: {
            src: parabola3,
            latex: 'y=-0.06x^{2}+x+3.3',
            vertex: {correctParabolaX: 8.33, correctParabolaY: 7.47}
        },
        lens: {
            src: lens3,
            width: '15.2',
            height: '6.1',
            center: {x: 8.36, y: 5.04},
        },
        mathBounds: {
            left: -1,
            right: 19,
            bottom: -5,
            top: 15
        }
    },
    {
        id: 3,
        parabola: {
            src: parabola5,
            latex: 'y=-0.12x^{2}+x-0.7',
            vertex: {correctParabolaX: 4.17, correctParabolaY: 1.38}
        },
        lens: {
            src: lens5,
            width: '10',
            height: '5.2',
            center: {x: 4.291, y: -0.58},
        },
        mathBounds: {
            left: -3,
            right: 12,
            bottom: -8,
            top: 7
        }
    },
    {
        id: 4,
        parabola: {
            src: parabola6,
            latex: 'y=-0.33x^{2}-2.5x-0.7',
            vertex: {correctParabolaX: -3.79, correctParabolaY: 4.04}
        },
        lens: {
            src: lens6,
            width: '7.36',
            height: '7.4',
            center: {x: -3.76, y: 1.39},
        },
        mathBounds: {
            left: -14,
            right: 4,
            bottom: -8,
            top: 10
        }
    },
    {
        id: 5,
        parabola: {
            src: parabola7,
            latex: 'y=-0.29x^{2}-2.5x-5',
            vertex: {correctParabolaX: -4.31, correctParabolaY: 0.39}
        },
        lens: {
            src: lens7,
            width: '3.47',
            height: '1.598',
            center: {x: -4.32, y: -0.297},
        },
        mathBounds: {
            left: -6.25,
            right: -2.25,
            bottom: -2.5,
            top: 1.5
        }
    },
    {
        id: 6,
        parabola: {
            src: parabola8,
            latex: 'y=-0.28x^{2}-2x-1',
            vertex: {correctParabolaX: -3.57, correctParabolaY: 2.57}
        },
        lens: {
            src: lens8,
            width: '3.255',
            height: '1.724',
            center: {x: -3.574, y: 1.89},
        },
        mathBounds: {
            left: -7,
            right: 0,
            bottom: -2,
            top: 5
        }
    },
    {
        id: 7,
        parabola: {
            src: parabola9,
            latex: 'y=-0.07x^{2}+0.4x-1.6',
            vertex: {correctParabolaX: 2.86, correctParabolaY: -1.03}
        },
        lens: {
            src: lens9,
            width: '6.62',
            height: '2.92',
            center: {x: 2.92, y: -2.18},
        },
        mathBounds: {
            left: -5,
            right: 10,
            bottom: -10,
            top: 5
        }
    },
    {
        id: 8,
        parabola: {
            src: parabola11,
            latex: 'y=-0.08x^{2}+1.7x-1.6',
            vertex: {correctParabolaX: 10.63, correctParabolaY: 7.43}
        },
        lens: {
            src: lens11,
            width: '9.6',
            height: '4',
            center: {x: 10.6, y: 6.05},
        },
        mathBounds: {
            left: 0,
            right: 20,
            bottom: -5,
            top: 15
        }
    },
    {
        id: 9,
        parabola: {
            src: parabola13,
            latex: 'y=-0.22x^{2}+3x-21.3',
            vertex: {correctParabolaX: 6.82, correctParabolaY: -11.07}
        },
        lens: {
            src: lens13,
            width: '6.375',
            height: '5.425',
            center: {x: 6.8, y: -13.27},
        },
        mathBounds: {
            left: 0,
            right: 14,
            bottom: -21,
            top: -7
        }
    }
];

export const defaultData = 
    {
        id: null,
        parabola: {
            src: null,
            vertex: {correctParabolaX: null, correctParabolaY: null}
        },
        lens: {
            src: null,
            center: {x: 0, y: 0},
        },
        mathBounds: {
            left: -10,
            right: 10,
            bottom: -10,
            top: 10
        }
    };
