import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import { BrowserRouter } from 'react-router-dom';
import ScrollToTop from './utils/ScrollToTop';
import './i18n';

const app = (
  <BrowserRouter>
    <ScrollToTop />
    <Suspense fallback={<div/>}>
      <App />
    </Suspense>
  </BrowserRouter>
);

ReactDOM.render(app, document.getElementById('root'));
