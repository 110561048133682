import React, { Component, Fragment } from 'react';
import Des from 'desmos';
import classes from './GraphingCalculator.module.css';
import { calcAppendScript } from '../../../../components/calcAppendScript';
import Vehicle from '../../../../components/common_components/Vehicle/Vehicle';
import car from '../../../../assets/vehicles/car.png';

let calculator;
const widthDelta = 13.5;
const heightDelta = 27;
const POINTS = ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J'];

class GraphingCalculator extends Component {
  
  state = {
    //Forklift settings
    xValue: null,
    yValue: 0,
    angle: 0,
    speed: 1,
    pointIndex: 1, // index of point coordinates in arrays (xValues & yValues)
    moveDirection: null,
    coefficient: 1,
    pointsResetIndex: 0,
    isLinesVisible: true,
  }
  animationID = null; 
   
  setVehicleInitialPosition(value) {
    if (value === 'grid') {                            // Grid On/Off
      calculator.updateSettings(this.props.settings);
    }
    else if (value === 'linesAndPoints') {
      this.setState({ isLinesVisible: !this.state.isLinesVisible }, 
        () => this.showHideGraphsAndPoints()
      );
    }
    else if (!this.props.isAnimationRunning && this.props.xValues.length !== 0 && this.props.isDataReseted) {    // "OK" button clicked 
      calculator.updateSettings(this.props.settings);
      this.setPoints();
      this.setState({ 
        xValue: calculator.mathToPixels({ x: this.props.xValues[0] }).x - widthDelta,
        yValue: calculator.mathToPixels({ y: this.props.yValues[0] }).y - heightDelta,
        angle: this.props.angleValues[0],
        pointsResetIndex: this.props.xValues.length
      });
    } 
    else if(!this.props.isAnimationRunning && this.props.xValues.length === 0) {        // "Reset" button klicked
      calculator.updateSettings(this.props.settings);
      this.setPoints();
      this.setState({
        xValue: null, 
        yValue: 0, 
        angle: 0,  
        pointIndex: 1,
        moveDirection: null, 
        coefficient: 1, 
      })
    } else {                                                                            // Start" button clicked
      this.animationID = window.requestAnimationFrame(() => this.animate());
    }
  }

  componentWillUnmount() {
    window.cancelAnimationFrame(this.animationID);
    calculator.destroy();
  }

  componentDidMount() {
    calcAppendScript();
    const elt = document.getElementById('graphCalcId');
    calculator = Des.GraphingCalculator(elt, this.props.settings);
    calculator.setMathBounds(this.props.mathBounds);
  }

  setPoints() {
    calculator.setExpression({
      type: 'table',
      id: 'points',
      columns: [
        {
          latex: 'x',
          values: this.props.xValues,
        },
        {
          latex: 'y',
          values: this.props.yValues,
          color: Des.Colors.GREEN,
          lines: this.state.isLinesVisible,
          points: this.state.isLinesVisible,
        },  
      ]
    });
    if (this.props.xValues.length !== 0) {
      for (let i = 0; i < this.props.xValues.length; i++) {
        calculator.setExpression({
          id: '1_' + i, 
          latex: this.state.isLinesVisible ? '(' + this.props.xValues[i] + ',' + this.props.yValues[i] + ')' : '',
          label: POINTS[i] + '(' + this.props.xValues[i] + ',' + this.props.yValues[i] + ')',
          showLabel: this.state.isLinesVisible,
          labelSize: Des.LabelSizes.SMALL,
          color: Des.Colors.GREEN
        })
      }
    } 
    else if (this.props.xValues.length === 0) {
      calculator.removeExpression({ id: 'points' });
      for (let i = 0; i < this.state.pointsResetIndex; i++) {
        calculator.removeExpression({ id: '1_' + i });
      } 
      this.setState({ pointsResetIndex: 0 });
    }
    this.setDirection();
  }

  showHideGraphsAndPoints() {
    calculator.setExpression({
      type: 'table',
      id: 'points', 
      columns: [
        {}, 
        { 
          points: this.state.isLinesVisible, 
          lines: this.state.isLinesVisible, 
          showLabel: this.state.isLinesVisible,
        }
      ]
    });
    if (this.props.xValues.length !== 0) {
      for (let i = 0; i < this.props.xValues.length; i++) {
        calculator.setExpression({
          id: '1_' + i, 
          latex: this.state.isLinesVisible ? '(' + this.props.xValues[i] + ',' + this.props.yValues[i] + ')' : '',
          label: this.state.isLinesVisible ? POINTS[i] + '(' + this.props.xValues[i] + ',' + this.props.yValues[i] + ')' : '',
          showLabel: this.state.isLinesVisible,
          color: Des.Colors.GREEN
        });
      }
    } 
  }

  setDirection() {
    const xValueOfCurrentPoint = this.props.xValues[this.state.pointIndex - 1];
    const yValueOfCurrentPoint = this.props.yValues[this.state.pointIndex - 1];
    const xValueOfNextPoint = this.props.xValues[this.state.pointIndex];
    const yValueOfNextPoint = this.props.yValues[this.state.pointIndex];

    const k = this.getCoefficient(xValueOfCurrentPoint, xValueOfNextPoint, yValueOfCurrentPoint, yValueOfNextPoint);
    this.setState({coefficient: k});
    
    if(xValueOfNextPoint >  xValueOfCurrentPoint && yValueOfNextPoint > yValueOfCurrentPoint) {
      this.setState({moveDirection: 1});
    }
    else if (xValueOfNextPoint <  xValueOfCurrentPoint && yValueOfNextPoint > yValueOfCurrentPoint) {
      this.setState({moveDirection: 2});
    }
    else if (xValueOfNextPoint <  xValueOfCurrentPoint && yValueOfNextPoint < yValueOfCurrentPoint) {
      this.setState({moveDirection: 3});
    }
    else if (xValueOfNextPoint >  xValueOfCurrentPoint && yValueOfNextPoint < yValueOfCurrentPoint) {
      this.setState({moveDirection: 4});
    }
    else if (xValueOfNextPoint >  xValueOfCurrentPoint && yValueOfNextPoint === yValueOfCurrentPoint) {
      this.setState({moveDirection: 14});
    }
    else if (xValueOfNextPoint <  xValueOfCurrentPoint && yValueOfNextPoint === yValueOfCurrentPoint) {
      this.setState({moveDirection: 23});
    }
    else if (yValueOfNextPoint >  yValueOfCurrentPoint && xValueOfNextPoint === xValueOfCurrentPoint) {
      this.setState({moveDirection: 12});
    }
    else if (yValueOfNextPoint <  yValueOfCurrentPoint && xValueOfNextPoint === xValueOfCurrentPoint) {
      this.setState({moveDirection: 34});
    }
  }

  // Calculates linear function k coefficient
  getCoefficient (x1, x2, y1, y2) {
    if (x2 - x1 === 0) {
      return 0;
    } else {
      return (y2 - y1) / (x2 - x1);
    }
  }
    
  moveForklift() {
    if(this.state.moveDirection === 1 && 
      (this.state.xValue + widthDelta) <= calculator.mathToPixels({x: this.props.xValues[this.state.pointIndex]}).x && 
        (this.state.yValue +  heightDelta) >= calculator.mathToPixels({y: this.props.yValues[this.state.pointIndex]}).y) {
      if (this.state.coefficient >= 1) {
        const updatedX_value = this.state.xValue + (this.state.speed / this.state.coefficient);
        const updatedY_value = this.state.yValue - this.state.speed;
        this.setState({xValue: updatedX_value, yValue: updatedY_value});
      }
      if (this.state.coefficient < 1) {
        const updatedX_value = this.state.xValue + this.state.speed;
        const updatedY_value = this.state.yValue - (this.state.speed * this.state.coefficient);
        this.setState({xValue: updatedX_value, yValue: updatedY_value});  
      }
    }

    else if(this.state.moveDirection === 2 &&
      (this.state.xValue + widthDelta) >= calculator.mathToPixels({x: this.props.xValues[this.state.pointIndex]}).x && 
        (this.state.yValue + heightDelta) >= calculator.mathToPixels({y: this.props.yValues[this.state.pointIndex]}).y) {
      if (this.state.coefficient <= -1) {
        const updatedX_value = this.state.xValue + (this.state.speed / this.state.coefficient);
        const updatedY_value = this.state.yValue - this.state.speed;
        this.setState({xValue: updatedX_value, yValue: updatedY_value});
      }
      if (this.state.coefficient > -1) {
        const updatedX_value = this.state.xValue - this.state.speed;
        const updatedY_value = this.state.yValue + (this.state.speed * this.state.coefficient);
        this.setState({xValue: updatedX_value, yValue: updatedY_value});  
      }
    }

    else if(this.state.moveDirection === 3 &&
      (this.state.xValue + widthDelta) >= calculator.mathToPixels({x: this.props.xValues[this.state.pointIndex]}).x &&
        (this.state.yValue + heightDelta) <= calculator.mathToPixels({y: this.props.yValues[this.state.pointIndex]}).y) {
      if (this.state.coefficient >= 1) {
        const updatedX_value = this.state.xValue - (this.state.speed / this.state.coefficient);
        const updatedY_value = this.state.yValue + this.state.speed;
        this.setState({xValue: updatedX_value, yValue: updatedY_value});
      }
      if (this.state.coefficient < 1) {
        const updatedX_value = this.state.xValue - this.state.speed;
        const updatedY_value = this.state.yValue + (this.state.speed * this.state.coefficient);
        this.setState({xValue: updatedX_value, yValue: updatedY_value});  
      }
    }

    else if(this.state.moveDirection === 4 &&
      (this.state.xValue + widthDelta) <= calculator.mathToPixels({x: this.props.xValues[this.state.pointIndex]}).x &&
        (this.state.yValue + heightDelta) <= calculator.mathToPixels({y: this.props.yValues[this.state.pointIndex]}).y) {
      if (this.state.coefficient >= -1) {
        const updatedX_value = this.state.xValue + this.state.speed;
        const updatedY_value = this.state.yValue - (this.state.speed * this.state.coefficient);
        this.setState({xValue: updatedX_value, yValue: updatedY_value});
      }
      if (this.state.coefficient < -1) {
        const updatedX_value = this.state.xValue - (this.state.speed / this.state.coefficient);
        const updatedY_value = this.state.yValue + this.state.speed;
        this.setState({xValue: updatedX_value, yValue: updatedY_value});  
      }
    }

    else if(this.state.moveDirection === 12 &&
      this.state.yValue >= calculator.mathToPixels({y: this.props.yValues[this.state.pointIndex]}).y - heightDelta) {
      const updatedY_value = this.state.yValue - this.state.speed;
      this.setState({yValue: updatedY_value});
    }

    else if(this.state.moveDirection === 23 &&
      this.state.xValue >= calculator.mathToPixels({x: this.props.xValues[this.state.pointIndex]}).x - widthDelta) {
      const updatedX_value = this.state.xValue - this.state.speed;
      this.setState({xValue: updatedX_value});
    }

    else if(this.state.moveDirection === 34 &&
      this.state.yValue <= calculator.mathToPixels({y: this.props.yValues[this.state.pointIndex]}).y - heightDelta) {
      const updatedY_value = this.state.yValue + this.state.speed;
      this.setState({yValue: updatedY_value});
    }

    else if(this.state.moveDirection === 14 &&
      this.state.xValue <= calculator.mathToPixels({x: this.props.xValues[this.state.pointIndex]}).x - widthDelta) {
      const updatedX_value = this.state.xValue + this.state.speed;
      this.setState({xValue: updatedX_value});
    }

    else if(Math.round(this.state.angle) !== Math.round(this.props.angleValues[this.state.pointIndex]) && this.state.pointIndex < this.props.angleValues.length) {
      const x1 = this.props.xValues[this.state.pointIndex - 1];
      const y1 = this.props.yValues[this.state.pointIndex - 1];
      const x2 = this.props.xValues[this.state.pointIndex];
      const y2 = this.props.yValues[this.state.pointIndex];
      const x3 = this.props.xValues[this.state.pointIndex + 1];
      const y3 = this.props.yValues[this.state.pointIndex + 1];

      const D = (x3 - x1) * (y2 -y1) - (y3 - y1) * (x2 - x1);

      if(D >= 0) { this.setState({ angle: this.state.angle + 1 }, () => this.resetAngle()); }
      if(D < 0) { this.setState({ angle: this.state.angle - 1 }, () => this.resetAngle()); }
    }

    else if (this.state.pointIndex + 1 < this.props.xValues.length) {
      this.setState({
        xValue: calculator.mathToPixels({x: this.props.xValues[this.state.pointIndex]}).x - widthDelta,
        yValue: calculator.mathToPixels({y: this.props.yValues[this.state.pointIndex]}).y - heightDelta,
        pointIndex: this.state.pointIndex + 1, 
      });
      this.setDirection();
    }

    else {
      this.props.restoreInitialSettings();
    }
  }

  animate() { 
    this.moveForklift();
    if (this.props.isAnimationRunning) {
      this.animationID = window.requestAnimationFrame(() => this.animate()); 
    }   
  }

  resetAngle() {
    if(this.state.angle < -90) {
      this.setState({angle: 270});
    }
    if(this.state.angle >= 270) {
      this.setState({angle: -90});
    }
  }

  render() { 
    return (
      <Fragment>
        <div id='graphCalcId' className={classes.GraphingCalculator}></div> 
        {this.state.xValue !== null && (<Vehicle 
          position={{
            left: this.state.xValue,
            top: this.state.yValue,
            transform: 'rotate(' + this.state.angle + 'deg)',
            backgroundImage: `url(${car})`
          }} />)}
      </Fragment>
    );
  }
}
 
export default GraphingCalculator;
