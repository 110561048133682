import React, { useState } from 'react';
import Button from '../../../../components/UI/Button/Button';
import classes from './Parameters.module.css';

const ParameteresWithSymbols = (props) => {
    const { values } = props;
    const [value, setValue] = useState('');

    return (
        <section className={classes.Container}>
            <div className={classes.Display}>
                {value}
                <button className={classes.CloseButton} onClick={props.closeVariables_2_Panel}>&times;</button>
            </div>
            {values.map(value => (
                <Button
                    btnType='Secondary'
                    key={value}
                    style={{ width: '100%', margin: '0', borderRadius: value !== 'z' ? '0' : '0 0 0 9px', border: '1px solid #6d6d6d' }}
                    clicked={() => setValue(value)}>{value}
                </Button>
            ))}
            <Button
                btnType='Success'
                disabled={value.length === 0}
                style={{ margin: '0', borderRadius: '0 0 9px 0', border: '1px solid #6d6d6d' }}
                clicked={() => props.setInputBlockValue(value)}>OK
            </Button>
        </section>
    );
}

export default ParameteresWithSymbols;
