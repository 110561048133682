import React from 'react';
import classes from './ImageWithLoading.module.css';

const ImageWithLoading = ({width = 1500, height = 1000, containerWidth = 0.7, coeficient = 1.5}) => {
    return (  
        <div className={classes.loading} style={{'--height': containerWidth / (width / height), '--coeficient': coeficient}} /> 
    );
}
 
export default ImageWithLoading;
