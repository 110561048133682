import React, { useState } from 'react';
import classes from './Form.module.css';
import Button from '../../UI/Button/Button';
import Input from '../../UI/Input/Input';
import { FaTemperatureHigh } from 'react-icons/fa';
import { useTranslation } from 'react-i18next';

const Form = (props) => {
    const {environmentTemperature, counter, LIexpression_v1, LIexpression_v2, isAirParcelLifted, isDataReseted} = props;
    const { t } = useTranslation();

    const checkboxesArr = [
        {id: 0, checked: false},
        {id: 1, checked: false},
        {id: 2, checked: false},
        {id: 3, checked: false},
        {id: 4, checked: false}
    ];

    const [checkboxes, setCheckboxes] = useState(checkboxesArr);
    const [expression, setExpression] = useState({
        elementType: 'input',
        elementConfig: {
            type: 'text',
        },
        value: '',
        validation: {},
        valid: false,
        empty: false,
        touched: false,
    });

    const expressionInputChangeHandler = (event) => {
        setExpression({...expression, value: event.target.value});
    }

    const updateCheckboxes = (id) => {
        const tempCheckboxes = [...checkboxes];
        for (const checkbox of tempCheckboxes) {
            checkbox.id === id ? checkbox.checked = true : checkbox.checked = false;
        }
        setCheckboxes(tempCheckboxes);
    }

    const isEmpty = value => value.trim() === '';

    const formSubmissionHandler = (event) => {
        event.preventDefault();
        const forecastIndex = checkboxes.findIndex(cb => cb.checked === true);
        const expressionIsEmpty = isEmpty(expression.value);
        const enteredExpressionIsValid = expression.value === LIexpression_v1 || expression.value === LIexpression_v2;
        if(!enteredExpressionIsValid && !expressionIsEmpty) {
            setExpression({
                ...expression, 
                valid: false, 
                touched: true,
                empty: expressionIsEmpty,
            });
            return;
        }
        if(expressionIsEmpty) {
            setExpression({
                ...expression, 
                valid: false, 
                touched: true,
                empty: expressionIsEmpty,
            });
            return;
        }
        props.checkForecast(forecastIndex);
    }

    const clearState = () => {
        setCheckboxes(checkboxesArr);
        setExpression({...expression, touched: false});
    }
    
    return (
        <section className={classes.wrapper}>
            <Button 
                type='button' 
                style={{width: '100%', marginBottom: '15px'}} 
                btnType='Primary' 
                disabled={environmentTemperature !== ''}
                clicked={() => props.generateTemperatures()}
            >   
                <FaTemperatureHigh className={classes.temp}/>
                {t('buttons.new-variant') + ` (${counter}s)`}
            </Button>
            <form className={classes.form} onSubmit={formSubmissionHandler}>  
                <Input 
                    disabled={!isAirParcelLifted || !isDataReseted}
                    onFocus={() => setExpression({...expression, touched: false})}
                    elementType={expression.elementType}
                    label={t('SNA9.form.input.label')}
                    elementConfig={expression.elementConfig}
                    placeholder={t('SNA9.form.input.placeholder')}
                    errorMessage={expression.empty ? t('SNA9.form.input.errors.error-2') : t('SNA9.form.input.errors.error-1')}
                    invalid={!expression.valid}
                    touched={expression.touched}
                    changed={expressionInputChangeHandler}
                />
                <p>{t('SNA9.form.checkboxes.paragraph')}</p>
                {checkboxes.map((checkbox, index) =>
                    <div key={checkbox.id} className={classes['form-control-cb']}>    
                        <input 
                            disabled={!isAirParcelLifted || !isDataReseted}
                            id={`cb${checkbox.id}`}
                            type='checkbox' 
                            checked={checkbox.checked} 
                            onChange={() => updateCheckboxes(checkbox.id)}
                        /> 
                        <label htmlFor={`cb${checkbox.id}`}>{t(`SNA9.form.checkboxes.cb-${index  + 1}`)} </label>
                    </div>
                )} 
                <div className={classes['form-action']}>
                    <Button 
                        style={{width: '100%', marginBottom: '15px'}} 
                        btnType='Success'
                        disabled={!checkboxes.some(cb => cb.checked) || !isDataReseted}
                    >
                        {t('SNA9.form.check-btn')}
                    </Button>
                    <Button 
                        type='button' 
                        style={{width: '100%'}} 
                        btnType='Primary' 
                        clicked={() => {props.reset(); clearState()}}
                    >
                        {t('buttons.reset')}
                    </Button>
                </div>
            </form>
        </section>
    );
}
 
export default Form;
