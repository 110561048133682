import React from 'react';
import classes from './KeypadButton.module.css';

const KeypadButton = ({ children, clicked }) => {
    let value = children;
    if (children.length === 2 && children !== '()') {
        const [a, b] = children.split('');
        value = <div>
            <span>{a}</span>
            <span className={classes.exponent}>{b}</span>
        </div>
    }
    if (children === 'sqrt') value = <span>&radic;</span>
    
    return (
        <button 
            className={classes['keypad-btn']}
            onClick={clicked}
        >
            {value}
        </button>
    );
}
 
export default KeypadButton;
