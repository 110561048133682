import React from 'react';
import RoadSection from './RoadSection';

const road = (props) => {
    return (
        <div>
            {[...Array(props.roadSectionCoordinates.length)].map((_, index) => (
            <RoadSection key={index} _x={{right: props.roadSectionCoordinates[index] + 'px'}} />
          ))}
        </div>        
    );  
};

export default road;
