import React from 'react';
import classes from './Display.module.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const displayFA = (props) => (
    <div className={classes.Display} style={props.style}>
        <FontAwesomeIcon className={classes.DisplayIcon} style={{ color: 'rgb(52, 58, 63)' }} icon={props.icon} size="2x" />
        <div className={classes.DisplayValue}>{props.value}</div>
        <div className={classes.DisplayUnit}>{props.unit}</div>
    </div>
);

export default displayFA;
