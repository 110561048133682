import React, { useEffect, useState } from 'react';
import { calcAppendScript } from '../../calcAppendScript';
import Des from 'desmos';
import classes from './GraphingCalculator.module.css';
import { data } from '../../../data/SNA25data';
import { useSNA25Context } from '../../../context/SNA25-context';
import { round } from '../../../utils/helpers';
import { useTranslation } from 'react-i18next';

let calculator;;

const SETTINGS = {
    autosize: true,  
    settingsMenu: false, 
    zoomButtons: false, 
    lockViewport: false,
    expressionsCollapsed: false,
    projectorMode: true, 
    expressions: false,
    pointsOfInterest: false,
};

const GraphingCalculator = () => {
    const { t } = useTranslation();
    const [base64obj, setBase64obj] = useState({});
    const { currentData, isParabolaHidden, setCrossMarkCoords, crossMarkCoords, isDataReseted } = useSNA25Context();
    useEffect(() => {
        calcAppendScript();
        const elt = document.getElementById('graphCalcId');
        calculator = Des.GraphingCalculator(elt, SETTINGS);
        calculator.setExpression({
            type: 'folder',
            id: 'f1',
        });
        return () => {
            calculator.destroy();
        }
    }, []);

    useEffect (() => {
        const tempBase64obj = {};

        const convert = (task) => {
            toDataURL(task.lens.src)
                .then(dataUrl => {
                    tempBase64obj[task.id] = dataUrl
            });
        }

        const toDataURL = (url) => fetch(url)
            .then(response => response.blob())
            .then(blob => new Promise((resolve, reject) => {
                const reader = new FileReader();
                reader.onloadend = () => resolve(reader.result);
                reader.onerror = reject;
                reader.readAsDataURL(blob);
            }))
        
        for (const task of data) convert(task);
        setBase64obj(tempBase64obj);
    }, []);
    
    useEffect(() => {
        if (!Object.keys(base64obj).length) return;
        const state = calculator.getState();
        const updatedState = {...state};
        const updatedList = updatedState.expressions.list;
        updatedList[1] = {
            type: 'image', 
            id: 'img', 
            folderId: 'f1',
            image_url: base64obj[currentData.id], 
            name: 'lens', 
            width: currentData.lens.width,
            height: currentData.lens.height,
            center: `\\left(${currentData.lens.center.x},${currentData.lens.center.y}\\right)`
        };
        updatedList[0].collapsed = true; // collaps folder to prevent image drag & resize (not possible while creating)
        calculator.setState(updatedState);
        calculator.setMathBounds(currentData.mathBounds);
    }, [currentData, base64obj]);

    useEffect(() => {
        if (!Object.keys(base64obj).length) return;
        calculator.setExpression({
            id: 'p1',
            latex: currentData.parabola.latex,
            color: Des.Colors.GREEN,
            hidden: isParabolaHidden
        });
    }, [base64obj, currentData, isParabolaHidden]);

    useEffect(() => {
        if (isDataReseted) return;
        calculator.setExpression({
            id: 'parabola_vertex',
            label: t('SNA25.gc.parabola-vertex'),
            showLabel: !isParabolaHidden,
            labelSize: Des.LabelSizes.SMALL,
            latex: `(${currentData.parabola.vertex.correctParabolaX}, ${currentData.parabola.vertex.correctParabolaY})`,
            color: Des.Colors.GREEN,
            hidden: isParabolaHidden
        });
    }, [isDataReseted, currentData, isParabolaHidden, t]);

    useEffect(() => {
        const { x } = crossMarkCoords;
        if (x === null) {
            calculator.removeExpression({ id: 'point' });
            calculator.removeExpression({ id: 'parabola_vertex' });
        } 
    }, [crossMarkCoords]);

    const calcClickHandler = (event) => {
        if (!isDataReseted || currentData.id === null) return;
        if (event.detail === 2) {
            const elt = document.getElementById('graphCalcId');
            const rect = elt.getBoundingClientRect();
            const mathCoords = calculator.pixelsToMath({
                x: event.clientX - rect.left,
                y: event.clientY - rect.top
            });
            mathCoords.x = round(mathCoords.x, 2);
            mathCoords.y = round(mathCoords.y, 2);
            const {x, y} = mathCoords;
            calculator.setExpression({
                id: 'point',
                latex: `(${x}, ${y})`,
                pointStyle: Des.Styles.CROSS,
                color: Des.Colors.RED
            });
            setCrossMarkCoords(mathCoords);
        } 
    }
    return (
        <div onClick={calcClickHandler} id='graphCalcId' className={classes['graphing-calculator']} />
    );
}
 
export default GraphingCalculator;
