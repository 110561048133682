import React from 'react';
import { SNA22Provider } from '../../context/SNA22-context';
import Content from '../../components/SNA22/Content/Content';

const SNA22 = () => {
    return (
        <SNA22Provider>
            <Content />
        </SNA22Provider>
    );
}
 
export default SNA22;
