import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSNA23Context } from '../../../context/SNA23-context';
import classes from './Info.module.css';

const Info = () => {
    const { t } = useTranslation();
    const { taskIndex, data: { formula, higherDifficulty, hint } } = useSNA23Context();
    
    return (  
        <article>
            <hr/>
            <p className={classes.task}>{t('SNA23.info.task')}</p>
            {higherDifficulty && <p><strong>{t('SNA23.info.higher-dificulty')}</strong></p>}
            <p>{t(`SNA23.info.task${taskIndex + 1}.p1`)}</p>
            <div className={classes.formula}>
                <img src={formula} alt='formula' />
                <span>{t(`SNA23.info.task${taskIndex + 1}.unit`)}</span>
            </div>
            <p>{t(`SNA23.info.task${taskIndex + 1}.p2`)}</p>
            {hint && <p className={classes.hint}>{t('SNA23.info.hint')}</p>}
            <hr/>
            <br/>
        </article>
    );
}
 
export default Info;
