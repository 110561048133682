import React from 'react';
import classes from './Controls.module.css';
import Form from '../../../containers/SNA5/Form/Form';
import AnimatedButton from '../../UI/Button/AnimatedButton';
import Button from '../../UI/Button/Button';
import DashBoard from '../DashBoard/DashBoard';
import { useTranslation } from 'react-i18next';

const Controls = (props) => {
    const { t} = useTranslation();
    return (
        <div className={classes.Controls}>
            <DashBoard 
                stopwatch={props.stopwatch}
                inFrontCarDistance={props.inFrontCarDistance}
                overtakingCarDistance={props.overtakingCarDistance}/>
            <Form 
                canStart={props.canStart} 
                isDataReseted={props.isDataReseted}
                inputDisabled={props.inputDisabled}
                formDataCallback={formData => props.formDataCallback(formData)}/>
            <div style={{display: "flex"}}>
                <AnimatedButton 
                    btnType="Danger"
                    style={{width: '50%', marginRight: '5px'}}
                    disabled={!props.canStart}
                    clicked={() => props.start()}>
                    {!props.isTimerOn ? t('buttons.start') : t('buttons.pause')}
                </AnimatedButton>
                <Button 
                    btnType="Primary"
                    style={{width: '50%', marginLeft: '5px'}}
                    clicked={() => props.reset()}
                >
                    {t('buttons.reset')}
                </Button>
            </div>
        </div>
    );
}
 
export default Controls;
