import { useState } from 'react';

const useToggle = (initiaValue) => {
    const [value, setValue] = useState(initiaValue);

    const toggleValue = () => {
        setValue(prevValue => !prevValue);
    }

    const resetValue = () => {
        setValue(false)
    }
    return [value, toggleValue, resetValue];
}

export default useToggle;
