import React, { useContext, useReducer } from 'react';
import reducer from '../reducers/SNA26-reducer';

const SNA26Context = React.createContext();

export const SNA26Provider = (props) => {
    const initialState = {
        frequency: 0,
        percentage: 0,
        isPlaying: false,
        track: 'octave',
    };

    const [state, dispatch] = useReducer(reducer, initialState);

    const setTimelineData = (frequency, percentage) => {
        dispatch({type: 'TIMELINE', payload: {frequency, percentage} })
    }

    const handleStartClick = () => {
        dispatch({ type: 'START' });
    }

    const onChangeRadioHandler = (e) => {
        const track = e.target.value.toLowerCase();      
        dispatch({ type: 'RADIO', payload: track });
    }

    const handleEnd = () => {
        dispatch({ type: 'END' });
    }

    return (
        <SNA26Context.Provider
            value={{
                ...state,
                setTimelineData,
                handleStartClick,
                onChangeRadioHandler,
                handleEnd,
            }}
        >
            {props.children}
        </SNA26Context.Provider>
    );
}

export const useSNA26Context = () => {
    return useContext(SNA26Context);
};
