import React, { Component } from 'react';
import classes from '../SNA10.module.css';
import Controls from '../../../components/SNA10/SNA10c/Controls/Controls';
import SmartWatch from '../../../components/SNA10/SNA10c/SmartWatch/SmartWatch';

const PROGRESS_BAR_SIZE_COEFICENT = 0.243;       // percentage from window width
const PROGRESS_BAR_WIDTH_COEFICIENT = 0.01;      // percentage from window width
const FLEX_DIRECTION_BOUNDARY = 1000;            // flex row / column change point: window width
const FLEX_DIRECTION_PROPORTION = 2.25;          // proportion between image width when flex direction changes: row and column

class SNA10c extends Component {
    state = {
        progressBar: {
            isRunning: false,
            size: 200,
            progress: 0, 
            strokeWidth: 0,
            firstCircleStroke: '#a9dda9',
            secondCircleStroke: '#2aab2a',
            circleFillColor: 'none'
        },
        timerDelay: 1,
        currentSteps: 10000,
        updatedDailyRate: 10000,
        activityUnit: 'steps',
        activityType: 'walk',
        isDataReseted: true,
        isToggled: false,
        canStart: false
    }

    componentDidMount () {
        window.addEventListener("resize", this.handleResize);
        this.setState({
            progressBar: { 
                ...this.state.progressBar, 
                size: window.innerWidth > FLEX_DIRECTION_BOUNDARY ? 
                    window.innerWidth * PROGRESS_BAR_SIZE_COEFICENT : 
                    window.innerWidth * PROGRESS_BAR_SIZE_COEFICENT * FLEX_DIRECTION_PROPORTION,
                strokeWidth: window.innerWidth > FLEX_DIRECTION_BOUNDARY ? 
                    window.innerWidth * PROGRESS_BAR_WIDTH_COEFICIENT : 
                    window.innerWidth * PROGRESS_BAR_WIDTH_COEFICIENT * FLEX_DIRECTION_PROPORTION,
            } 
        });
    }

    componentWillUnmount() {
        clearInterval(this.pb);
        window.removeEventListener("resize", this.handleResize);
    }

    handleResize = (event) => {
        this.setState({
            progressBar: { 
                ...this.state.progressBar, 
                size: window.innerWidth > FLEX_DIRECTION_BOUNDARY ? 
                    window.innerWidth * PROGRESS_BAR_SIZE_COEFICENT : 
                    window.innerWidth * PROGRESS_BAR_SIZE_COEFICENT * FLEX_DIRECTION_PROPORTION,
                strokeWidth: window.innerWidth > FLEX_DIRECTION_BOUNDARY ? 
                    window.innerWidth * PROGRESS_BAR_WIDTH_COEFICIENT : 
                    window.innerWidth * PROGRESS_BAR_WIDTH_COEFICIENT * FLEX_DIRECTION_PROPORTION,
            } 
        });
    }

    toggle = () => {
        this.setState({ isToggled: !this.state.isToggled }, () => this.setTimerDelay());
    }

    setTimerDelay = () => {
        const stepsOrMinutes = this.state.activityUnit.toLowerCase().startsWith('s');
        this.setState({ timerDelay: this.state.isToggled ? stepsOrMinutes ? 25 : 300 : stepsOrMinutes ? 1 : 100}, () => {
            this.stopRunning();
            this.startRunning();
        });
    }

    getFormData = ({prevDailyRate, activityUnit, activityType, operationType, percentages}) => {
        const updatedDailyRate = prevDailyRate + ((operationType.toLowerCase().startsWith('i') ? 1 : -1) * percentages * prevDailyRate / 100);
        this.setState({ 
            timerDelay: activityUnit.toLowerCase().startsWith('s') ? 1 : 100,
            currentSteps: updatedDailyRate,
            updatedDailyRate,
            activityUnit,
            activityType,
            isDataReseted: false, 
            canStart: true,
        });
    }

    handleStartClick = () => {
        this.state.progressBar.isRunning ? this.stopRunning() : this.startRunning();
    }

    handleResetClick = () => {
        clearInterval(this.pb);
        this.setState({
            progressBar: { ...this.state.progressBar, isRunning: false,  progress: 0 },
            timerDelay: 1,
            currentSteps: 10000,
            updatedDailyRate: 10000,
            activityUnit: 'steps',
            activityType: 'walk',
            isDataReseted: true,
            isToggled: false,
            canStart: false
        });
    }

    startRunning = () => {
        this.pb = setInterval(() => {
            this.setState({ 
                currentSteps: this.state.currentSteps === 0 ? 0 :this.state.currentSteps - 1,
                progressBar: { 
                    ...this.state.progressBar, 
                    isRunning: true,  
                    progress: this.state.progressBar.progress + (1 / this.state.updatedDailyRate * 100)
                },

            }, () => this.checkBounds());
        }, this.state.timerDelay);
    }

    stopRunning = () => {
        this.setState({ progressBar: {...this.state.progressBar, isRunning: false, }});
        clearInterval(this.pb);
    }

    checkBounds() {
        if (this.state.currentSteps <= 0){
            clearInterval(this.pb);
            this.setState({ 
                canStart: false, 
                progressBar: {...this.state.progressBar, progress: 100, isRunning: false}
            });
        }   
    }

    render() { 
        const { isRunning } = this.state.progressBar;
        const { currentSteps, activityUnit,activityType, isDataReseted, canStart, isToggled } = this.state;
        return (
            <div className={classes.SNA10}>
                <SmartWatch 
                    progressBar={this.state.progressBar}
                    currentSteps={currentSteps}
                    activityUnit={activityUnit}
                    activityType={activityType}
                    isToggled={isToggled}
                    isRunning={isRunning}
                    toggle={() => this.toggle()}/>
                <Controls 
                    actionType={isRunning ? 'Run' : 'Pause'}
                    activityType={activityType}
                    isToggled={isToggled}
                    isDataReseted={isDataReseted}
                    canStart={canStart}
                    isRunning={isRunning}
                    formData={this.getFormData}
                    start={this.handleStartClick}
                    reset={this.handleResetClick}/> 
            </div>
        );
    }
}
 
export default SNA10c;
