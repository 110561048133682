import React, { Component } from 'react';
import v_red from '../../../assets/icons/SNA5/velocity_red.png';
import v_blue from '../../../assets/icons/SNA5/velocity_blue.png';
import distance from '../../../assets/icons/SNA5/distance.png';
import Input from '../../../components/UI/Input/Input';
import Button from '../../../components/UI/Button/Button';
import { withTranslation } from 'react-i18next';

class Form extends Component {
    state = {
        form: {
            inFrontCarSpeed: {
                elementType: 'input',
                elementConfig: {
                    type: 'number',
                    icon: v_blue,
                    icontype: 'Reqtangle-xl'
                },
                value: '',
                validation: {
                    required: true,
                    minSpeed: 0,
                    maxSpeed: 120,
                },
                valid: false,
                touched: false,
            },
            overtakingCarSpeed: {
                elementType: 'input',
                elementConfig: {
                    type: 'number',
                    icon: v_red,
                    icontype: 'Reqtangle-xl'
                },
                value: '',
                validation: {
                    required: true,
                    minSpeed: 0,
                    maxSpeed: 120,
                },
                valid: false,
                touched: false,
            },
            distanceBetweenCars: {
                elementType: 'input',
                elementConfig: {
                    type: 'number',
                    icon: distance,
                    icontype: 'Reqtangle-xl'
                },
                value: '',
                validation: {
                    required: true,
                    minDistance: 20,
                    maxDistance: 200,
                },
                valid: false,
                touched: false,
            },
        },
        formIsValid: false,
    }

    checkValidity(value, rules) {
        let isValid = true;
        if (rules.required) {
            isValid = value.trim() !== '' && isValid;
        }
        if (rules.maxSpeed) {
            isValid = (value >= rules.minSpeed && value <= rules.maxSpeed) && isValid;
        }
        if (rules.maxDistance) {
            isValid = (value >= rules.minDistance && value <= rules.maxDistance) && isValid;
        }
        return isValid;
    }

    dataHandler = (event) => {
        event.preventDefault();
        const formData = {};
        for (let key in this.state.form) {
            formData[key] = Number(this.state.form[key].value);
        }
        this.props.formDataCallback(formData);
    }

    inputChangedHandler = (event, id) => {
        const updatedForm = {...this.state.form};
        const updatedItem = {...updatedForm[id]}; 
        updatedItem.value = event.target.value;  
        updatedItem.valid = this.checkValidity(updatedItem.value, updatedItem.validation); 
        updatedItem.touched = true;
        updatedForm[id] = updatedItem;

        let formIsValid = true;
        for(let id in updatedForm) {
            formIsValid = updatedForm[id].valid && formIsValid;
        }
        this.setState({ form: updatedForm, formIsValid });
    }

    render() { 
        const { t } = this.props;
        const formElementsArray = [];
        for(let key in this.state.form) {
            formElementsArray.push({
                id: key,
                config: this.state.form[key]
            });
        }
        
        return (
            <form onSubmit={this.dataHandler}> 
                {formElementsArray.map((formElement, index) => (
                    <div key={formElement.id} style={{display: "flex", alignItems: "flex-start"}}>
                        <Input 
                            elementType={formElement.config.elementType}
                            elementConfig={formElement.config.elementConfig}
                            placeholder={index === 2 ? t('SNA5.form.placeholders.distance') : t('SNA5.form.placeholders.velocity')}
                            value={formElement.config.value}
                            invalid={!formElement.config.valid}
                            touched={formElement.config.touched}
                            validation={formElement.config.validation}
                            errorMessage={
                                t('SNA5.form.error', {
                                    minValue: formElement.config.validation[index === 2 ? 'minDistance' : 'minSpeed'], 
                                    maxValue: formElement.config.validation[index === 2 ? 'maxDistance' : 'maxSpeed']
                                })
                            }
                            disabled={this.props.inputDisabled}
                            changed={(event) => this.inputChangedHandler(event, formElement.id)}/> 
                        {index !== 2  ? <p style={{ overflow: 'hidden', width: '120px', border: '1px solid #ccc', backgroundColor: '#ccc', padding: '6px', margin: '3px 10px'}}>
                            {(formElement.config.value / 3.6).toFixed(2) + t('SNA5.form.units')}</p> : null}
                        {index !== 2 ? <p style={{ overflow: 'hidden', width: '35%', border: '1px solid #ccc', backgroundColor: '#ccc', padding: '6px', margin: '3px 0 10px'}}>
                            {`f(x)=${formElement.config.value !== '' ? (formElement.config.value / 3.6).toFixed(2) + 'x' : ''}`}
                            {`${((this.state.form.distanceBetweenCars.value === '' || Number(this.state.form.distanceBetweenCars.value) === 0) || index !== 1 ) ? '' : `-${this.state.form.distanceBetweenCars.value}`}`}</p> : null}
                    </div>
                ))}
                <Button 
                    btnType="Success" 
                    disabled={!(this.state.formIsValid && !this.props.canStart && this.props.isDataReseted)}
                    style={{width: '100%', marginBottom: '15px'}}
                >
                    {t('buttons.submit')}
                </Button>
            </form>
        );
    }
}
 
export default withTranslation() (Form);
