import React, { Component } from 'react';
import Button from '../../../../components/UI/Button/Button';
import Input from '../../../../components/UI/Input/Input';
import classes from './Form.module.css';
import { withTranslation } from 'react-i18next';

const REGEX_WHOLE_NUMBER = /^\d+$/;

class Form extends Component {
    state = {
        form: {
            settings: {
                elementType: 'input',
                elementConfig: {
                    type: 'number',
                },
                value: '100',
                validation: {
                    required: true,
                    minValue: 1,
                    maxValue: 10000,
                },
                valid: true,
                touched: false,
            },
            activityUnit: {
                elementType: 'select',
                elementConfig: {
                    options: []
                },
                value: 'steps',
                validation: {},
                valid: true,
            },
            activityType: {
                elementType: 'select',
                elementConfig: {
                    options: []
                },
                value: 'walk',
                validation: {},
                valid: true,
            },
            percentageOfDailyRate: {
                elementType: 'input',
                elementConfig: {
                    type: 'number',
                },
                value: '1',
                validation: {
                    required: true,
                    minValue: 1,
                    maxValue: 100,
                },
                valid: true,
                touched: false,
            },
            activity: {
                elementType: 'input',
                elementConfig: {
                    type: 'number',
                },
                value: 0,
                validation: {
                    required: true,
                    minValue: 0,
                    maxValue: 100,
                },
                valid: true,
                touched: false,
            },
            actionValue: {
                elementType: 'input',
                elementConfig: {
                    type: 'number',
                },
                value: '',
                validation: {
                    required: true,
                    integer: true,
                    minValue: 1,
                    maxValue: 20000,
                },
                valid: false,
                label: '',
                touched: false,
            },
        },
        formIsValid: false
    }

    checkValidity(value, rules) {
        let isValid = true;
        if (rules.required) {
            isValid = value.trim() !== '' && isValid;
        }
        if (rules.integer) {
            isValid = REGEX_WHOLE_NUMBER.test(value.trim());
        }
        if (rules.maxValue) {
            isValid = (value >= rules.minValue && value <= rules.maxValue) && isValid;
        }
        return isValid;
    }

    replaceStrElement = (i, el, str) => {
        const value = str.split(' ');
        value[i] = el;
        return value.join(',').replaceAll(',', ' ');
    }

    inputChangedHandler = (event, id) => {
        const updatedForm = {...this.state.form};
        const updatedItem = {...updatedForm[id]}; 
        updatedItem.value = event.target.value;  

        if (id === 'activityUnit') {
            const updatedActionValue = {...updatedForm['actionValue']}; 
            updatedActionValue.label = this.replaceStrElement(1, event.target.value, updatedActionValue.label); 
            updatedForm['actionValue'] = updatedActionValue;
        }

        updatedItem.valid = this.checkValidity(updatedItem.value, updatedItem.validation); 
        updatedItem.touched = true;
        updatedForm[id] = updatedItem;
        let formIsValid = true;
        for (let id in updatedForm) {
            formIsValid = updatedForm[id].valid && formIsValid;
        }
        this.setState({ form: updatedForm, formIsValid });
    }

    dataHandler = (event) => {
        event.preventDefault();
        const formData = {};
        for (let key in this.state.form) {
            formData[key] = key === 'activityUnit' || key === 'activityType' ? this.state.form[key].value : Number(this.state.form[key].value);
        }
        this.props.formData(formData);
    }

    render() { 
        const { formIsValid } = this.state;
        const { isDataReseted, t } = this.props;

        const formElementsArray = [];
        for (let key in this.state.form) {
            const config = this.state.form[key];
            if (key === 'activityUnit' || key === 'activityType' ) {
                config.elementConfig.options = [
                    {
                        value: key === 'activityUnit' ? 'steps' : 'walk', 
                        displayValue: key === 'activityUnit' ? t('SNA10b.form.inputs.input-2.selects.option-1') : t('SNA10b.form.inputs.input-3.selects.option-1')
                    }, 
                    {
                        value: key === 'activityUnit' ? 'minutes' : 'run', 
                        displayValue: key === 'activityUnit' ? t('SNA10b.form.inputs.input-2.selects.option-2') : t('SNA10b.form.inputs.input-3.selects.option-2')
                    }
                ];
            }
            if (key === 'actionValue') {
                config.label = t('SNA10b.form.inputs.input-6.label', 
                    {
                        units: this.state.form.activityUnit.value === 'steps' ? 
                        t('SNA10b.form.inputs.input-2.selects.option-1') : 
                        t('SNA10b.form.inputs.input-2.selects.option-2'),

                        type: this.state.form.activityType.value === 'walk' ? 
                        t('SNA10b.form.inputs.input-6.types.walk') : 
                        t('SNA10b.form.inputs.input-6.types.run'),
                    }
                );
            } 
            formElementsArray.push({ id: key, config });
        }

        return (
            <form onSubmit={this.dataHandler}>
                <div className={classes.Inputs}>
                    {formElementsArray.map((formElement, index) => (
                        <div key={formElement.id}>
                            <Input 
                                elementType={formElement.config.elementType}
                                elementConfig={formElement.config.elementConfig}
                                label={index !== 5 ? t(`SNA10b.form.inputs.input-${index + 1}.label`) : formElement.config.label}
                                placeholder={t(`SNA10b.form.inputs.input-${index + 1}.placeholder`)}
                                value={formElement.config.value}
                                labelTextColor={(index === 0 || index === 1 || index === 2) && 'Transparent'}
                                invalid={!formElement.config.valid}
                                touched={formElement.config.touched}
                                validation={formElement.config.validation}
                                errorMessage={
                                    t(`SNA10b.form.errors.${index !== 5 ? 'error-1' : 'error-2'}`, 
                                    {minValue: formElement.config.validation.minValue, maxValue: formElement.config.validation.maxValue})
                                }
                                disabled={!isDataReseted}
                                changed={(event) => this.inputChangedHandler(event, formElement.id)}/>
                        </div>
                    ))}
                </div>
                <Button 
                    btnType="Success" 
                    disabled={!(formIsValid && isDataReseted)}
                    style={{width: '100%', marginBottom: '15px'}}
                >
                    {t('buttons.submit')}
                </Button>   
            </form>
        );
    }
}
 
export default withTranslation() (Form);
