import React from 'react';
import fountains from '../../../../assets/fountains.png';
import classes from './Animation.module.css';
import { useTranslation } from 'react-i18next';
import ImageWithLoading from '../../../../components/UI/ImageWithLoading/ImageWithLoading';
import useImagesLoaded from '../../../../hooks/useImagesLoaded';

const FountainAnimation = (props) => {
    const { currentHeights, targetHeights, numeration } = props;
    const { t } = useTranslation();
    const [ref, imagesLoaded] = useImagesLoaded();

    return (
        <div ref={ref} className={classes.Container}>
            {currentHeights.map((currentHeight, index) => (
                <div height-data={`${targetHeights[index]?.toFixed(2)} ` + t('SNA11.unit')} key={index} className={classes.Fountain} style={{'--height': currentHeight}}></div>
            ))}
            {!imagesLoaded && <ImageWithLoading /> }
            <img className={classes.Img} width='1500' height='1000' alt='fountains' src={fountains}/>              
            <img className={classes.Img} width='1500' height='1000' alt='numeration' src={numeration}/>  
        </div>
    );
}
 
export default FountainAnimation;
