import React, { Fragment } from 'react';
import Accordion from '../../UI/Accordion/Accordion';
import classes from './QuickStart.module.css';
import expression from '../../../assets/SNA24/expression-1.png';
import button from '../../../assets/SNA24/button.png';
import { useSNA24Context } from '../../../context/SNA24-context';

const QuickStartA = () => {
    const { expressionIsValid, fieldsAreValid, isCalculated } = useSNA24Context();

    return (
        <Fragment>
            <Accordion title={'Sissejuhatus'}>
                <p className={classes.paragraph}>Programmeerijad üritavad oma programme võimalikult palju lihtsustada, ehk optimeerida, et arvutiprogrammid ja äppid töötaksid kiiresti, tõhusalt ning ei jookseks kokku. Selline optimeerimine on eriti vajalik igasugu äppidele, mis vajavad head graafikat (videomängud, 3D modelleerimine, videotöötlus, jms.). Seda lihtsustamist tehakse selleks, et vähendada aega, mis on vajalik arvutil programmiga töötamiseks.</p>
                <br/>
                <p className={classes.paragraph}>See programm võimaldab teha väikest uurimistööd ning selgitada, millised matemaatilised operatsioonid võtavad arvutitel kõige rohkem aega. Saad kasutada oma teadmisi ratsionaalavaldiste lihtsustamisest.</p>
            </Accordion>
            <Accordion title={'Kelle tööd see programm simuleerib?'}>
                <p className={classes.paragraph}>Arvutiprogrammide optimeerimisega tegelevad eeskätt programmeerijad, arvutiinsenerid, elektroonika insenerid – ja teised inimesed, kes loovad uusi programme või arvuteid. See programm näitab sulle tükikest nende tööst. Ka majandusprognooside tegemisel kasutatakse ratsionaalavaldisi ja majandusanalüütikud aeg-ajalt lihtsustavad oma majandusmudeleid.</p>
            </Accordion>
            <Accordion title={'Kuidas programmi kasutada?'}>
                <p className={classes.paragraph}><strong>NB! </strong>Enne programmi kasutamist pane kinni kõik ebavajalikud programmid ja brauseriaknad!</p>
                <div className={classes['step-with-img']}>
                    <div className={classes['step-inner']}>
                        <span className={classes.numeration}>1.</span>
                        <div>
                            <p className={classes.paragraph}>Sisesta avaldis programmi, näiteks:</p>
                            <p className={`${classes.paragraph} ${classes.italic}`}>(avaldise sisestamiseks kasuta arvutiklahve või sisseehitatud klaviatuuri)</p>
                        </div>
                    </div>
                    <img className={classes['expression-img']} src={expression} alt="expression" />
                </div>
                <div className={`${classes.step} ${expressionIsValid || classes.filter}`}>
                    <span className={classes.numeration}>2.</span>
                    <p className={classes.paragraph}>Sisesta muutujate väärtused (näiteks a=1234567890, b=9876543210). <strong>NB! </strong>Kasuta suuri muutujaid (miljoni või miljardi suurusjärgus), sest äppid tihti töötavad väga suurte muutujatega.</p>
                </div>
                <div className={`${classes['step-with-img']} ${fieldsAreValid || classes.filter}`}>
                    <div className={classes['step-inner']}>
                        <span className={classes.numeration}>3.</span>
                        <p className={classes.paragraph}>Selleks, et uurida, kui palju aega vajab arvuti, et arvutada avaldist, vajuta nuppu:</p>
                    </div>
                    <img className={classes['button-img']} src={button} alt="button" />
                </div>
                <div className={`${classes.step} ${isCalculated || classes.filter}`}>
                    <span className={classes.numeration}>4.</span>
                    <p className={classes.paragraph}>Korda katset miinimum 5 korda ja seejärel arvuta aritmeetiline keskmine.</p>
                </div>
                <div className={`${classes.step} ${isCalculated || classes.filter}`}>
                    <span className={classes.numeration}>5.</span>
                    <p className={classes.paragraph}>Lihtsusta eelnevalt sisestatud avaldis, jäta muutujate väärtused samaks ja uuri arvutuse aega (korda katset miinimum 5 korda ja arvuta aritmeetiline keskmine).</p>
                </div>
                <div className={`${classes.step} ${isCalculated || classes.filter}`}>
                    <span className={classes.numeration}>6.</span>
                    <p className={classes.paragraph}>Kumba avaldise puhul läheb arvutil vähem aega arvutamisele? Mitu protsenti vähem?</p>
                </div>
            </Accordion>
        </Fragment>
    );
}
 
export default QuickStartA;
