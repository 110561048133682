import React, { Fragment } from 'react';
import Accordion from '../../UI/Accordion/Accordion';
import classes from './QuickStart.module.css';
import toggle from '../../../assets/SNA22/toggle.png';
import angle from '../../../assets/SNA22/angle.png';
import { useSNA22Context } from '../../../context/SNA22-context';

const QuickStart = () => {
    const { isLinesVisible, isDataReseted } = useSNA22Context();

    return (  
        <Fragment>
            <Accordion title={'Sissejuhatus'}>
                <p className={classes.paragraph}>Oled kindlasti näinud, et spordivõistlustel (näiteks jalgpalli meistrivõistlustel) on tihti olemas oma maskott. Neid maskotte kasutatakse muuseas ka televisiooni ülekannete ajal, näiteks reklaami jaoks. Selleks, et maskott näeks jalgpalliväljakul välja nagu „päris“, oskavad tänapäeva arvutialgoritmid analüüsida telepilti ja joonistavad maskotile ka varju, mis on sama suunaga nagu jalgpallimängijate varjud.</p>
                <br/>
                <p className={classes.paragraph}>See programm näitab põhimõtet, kuidas need algoritmid töötavad. Saad kasutada oma teadmisi paralleelsetest ja lõikuvatest sirgetest.</p>
            </Accordion>
            <Accordion title={'Kelle tööd see programm simuleerib?'}>
                <p className={classes.paragraph}>See programm näitab sulle väikest tükikest arvutigraafika spetsialisti või arvutigraafika inseneri tööst. Need spetsialistid kasutavad geomeetriat oma töös selleks, et ehitada ilusaid ja realistlikke kahe- ja kolmemõõtmelisi mudeleid, näiteks liitreaalsuse, arvutimängude, reklaami ja muu jaoks. Pea meeles, et programm näitab vaid ühte väikest osa nende spetsialistide tööst!</p>
            </Accordion>
            <Accordion title={'Kuidas programmi kasutada?'}>
                <div className={classes['step-with-img']}>
                    <div className={classes['step-inner']}>
                        <span className={classes.numeration}>1.</span>
                        <p className={classes.paragraph}>Programm näitab jalgpalliväljakut, kus peal on mängijad ja maskott. Vaata, kuhu on suunatud mängijate varjud. Vajuta nuppu "Näita abijooni" – need aitavad sul täpselt määrata maskoti varju nurka.</p>
                    </div>
                    <img className={classes['toggle-img']} src={toggle} alt="toggle auxiliary lines" />
                </div>
                <div className={`${classes['step-with-img']} ${!isLinesVisible && classes.filter}`}>
                    <div className={classes['step-inner']}>
                        <span className={classes.numeration}>2.</span>
                        <p className={classes.paragraph}>Programm annab sulle vihje (vt. <span className={classes.red}>"Vihje!"</span>), kuidas rakendada paralleelsete ja lõikuvate sirgete geomeetriat selleks, et välja arvutada maskoti varju nurk. Arvuta nurk välja ja sisesta see programmi. Seejärel vajuta "OK" ja "NÄITA".</p>
                    </div>
                    <img className={classes['angle-img']} src={angle} alt="angle input" />
                </div>
                <div className={`${classes.step} ${isDataReseted && classes.filter}`}>
                    <span className={classes.numeration}>3.</span>
                    <p className={classes.paragraph}>Järgmise ülesande jaoks vajuta "RESET" ning seejärel "Uus ülesanne".</p>
                </div>
            </Accordion>
        </Fragment>
    );
}
 
export default QuickStart;