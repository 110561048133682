import React, { Fragment} from 'react';
import CardList from '../../components/Cards/CardList';
import Footer from '../../components/Footer/Footer';

const Home = () => {
    return (
        <Fragment>
            <main className={'page'}>
                <CardList />             
            </main>
            <Footer />
        </Fragment>
    );
}
 
export default Home;
