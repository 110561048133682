import React, { Fragment } from 'react';
import Accordion from '../../UI/Accordion/Accordion';
import classes from './QuickStart.module.css';
import button from '../../../assets/SNA26/show-list.png';
import expression from '../../../assets/SNA26/expression.png';

const QuickStart = () => {
    const open = <img className={classes['show-list-img']} src={button} alt="show-list" />;

    const exp1 = <span className={classes.expression}>y=ax</span>;
    const exp2 = <span className={classes.expression}>a</span>;
    const exp3 = <span className={classes.expression}>a=0.1</span>;
    const exp4 = <span className={classes.expression}>a=0.2</span>;
    const exp5 = <span className={classes.expression}>y=ax, y=-ax, y=0.1ax, y=-0.1ax, y=0.3ax ja y=-0.3ax</span>;

    return (
        <Fragment>
            <Accordion title={'Mida me teeme selles programmis?'}>
                <p className={classes.paragraph}>Oled kindlasti näinud kuidas erinevad arvutiprogrammid visualiseerivad muusikat. Väga paljudes visualiseerimisprogrammides on kasutusel matemaatilised meetodid. See programm näitab sulle kuidas saaks teha sellist visualiseerimist. Saad kasutada oma teadmisi hulkliikmetest ja hulkliikmete korrutamisest/jagamisest üksliikmega.</p>
            </Accordion>
            <Accordion title={'Kelle tööd see programm tutvustab?'}>
                <p className={classes.paragraph}>Inimesi, kes loovad visualiseerimise graafilist teostust kutsutakse graafiliseks disaineriteks. Muusikavisualiseerimise tehnilist teostust tavaliselt teevad programmeerijad. Graafilised disainerid ja programmeerijad peavad tihti tegema tihedat koostööd: graafiline disainer peab oma disainis arvestama sellega, mida on võimalik luua programmeerimisvahenditega ja programmeerija peab oskama muuta graafilist disaini arvutiprogrammiks.</p>
            </Accordion>
            <Accordion title={'Kuidas programmi kasutada?'}>
                <div className={classes.step}>
                    <span className={classes.numeration}>1.</span>
                    <div>
                    <p className={classes.paragraph}>Vajuta <strong>"MÄNGI"</strong>, et kuulata kuidas kõlab esimene oktaav (ehk heli sagedustel 0.262kHz kuni 0.494kHz. Pilt, mida näed programmis on esimese oktaavi nn. spektrogramm, mis visualiseerib, kuidas heli sagedus muutub ajas (teisisõnu sageduse funktsioon ajast).</p>
                    <br/>
                    <p className={classes.paragraph}><strong>NB! </strong> Ole ettevaatlik heli tugevusega!</p>
                    </div>
                </div>
                <div className={classes['step-with-img']}>
                    <div className={classes['step-inner']}>
                        <span className={classes.numeration}>2.</span>
                        <p className={classes.paragraph}>Koordinaatteljestikul vajuta nuppu {open} ja sisesta: {exp1}. See üksliige on lineaarfunktsioon, mida õppisid 7. klassis, kus {exp2} on sirge tõus. Mida suurem on {exp2}, seda suurem on lineaarfunktsiooni tõus.</p> 
                    </div>
                    <img className={classes['expression-img']} src={expression} alt="expression" />
                </div>
                <div className={`${classes.step}`}>
                    <span className={classes.numeration}>3.</span>
                    <p className={classes.paragraph}>Programm on tehtud nii, et teguri a väärtus vastab heli sageduse väärtusele. Näiteks, kui heli kõrgus on 0.1kHz, siis {exp3}, kui heli kõrgus on 0.2kHz, siis {exp4}, jne. Vajuta <strong>"MÄNGI"</strong>, ja veendu, et mida kõrgem on heli – seda suurem on {exp2}, ja seda suurem on sirge {exp1} tõus.</p>
                </div>
                <div className={`${classes.step}`}>
                    <span className={classes.numeration}>4.</span>
                    <p className={classes.paragraph}>Pane mängima klassikaline või popmuusika ja vaata, kuidas sirge muudab oma tõusu muusika taktis.</p>
                </div>
                <div className={`${classes.step}`}>
                    <span className={classes.numeration}>5.</span>
                    <p className={classes.paragraph}>Sisesta programmi järgmised funktsioonid: {exp5}. Pane oma valitud meloodia mängima ja vaata visualiseerimist.</p>
                </div>
            </Accordion>
        </Fragment>
    );
}
 
export default QuickStart;
