import React from 'react';
import { Link } from 'react-router-dom';
import classes from './ErrorPage.module.css';
import Button from '../../components/UI/Button/Button';
import { useTranslation } from 'react-i18next';

const ErrorPage = () => {
    const { t } = useTranslation();
    return (
        <main className={`${'page-100'} ${classes.wrapper}`}>
            <section>
                <h1>{t('errorPage.h1')}</h1>
                <h3>404</h3>
                <Link to='/'>
                    <Button btnType='Primary'>
                        {t('buttons.home')}
                    </Button>
                </Link>
            </section>
        </main>
    );
}
 
export default ErrorPage;
