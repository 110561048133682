import React, { useEffect } from 'react';
import { calcAppendScript } from '../../calcAppendScript';
import Des from 'desmos';
import classes from './GraphingCalculator.module.css';
import { useSNA26Context } from '../../../context/SNA26-context';
import { tracksMap } from '../../../data/SNA26data';
import { yOffset } from '../../../data/SNA26data';

let calculator;

const SETTINGS = {
    autosize: true,  
    settingsMenu: false, 
    zoomButtons: false, 
    lockViewport: false,
    expressionsCollapsed: true,
    projectorMode: true, 
    expressions: true,
    pointsOfInterest: false,
    administerSecretFolders: false
};

const GraphingCalculator = () => {
    const { frequency, track } = useSNA26Context();

    useEffect(() => {
        calcAppendScript();
        const elt = document.getElementById('graphCalcId1');
        calculator = Des.GraphingCalculator(elt, SETTINGS);
    
        calculator.setExpression({
            id: 'folder1',
            type: 'folder',
        });

        calculator.setExpression({
            id: 'slider',
            latex: 'a=0',
        });

        const state = calculator.getState();
        const updatedState = {...state};
        const updatedList = updatedState.expressions.list;
        updatedList[0].collapsed = true;
        updatedList[0].secret = true;
        updatedList[1].folderId = 'folder1';
        calculator.setState(updatedState);

        return () => {
            calculator.destroy();
        }
    }, []);

    useEffect(() => {
        const coeficient = tracksMap.get(track).frequencyCoeficient;
        const offset = track === 'octave' && yOffset;
        calculator.setExpression({
            id: 'slider',
            latex: `a=${(frequency * coeficient + offset).toFixed(3)}`,
        });
    }, [frequency, track]);

    return (
        <div id='graphCalcId1' className={classes['graphing-calculator']}/>  
    );
}
 
export default GraphingCalculator;
