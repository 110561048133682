import React, { useEffect} from 'react';
import { calcAppendScript } from '../../calcAppendScript';
import Des from 'desmos';
import classes from './GraphingCalculator.module.css';
import { useTranslation } from 'react-i18next';

let calculator;

const GraphingCalculator = (props) => {
    const { t } = useTranslation();
    useEffect(() => {
        calcAppendScript();
        const elt = document.getElementById('graphCalcId');
        calculator = Des.GraphingCalculator(elt, props.settings);
        calculator.setMathBounds(props.mathBounds);
        return () => {
            calculator.destroy();
        }
    }, [props.settings, props.mathBounds]);

    useEffect(() => {
        calculator.setExpression({ 
            id: 'sign', 
            latex: 'a(x)=195',
            color: '#c74440',
            lineStyle: Des.Styles.DASHED
        });
    }, []);

    useEffect(() => {
        calculator.setExpression({ 
            id: 'signPoint', 
            latex: '(10,210)',
            color: '#c74440',
            label: t('SNA5.GC.road-sign') + ' (y=195)',
            showLabel: true,
            labelSize: Des.LabelSizes.SMALL,
            hidden: true
        });

        calculator.updateSettings({ 
            xAxisLabel: t('SNA5.GC.labels.x-axis') ,
            yAxisLabel: t('SNA5.GC.labels.y-axis') 
        });        
    }, [t]);


    useEffect(() => {
        calculator.setExpression({ 
            id: 'redPoint', 
            latex: '(n,f(n))', 
            color: '#c74440', 
            dragMode: Des.DragModes.NONE 
        });

        calculator.setExpression({ 
            id: 'bluePoint', 
            latex: '(m,g(m))', 
            color: '#2d70b3', 
            dragMode: Des.DragModes.NONE 
        });

        calculator.setExpression({ 
            id: 'redLine', 
            latex: `f(x)=${props.overtakingCarSpeed + 'x-' + props.distanceBetweenCars}`,
            color: '#c74440',
        });

        calculator.setExpression({ 
            id: 'blueLine', 
            latex: `g(x)=${props.inFrontCarSpeed + 'x'}`,
            color: '#2d70b3' 
        });

        calculator.setExpression({ 
            id: 'red', 
            latex: 'n=0', 
        });

        calculator.setExpression({ 
            id: 'blue', 
            latex: 'm=0', 
        });
    }, [props.overtakingCarSpeed, props.inFrontCarSpeed, props.distanceBetweenCars]);


    useEffect(() => {
        calculator.setExpression({ 
            id: 'red', 
            latex: `n=${props.time}`, 
        });

        calculator.setExpression({ 
            id: 'blue', 
            latex: `m=${props.time}`, 
        });
    }, [props.time]);
    
    return (
        <div id='graphCalcId' className={classes.GraphingCalculator} />
    );
}
 
export default GraphingCalculator;
