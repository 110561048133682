import React, { useContext, useReducer } from 'react';
import reducer from '../reducers/SNA22-reducer';
import { DATA } from '../data/SNA22data';
import { randomNum } from '../utils/helpers';

const SNA22Context = React.createContext();

export const SNA22Provider = (props) => {
    const initialState = {
        isLinesVisible: false,
        index: 0,
        data: DATA[0],
        mascotShadowAngle: null,
        isMascotShadowVisible: false,
        isDataReseted: true,
        canShow: false,
    };

    const [state, dispatch] = useReducer(reducer, initialState);

    const handleNewVariantClick = () => {
        let random = randomNum(0, DATA.length - 1);
        while (random === state.index) random = randomNum(0, DATA.length - 1);
        const data =  DATA[random];
        dispatch({ 
            type: 'NEW_VARIANT',  
            payload: {data, random}
        });
    }

    const toggleImage = () => {
        dispatch({ type: 'TOGGLE' });
    }

    const handleShowClick = () => {
        dispatch({ type: 'SHOW' });
    }

    const handleResetClick = () => {
        dispatch({ type: 'RESET' });
    }

    const setMascotAngle = (angle) => {
        dispatch({ type: 'SET_ANGLE', payload: angle })
    }

    return (
        <SNA22Context.Provider
            value={{
                ...state,
                handleNewVariantClick,
                toggleImage,
                handleShowClick, 
                handleResetClick,
                setMascotAngle,
            }}
        >
            {props.children}
        </SNA22Context.Provider>
    );
}

export const useSNA22Context = () => {
    return useContext(SNA22Context);
};
