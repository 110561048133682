import React, { Component } from 'react';
import Input from '../../../../components/UI/Input/Input';
import Button from '../../../../components/UI/Button/Button';
import classes from './Form.module.css';
import { withTranslation } from 'react-i18next';

const REGEX_FLOAT = /^[+-]?\d*\.?\d$/;  // obe digit after decimal

class Form extends Component {
    state = {
        hypotenuse: {
            elementType: 'input',
            elementConfig: {
                type: 'number',
            },
            validation: {
                required: true,
                minValue: this.props.minValue,
                maxValue: this.props.maxValue,
            },
            value: '',
            valid: false,
            touched: false,
        },
    }

    checkValidity(value, rules) {
        let isValid = true;
        isValid = value.trim() !== '' && isValid;
        isValid = REGEX_FLOAT.test(value) && isValid;
        isValid = (value >= rules.minValue && value <= rules.maxValue) && isValid;
        return isValid;
    }

    inputChangedHandler = (event) => {
        const updatedHypotenuse = { ...this.state.hypotenuse };
        updatedHypotenuse.value = event.target.value;
        updatedHypotenuse.valid = this.checkValidity(updatedHypotenuse.value, updatedHypotenuse.validation);
        updatedHypotenuse.touched = true;
        this.setState({ hypotenuse: updatedHypotenuse });
    }

    dataHandler = (event) => {
        event.preventDefault();
        const hypotenuse = this.state.hypotenuse;
        this.props.formDataCallback(hypotenuse);
    }

    render() {
        const { t, beforeInputText, minValue, maxValue, index, canStart, hypotenuse} = this.props;
        return (
            <div className={classes.wrapper} onSubmit={this.dataHandler}>
                <form>
                    <h4>{beforeInputText}=</h4>
                    <Input
                        style={{margin: '0 10px 0 0' }}
                        elementType={this.state.hypotenuse.elementType}
                        elementConfig={this.state.hypotenuse.elementConfig}
                        placeholder={beforeInputText.includes('AC') ? t('SNA2.form.placeholder') : ''}
                        value={this.state.hypotenuse.value}
                        invalid={!this.state.hypotenuse.valid}
                        validation={this.state.hypotenuse.validation}
                        touched={this.state.hypotenuse.touched}
                        errorMessage={t('SNA2.form.error', {minValue, maxValue})}
                        disabled={index === null || canStart}
                        changed={(event) => this.inputChangedHandler(event)} />
                    <Button
                        btnType="Success"
                        disabled={!this.state.hypotenuse.valid || hypotenuse || index === null}
                        style={{ width: '45%' }}
                    >
                        {t('buttons.submit')}
                    </Button>
                </form>
            </div>
        );
    }
}

export default withTranslation() (Form);
