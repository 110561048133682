import React from 'react';
import classes from './ToggleSwitch.module.css';

const ToggleSwitch = ({ 
    style, 
    disabled, 
    isToggled, 
    onToggle, 
    sliderColor='SliderWhite', 
    sliderType='Rounded', 
    content, 
    label
}) => (
    <label className={classes.switch} style={style}>
        <input 
            type="checkbox" 
            disabled={disabled}
            checked={isToggled} 
            onChange={onToggle} />
        <span className={[classes[sliderColor], classes[sliderType], disabled && classes.disabled].join(' ')} data-content={content}/>
        {label}
    </label>
);

export default ToggleSwitch;
