import React, { Component } from 'react';
import { Fragment } from 'react';
import Form from '../../components/SNA9/Form/Form';
import World from '../../components/SNA9/World/World';
import classes from './SNA9.module.css';
import Notification from '../../components/UI/Notification/Notification';
import { withTranslation } from 'react-i18next';

const TIMER_DELAY = 45;
const CIRCLE_MAX = 33;        // vw
const TEMPERATURE_DELTA = 30; // delta between ground surface and atmosphere
const AIR_PARCEL_MAX = 34;    // vw

const CLOUDS_POSITIONS = [[60, 60, 60], [3, 22, 60], [3, 22, 41], [3, 22, 41], [3, 22, 41]];  // vw
 
class SNA9 extends Component {
    state = {
        test: 1,
        isAirFlowOn: false,
        airParcel_y: 12,   // vw
        environmentTemperature: '',
        finalAirParcelTemperature: '',
        tempAirParcelTemperature: '',
        forecastIndex: 0,
        userForecastIndex: '',
        LI: '',
        LIexpression_v1: '',
        LIexpression_v2: '',
        notifications: [],
        isRainImgVisible: false,
        isAirParcelLifted: false,
        iterator: 0,
        counter: 3,
        isDataReseted: true,
        airFlows: [
            Array.from({length: 12}, () => {return {x: 18, y: 8, size: ((Math.random() * 20) + 10) / 10, opacity: 0.2}}),
            Array.from({length: 11}, () => {return {x: 18.5, y: 8, size: ((Math.random() * 20) + 10) / 10, opacity: 0.2}}),
            Array.from({length: 10}, () => {return {x: 19, y: 8, size: ((Math.random() * 20) + 10) / 10, opacity: 0.2}}),
            Array.from({length: 15}, () => {return {x: 20, y: 8, size: ((Math.random() * 20) + 10) / 10, opacity: 0.2}}),
            Array.from({length: 10}, () => {return {x: 21, y: 8, size: ((Math.random() * 20) + 10) / 10, opacity: 0.2}}),
            Array.from({length: 10}, () => {return {x: 22, y: 8, size: ((Math.random() * 20) + 10) / 10, opacity: 0.2}}),
            Array.from({length: 11}, () => {return {x: 23, y: 8, size: ((Math.random() * 20) + 10) / 10, opacity: 0.2}}),
            Array.from({length: 10}, () => {return {x: 23.5, y: 8, size: ((Math.random() * 20) + 10) / 10, opacity: 0.2}}),
            Array.from({length: 10}, () => {return {x: 24.6, y: 8, size: ((Math.random() * 20) + 10) / 10, opacity: 0.2}}),
            Array.from({length: 13}, () => {return {x: 25, y: 8, size: ((Math.random() * 20) + 10) / 10, opacity: 0.2}}),
        ]
    }

    getNotification(identifier) {
        const { t } = this.props;
        if (identifier === 'SUCCESS') {
            return {
                id: 1,
                type: 'Success',
                title: t('SNA9.notifications.titles.success'),
                description: t('SNA9.notifications.descriptions.success', {expression: this.state.LIexpression_v1}) 
            }
        }
        if (identifier === 'DANGER') {
            return {
                id: 2,
                type: 'Danger',
                title: t('SNA9.notifications.titles.fail'),
                description: t('SNA9.notifications.descriptions.danger', 
                    {
                        forecast: t(`SNA9.notifications.descriptions.forecasts.${this.state.userForecastIndex + 1}`), 
                        expression: this.state.LIexpression_v1
                    }
                ),   
            }
        }
    }

    startAirFlowAnimation = () => {
        this.state.isAirFlowOn ? this.stopTimer() : this.startTimer();    
    }

    handleResetClick = () => {
        clearInterval(this.airFlow);
        clearInterval(this.counter);
        this.setState({ 
            isAirFlowOn: false,
            airParcel_y: 12,   
            environmentTemperature: '',
            finalAirParcelTemperature: '',
            tempAirParcelTemperature: '',
            forecastIndex: 0,
            userForecastIndex: '',
            LI: '',
            LIexpression_v1: '',
            LIexpression_v2: '',
            notifications: [],
            isRainImgVisible: false,
            isAirParcelLifted: false,
            iterator: 0,
            counter: 3,
            isDataReseted: true,
            airFlows: [
                Array.from({length: 12}, () => {return {x: 18, y: 8, size: ((Math.random() * 20) + 10) / 10, opacity: 0.2}}),
                Array.from({length: 11}, () => {return {x: 18.5, y: 8, size: ((Math.random() * 20) + 10) / 10, opacity: 0.2}}),
                Array.from({length: 10}, () => {return {x: 19, y: 8, size: ((Math.random() * 20) + 10) / 10, opacity: 0.2}}),
                Array.from({length: 15}, () => {return {x: 20, y: 8, size: ((Math.random() * 20) + 10) / 10, opacity: 0.2}}),
                Array.from({length: 10}, () => {return {x: 21, y: 8, size: ((Math.random() * 20) + 10) / 10, opacity: 0.2}}),
                Array.from({length: 10}, () => {return {x: 22, y: 8, size: ((Math.random() * 20) + 10) / 10, opacity: 0.2}}),
                Array.from({length: 11}, () => {return {x: 23, y: 8, size: ((Math.random() * 20) + 10) / 10, opacity: 0.2}}),
                Array.from({length: 10}, () => {return {x: 23.5, y: 8, size: ((Math.random() * 20) + 10) / 10, opacity: 0.2}}),
                Array.from({length: 10}, () => {return {x: 24.6, y: 8, size: ((Math.random() * 20) + 10) / 10, opacity: 0.2}}),
                Array.from({length: 13}, () => {return {x: 25, y: 8, size: ((Math.random() * 20) + 10) / 10, opacity: 0.2}}),
            ]
        });
    }

    handleCheckForecastClick = (userForecastIndex) => {
        const {LI} = this.state;
        this.setState({ isDataReseted: false });
        if (LI > 6) this.setState({ forecastIndex: 0, userForecastIndex }, () => this.checkUserForecast(userForecastIndex));
        if (LI <= 6 && LI > 0) this.setState({ forecastIndex: 1, userForecastIndex }, () => this.checkUserForecast(userForecastIndex));
        if (LI <= 0 && LI > -2) this.setState({ forecastIndex: 2, userForecastIndex }, () => this.checkUserForecast(userForecastIndex));
        if (LI <= -2 && LI >= -6) this.setState({ forecastIndex: 3, userForecastIndex }, () => this.checkUserForecast(userForecastIndex));
        if (LI < -6) this.setState({ forecastIndex: 4, userForecastIndex }, () => this.checkUserForecast(userForecastIndex));
    }

    checkUserForecast = (userForecastIndex) => {
        if (userForecastIndex !== this.state.forecastIndex) this.setState({ notifications: [this.getNotification('DANGER')] });
        if (userForecastIndex === this.state.forecastIndex) this.setState({ notifications: [this.getNotification('SUCCESS')] });
    }

    startCountDown = () => {
        this.counter = setInterval(() => { 
            this.countDown();   
        }, 1000 );
    }

    countDown = () => {
        if (this.state.counter === 0) {
            clearInterval(this.counter);
            this.startTimer();
        }
        else this.setState(prevState =>({ counter: prevState.counter - 1 }));
    }

    startTimer = () => {
        this.airFlow = setInterval(() => { 
            this.state.counter === 0 && this.animateAirFlow();
            this.state.counter === 0 && this.anmateAirParcel();
        }, TIMER_DELAY );
    }

    stopTimer = () => {
        clearInterval(this.airFlow);
        this.setState({ isAirFlowOn: false });
    }

    getRandom = (min, max) => {
        return Math.floor(Math.random() * (max - min + 1) + min);
    }

    handleGenerateTemperaturesClick = () => {
        const airParcelTemperature = this.getRandom(-13, -5);
        const environmentTemperature = this.getRandom(-13, -5);
        this.setState({ 
            environmentTemperature,
            tempAirParcelTemperature: airParcelTemperature + TEMPERATURE_DELTA,
            finalAirParcelTemperature: airParcelTemperature,
            LI: environmentTemperature - (airParcelTemperature),
            LIexpression_v1: `${environmentTemperature}-(${airParcelTemperature})=${environmentTemperature - (airParcelTemperature)}`,
            LIexpression_v2: `${environmentTemperature}+${airParcelTemperature * -1}=${environmentTemperature - (airParcelTemperature)}`
        });
        this.startCountDown();
    }

    anmateAirParcel() {
        if (this.state.airParcel_y < AIR_PARCEL_MAX) this.setState(prevState =>({ 
            airParcel_y: prevState.airParcel_y + 0.3,
            iterator: this.state.iterator === 6 ? 0 : prevState.iterator + 1,
            tempAirParcelTemperature: this.state.iterator === 6 ? prevState.tempAirParcelTemperature - 3 : prevState.tempAirParcelTemperature
        }));
        if (this.state.airParcel_y >= AIR_PARCEL_MAX) {
            this.setState({ isRainImgVisible: true, tempAirParcelTemperature: this.state.finalAirParcelTemperature, isAirParcelLifted: true});
        }
    
    } 

    animateAirFlow() {
        this.setState({ isAirFlowOn: true });
        const updatedAirFlows = [...this.state.airFlows];
        const xDelta = +((Math.random() * 0.01) + 0.01).toFixed(2);
        for (const [j, flow] of updatedAirFlows.entries()) {
            for (const [i, circle] of flow.entries()) {
                if (i === 0 && circle.y < CIRCLE_MAX && !this.state.isAirParcelLifted) {
                    circle.y += 0.3;
                    j === 0  && (circle.x -= xDelta);
                    j === this.state.airFlows.length - 1 && (circle.x += xDelta);
                    circle.y > 20 ? circle.size += 0.15 : circle.size += 0.08;
                    circle.y > 20 && (circle.opacity -= 0.009);
                }
                if (((i > 0 && flow[i - 1].y - circle.y > 3) || (i > 0 && circle.y >= 29)) && !this.state.isAirParcelLifted) {
                    circle.y += 0.3;
                    j === 0 && (circle.x -= xDelta);
                    circle.y > 20 ? circle.size += 0.13 : circle.size += 0.08;
                    circle.y > 20 && (circle.opacity -= 0.009);
                }
                if (circle.y >= CIRCLE_MAX) {
                    j === 0 && (circle.x = 18);
                    j === this.state.airFlows.length - 1 && (circle.x = 25);
                    circle.y = 8;
                    circle.size = 2;
                    circle.opacity = 0.2;
                }
                if (this.state.isAirParcelLifted) {
                    circle.opacity -= 0.004;
                    circle.size += 0.08;
                }
            }
        }
        if (updatedAirFlows[0][updatedAirFlows.length - 1].opacity <= 0) this.stopTimer();
        this.setState({ airFlows: updatedAirFlows });
    }

    deleteNotificationHandler = () => {
        this.setState({ notifications: [] });
    }

    componentWillUnmount() {
        clearInterval(this.airFlow);
        clearInterval(this.counter);
    }

    render() { 
        const {
            airFlows, 
            airParcel_y, 
            environmentTemperature, 
            tempAirParcelTemperature, 
            forecastIndex, 
            isRainImgVisible, 
            counter,
            LIexpression_v1,
            LIexpression_v2,
            isAirParcelLifted, 
            userForecastIndex,
            isDataReseted
        } = this.state;
        
        return (
            <Fragment>
                <div className={classes.wrapper}>
                    <World 
                        isDataReseted={isDataReseted}
                        userForecastIndex={userForecastIndex}
                        cloudPositions={CLOUDS_POSITIONS}
                        airFlows={airFlows}
                        airParcel_y={airParcel_y}
                        forecastIndex={forecastIndex}
                        isRainImgVisible={isRainImgVisible}
                        environmentTemperature={environmentTemperature}
                        airParcelTemperature={tempAirParcelTemperature}/>
                    <Form 
                        isDataReseted={isDataReseted}
                        counter={counter}
                        LIexpression_v1={LIexpression_v1}
                        LIexpression_v2={LIexpression_v2}
                        environmentTemperature={environmentTemperature}
                        isAirParcelLifted={isAirParcelLifted}
                        generateTemperatures={this.handleGenerateTemperaturesClick}
                        checkForecast={(forecastIndex) => this.handleCheckForecastClick(forecastIndex)}
                        reset={this.handleResetClick}/>
                </div>
                <Notification 
                    notifications={this.state.notifications}
                    notificationDelay={10000}
                    deleteNotification={this.deleteNotificationHandler}/>
            </Fragment>
        );
    }
}
 
export default withTranslation() (SNA9);
