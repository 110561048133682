import React, { Component, Fragment } from 'react';
import classes from './SNA2c.module.css';
import Knee from '../../../components/SNA2/Knee/Knee';
import Triangle from '../../../components/SNA2/Triangle/TriangleC';
import Controls from '../../../components/SNA2/Controls/Controls';
import Syringe from '../../../components/SNA2/Syringe/Syringe';
import syringe from '../../../assets/trigonometry/syringe/syringe.png';
import line from '../../../assets/trigonometry/syringe/line.png';
import ProgressBar from '../../../components/SNA2/ProgressBar/ProgressBar';
import Notification from '../../../components/UI/Notification/Notification';
import positive_notification from '../../../assets/audio/positive_notification.wav';
import negative_notification from '../../../assets/audio/negative_notification.wav';
import { withTranslation } from 'react-i18next';
import SideDrawer from '../../../components/UI/SideDrawer/SideDrawer';
import QuickStartC from '../../../components/SNA2/QuickStart/QuickStartC';

const FLEX_DIRECTION_BOUNDARY = 1000;            // flex row / column change point: window width
const FLEX_DIRECTION_PROPORTION = 2.25;          // proportion between image width when flex direction changes: row and column
const KNEE_WIDTH = 0.4;                          // 40vw

// Initial coordinates of syringe red dot, depends on knee container width (vw)
const TRIANGLES_C_POINTS = [{ x: 0.679, y: 0.363 }, { x: 0.604, y: 0.655 }, { x: 0.371, y: 0.595 }, { x: 0.36, y: 0.368 }];

// Finish coordinates of syringe red dot, depends on knee container width (vw)
const TRIANGLES_A_POINTS = [{ x: 0.496 }, { x: 0.496 }, { x: 0.497 }, { x: 0.493 }];

// Different change of Y => depends on triangle angle
const Y_COEFICIENTS = [0.577, 1.73, 1, 0.753];

const SYRINGE_WIDTH = 83;
const SYRINGE_HEIGHT = 513;
const ROTATION_SPEED = 0.1;
const SYRINGE_SPEED = 0.3;

const MIN_INPUT_VALUE = -360;
const MAX_INPUT_VALUE = 360;

const INITIAL_ANGLE = 270;
const ANGLE_1_1 = 30;
const ANGLE_1_2 = -330;
const ANGLE_2_1 = 300;
const ANGLE_2_2 = -60;
const ANGLE_3_1 = 225;
const ANGLE_3_2 = -135;
const ANGLE_4_1 = 143;
const ANGLE_4_2 = -217;

const successSound = new Audio(positive_notification);
const failSound = new Audio(negative_notification);

class SNA2c extends Component {
    state = {
        progressBar: {
            size: 160,
            progress: 0,
            strokeWidth: 22,
            firstCircleStroke: '#cce5f3',
            secondCircleStroke: '#2a9af0',
            circleFillColor: 'none'
        },
        transparent: false,
        canStart: false,
        canPause: false,
        isNewTaskDisabled: false,
        isDataReseted: true,
        triangleIndex: null,
        coefficient: FLEX_DIRECTION_BOUNDARY * KNEE_WIDTH / FLEX_DIRECTION_BOUNDARY,
        x_initial: 0,
        y_initial: 0,
        x_current: 0,
        y_current: 0,
        finishPoint: 0,
        angle: null,
        currentAngle: 0,
        targetAngle: null,
        windowWidth: window.innerWidth,
        isTimerOn: false,
        isAnimationRan: false,
        notifications: [],
        isSideDrawerOpen: false
    }

    componentDidMount() {
        window.addEventListener("resize", this.handleResize);
        this.setState({
            coefficient: (window.innerWidth * (window.innerWidth > FLEX_DIRECTION_BOUNDARY ? KNEE_WIDTH : KNEE_WIDTH * FLEX_DIRECTION_PROPORTION)) / FLEX_DIRECTION_BOUNDARY
        });
    }

    componentWillUnmount() {
        window.removeEventListener("resize", this.handleResize);
    }

    handleResize = (event) => {
        this.setState({
            windowWidth: window.innerWidth,
            coefficient: (window.innerWidth * (window.innerWidth > FLEX_DIRECTION_BOUNDARY ? KNEE_WIDTH : KNEE_WIDTH * FLEX_DIRECTION_PROPORTION)) / FLEX_DIRECTION_BOUNDARY
        }, () => this.setSyringeInitialPosition(this.state.triangleIndex));
    };

    getFormData = (angle) => {
        this.setState({
            angle: +(+(angle.value)).toFixed(1),
            isNewTaskDisabled: true,
            canStart: angle.valid,
            isDataReseted: false
        });
        if (!this.state.isDataReseted) {
            this.setSyringeInitialPosition(this.state.triangleIndex);
            const updatedProgress = { ...this.state.progressBar };
            updatedProgress.progress = 0;
            updatedProgress.circleFillColor = 'none';
            this.setState({
                currentAngle: 270,
                progressBar: updatedProgress,
                canPause: false,
                notifications: [],
            });
        }
    }

    setTransparency = () => {
        this.setState({ transparent: !this.state.transparent });
    }

    setTriangle = (index) => {
        this.setState({ triangleIndex: index }, () => this.setSyringeInitialPosition());
    }

    setSyringeInitialPosition() {
        const x = window.innerWidth *
            (window.innerWidth > FLEX_DIRECTION_BOUNDARY ?
                KNEE_WIDTH : KNEE_WIDTH * FLEX_DIRECTION_PROPORTION) *
            TRIANGLES_C_POINTS[this.state.triangleIndex || 0].x - (SYRINGE_WIDTH * this.state.coefficient / 2);

        const y = window.innerWidth *
            (window.innerWidth > FLEX_DIRECTION_BOUNDARY ?
                KNEE_WIDTH : KNEE_WIDTH * FLEX_DIRECTION_PROPORTION) *
            TRIANGLES_C_POINTS[this.state.triangleIndex || 0].y;

        const A = window.innerWidth *
            (window.innerWidth > FLEX_DIRECTION_BOUNDARY ? KNEE_WIDTH : KNEE_WIDTH * FLEX_DIRECTION_PROPORTION) *
            TRIANGLES_A_POINTS[this.state.triangleIndex || 0].x -
            (SYRINGE_WIDTH * this.state.coefficient / 2);
        this.setState({
            x_initial: x,
            y_initial: y,
            x_current: x,
            y_current: y,
            currentAngle: 270,
            finishPoint: A
        });
    }

    handleStartClick = () => {
        if (!this.state.canPause) {
            this.setState({
                targetAngle: this.state.angle > 0 ? 270 - this.state.angle : -90 - this.state.angle,
                currentAngle: this.state.angle > 0 ? 270 : -90,
                canPause: true
            });
        }
        this.state.isTimerOn ? this.stopTimer() : this.startTimer();
    }

    handleResetClick = () => {
        clearInterval(this.timer);
        const updatedProgress = { ...this.state.progressBar };
        updatedProgress.progress = 0;
        updatedProgress.circleFillColor = 'none';
        this.setState({
            transparent: false,
            angle: null,
            targetAngle: null,
            currentAngle: 270,
            canStart: false,
            canPause: false,
            isNewTaskDisabled: false,
            isDataReseted: true,
            triangleIndex: null,
            progressBar: updatedProgress,
            isTimerOn: false,
            isAnimationRan: false,
            notifications: [],
        });
    }

    startTimer = () => {
        this.setState({
            isTimerOn: true,
            isAnimationRan: true
        });
        this.timer = setInterval(() => {
            this.rotateSyringe();
        }, 2);
    }

    stopTimer = () => {
        this.setState({ isTimerOn: false });
        clearInterval(this.timer);
    }

    rotateSyringe() {
        const updatedProgress = { ...this.state.progressBar };
        if (this.state.targetAngle !== +this.state.currentAngle.toFixed(1) && this.state.angle > 0) {
            updatedProgress.progress = updatedProgress.progress - ROTATION_SPEED;
            this.setState({ currentAngle: this.state.currentAngle - ROTATION_SPEED, progressBar: updatedProgress });
        }
        else if (this.state.targetAngle !== +this.state.currentAngle.toFixed(1) && this.state.angle < 0) {
            updatedProgress.progress = updatedProgress.progress + ROTATION_SPEED;
            this.setState({ currentAngle: this.state.currentAngle + ROTATION_SPEED, progressBar: updatedProgress });
        }
        else {
            updatedProgress.circleFillColor = '#b2f3c4';  //green
            this.setState({ progressBar: updatedProgress });
            this.moveSyringe();
        }
    }

    moveSyringe() {
        let updatedX = null;
        let updatedY = null;
        if (this.state.triangleIndex === 0 && ((this.state.angle - ANGLE_1_1 <= 1 && this.state.angle - ANGLE_1_1 >= -1) || (this.state.angle - ANGLE_1_2 <= 1 && this.state.angle - ANGLE_1_2 >= -1))) {
            updatedX = this.state.x_current - SYRINGE_SPEED
            updatedY = this.state.y_current + SYRINGE_SPEED * Y_COEFICIENTS[this.state.triangleIndex];
            this.setState({ x_current: updatedX, y_current: updatedY }, () => this.checkBounds());
        }
        else if (this.state.triangleIndex === 1 && ((this.state.angle - ANGLE_2_1 <= 1 && this.state.angle - ANGLE_2_1 >= -1) || (this.state.angle - ANGLE_2_2 <= 1 && this.state.angle - ANGLE_2_2 >= -1))) {
            updatedX = this.state.x_current - SYRINGE_SPEED;
            updatedY = this.state.y_current - SYRINGE_SPEED * Y_COEFICIENTS[this.state.triangleIndex];
            this.setState({ x_current: updatedX, y_current: updatedY }, () => this.checkBounds());
        }
        else if (this.state.triangleIndex === 2 && ((this.state.angle - ANGLE_3_1 <= 1 && this.state.angle - ANGLE_3_1 >= -1) || (this.state.angle - ANGLE_3_2 <= 1 && this.state.angle - ANGLE_3_2 >= -1))) {
            updatedX = this.state.x_current + SYRINGE_SPEED;
            updatedY = this.state.y_current - SYRINGE_SPEED * Y_COEFICIENTS[this.state.triangleIndex];
            this.setState({ x_current: updatedX, y_current: updatedY }, () => this.checkBounds());
        }
        else if (this.state.triangleIndex === 3 && ((this.state.angle - ANGLE_4_1 <= 1 && this.state.angle - ANGLE_4_1 >= -1) || (this.state.angle - ANGLE_4_2 <= 1 && this.state.angle - ANGLE_4_2 >= -1))) {
            updatedX = this.state.x_current + SYRINGE_SPEED;
            updatedY = this.state.y_current + SYRINGE_SPEED * Y_COEFICIENTS[this.state.triangleIndex];
            this.setState({ x_current: updatedX, y_current: updatedY }, () => this.checkBounds());
        }
        else {
            const updatedProgress = { ...this.state.progressBar };
            updatedProgress.circleFillColor = '#fac4c5';
            this.setState({
                angle: null,
                canStart: false,
                progressBar: updatedProgress,
                notifications: [this.getNotification('DANGER')],
            });
            this.stopTimer();
            this.playSound('FAIL');
        }
    }

    checkBounds() {
        if (((this.state.triangleIndex === 0 || this.state.triangleIndex === 1) && this.state.x_current <= this.state.finishPoint) ||
            ((this.state.triangleIndex === 2 || this.state.triangleIndex === 3) && this.state.x_current >= this.state.finishPoint)) {
            this.setState({
                angle: null,
                canStart: false,
                notifications: [this.getNotification('SUCCESS')],
            });
            this.stopTimer();
            this.playSound('SUCCESS');
        }
    }

    getNotification = (identifier) => {
        const { t } = this.props;
        if (identifier === 'SUCCESS') {
            return {
                id: 1,
                type: 'Success',
                title: t('SNA2.notifications.titles.success'),
                description: t('SNA2.notifications.descriptions.success')
            }
        }
        if (identifier === 'DANGER') {
            return {
                id: 1,
                type: 'Danger',
                title: t('SNA2.notifications.titles.fail'),
                description: t('SNA2.notifications.descriptions.danger')
            }
        }
    }

    deleteNotificationHandler = () => {
        this.setState({ notifications: [] });
    }

    playSound(identifier) {
        identifier === 'SUCCESS' ? successSound.play() : failSound.play();
    }

    openSideDrawerHandler = () => {
        this.setState({ isSideDrawerOpen: true });
    }

    closeSideDrawerHandler = () => {
        this.setState({ isSideDrawerOpen: false });
    }

    render() {
        const {
            coefficient,
            x_initial,
            y_initial,
            x_current,
            y_current,
            currentAngle,
            transparent,
            triangleIndex,
            angle,
            canStart,
            isNewTaskDisabled,
            isDataReseted,
            isTimerOn,
            notifications,
            isSideDrawerOpen,
            isAnimationRan
        } = this.state;
        return (
            <Fragment>
                <SideDrawer 
                    isSideDrawerOpen={isSideDrawerOpen}
                    openSideDrawer={this.openSideDrawerHandler}
                    closeSideDrawer={this.closeSideDrawerHandler}
                >
                    <QuickStartC 
                        index={triangleIndex}
                        isAnimationRan={isAnimationRan}
                    />
                </SideDrawer>
                <div className={classes.SNA8}>
                    <div className={classes.KneeContainer}>
                        <Syringe
                            index={triangleIndex}
                            position={{
                                transformOrigin: '50% 1%',
                                left: x_initial,
                                top: y_initial,
                                width: SYRINGE_WIDTH * coefficient,
                                height: SYRINGE_HEIGHT * coefficient,
                                transform: 'rotate(' + INITIAL_ANGLE + 'deg)',
                                backgroundImage: `url(${line})`
                            }} />
                        <Syringe
                            index={triangleIndex}
                            position={{
                                transformOrigin: '50% 1%',
                                left: x_current,
                                top: y_current,
                                width: SYRINGE_WIDTH * coefficient,
                                height: SYRINGE_HEIGHT * coefficient,
                                transform: 'rotate(' + currentAngle + 'deg)',
                                backgroundImage: `url(${syringe})`
                            }} />
                        <Knee style={{ backgroundColor: '#fff3f5' }} transparent={transparent} />
                        <Triangle
                            index={triangleIndex}
                            transparent={!transparent} />
                    </div>
                    <div style={{ width: window.innerWidth > FLEX_DIRECTION_BOUNDARY ? '40vw' : '90vw' }}>
                        <ProgressBar
                            {...this.state.progressBar}
                            maxValue={MAX_INPUT_VALUE}
                            units={'°'} />
                        <Controls
                            changeImage={this.setTransparency}
                            transparent={transparent}
                            index={triangleIndex}
                            randomMax={4}
                            setTriangle={this.setTriangle}
                            start={this.handleStartClick}
                            reset={this.handleResetClick}
                            formDataCallback={this.getFormData}
                            hypotenuse={angle}
                            minValue={MIN_INPUT_VALUE}
                            maxValue={MAX_INPUT_VALUE}
                            beforeInputText={'∠CAB'}
                            canStart={canStart}
                            isNewTaskDisabled={isNewTaskDisabled}
                            isDataReseted={!isDataReseted}
                            isAnimationRunning={isTimerOn} />
                    </div>
                </div>
                <Notification
                    notifications={notifications}
                    notificationDelay={10000}
                    deleteNotification={this.deleteNotificationHandler} />
            </Fragment>
        );
    }
}

export default withTranslation() (SNA2c);
