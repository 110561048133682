import React from 'react';
import classes from './Knee.module.css';
import knee_normal from '../../../assets/trigonometry/knee/knee.png';
import knee_transparent from '../../../assets/trigonometry/knee/knee_transparent.png';
import ImageWithLoading from '../../UI//ImageWithLoading/ImageWithLoading';
import useImagesLoaded from '../../../hooks/useImagesLoaded';

const Knee = (props) => {
    const [ref, imagesLoaded] = useImagesLoaded();

    return (
        <div ref={ref} className={classes.wrapper}>
            {!imagesLoaded && <ImageWithLoading />}
            <img style={props.style} width='1000' height='1000' alt="knee" src={!props.transparent ? knee_normal : knee_transparent}/>
        </div>
    );
}

export default Knee;
