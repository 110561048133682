import React, { Component } from 'react';
import classes from '../SNA11.module.css';
import Animation from '../../../components/SNA11/common_components/Animation/Animation';
import Controls from '../../../components/SNA11/common_components/Controls/Controls';
import Form from './Form/Form';
import numeration from '../../../assets/fountains_numeration.png';

const TIMER_DELAY = 150;
const FOUNTAINS_DEFAULT_HEIGHT = 0;

class SNA11c extends Component {
    state = {
        isWaterOn: false,
        isDataReseted: true,
        canStart: false,
        currentHeights: Array.from({length: 28}, () => FOUNTAINS_DEFAULT_HEIGHT),
        targetHeights: []
    }

    componentWillUnmount() {
        clearInterval(this.fountains);
    }

    formData = (targetHeights) => {
        this.setState({ 
            targetHeights,
            isDataReseted: false, 
            canStart: true,
        });
    }

    handleStartClick = () => {
        this.state.isWaterOn ? this.stopTimer() : this.startTimer();   
    }

    handleResetClick = () => {
        clearInterval(this.fountains);
        this.setState({
            isWaterOn: false,
            isDataReseted: true,
            canStart: false,
            currentHeights: Array.from({length: 28}, () => FOUNTAINS_DEFAULT_HEIGHT),
            targetHeights: []
        });
    }

    startTimer = () => {
        this.fountains = setInterval(() => { 
            this.animateFountains();
        }, TIMER_DELAY );
    }

    stopTimer = () => {
        clearInterval(this.fountains);
        this.setState({ isWaterOn: false });
    }

    animateFountains = () => {
        this.setState({ isWaterOn: true });
        const updatedCurrentHeights = [...this.state.currentHeights];
        for (const [i, targetValue] of this.state.targetHeights.entries()) {
            if (targetValue > this.state.currentHeights[i] && targetValue !== 0) {
                updatedCurrentHeights[i] += 0.2;
            } else {
                updatedCurrentHeights[i] -= Math.random() * 0.1;
            } 
        }
        this.setState({ currentHeights: updatedCurrentHeights });
    }

    render() { 
        const { isWaterOn, currentHeights, targetHeights, isDataReseted, canStart } = this.state;
        return (
            <div className={classes.SNA11}>
                <Animation 
                    numeration={numeration}
                    isWaterOn={isWaterOn}
                    currentHeights={currentHeights}
                    targetHeights={targetHeights}/>
                <Controls
                    isWaterOn={isWaterOn}
                    isDataReseted={isDataReseted}
                    canStart={canStart}
                    start={this.handleStartClick}
                    reset={this.handleResetClick}>
                    <Form 
                        isDataReseted={isDataReseted} 
                        formData={targetHeights  => this.formData(targetHeights)} /> 
                </Controls>
            </div>
        );
    }
}
 
export default SNA11c;
