import React from 'react';
import classes from './Rain.module.css';
import rain from '../../../assets/SNA9/rain.png';

const Rain = () => {
    return (
        <div 
            className={classes.rain} 
            style={{backgroundImage: `url(${rain})`}}/>
    );
}
 
export default Rain;
