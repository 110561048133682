import React from 'react';
import classes from './Controls.module.css';
import Button from '../../UI/Button/Button';
import { useTranslation } from 'react-i18next';
import Form from '../Form/Form';
import Info from '../Info/Info';
import SpeedControl from '../../SpeedControl/SpeedControl';
import { useSNA19Context } from '../../../context/SNA19-context';
import { IoDocuments, IoPlay, IoPause, IoReload } from "react-icons/io5";

const Controls = () => {
    const { t } = useTranslation();
    const { 
        isStarcraftAnimationRunning,
        isDataReseted, 
        canStart,
        handleStartClick, 
        handleResetClick, 
        handleNewVariantClick, 
        adjustAnimationSpeed
    } = useSNA19Context();

    return (
        <section className={classes.wrapper}>
            <Button 
                btnType='Info' 
                disabled={!isDataReseted}
                clicked={handleNewVariantClick}
            >
                <IoDocuments/> {t('buttons.new-variant')}
            </Button>
            <Info/>
            <Form/>
            <div className={classes.btns}>
                <Button 
                    btnType='Danger' 
                    disabled={isDataReseted || !canStart}
                    clicked={handleStartClick}
                >
                    {!isStarcraftAnimationRunning ? <IoPlay/> : <IoPause/>} {!isStarcraftAnimationRunning ? t('buttons.start') : t('buttons.pause')}
                </Button>
                <Button 
                    btnType='Primary' 
                    clicked={handleResetClick}
                >
                    <IoReload/> {t('buttons.reset')}
                </Button>
            </div>
            <SpeedControl 
                toggle={adjustAnimationSpeed}
                reset={isDataReseted}/>
        </section>
    );
}
 
export default Controls;
