import React from 'react';
import classes from './Controls.module.css';
import Button from '../../UI/Button/Button';
import { useTranslation } from 'react-i18next';
import { IoDocuments, IoPlay, IoPause, IoReload } from "react-icons/io5";
import Info from '../Info/Info';
import Form from '../Form/Form';
import SpeedControl from '../../SpeedControl/SpeedControl';
import { useSNA23Context } from '../../../context/SNA23-context';

const Controls = () => {
    const { t } = useTranslation();
    const { 
        handleNewVariantClick, 
        handleStartClick, 
        handleResetClick, 
        isTimerOn, 
        isDataReseted, 
        canStart,
        adjustAnimationSpeed
    } = useSNA23Context();

    return (
        <section className={classes.wrapper}>
            <Button 
                btnType='Info' 
                disabled={!isDataReseted}
                clicked={handleNewVariantClick}
            >
                <IoDocuments/> {t('buttons.new-variant')}
            </Button>
            <Info />
            <Form />
            <div className={classes.btns}>
                <Button 
                    btnType='Danger' 
                    disabled={isDataReseted || !canStart}
                    clicked={handleStartClick}
                >
                    {!isTimerOn ? <IoPlay/> : <IoPause/>} {!isTimerOn ? t('buttons.start') : t('buttons.pause')}
                </Button>
                <Button 
                    btnType='Primary' 
                    clicked={handleResetClick}
                >
                    <IoReload/> {t('buttons.reset')}
                </Button>
            </div>
            <SpeedControl toggle={adjustAnimationSpeed} />
        </section>
    );
}
 
export default Controls;
