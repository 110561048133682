import React, { useState } from 'react';
import Input from '../../UI/Input/Input';
import Button from '../../UI/Button/Button';
import { useTranslation } from 'react-i18next';
import { useSNA23Context } from '../../../context/SNA23-context';

const Form = () => {
    const { t } = useTranslation();
    const { setElectricityCost, isDataReseted } = useSNA23Context();

    const [cost, setCost] = useState({
        elementType: 'input',
        elementConfig: {
            type: 'number',
        },
        value: '',
        validation: {
            minCost: 0.01,
            maxCost: 150
        },
        valid: false,
        touched: false,
    });

    const inputChangedHandler = (event) => {
        setCost({
            ...cost, 
            value: event.target.value,
            touched: true,
            valid: checkValidity(event.target.value)
        });
    }

    const checkValidity = (value) => {
        let isValid = true;
        isValid = value.trim() !== '' && isValid;
        isValid = (value >= cost.validation.minCost && value <= cost.validation.maxCost) && isValid;
        return isValid;
    }

    const formSubmissionHandler = (event) => {
        event.preventDefault();
        setElectricityCost(+cost.value);
    }

    const { elementType, elementConfig, value, validation: { minCost, maxCost }, valid, touched } = cost;
    
    return (  
        <form onSubmit={formSubmissionHandler}>
            <Input
                elementType={elementType}
                elementConfig={elementConfig}
                placeholder={t('SNA23.form.placeholder')}
                value={value}
                invalid={!valid}
                touched={touched}
                label={t('SNA23.form.label')}
                errorMessage={t('SNA23.form.error', {minValue: minCost, maxValue: maxCost})}
                disabled={!isDataReseted}
                changed={inputChangedHandler}
            />
            <Button 
                btnType='Success'
                disabled={!valid || !isDataReseted}
            >
                {t('buttons.submit')}
            </Button>
        </form>
    );
}
 
export default Form;
