import React, { useEffect } from 'react';
import ToggleSwitch from '../UI/ToggleSwitch/ToggleSwitch';
import classes from './SpeedControl.module.css';
import { GiTurtle, GiRabbit } from 'react-icons/gi';
import useToggle from '../../hooks/useToggle';

const SpeedControl = ({toggle, reset}) => {
    const [value, toggleValue, resetValue] = useToggle(false);

    useEffect(() => {
        reset && resetValue();
    }, [reset, resetValue]);

    return (
        <div className={classes.wrapper}>
            <GiRabbit />
            <ToggleSwitch 
                sliderColor='SliderWhite'
                sliderType='Rounded'
                isToggled={value}
                onToggle={() => { toggleValue();  toggle(); }}
            />
            <GiTurtle />
        </div>
    );
}
 
export default SpeedControl;