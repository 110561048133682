import React, { useState, useEffect } from 'react';
import classes from './Accordion.module.css';
import { FaChevronRight } from 'react-icons/fa';

const Accordion = ({title, children, showContentOutside}) => {
    const [showContent, setShowContent] = useState(false);

    useEffect(() => {
        showContentOutside && setShowContent(true);
    }, [showContentOutside]);

    return (
        <article className={`${classes.wrapper} ${classes[showContent && 'content-visible']}`}>
            <header 
                className={classes.header} 
                style={{borderBottom: showContent ? '1px solid #ccc' : 'none'}}
                onClick={() => setShowContent(!showContent)}
            >
                <h4>{title}</h4>
                <button className={classes.btn}>
                    <FaChevronRight />
                </button>
            </header>
            <div className={classes.content} style={{display: !showContent && 'none'}}>
                {children}
            </div>
        </article>
    );
}
 
export default Accordion;
