import React from 'react';
import classes from './Controls.module.css';
import Button from '../../UI/Button/Button';
import { useTranslation } from 'react-i18next';
import Form from '../Form/Form';
import { IoDocuments, IoPlay, IoReload } from "react-icons/io5";
import ImageControl from '../ImageControl/ImageControl';
import Info from '../Info/Info';
import { useSNA22Context } from '../../../context/SNA22-context';

const Controls = () => {
    const { t } = useTranslation();
    const { isDataReseted, canShow, handleNewVariantClick, handleShowClick, handleResetClick } = useSNA22Context();
    return (
        <section className={classes.wrapper}>
            <Button 
                btnType='Info' 
                disabled={!isDataReseted}
                clicked={handleNewVariantClick}
            >
                <IoDocuments/> {t('buttons.new-variant')}
            </Button>
            <ImageControl/>
            <Info />
            <Form />
            <div className={classes.btns}>
                <Button 
                    btnType='Danger' 
                    disabled={isDataReseted || !canShow}
                    clicked={handleShowClick}
                >
                    <IoPlay/>  {t('buttons.show')}
                </Button>
                <Button 
                    btnType='Primary' 
                    clicked={handleResetClick}
                >
                    <IoReload/> {t('buttons.reset')}
                </Button>
            </div>
        </section>
    );
}
 
export default Controls;