import React, { useState, useEffect, useReducer } from 'react';
import Accordion from '../../UI/Accordion/Accordion';
import Controls from '../Controls/Controls';
import classes from './Content.module.css';
import Grinder from '../Grinder/Grinder';
import { useTranslation } from 'react-i18next';
import Chart from '../../Chart/Chart';
import { useSNA23Context } from '../../../context/SNA23-context';
import { getNotification } from '../../../utils/SNA23/utils';
import Notification from '../../UI/Notification/Notification';
import { round } from '../../../utils/helpers';
import SideDrawer from '../../UI/SideDrawer/SideDrawer';
import useToggle from '../../../hooks/useToggle';
import QuickStart from '../QuickStart/QuickStart';

const TIMER_DELAY = 40;
const SLOWED_TIMER_DELAY = 80;
const STEPS = 200;
const WARNING_THRESHOLD = 1.05;
const NOTIFICATION_DELAY = 20000;

const chartReducer = (state, action) => {
    if (action.type === 'UPDATE_COORDS') {
        const { realCost, userCost, grainAmount } = action.payload;
        const deltaX = grainAmount / STEPS;
        const realDeltaY = realCost / STEPS;
        const userDeltaY = userCost / STEPS;
        const [ ,[prevRealX, prevRealY]] = state.realData.coords;
        const [ ,[prevUserX, prevUserY]] = state.userData.coords;
        return {
            ...state, 
            realData: {
                ...state.realData,
                coords: [[0, 0], [prevRealX + deltaX, prevRealY + realDeltaY]]
            },
            userData: {
                ...state.userData,
                coords: [[0, 0], [prevUserX + deltaX, prevUserY + userDeltaY]]
            },
        };
    }
    if (action.type === 'RESET_COORDS') {
        return {
            ...state, 
            realData: {
                ...state.realData,
                coords: [[0, 0], [0, 0]]
            },
            userData: {
                ...state.userData,
                coords: [[0, 0], [0, 0]]
            },
        };
    }
    if (action.type === 'UPDATE_LANG') {
        return {
            ...state,
            realData: {
                ...state.realData,
                settings: {
                    ...state.realData.settings,
                    name: action.payload.t('SNA23.chart.legends.l2')
                }
            },
            userData: {
                ...state.userData,
                settings: {
                    ...state.userData.settings,
                    name: action.payload.t('SNA23.chart.legends.l1')
                }
            },
        };
    }
};

const SNA23 = () => {
    const { t } = useTranslation();
    const { 
        data: { grainAmount, correctCost, yMax }, 
        electricityCost, 
        isTimerOn, 
        isAnimationSpeedSlowed, 
        stopAnimation 
    } = useSNA23Context();

    const [isOpen, setIsOpen] = useToggle(false);
    const [notifications, setNotifications] = useState([]);
    const [axisData, setAxisData] = useState();
    const initialState = {
        realData: {
            settings: {color: 'black', lineWidth: 5, dashes: [10, 5], name: t('SNA23.chart.legends.l2')},
            coords: [[0, 0], [0, 0]]
        },
        userData: {
            settings: {color: 'red', lineWidth: 5, dashes: [5, 10], name: t('SNA23.chart.legends.l1')},
            coords: [[0, 0], [0, 0]]
        },
    }

    const [chartState, dispatchChartData] = useReducer(chartReducer, initialState);

    useEffect(() => {
        setAxisData([[0, 0], [grainAmount, yMax > electricityCost ? yMax : electricityCost]]);
    }, [yMax, electricityCost, grainAmount]);

    useEffect(() => {
        dispatchChartData({ type: 'UPDATE_LANG', payload: { t } });
    }, [t]);

    useEffect(() => {
        let intervalId = null;
        if (isTimerOn) {
            intervalId = setInterval(() => {
                dispatchChartData({ 
                    type: 'UPDATE_COORDS', 
                    payload: {
                        realCost: correctCost, 
                        userCost: electricityCost, 
                        grainAmount: grainAmount
                    } 
                });
                if (chartState.realData.coords[1][0] >= grainAmount) {
                    const data = {
                        difference: round(correctCost > electricityCost ? correctCost / electricityCost : electricityCost / correctCost, 2),
                        isMore: correctCost < electricityCost 
                    };
                    stopAnimation();
                    setNotifications(
                        correctCost === electricityCost ? 
                            [getNotification('SUCCESS', t)] : 
                            data.difference > WARNING_THRESHOLD ? [getNotification('DANGER', t, data)] : [getNotification('WARNING', t)]
                    );
                }
            }, isAnimationSpeedSlowed ? SLOWED_TIMER_DELAY : TIMER_DELAY);
        } else if (!electricityCost && chartState.realData.coords[1][0] !== 0) { 
            dispatchChartData({ type: 'RESET_COORDS' });
            setNotifications([]);
        } else {
            clearInterval(intervalId);
        }
        return () => clearInterval(intervalId);
    }, [isTimerOn, correctCost, electricityCost, grainAmount, isAnimationSpeedSlowed, chartState, stopAnimation, t]);

    const openSideDrawerHandler = () => {
        setIsOpen(true);
    }

    const closeSideDrawerHandler = () => {
        setIsOpen(false);
    }

    return (
        <main className={classes.wrapper}>
            <section className={classes.accordion}>
                <Accordion 
                    title={t('SNA23.accordion')}
                    showContentOutside={electricityCost}
                >
                    <Chart 
                        labels={{x: 'kg', y: '€'}} 
                        axisData={axisData}
                        drawingData={chartState} />
                </Accordion>
            </section>
            <Grinder />
            <Controls />
            <Notification
                notifications={notifications}
                notificationDelay={NOTIFICATION_DELAY}
                deleteNotification={() => setNotifications([])}
            />
            <SideDrawer
                isSideDrawerOpen={isOpen}
                openSideDrawer={openSideDrawerHandler}
                closeSideDrawer={closeSideDrawerHandler}
            >
                <QuickStart />
            </SideDrawer>
        </main>
            
    );
}
 
export default SNA23;
