import React from 'react';
import classes from './Syringe.module.css';

const Syringe = (props) => {
    
    return (
        props.index !== null && <div 
            className={classes.Syringe} 
            style={props.position}/>
    );
}
 
export default Syringe;
