import React from 'react';
import classes from './WaterSpray.module.css';

const waterSpray = (props) => {
    return (
        props.waterSpray === 'On' ?  <div className={classes.WaterSpray}/> : null
    );
}
 
export default waterSpray;
