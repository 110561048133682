import React, { useCallback, useState, useEffect } from 'react';
import Controls from '../Controls/Controls';
import FootballField from '../FootballField/FootballField';
import classes from './Content.module.css';
import { useTranslation } from 'react-i18next';
import { useSNA22Context } from '../../../context/SNA22-context';
import Notification from '../../UI/Notification/Notification';
import SideDrawer from '../../UI/SideDrawer/SideDrawer';
import QuickStart from '../QuickStart/QuickStart';
import useToggle from '../../../hooks/useToggle';

const Content = () => {
    const { i18n } = useTranslation();
    const { data: { correctAngle }, isMascotShadowVisible, mascotShadowAngle } = useSNA22Context();

    const [isOpen, setIsOpen] = useToggle(false);
    const [notifications, setNotifications] = useState([]);

    const getNotification = useCallback((identifier) => {
        if (identifier === 'SUCCESS') {
            return {
                id: 1,
                type: 'Success',
                title: i18n.t('SNA22.notifications.titles.success'),
                description: i18n.t('SNA22.notifications.descriptions.success')
            }
        }
        if (identifier === 'DANGER') {
            return {
                id: 2,
                type: 'Danger',
                title: i18n.t('SNA22.notifications.titles.fail'),
                description: i18n.t('SNA22.notifications.descriptions.fail')
            }
        }
        if (identifier === 'WARNING') {
            return {
                id: 3,
                type: 'Warning',
                title: i18n.t('SNA22.notifications.titles.warning'),
                description: i18n.t('SNA22.notifications.descriptions.warning')
            }
        }
    }, [i18n]);

    const deleteNotificationHandler = useCallback(() => {
        setNotifications([]);
    }, []);

    
    useEffect(() => {
        if (isMascotShadowVisible) {
            mascotShadowAngle === correctAngle ? 
                setNotifications([getNotification('SUCCESS')]) : 
                Math.abs(mascotShadowAngle - correctAngle) > 5 ? 
                    setNotifications([getNotification('DANGER')]) : 
                    setNotifications([getNotification('WARNING')]);
        }
    }, [isMascotShadowVisible, mascotShadowAngle, correctAngle, getNotification]);

    const openSideDrawerHandler = () => {
        setIsOpen(true);
    }

    const closeSideDrawerHandler = () => {
        setIsOpen(false);
    }

    return (
        <main className={classes.wrapper}>
            <FootballField />
            <Controls />
            {!!notifications.length &&<Notification 
                notifications={notifications}
                notificationDelay={10000}
                deleteNotification={deleteNotificationHandler}
            />}
            <SideDrawer
                isSideDrawerOpen={isOpen}
                openSideDrawer={openSideDrawerHandler}
                closeSideDrawer={closeSideDrawerHandler}
            >
                <QuickStart />
            </SideDrawer>
        </main>
            
    );
}
 
export default Content;
