import React, { Fragment } from 'react';
import Accordion from '../../UI/Accordion/Accordion';
import classes from './QuickStart.module.css';
import lens from '../../../assets/SNA25/quick-start/lens.png';
import form_vertex from '../../../assets/SNA25/quick-start/form-vertex.png';
import form_deviation from '../../../assets/SNA25/quick-start/form-deviation.png';
import { useSNA25Context } from '../../../context/SNA25-context';

const QuickStart = () => {
    const { crossMarkCoords, isParabolaHidden, isDataReseted, currentData: {id} } = useSNA25Context();

    return (
        <Fragment>
            <Accordion title={'Sissejuhatus'}>
                <p className={classes.paragraph}>Tänapäeva tootmises toodangu kvaliteeti kontrollivad spetsiaalsed kaamerad, mis oskavad ära tunda, kui toodangul on mingi defekt küljes. Need kaamerad kasutavad oma töös erinevaid matemaatilisi meetmeid. See programm näitab sulle, mis põhimõttel töötavad sellised kaamerad. Saad kasutada oma teadmisi parabooli haripunkti leidmisest.</p>
            </Accordion>
            <Accordion title={'Kelle tööd see programm simuleerib?'}>
                <p className={classes.paragraph}>Inimesi, kes töötavad tootmises testimissüsteemidega kutsutakse testimisspetsialistideks ja testiinsenerideks. See programm näitab sulle väikest osa nende tööst.</p>
            </Accordion>
            <Accordion title={'Kuidas programmi kasutada?'}>
            <div className={classes.step}>
                    <span className={classes.numeration}>1.</span>
                    <p className={classes.paragraph}>Alusta programmi kasutamist vajutades nuppu "Uus ülesanne".</p>
                </div>
                <div className={`${classes['step-with-img']} ${id === null && classes.filter}`}>
                    <div className={classes['step-inner']}>
                        <span className={classes.numeration}>2.</span>
                        <p className={classes.paragraph}>Programm näitab sulle paraboolse kujuga läätse. Tee topelt klõps seal, kus sinu arvates asub läätse haripunkt. Pööra tähelepanu, et programm näitab sulle saadud koordinaadid, näiteks:</p>
                    </div>
                    <img className={classes['lens-img']} src={lens} alt='lens vertex coordinates' />
                </div>
                <div className={`${classes.step} ${!crossMarkCoords.x && classes.filter}`}>
                    <span className={classes.numeration}>3.</span>
                    <p className={classes.paragraph}>Programm näitab sulle ideaalse parabooli valemit, mis vastab sellele läätsele, näiteks <span className={classes.expression}>y=-0.06x<span className={classes.exponent}>2</span>+x+3.3</span>. Vajuta nuppu "Näita parabooli", et näha selle parabooli graafikut.</p>
                </div>
                <div className={`${classes['step-with-img']} ${isParabolaHidden && classes.filter}`}>
                    <div className={classes['step-inner']}>
                        <span className={classes.numeration}>4.</span>
                        <p className={classes.paragraph}>Arvuta ideaalse parabooli haripunkti koordinaate ja sisesta neid programmi:</p>
                    </div>
                    <img className={classes['form-img']} src={form_vertex} alt='parabola vertex coordinates' />
                </div>
                <div className={`${classes['step-with-img']} ${isParabolaHidden && classes.filter}`}>
                    <div className={classes['step-inner']}>
                        <span className={classes.numeration}>5.</span>
                        <div>
                            <p className={classes.paragraph}>Arvuta, mitme protsendi võrra reaalse läätse ja selle ideaalse mudeli haripunkti koordinaadid erinevad. Sisesta need hälbed programmi: </p>
                            <p className={`${classes.paragraph} ${classes.italic}`}><strong>NB! </strong>Protsentide arvutamisel võta tervikuks parabooli koordinaadid. Hälve võib olla nii positiivne kui ka negatiivne.</p>
                        </div>
                    </div>
                    <img className={classes['form-img']} src={form_deviation} alt='deviation percentage' />
                </div>
                <div className={`${classes.step} ${isParabolaHidden && classes.filter}`}>
                    <span className={classes.numeration}>6.</span>
                    <p className={classes.paragraph}>Juhul, kui kasvõi ühe koordinaadi erinevus on suurem, kui 3%, on lääts defektiga. Vajuta vastavat nupu.</p>
                </div>
                <div className={`${classes.step} ${isDataReseted && classes.filter}`}>
                    <span className={classes.numeration}>7.</span>
                    <p className={classes.paragraph}>Järgmise ülesande jaoks vajuta "RESET" ning seejärel nuppu "Uus ülesanne".</p>
                </div>
            </Accordion>
        </Fragment>
    );
}
 
export default QuickStart;
