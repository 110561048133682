import React from 'react';
import IncrementControl from '../../../components/UI/IncrementControl/IncrementControl';
import classes from './IncrementControls.module.css';
import { useTranslation } from 'react-i18next';

const IncrementControls = (props) => {
    const { variablesArray, isAnimationRunning, isDataReseted } = props;
    const { t } = useTranslation();
    return (
        <div className={classes['increment-controls']}>
            {variablesArray.map((variable, index) => (
                    <div key={variable.type} className={classes['increment-control']}>
                        <span className={classes['variable-type']}>
                            {`${variable.type}=`}
                        </span>
                        <IncrementControl 
                            value={variable.value}
                            minusDisabled={variable.value <= 0 || isAnimationRunning || !isDataReseted}
                            plusDisabled={variable.value >= ((variable.type === 'a' || variable.type === 'b'|| variable.type === 'c') ? 5 : 50) || isAnimationRunning || !isDataReseted}
                            added={() => props.incrementValue( variablesArray[index] )}
                            removed={() => props.decrementValue( variablesArray[index] )}
                            style={{ margin: '0', flexDirection: 'column-reverse', alignItems: 'center'}}
                        />
                        {(variable.type === 'a' || variable.type === 'b' || variable.type === 'c') ? 
                            <span className={classes.unit}>&#13216;</span> : 
                            <span className={classes.unit}>{t('SNA15.units.milliliter')}</span>
                        }
                    </div>
                ))}
        </div>
    );
}
 
export default IncrementControls;
