import React, { Component, Fragment } from 'react';
import GraphingCalculator from './GraphingCalculator/GraphingCalculator';
import classes from './SNA3c.module.css';
import FormBuilder from './FormBuilder/FormBuilder';
import SideDrawer from '../../../components/UI/SideDrawer/SideDrawer';
import QuickStartC from '../../../components/SNA3/QuickStartC';

class SNA3c extends Component {
    vehicleInitialPosition = React.createRef();
    startStopAnimation = React.createRef();
    state = { 
        settings: {
            autosize: true,  
            settingsMenu: false, 
            zoomButtons: false, 
            lockViewport: false,
            expressionsCollapsed: true,
            projectorMode: true, 
            expressions: true,
            showGrid: false,
        },
        mathBounds: {
            left: -20,
            right: 20,
            bottom: -20,
            top: 20
        },
        xValues: [],
        yValues: [],
        angleValues: [],
        canStart: false,
        isAnimationRunning: false,
        isDataReseted: true,
        inputDisabled: false,
        isSideDrawerOpen: false,
     }
   
    getFormData = (pointsData) => {
        const points = [];
        pointsData.forEach(point => points.push(point.value));

        const xAxisCoordinatesArray = [];
        const yAxisCoordinatesArray = [];
        const anglesArray = [];
        
        for (let i = 0; i < points.length; i++) {
            const subArray = points[i].slice(1, -1).split(',');
                xAxisCoordinatesArray.push(Number(subArray[0].trim()));
                yAxisCoordinatesArray.push(Number(subArray[1].trim()));   
        }

        for (let i = 0; i < xAxisCoordinatesArray.length - 1; i++) {
            const angle = 90 - Math.atan2(yAxisCoordinatesArray[i + 1] - yAxisCoordinatesArray[i], 
                xAxisCoordinatesArray[i + 1] - xAxisCoordinatesArray[i]) * 180 / Math.PI;
            anglesArray.push(angle);   
        }

        const updatedSettings = {...this.state.settings};
        updatedSettings.lockViewport = true;
        updatedSettings.expressions = false;

        this.setState({
            settings: updatedSettings,
            xValues: xAxisCoordinatesArray,
            yValues: yAxisCoordinatesArray, 
            angleValues: anglesArray,
            canStart: true,
            inputDisabled: true,
            
        }, () =>
            this.vehicleInitialPosition.current.setVehicleInitialPosition());
    }

    handleStartClick = () => {
        this.setState({
            isAnimationRunning: !this.state.isAnimationRunning, 
            isDataReseted: false
        }, () => this.vehicleInitialPosition.current.setVehicleInitialPosition());
    }

    handleResetClick = () => {
        const updatedSettings = {...this.state.settings};
        updatedSettings.lockViewport = false;
        updatedSettings.expressions = true;
        this.setState({
            settings: updatedSettings,
            xValues: [],
            yValues: [],
            angleValues: [],
            canStart: false,
            isAnimationRunning: false,
            isDataReseted: true,
            inputDisabled: false,
        }, () => 
            this.vehicleInitialPosition.current.setVehicleInitialPosition());
    }

    showHideGrid = () => {
        const updatedSettings = {...this.state.settings};
        updatedSettings.showGrid = !this.state.settings.showGrid;
        this.setState({ settings: updatedSettings }, () => this.vehicleInitialPosition.current.setVehicleInitialPosition('grid'));
    }

    showHideLinesAndPoints = () => {
        this.vehicleInitialPosition.current.setVehicleInitialPosition('linesAndPoints');
    }

    restoreInitialSettings = () => {
        this.setState({
            //xValues: [],
            //yValues: [],
            //angleValues: [],
            canStart: false,
            isAnimationRunning: false,
        });
    }

    openSideDrawerHandler = () => {
        this.setState({ isSideDrawerOpen: true });
    }

    closeSideDrawerHandler = () => {
        this.setState({ isSideDrawerOpen: false });
    }

    render() { 
        const { isSideDrawerOpen } = this.state;
        
        return (
            <Fragment>
                <div className={classes.CoordinatePlane}>
                    <GraphingCalculator 
                        settings={this.state.settings} 
                        mathBounds={this.state.mathBounds}
                        xValues={this.state.xValues}
                        yValues={this.state.yValues} 
                        angleValues={this.state.angleValues}
                        destinationValues={this.state.destinationValues}
                        ref={this.vehicleInitialPosition}
                        isAnimationRunning={this.state.isAnimationRunning}
                        isDataReseted={this.state.isDataReseted}
                        restoreInitialSettings={this.restoreInitialSettings}/> 
                    <FormBuilder 
                        className={classes.FormBuilder} 
                        canStart={this.state.canStart}
                        isDataReseted={this.state.isDataReseted}
                        isAnimationRunning={this.state.isAnimationRunning}
                        inputDisabled={this.state.inputDisabled}
                        formDataCallback={this.getFormData}
                        start={this.handleStartClick}
                        reset={this.handleResetClick}
                        showHideLinesAndPoints={this.showHideLinesAndPoints}
                        showHideGrid={this.showHideGrid}/>   
                </div> 
                <SideDrawer 
                    isSideDrawerOpen={isSideDrawerOpen}
                    openSideDrawer={this.openSideDrawerHandler}
                    closeSideDrawer={this.closeSideDrawerHandler}
                >
                    <QuickStartC/>
                </SideDrawer>
            </Fragment>
        );
    }
}
 
export default SNA3c;
