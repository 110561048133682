import React from 'react';
import classes from './Mascot.module.css';
import { useSNA22Context } from '../../../context/SNA22-context';

const ANGLE_SHIFT = 90; // difference betwewn trigonometric and css angle

const Mascot = () => {
    const { 
        mascotShadowAngle, 
        isMascotShadowVisible, 
        data: { 
            mascot: { 
                image, 
                position: { left, top }, 
                size: { width, height, divShadowHeight, divShadowRight } 
            } 
        } 
    } = useSNA22Context();

    let heightShift, rotationAngle, shadowAngle = mascotShadowAngle - ANGLE_SHIFT;

    if (shadowAngle >= -82 || shadowAngle <= 82) {
        shadowAngle >= -10 && shadowAngle <= 10 ? heightShift = -20 : heightShift = Math.abs(shadowAngle * 0.9);  
    }
    if (shadowAngle < -82 || shadowAngle > 82) {
        rotationAngle = shadowAngle < 0 ? Math.abs(shadowAngle) : shadowAngle * -1;  
    }
    return (
        <div 
            className={classes.wrapper} 
            style={{
                '--left': left, 
                '--top': top, 
                '--width': width, 
                '--height': height, 
                '--height-shift': heightShift,
                '--mascot-shadow-angle': shadowAngle
            }}
        >
            {isMascotShadowVisible && (shadowAngle >= -82 && shadowAngle <= 82) && <img src={image} alt='back-mascot' />}
            {isMascotShadowVisible && (shadowAngle < -82 || shadowAngle > 82) && 
                <div style={{'--rotation-angle': rotationAngle, '--shadow-height': divShadowHeight,'--shadow-right': divShadowRight}} />
            }
            <img src={image} alt='front-mascot' />
        </div>
    );
}
 
export default Mascot;
