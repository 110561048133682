import React from 'react';
import classes from './Box.module.css';

const Box = ({ waterVolume, plantsArea, cubePosition }) => {
    
    return (
        <div className={classes.wrapper}>
            <div className={classes.box} style={{'--cubePosition': cubePosition}}>
                <div className={`${classes.side} ${classes.front}`}>
                    {new Array(4).fill(0).map((_, index) => (
                        <div 
                            key={index} 
                            className={classes.plant}
                            style={{'--size': plantsArea * 0.043 > 5.4 ? 5.4 : plantsArea * 0.043}} />
                    ))} 
                </div>
                <div className={`${classes.side} ${classes.back}`}></div>
                <div className={`${classes.side} ${classes.right}`}></div>
                <div className={`${classes.side} ${classes.left}`}></div>
                <div className={`${classes.side} ${classes.top}`}></div>
                <div className={`${classes.side} ${classes.bottom}`}>{`${waterVolume?.toFixed(3) || 0} L`}</div>
            </div>
        </div>
    );
}
 
export default Box;
