export const getNotification = (identifier, t, data) => {
    if (identifier === 'SUCCESS') {
        return {
            id: 1,
            type: 'Success',
            title: t('SNA23.notifications.titles.success'),
            description: t('SNA23.notifications.descriptions.success')
        }
    }
    if (identifier === 'DANGER') {
        const { difference, isMore } = data;
        return {
            id: 2,
            type: 'Danger',
            title: t('SNA23.notifications.titles.fail'),
            description: t('SNA23.notifications.descriptions.fail', {
                difference, 
                status: isMore ? t('SNA23.notifications.results.more') : t('SNA23.notifications.results.less'),
                position: isMore ? t('SNA23.notifications.results.above') : t('SNA23.notifications.results.below')
            })
        }
    }
    if (identifier === 'WARNING') {
        return {
            id: 3,
            type: 'Warning',
            title: t('SNA23.notifications.titles.warning'),
            description: t('SNA23.notifications.descriptions.warning')
        }
    }
};