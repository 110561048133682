import React, { Component, Fragment } from 'react';
import GraphingCalculator from './GraphingCalculator/GraphingCalculator';
import classes from './SNA1.module.css';
import Theater from '../../components/SNA1/Theater';
import Form from './Form/Form';
import Button from '../../components/UI/Button/Button';
import { create, all } from 'mathjs';
import DisplayFA from '../../components/UI/Display/DisplayFA';
import DisplayWithRadioButtonsFA from '../../components/UI/Display/DisplayWithRadioButtonsFA';
import { faClock, faLightbulb, faQuestionCircle } from '@fortawesome/free-solid-svg-icons';
import Modal from '../../components/UI/Modal/Modal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { withTranslation } from 'react-i18next';

const INTERVAL_FREQUENCY = 50;
const MATH = create(all);
const PARSER = MATH.parser();

const TRIG_FUNCTIONS = ["arcsin", "arccos", "arctan", "arccot", "arcsec", "arccsc", "sin", "cos", "tan", "cot", "sec", "csc"];
const TRIG_FUNCTIONS_NM = ["arcsin", "arccos", "arctan", "arccot", "arcsec", "arccsc"]; // For math.js need to modify arcsin -> asin ...
const TRIG_FUNCTIONS_S = ["asin", "acos", "atan", "acot", "asec", "acsc"];

class SNA1a extends Component {
    video_1 = React.createRef();
    video_2 = React.createRef();
    state = {
        settings: {
            autosize: true,  
            settingsMenu: false, 
            zoomButtons: false, 
            lockViewport: false,
            expressionsCollapsed: true,
            projectorMode: true, 
            expressions: false,
        },
        mathBounds: {
            left: -100,
            right: 100,
            bottom: -100,
            top: 100
        },
        func: '0',
        funcMJS: '0',              // Function for mathjs
        trigonometryFunction: null,
        startDisabled: true,
        isDataReseted: true,
        time: 0, 
        start: 0,
        iterator: 0,
        isOn: false,
        stopWatchSpeed: 0,
        error: false,
        isVideo_1_ModalOpen: false,
        isVideo_2_ModalOpen: false,
        inputDisabled: false,
    }

    openModal = (videoNumber) => {
        videoNumber === 1 ? this.setState({ isVideo_1_ModalOpen: true }) : this.setState({ isVideo_2_ModalOpen: true });
    }

    closeModal = (videoNumber) => {
        videoNumber === 1 ? this.setState({ isVideo_1_ModalOpen: false }) : this.setState({ isVideo_2_ModalOpen: false });
        this.stopVideo(videoNumber);
    }

    stopVideo = (videoNumber) => {
        const iframe = videoNumber === 1 ? this.video_1.current : this.video_2.current;
        const iframeSrc = iframe.src;
        iframe.src = iframeSrc;
    }
 
    handleStartClick = () => {
        this.state.isOn ? this.stopTimer() : this.startTimer();
    }

    startTimer = () => {
        this.setState({
            time: this.state.time,
            start: Date.now() - this.state.time,
            isOn: true
        });
        this.timer = setInterval(() => this.setState({
            time: Date.now() - this.state.start - (this.state.stopWatchSpeed * INTERVAL_FREQUENCY * this.state.iterator),
            iterator: this.state.iterator + 1,
        }), INTERVAL_FREQUENCY);
    }

    stopTimer = () => {
        this.setState({
            isOn: false,
            iterator: 0
        });
        clearInterval(this.timer);
    }

    resetTimer = () => {
        clearInterval(this.timer);
        this.setState({
            func: '0',
            funcMJS: '0',
            trigonometryFunction: null,
            startDisabled: true, 
            isDataReseted: true,
            setFunctionDisabled: false,
            time: 0,
            start: 0,
            iterator: 0,
            isOn: false, 
            error: false,
            inputDisabled: false 
        });
    }

    setStopwatchSpeed = (event) => {
        this.setState({
            stopWatchSpeed: Number(event.target.value),
            iterator: 1,
            start: Date.now() - this.state.time,
        });
    }

    getFormData = (func) => {
        this.setTringonometryFunc(func);
        const modifiedTrigonometryFunc = this.modifyTrigonometryFunc(func);
        let funcMJS = modifiedTrigonometryFunc === 0 ? func : modifiedTrigonometryFunc;
        funcMJS = func = funcMJS.startsWith('y') ? funcMJS.replace('y', 'f(x)') : funcMJS;
        
        this.setState({
            func, 
            funcMJS,
            startDisabled: !this.state.startDisabled,
            isDataReseted: false,
            inputDisabled: true 
        }, () => this.setFunctionType());
    }

    setFunctionType = () => {
        try {
            PARSER.evaluate('x = 0');
            PARSER.evaluate(this.state.funcMJS);
            PARSER.evaluate('f(x)');
            this.setState({ error: false});
        } catch (err) {
            this.setState({ error: true, startDisabled: true });
        }    
    }

    setTringonometryFunc(func) {
        for (let i = 0; i < TRIG_FUNCTIONS.length; i++) {
            const n = func.indexOf(TRIG_FUNCTIONS[i]);
            if (n !== -1) {
                const updatedFunction = func.replace(new RegExp(TRIG_FUNCTIONS[i], 'g'), '\\' + TRIG_FUNCTIONS[i]);
                this.setState({ trigonometryFunction: updatedFunction  });
                break;
            }
        }
    }

    modifyTrigonometryFunc(func) {
        for (let i = 0; i < TRIG_FUNCTIONS_NM.length; i++) {
            const n = func.indexOf(TRIG_FUNCTIONS_NM[i]);
            if (n !== -1) {
                const updatedFunction = func.replace(new RegExp(TRIG_FUNCTIONS_NM[i], 'g'), TRIG_FUNCTIONS_S[i]);
                return updatedFunction;
            }
        }
        return 0;
    }

    componentWillUnmount() {
        clearInterval(this.timer);
    }

    render() { 
        let pointY = 101;
        let nextY = 0;
        let prevY = 0;
        const { t } = this.props;
        try {
            PARSER.evaluate('x = ' + (this.state.time / 1000));
            PARSER.evaluate(this.state.funcMJS);
        
            if(this.state.funcMJS !== '0') {
                pointY = Number(PARSER.evaluate('f(x)'));
                PARSER.evaluate('x = ' + ((this.state.time + 5) / 1000));
                nextY = PARSER.evaluate('f(x)');
                PARSER.evaluate('x = ' + ((this.state.time - 5) / 1000));
                prevY = PARSER.evaluate('f(x)');             
                if(((nextY < pointY && pointY <= 0) || (prevY > pointY && nextY > pointY && pointY <= 0 && this.state.time !== 0))|| 
                    ((nextY > pointY && pointY >= 100) || (prevY < pointY && nextY < pointY && pointY >= 100 && this.state.time !== 0)) || 
                        this.state.time >= 60000) {
                            clearInterval(this.timer);
                            this.setState({ startDisabled: true });
                }
            } 
        } catch(err) {}
        
        return ( 
            <Fragment>
                <Modal 
                    title={t('SNA1.modal.title-1')}
                    show={this.state.isVideo_1_ModalOpen} 
                    modalClosed={() => this.closeModal(1)}>
                    <iframe ref={this.video_1} title="Functions" className={classes.Youtube} src={t('SNA1.modal.youtube-link-introduction')}
                        frameBorder="0" allow="accelerometer; clipboard-write; encrypted-media; gyroscope; 
                        picture-in-picture" allowFullScreen>
                    </iframe>
                </Modal>
                {/* <Modal 
                    title={t('SNA1.modal.title-2')}
                    show={this.state.isVideo_2_ModalOpen} 
                    modalClosed={() => this.closeModal(2)}>
                    <iframe ref={this.video_2} title="Functions" className={classes.Youtube} src={t('SNA1.modal.youtube-link-how-to-use')}
                        frameBorder="0" allow="accelerometer; clipboard-write; encrypted-media; gyroscope; 
                        picture-in-picture" allowFullScreen>
                    </iframe>
                </Modal> */}
                <div className={classes.SNA1}>
                    <Theater rgb={pointY > 100 || pointY === "NaN" ? 
                        "rgb(255, 255, 255)" : `rgb(${pointY * 2.55}, ${pointY * 2.55}, ${pointY * 2.55})`}/>  
                    <GraphingCalculator 
                        settings={this.state.settings} 
                        mathBounds={this.state.mathBounds}
                        func={this.state.trigonometryFunction === null ? this.state.func : this.state.trigonometryFunction} 
                        pointX={(this.state.time / 1000)} /> 
                </div>
                <div className={classes.Controls}>
                    <div className={classes.Dashboard}>
                        <DisplayFA
                            value={(pointY > 100 || pointY < 0 || pointY === "NaN" || Object.keys(pointY).length !== 0) ? 
                            "∞" : pointY.toFixed(2)} 
                            unit={"%"} 
                            icon={faLightbulb}/>
                        <DisplayWithRadioButtonsFA 
                            value={(this.state.time / 1000).toFixed(1)} 
                            unit={"s"} 
                            icon={faClock}
                            setStopwatchSpeed={this.setStopwatchSpeed}/>
                    </div> 
                    <div className={classes.Form}>
                        <Form 
                            formDataCallback={this.getFormData} 
                            canStart={this.state.startDisabled}
                            isDataReseted={this.state.isDataReseted}
                            inputDisabled={this.state.inputDisabled}
                            error={this.state.error}
                            placeholderEST={'Näiteks f(x)=15/x'}
                            placeholderENG={'e.g. f(x)=15/x'}/>
                        <div className={classes.StartReset}>
                            <Button 
                                btnType="Danger" clicked={this.handleStartClick} 
                                disabled={this.state.startDisabled}
                            >
                                {!this.state.isOn ? t('buttons.start') : t('buttons.pause')}
                            </Button>
                            <Button   
                                btnType="Primary" clicked={this.resetTimer}
                            >
                                {t('buttons.reset')}
                            </Button>
                        </div> 
                    </div>
                    
                    <div className={classes.HelpVideos}>
                        <FontAwesomeIcon className={classes.Icon} style={{ color: 'rgb(52, 58, 63)' }} icon={faQuestionCircle} size="2x" />
                        <div className={classes.VideoButtons}>
                            <Button style={{width: "calc(50% - 15px)", marginRight: "5px"}} btnType="Dark" clicked={() => this.openModal(1)}>{t('SNA1.video.btn-1')}</Button> 
                            {/* <Button style={{width: "calc(50% - 15px)", marginLeft: "5px"}} btnType="Dark" clicked={() => this.openModal(2)}>{t('SNA1.video.btn-2')}</Button>   */}
                        </div>
                    </div>
                </div>
            </Fragment>
        );
    }
}
 
export default withTranslation() (SNA1a);
