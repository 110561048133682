import React, { Component } from 'react';
import SmartWatch from '../../../components/SNA10/SNA10b/SmartWatch/SmartWatch';
import classes from '../SNA10.module.css';
import Controls from '../../../components/SNA10/SNA10b/Controls/Controls';

const PROGRESS_BAR_SIZE_COEFICENT = 0.243;       // percentage from window width
const PROGRESS_BAR_WIDTH_COEFICIENT = 0.01;      // percentage from window width
const FLEX_DIRECTION_BOUNDARY = 1000;            // flex row / column change point: window width
const FLEX_DIRECTION_PROPORTION = 2.25;          // proportion between image width when flex direction changes: row and column

class SNA10b extends Component {
    state = {
        progressBar: {
            isRunning: false,
            size: 200,
            progress: 0, 
            strokeWidth: 0,
            firstCircleStroke: '#a9dda9',
            secondCircleStroke: '#2aab2a',
            circleFillColor: 'none'
        },
        timerDelay: 1,
        progressDelta: 0,
        activityUnit: 'steps',
        activityType: 'walk',
        activity: 0,
        actionValue: 0,
        isDataReseted: true,
        canStart: false,
        isToggled: false
    }

    componentDidMount () {
        window.addEventListener("resize", this.handleResize);
        this.setState({
            progressBar: { 
                ...this.state.progressBar, 
                size: window.innerWidth > FLEX_DIRECTION_BOUNDARY ? 
                    window.innerWidth * PROGRESS_BAR_SIZE_COEFICENT : 
                    window.innerWidth * PROGRESS_BAR_SIZE_COEFICENT * FLEX_DIRECTION_PROPORTION,
                strokeWidth: window.innerWidth > FLEX_DIRECTION_BOUNDARY ? 
                    window.innerWidth * PROGRESS_BAR_WIDTH_COEFICIENT : 
                    window.innerWidth * PROGRESS_BAR_WIDTH_COEFICIENT * FLEX_DIRECTION_PROPORTION,
            } 
        });
    }

    componentWillUnmount() {
        clearInterval(this.pb);
        window.removeEventListener("resize", this.handleResize);
    }

    handleResize = (event) => {
        this.setState({
            progressBar: { 
                ...this.state.progressBar, 
                size: window.innerWidth > FLEX_DIRECTION_BOUNDARY ? 
                    window.innerWidth * PROGRESS_BAR_SIZE_COEFICENT : 
                    window.innerWidth * PROGRESS_BAR_SIZE_COEFICENT * FLEX_DIRECTION_PROPORTION,
                strokeWidth: window.innerWidth > FLEX_DIRECTION_BOUNDARY ? 
                    window.innerWidth * PROGRESS_BAR_WIDTH_COEFICIENT : 
                    window.innerWidth * PROGRESS_BAR_WIDTH_COEFICIENT * FLEX_DIRECTION_PROPORTION,
            } 
        });
    }

    toggle = () => {
        this.setState({ isToggled: !this.state.isToggled }, () => this.setTimerDelay());
    }

    setTimerDelay = () => {
        const stepsOrMinutes = this.state.activityUnit.toLowerCase().startsWith('s');
        this.setState({ timerDelay: this.state.isToggled ? stepsOrMinutes ? 25 : 300 : stepsOrMinutes ? 1 : 100 }, () => {
            this.stopRunning();
            this.startRunning();
        });
    }

    getFormData = ({settings, activityUnit, activityType, activity, percentageOfDailyRate, actionValue}) => {
        this.setState({ 
            progressBar: {
                ...this.state.progressBar,
                progress: activity,
            },
            timerDelay: activityUnit.toLowerCase().startsWith('s') ? 1 : 100,
            progressDelta: percentageOfDailyRate / settings,
            activityUnit,
            activityType,
            actionValue,
            isDataReseted: false, 
            canStart: true,
        });
    }

    handleStartClick = () => {
        this.state.progressBar.isRunning ? this.stopRunning() : this.startRunning();
    }

    handleResetClick = () => {
        clearInterval(this.pb);
        this.setState({
            progressBar: { ...this.state.progressBar, isRunning: false,  progress: 0 },
            currentSteps: 0,
            timerDelay: 1,
            activityUnit: 'steps',
            activityType: 'walk',
            actionValue: 0,
            isDataReseted: true,
            canStart: false,
            isToggled: false
        });
    }

    startRunning = () => {
        this.pb = setInterval(() => {
            this.setState({ 
                actionValue: this.state.actionValue - 1,
                progressBar: { 
                    ...this.state.progressBar, 
                    isRunning: true,  
                    progress: this.state.progressBar.progress + this.state.progressDelta
                },
            }, () => this.checkBounds());
        }, this.state.timerDelay);
    }

    stopRunning = () => {
        this.setState({ progressBar: {...this.state.progressBar, isRunning: false } });
        clearInterval(this.pb);
    }

    checkBounds() {
        if (this.state.actionValue <= 0) {
            this.stopRunning();
            this.setState({ canStart: false });
        }       
    }

    render() { 
        const { isRunning } = this.state.progressBar;
        const { activityUnit, activityType, actionValue, isDataReseted, canStart, isToggled } = this.state;
        return (
            <div className={classes.SNA10}>
                <SmartWatch 
                    progressBar={this.state.progressBar}
                    activityUnit={activityUnit}
                    activityType={activityType}
                    actionValue={actionValue}
                    isToggled={isToggled}
                    isRunning={isRunning}
                    toggle={() => this.toggle()}/>
                <Controls
                    actionType={isRunning ? 'Run' : 'Pause'}
                    activityType={activityType}
                    isToggled={isToggled}
                    isDataReseted={isDataReseted}
                    canStart={canStart}
                    isRunning={isRunning}
                    formData={this.getFormData}
                    start={this.handleStartClick}
                    reset={this.handleResetClick}/>
            </div>
        );
    }
}
 
export default SNA10b;
