import React from 'react';
import classes from './Card.module.css';
import { Link } from 'react-router-dom';

const card = (props) => (
    <Link className={classes['card-link']} to={props.cardItem.path}>
        <img alt="Pic" src={props.cardItem.image}/>
        <div className={classes['card-info']}>
            <h3>{props.cardItem.name}</h3>
            <p>{props.cardItem.subtitle}</p>
        </div>
    </Link>
);

export default card;
