import React from 'react';
import Polynomials from '../Polynomials/Polynomials';
import Button from '../../UI/Button/Button';
import IncrementControls from '../IncrementControls/IncrementControls';
import classes from './ExBuilder.module.css';
import { useTranslation } from 'react-i18next';

const ExBuilderB = (props) => {
    const { weeks = [], variablesArray, isAnimationRunning, isDataReseted, canStart } = props;
    const polynomials = [];
    const { t } = useTranslation();
    const addWeek = () => {
        for (const week of polynomials) {
            if (Object.keys(week.values).length === 0) {
                props.addWeek(week.id);
                return;
            }
        }
    }

    const deleteWeek = () => {
        for (const [i, week] of polynomials.entries()) {
            if (Object.keys(week.values).length === 0) {
                props.deleteWeek(polynomials[i - 1].id);
                return;
            } else if (i === polynomials.length - 1) {
                props.deleteWeek(week.id);
                return;
            }
        }
    }

    const addWeekButtonDisabled = () => {
        return Object.keys(polynomials[polynomials.length - 1].values).length !== 0;
    }

    const deleteWeekButtonDisabled = () => {
        return Object.keys(polynomials[1].values).length === 0;
    }

    for (const week of Object.values(weeks)) {
        polynomials.push(week);
    }

    const weeksArr = polynomials.map((week, index) => (
        <div key={week.id} className={classes.Week}>
            {Object.keys(week.values).length !== 0 && 
                <p>
                    {t(`SNA15.ExpressionBuilder.weeks.week-${index + 1}`)}
                </p>
            }
            {Object.keys(week.values).length !== 0 &&
                <Polynomials
                    polynomialsArray={week}
                    isAnimationRunning={isAnimationRunning}
                    isDataReseted={isDataReseted}
                    openCoeficientsPanel={(weekId, polynomialId) => props.openCoeficientsPanel(weekId, polynomialId)}
                    openVariables_1_Panel={(weekId, polynomialId) => props.openVariables_1_Panel(weekId, polynomialId)}
                    openVariables_2_Panel={(weekId, polynomialId) => props.openVariables_2_Panel(weekId, polynomialId)} />}
        </div>
    ))

    return (
        <div className={classes.ExBuilder}>
            <h3>{t('SNA15.ExpressionBuilder.weeks.title-2')}</h3>
            {weeksArr}
            <div className={classes.Buttons}>
                <Button
                    btnType='Dark'
                    disabled={addWeekButtonDisabled() || isAnimationRunning || !isDataReseted}
                    clicked={addWeek}>{t('SNA15.ExpressionBuilder.buttons.add')}</Button>
                <Button
                    btnType='Dark'
                    style={{ marginLeft: '10px' }}
                    disabled={deleteWeekButtonDisabled() || isAnimationRunning || !isDataReseted}
                    clicked={deleteWeek}>{t('SNA15.ExpressionBuilder.buttons.delete')}</Button>
                <Button
                    btnType='Danger'
                    style={{ marginLeft: '50px' }}
                    disabled={!canStart}
                    clicked={() => props.start()}
                >
                    {isAnimationRunning ? t('buttons.pause') : t('buttons.start')}
                </Button> 
                <Button
                    btnType='Primary'
                    style={{ marginLeft: '10px' }}
                    clicked={() => props.reset()}
                >
                    {t('buttons.reset')}
                </Button>
            </div>
            <IncrementControls 
                variablesArray={variablesArray}
                isAnimationRunning={isAnimationRunning}
                isDataReseted={isDataReseted}
                incrementValue={(obj) => props.incrementValue(obj)}
                decrementValue={(obj) => props.decrementValue(obj)}/>
        </div>
    );
}

export default ExBuilderB;
