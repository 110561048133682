import React, { Fragment } from 'react';
import AmountControl from '../../UI/AmountControl/AmountControl';
import classes from './AmountControls.module.css';
import { useSNA24Context } from '../../../context/SNA24-context';
import { useTranslation } from 'react-i18next';

const AmountControls = () => {
    const { expressionVariables, increase, decrease, inputChangeHandler } = useSNA24Context();
    const { t } = useTranslation();
    return (
        <Fragment>
            {expressionVariables.length !== 0 && <p className={classes.paragraph}>{t('SNA24.expression-control.variables.p')}</p>}
            <div className={classes.wrapper}>
                {expressionVariables.map((control, index) =>
                    <div key={control.type} className={classes['single-control']}>
                        <span>{`${control.type}=`}</span> 
                        <AmountControl 
                            column={true}
                            value={control.value}
                            increase={() => increase(index)}
                            decrease={() => decrease(index)}
                            change={(event) => inputChangeHandler({'value': event.target.value, 'index': index})}
                        />
                    </div> 
                )}
            </div>
        </Fragment>
    );
}
 
export default AmountControls;
