import React from 'react';
import classes from './Input.module.css';

const input = (props) => {
    let inputElement = null;
    let validationError = null;
    const inputClasses = [classes.InputElement];
    if (props.invalid && props.touched) {
        inputClasses.push(classes.Invalid);
        validationError = <p className={classes.ValidationError}>{props.validation?.errorMessage || props?.errorMessage}</p>;
    }
    if (!props.invalid && props.belongsToFunction && props.touched) {
        inputClasses.push(classes.Invalid);
        validationError = <p className={classes.ValidationError}>{props.notBelongToFunctionErrorMessage}</p>;
    }
    if (!props.invalid && props.validHeight && props.touched && props.id.startsWith('section')) {  // only for SNA11
        inputClasses.push(classes.Invalid);
        validationError = <p className={classes.ValidationError}>{props.validation?.maxHeightError || props?.maxHeightError}</p>;
    }
    if (!props.invalid && props.belongsToParabola && props.touched && props.id === 'startPoint') {       // only for SNA6
        inputClasses.push(classes.Invalid);
        validationError = <p className={classes.ValidationError}>{props.validation.notBelongToParabolaErrorMessage}</p>;
    }
    if (!props.invalid && props.belongsToCircle && props.touched && props.id === 'startPoint') {         // only for SNA6
        inputClasses.push(classes.Invalid);
        validationError = <p className={classes.ValidationError}>{props.validation.notBelongToCircleErrorMessage}</p>;
    }
    if (props.disabled) {
        inputClasses.push(classes.Disabled);
    }
    if (props.elementConfig?.icon) {
        inputClasses.push(classes.InputElementWithIcon);
    }

    // For SNA1 only
    if (props.error) {
        inputClasses.push(classes.Invalid);
        validationError = <p className={classes.ValidationError}>{props.errorMessage}</p>;
    }

    switch (props.elementType) {
        case ('input'):
            inputElement = <input 
                className={inputClasses.join(' ')} 
                {...props.elementConfig}
                placeholder={props.elementConfig?.placeholder || props?.placeholder} 
                disabled = {props.disabled ? "disabled" : ''}
                value={props.value}
                onFocus={props.onFocus}
                onChange={props.changed}/>;
            break;
        case ('textarea'):
            inputElement = <textarea 
                className={inputClasses} 
                {...props.elementConfig} 
                value={props.value}
                onChange={props.changed}/>;
            break;
        case ('select'):
            inputElement = (
                <select 
                    className={inputClasses.join(' ')}
                    disabled = {props.disabled ? "disabled" : ''}
                    value={props.value}
                    onChange={props.changed}> 
                    {props.elementConfig.options.map(option => (
                        <option key={option.value} value={option.value}>
                            {option.displayValue}
                        </option>
                    ))}   
                </select>);
            break;
        default:
            inputElement = <input 
                className={inputClasses} 
                {...props.elementConfig} 
                value={props.value}
                onChange={props.changed}/>
    }
    return (
        <div className={classes.Input} style={props.style}>
            <label 
                className={[classes.Label, classes[props.labelTextColor]].join(' ')}>{props.label}
            </label>
            {inputElement}
            {props.elementConfig?.icon ? <img className={[classes.Icon, classes[props.elementConfig.icontype]].join(' ')} src={props.elementConfig.icon} alt=''/> : null} 
            {validationError}
        </div>
    );
};

export default input;
