import React from 'react';
import classes from './Button.module.css';

const button = (props) => (
    <button 
        type={props.type}
        name={props.name}
        style={props.style}
        onClick={props.clicked}
        disabled={props.disabled}
        className={[classes.Button, classes[props.btnType]].join(' ')}>
            {props.children}    
    </button>
);

export default button;
