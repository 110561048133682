import React, { useState, useEffect }  from 'react';
import classes from './CardList.module.css';
import Card from './Card/Card';
import { useTranslation } from "react-i18next";
import Skeleton from '../UI/Skeleton/Skeleton';
import { getCards } from './data';

const CardList = () => {
    const { t } = useTranslation();
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        let isMounted = false;
        const image = new Image();
        image.onload = () => !isMounted && setIsLoading(false);
        image.src = 'images/sna_1.png';
        return () => isMounted = true;
    }, []);

    return (
        <div className={classes.CardList}>
            {getCards(t).map((cardItem, index) => (
                !isLoading ? <Card key={index} cardItem={cardItem} /> : <Skeleton key={index} />
            ))}
        </div>
    );
}
 
export default CardList;
