import React from 'react';
import { useSNA22Context } from '../../../context/SNA22-context';
import { useTranslation } from 'react-i18next';
import classes from './Info.module.css';

const Info = () => {
    const { t } = useTranslation();
    const { index } = useSNA22Context();
    return (
        <div>
            <hr/>
            <p className={classes.hint}>{t('SNA22.info.hint')}</p>
            <p>{t(`SNA22.info.p${index + 1}`)}</p>
            <hr/>
            <br/>
        </div>
    );
}
 
export default Info;
