// 2020 - 5GHz
// 2010 - 2020 4 GHz
// 2000 - 2010 3 GHz
// 1990 - 2000 0.5 GHz
// 1980 - 1990 0.04 GHz
// 1970 - 1980  0.002 GHz
// 1960 - 1970 0.0005 GHz

export const data = [
    {   
        id: 0,
        coeficient: 0.000125,
        decade: '1960 - 1970',
        src: 'https://dartef.s3.eu-north-1.amazonaws.com/SNA24/computers/10.png'
    },
    {   
        id: 1,
        coeficient: 0.0005,
        decade: '1970 - 1980',
        src: 'https://dartef.s3.eu-north-1.amazonaws.com/SNA24/computers/8.png'
    },
    {   
        id: 2,
        coeficient: 0.01,
        decade: '1980 - 1990',
        src: 'https://dartef.s3.eu-north-1.amazonaws.com/SNA24/computers/9.png'
    },
    {   
        id: 3,
        coeficient: 0.125,
        decade: '1990 - 2000',
        src: 'https://dartef.s3.eu-north-1.amazonaws.com/SNA24/computers/6.png'
    },
    {   
        id: 4,
        coeficient: 0.6,
        decade: '2000 - 2010',
        src: 'https://dartef.s3.eu-north-1.amazonaws.com/SNA24/computers/5.png'
    },
    {   
        id: 5,
        coeficient: 0.75,
        decade: '2010 - 2020',
        src: 'https://dartef.s3.eu-north-1.amazonaws.com/SNA24/computers/3.png'
    },
    {   
        id: 6,
        coeficient: 1,
        decade: '2020 - ...',
        src: 'https://dartef.s3.eu-north-1.amazonaws.com/SNA24/computers/1.png'
    }
];

export const keypadButtons1 = [
    { 
        value: 'x',
        command: 'x'
    },
    { 
        value: 'y',
        command: 'y'
    },
    { 
        value: 'a2',
        command: '^2'
    },
    { 
        value: 'ab',
        command: '^{ }',
        keystroke: 'Left'
    },
    { 
        value: '()',
        command: '\\left(\\right)',
        keystroke: 'Left'
    },
    { 
        value: 'sqrt',
        command: '\\sqrt{ }',
        keystroke: 'Left'
    },
];

export const keypadButtons2 = [
    { 
        value: 7,
        command: 7
    },
    { 
        value: 8,
        command: 8
    },
    { 
        value: 9,
        command: 9
    },
    { 
        value: '÷',
        command: '\\frac{ }{ }',
        keystroke: 'Left Left'
    },
    { 
        value: 4,
        command: 4
    },
    { 
        value: 5,
        command: 5
    },
    { 
        value: 6,
        command: 6
    },
    { 
        value: '×',
        command: '\\cdot'
    },
    { 
        value: 1,
        command: 1
    },
    { 
        value: 2,
        command: 2
    },
    { 
        value: 3,
        command: 3
    },
    { 
        value: '-',
        command: '-'
    },
    { 
        value: 0,
        command: 0
    },
    { 
        value: '.',
        command: '.'
    },
    { 
        value: '=',
        command: '='
    },
    { 
        value: '+',
        command: '+'
    },
];
