import React, { Component, Fragment  } from 'react';
import SmartWatch from '../../../components/SNA10/SNA10a/SmartWatch/SmartWatch';
import classes from '../SNA10.module.css';
import Controls from '../../../components/SNA10/SNA10a/Controls/Controls';

const PROGRESS_BAR_SIZE_COEFICENT = 0.243;       // percentage from window width
const PROGRESS_BAR_WIDTH_COEFICIENT = 0.01;      // percentage from window width
const FLEX_DIRECTION_BOUNDARY = 1000;            // flex row / column change point: window width
const FLEX_DIRECTION_PROPORTION = 2.25;          // proportion between image width when flex direction changes: row and column

class SNA10a extends Component {
    state = {
        progressBar: {
            isRunning: false,
            size: 200,
            progress: 0, 
            strokeWidth: 0,
            firstCircleStroke: '#a9dda9',
            secondCircleStroke: '#2aab2a',
            circleFillColor: 'none'
        },
        timerDelay: 1, // ms
        currentSteps: 0,
        dailyRate: 10000,
        activityUnit: 'steps',
        activity: 0,
        activityType: 'walk',
        actionValue: 0,
        isDataReseted: true,
        canStart: false,
        isFormInputDisabled: false,
        isToggled: false,
    }

    componentDidMount () {
        window.addEventListener("resize", this.handleResize);
        this.setState({
            progressBar: { 
                ...this.state.progressBar, 
                size: window.innerWidth > FLEX_DIRECTION_BOUNDARY ? 
                    window.innerWidth * PROGRESS_BAR_SIZE_COEFICENT : 
                    window.innerWidth * PROGRESS_BAR_SIZE_COEFICENT * FLEX_DIRECTION_PROPORTION,
                strokeWidth: window.innerWidth > FLEX_DIRECTION_BOUNDARY ? 
                    window.innerWidth * PROGRESS_BAR_WIDTH_COEFICIENT : 
                    window.innerWidth * PROGRESS_BAR_WIDTH_COEFICIENT * FLEX_DIRECTION_PROPORTION,
            } 
        });
    }

    componentWillUnmount() {
        clearInterval(this.pb);
        window.removeEventListener("resize", this.handleResize);
    }

    handleResize = (event) => {
        this.setState({
            progressBar: { 
                ...this.state.progressBar, 
                size: window.innerWidth > FLEX_DIRECTION_BOUNDARY ? 
                    window.innerWidth * PROGRESS_BAR_SIZE_COEFICENT : 
                    window.innerWidth * PROGRESS_BAR_SIZE_COEFICENT * FLEX_DIRECTION_PROPORTION,
                strokeWidth: window.innerWidth > FLEX_DIRECTION_BOUNDARY ? 
                    window.innerWidth * PROGRESS_BAR_WIDTH_COEFICIENT : 
                    window.innerWidth * PROGRESS_BAR_WIDTH_COEFICIENT * FLEX_DIRECTION_PROPORTION,
            } 
        });
    }

    toggle = () => {
        this.setState({ isToggled: !this.state.isToggled }, () => this.setTimerDelay());
    }

    setTimerDelay = () => {
        const stepsOrMinutes = this.state.activityUnit.toLowerCase().startsWith('s');
        this.setState({ timerDelay: this.state.isToggled ? stepsOrMinutes ? 25 : 300 : stepsOrMinutes ? 1 : 100}, () => {
            this.stopRunning();
            this.startRunning();
        });
    }

    getFormData = ({dailyRate, activityUnit, activity, activityType, actionValue}) => {
        this.setState({ 
            progressBar: {
                ...this.state.progressBar,
                progress: activity,
            },
            timerDelay: activityUnit.toLowerCase().startsWith('s') ? 1 : 100,
            currentSteps: activity / 100 * dailyRate,
            dailyRate,
            activityUnit,
            activity,
            activityType,
            actionValue,
            isDataReseted: false, 
            canStart: true,
            isFormInputDisabled: true
        });
    }

    handleStartClick = () => {
        this.state.progressBar.isRunning ? this.stopRunning() : this.startRunning();
    }

    handleResetClick = () => {
        clearInterval(this.pb);
        this.setState({
            progressBar: { ...this.state.progressBar, isRunning: false,  progress: 0 },
            currentSteps: 0,
            timerDelay: 1,
            dailyRate: 10000,
            activityUnit: 'steps',
            activity: 0,
            actionValue: 0,
            isDataReseted: true,
            isFormInputDisabled: false,
            canStart: false,
            isToggled: false
        });
    }

    startRunning = () => {
        this.pb = setInterval(() => {
            this.setState({ 
                currentSteps: this.state.currentSteps + 1,
                progressBar: { 
                    ...this.state.progressBar, 
                    isRunning: true,  
                    progress: this.state.progressBar.progress + (1 / this.state.dailyRate * 100)
                },
            }, () => this.checkBounds());
        }, this.state.timerDelay);
    }

    stopRunning = () => {
        this.setState({ progressBar: {...this.state.progressBar, isRunning: false } });
        clearInterval(this.pb);
    }

    checkBounds() {
        if (this.state.currentSteps >= this.state.activity / 100 * this.state.dailyRate + this.state.actionValue) {
            this.stopRunning();
            this.setState({ canStart: false });
        }     
    }

    render() { 
        const { isRunning } = this.state.progressBar;
        const { 
            currentSteps, 
            activityUnit, 
            activityType, 
            dailyRate, 
            isDataReseted, 
            canStart, 
            isFormInputDisabled, 
            isToggled, 
        } = this.state;
        
        return (
            <Fragment>
                <div className={classes.SNA10}>
                    <SmartWatch 
                        progressBar={this.state.progressBar}
                        currentSteps={currentSteps}
                        dailyRate={dailyRate}
                        activityUnit={activityUnit}
                        isToggled={isToggled}
                        isRunning={isRunning}
                        toggle={() => this.toggle()}/>
                    <Controls
                        actionType={isRunning ? 'Run' : 'Pause'}
                        activityType={activityType}
                        isToggled={isToggled}
                        isDataReseted={isDataReseted}
                        canStart={canStart}
                        isFormInputDisabled={isFormInputDisabled}
                        isRunning={isRunning}
                        formData={this.getFormData}
                        start={this.handleStartClick}
                        reset={this.handleResetClick}/>
                </div>
            </Fragment>
        );
    }
}
 
export default SNA10a;
