import React, { Component, Fragment } from 'react';
import classes from './SNA2b.module.css';
import Knee from '../../../components/SNA2/Knee/Knee';
import Triangle from '../../../components/SNA2/Triangle/TriangleB';
import Controls from '../../../components/SNA2/Controls/Controls';
import Syringe from '../../../components/SNA2/Syringe/Syringe';
import syringe_right from '../../../assets/trigonometry/syringe/syringe_right.png';
import syringe_left from '../../../assets/trigonometry/syringe/syringe_left.png';
import Notification from '../../../components/UI/Notification/Notification';
import positive_notification from '../../../assets/audio/positive_notification.wav';
import negative_notification from '../../../assets/audio/negative_notification.wav';
import { withTranslation } from 'react-i18next';
import SideDrawer from '../../../components/UI/SideDrawer/SideDrawer';
import QuickStartB from '../../../components/SNA2/QuickStart/QuickStartB';
import { triangles } from '../../../data/SNA2data';
import { round } from '../../../utils/helpers';

const SPEED = 0.8;

const FLEX_DIRECTION_BOUNDARY = 1000;            // flex row / column change point: window width
const FLEX_DIRECTION_PROPORTION = 2.25;          // proportion between image width when flex direction changes: row and column
const KNEE_WIDTH = 0.4;                          // 40vw
const SYRINGE_WIDTH = 83;
const SYRINGE_HEIGHT = 513;

const NOTIFICATION_DELAY = 10000;
const FORM_MIN_VALUE = 1;
const FORM_MAX_VALUE = 7;
const TEXT_BEFORE_INPUT = 'AC';

const successSound = new Audio(positive_notification);
const failSound = new Audio(negative_notification);

class SNA2b extends Component {
    state = {
        transparent: false,
        userHypotenuse: null,
        canStart: false,
        isNewTaskDisabled: false,
        isDataReseted: true,
        triangleIndex: null,
        coefficient: (FLEX_DIRECTION_BOUNDARY * KNEE_WIDTH) / FLEX_DIRECTION_BOUNDARY,
        x: 0,
        y: 0,
        finishPoint: 0,
        windowWidth: window.innerWidth,
        isAnimationRunning: false,
        isAnimationRan: false,
        notifications: [],
        isSideDrawerOpen: false,
    }
    animationID = null;

    componentDidMount() {
        window.addEventListener("resize", this.handleResize);
        this.setState({ 
            coefficient: window.innerWidth * this.getKneeWidthProportion() / FLEX_DIRECTION_BOUNDARY 
        });
    }

    componentWillUnmount() {
        window.cancelAnimationFrame(this.animationID);
        window.removeEventListener("resize", this.handleResize);
    }

    handleResize = (event) => {
        this.setState({
            windowWidth: window.innerWidth,
            coefficient: window.innerWidth * this.getKneeWidthProportion() / FLEX_DIRECTION_BOUNDARY
        }, () => this.setSyringeInitialPosition(this.state.triangleIndex));
    };

    getKneeWidthProportion = () => {
        return window.innerWidth > FLEX_DIRECTION_BOUNDARY ? KNEE_WIDTH : KNEE_WIDTH * FLEX_DIRECTION_PROPORTION;
    }

    getFormData = ({ value, valid }) => {
        const { triangleIndex, isDataReseted } = this.state;
        this.setState({
            userHypotenuse: +value,
            isNewTaskDisabled: true,
            canStart: valid,
            isDataReseted: false,
        });
        if (!isDataReseted) {
            this.setSyringeInitialPosition(triangleIndex);
            this.setState({ notifications: [] });
        }
    }

    setTransparency = () => {
        this.setState({ transparent: !this.state.transparent });
    }

    setTriangle = (index) => {
        this.setState({ triangleIndex: index }, () => this.setSyringeInitialPosition());
    }

    setSyringeInitialPosition = () => {
        const { triangleIndex, coefficient } = this.state;
        const { cPoint: { x: initialX, y: initialY } } = triangles[triangleIndex || 0];
        const x = window.innerWidth * this.getKneeWidthProportion() * initialX - (SYRINGE_WIDTH * coefficient / 2);
        const y = window.innerWidth * this.getKneeWidthProportion() * initialY;
        this.setState({ x, y });
    }

    handleStartClick = () => {
        const { triangleIndex, coefficient, userHypotenuse, isAnimationRunning } = this.state;
        const { cPoint: { x: cCoordX }, aPoint: { x: aCoordX }, hypotenuse } = triangles[triangleIndex];
        const A = window.innerWidth * this.getKneeWidthProportion() * aCoordX - (SYRINGE_WIDTH * coefficient / 2);
        const C = window.innerWidth * this.getKneeWidthProportion() * cCoordX - (SYRINGE_WIDTH * coefficient / 2);
        const finishPoint = C - (C - A) / hypotenuse * userHypotenuse;

        this.setState({
            isAnimationRunning: !isAnimationRunning,
            isAnimationRan: true,
            finishPoint
        });
        this.animationID = window.requestAnimationFrame(() => this.animate());
    }

    handleResetClick = () => {
        this.setState({
            transparent: false,
            userHypotenuse: null,
            isAnimationRunning: false,
            isAnimationRan: false,
            isNewTaskDisabled: false,
            canStart: false,
            isDataReseted: true,
            triangleIndex: null,
            notifications: [],
        });
    }

    animate = () => {
        if (this.state.triangleIndex === null) return;
        this.moveSyringe();
        if (this.state.isAnimationRunning) {
            this.animationID = window.requestAnimationFrame(() => this.animate());
        }
    }

    moveSyringe = () => {
        let updatedX = null;
        let updatedY = null;
        const { triangleIndex, x, y } = this.state;
        const { direction, yCoeficient } = triangles[triangleIndex];

        if (direction === 1) {
            updatedX = x - SPEED;
            updatedY = y + SPEED * yCoeficient;
        }
        else if (direction === 2) {
            updatedX = x - SPEED;
            updatedY = y - SPEED * yCoeficient;

        }
        else if (direction === 3) {
            updatedX = x + SPEED;
            updatedY = y - SPEED * yCoeficient;
        }
        else if (direction === 4) {
            updatedX = x + SPEED;
            updatedY = y + SPEED * yCoeficient;
        }
        this.setState({ x: updatedX, y: updatedY }, () => this.checkBounds());
    }

    checkBounds = () => {
        const { x, finishPoint, userHypotenuse, triangleIndex } = this.state;
        const { direction, hypotenuse } = triangles[triangleIndex];
        if (((direction === 1 || direction === 2) && x <= finishPoint) ||
            ((direction === 3 || direction === 4) && x >= finishPoint)) {
            this.setState({
                userHypotenuse: null,
                isAnimationRunning: false,
                canStart: false,
                notifications: [this.getNotification(this.notificationType(userHypotenuse, hypotenuse))]
            });
            this.playSound(userHypotenuse === hypotenuse && 'SUCCESS');
        }
    }

    notificationType = (userHypotenuse, hypotenuse) => {
        if (userHypotenuse === hypotenuse) return 'SUCCESS';
        if (round(Math.abs(userHypotenuse - hypotenuse) <= 0.3, 2)) return 'WARNING';
        return 'DANGER';
    }

    getNotification = (identifier) => {
        const { t } = this.props;
        if (identifier === 'SUCCESS') {
            return {
                id: 1,
                type: 'Success',
                title: t('SNA2.notifications.titles.success'),
                description: t('SNA2.notifications.descriptions.success')
            }
        }
        if (identifier === 'DANGER') {
            return {
                id: 2,
                type: 'Danger',
                title: t('SNA2.notifications.titles.fail'),
                description: t('SNA2.notifications.descriptions.danger')
            }
        }
        if (identifier === 'WARNING') {
            return {
                id: 3,
                type: 'Warning',
                title: t('SNA2.notifications.titles.warning'),
                description: t('SNA2.notifications.descriptions.warning')
            }
        }
    }

    deleteNotificationHandler = () => {
        this.setState({ notifications: [] });
    }

    playSound = (identifier) => {
        identifier === 'SUCCESS' ? successSound.play() : failSound.play();
    }

    openSideDrawerHandler = () => {
        this.setState({ isSideDrawerOpen: true });
    }

    closeSideDrawerHandler = () => {
        this.setState({ isSideDrawerOpen: false });
    }

    render() {
        const {
            coefficient,
            x,
            y,
            triangleIndex,
            transparent,
            userHypotenuse,
            canStart,
            isNewTaskDisabled,
            isDataReseted,
            isAnimationRunning,
            isAnimationRan,
            notifications,
            isSideDrawerOpen
        } = this.state;
        const { syringeAngle, direction, triangle } = triangles[triangleIndex || 0];

        return (
            <Fragment>
                <SideDrawer 
                    isSideDrawerOpen={isSideDrawerOpen}
                    openSideDrawer={this.openSideDrawerHandler}
                    closeSideDrawer={this.closeSideDrawerHandler}
                >
                    <QuickStartB 
                        index={triangleIndex}
                        isAnimationRan={isAnimationRan}
                    />
                </SideDrawer>
                <div className={classes.SNA2}>
                    <div className={classes.KneeContainer}>
                        <Syringe
                            index={triangleIndex}
                            position={{
                                transformOrigin: '50% 1%',
                                left: x,
                                top: y,
                                width: SYRINGE_WIDTH * coefficient,
                                height: SYRINGE_HEIGHT * coefficient,
                                transform: 'rotate(' + syringeAngle + 'deg)',
                                backgroundImage: direction === 1 || direction === 2 ? `url(${syringe_right})` : `url(${syringe_left})`
                            }} 
                        />
                        <Knee transparent={transparent} />
                        <Triangle
                            triangle={triangle}
                            index={triangleIndex}
                            transparent={!transparent} 
                        />
                    </div>
                    <Controls
                        changeImage={this.setTransparency}
                        index={triangleIndex}
                        randomMax={triangles.length}
                        transparent={transparent}
                        setTriangle={this.setTriangle}
                        start={this.handleStartClick}
                        reset={this.handleResetClick}
                        formDataCallback={this.getFormData}
                        hypotenuse={userHypotenuse}
                        minValue={FORM_MIN_VALUE}
                        maxValue={FORM_MAX_VALUE}
                        beforeInputText={TEXT_BEFORE_INPUT}
                        canStart={canStart}
                        isNewTaskDisabled={isNewTaskDisabled}
                        isDataReseted={!isDataReseted}
                        isAnimationRunning={isAnimationRunning} 
                    />
                </div>
                <Notification
                    notifications={notifications}
                    notificationDelay={NOTIFICATION_DELAY}
                    deleteNotification={this.deleteNotificationHandler} 
                />
            </Fragment>
        );
    }
}

export default withTranslation() (SNA2b);
