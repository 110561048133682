import React, { Fragment } from 'react';
import Accordion from '../../UI/Accordion/Accordion';
import classes from './QuickStart.module.css';
import price from '../../../assets/SNA23/price.png';
import { useSNA23Context } from '../../../context/SNA23-context';

const QuickStar = () => {
    const { isDataReseted } = useSNA23Context();

    return (
        <Fragment>
            <Accordion title={'Sissejuhatus'}>
                <p className={classes.paragraph}>Kas sa teadsid, et ainult 1% teravilja jahvatamiseks kuluvast energiast läheb otse jahvatamiseks ja ülejäänud 99% läheb raisku? Jah, 99% sellest energiast kulub jahvatamismasina detailide ebavajalikuks kuumenemiseks! Vaatamata sellele, et inimkond jahvatab teravilja juba tuhandeid aastaid, siis me siiani ei ole leiutanud energiaefektiivseid masinaid teravilja, killustiku ja muude puistematerjalide jahvatamiseks.</p> 
                <br/>
                <p className={classes.paragraph}>Siiski, inimesed oskavad arvutada välja elektrienergia kogust ja maksumust, mida läheb vaja teravilja jahvatamiseks. See programm näitab sulle mis põhimõttel need arvutused tehakse. Saad kasutada oma teadmisi arvu astest.</p>
            </Accordion>
            <Accordion title={'Kelle tööd see programm simuleerib?'}>
                <p className={classes.paragraph}>Inimesi, kes uurivad kuidas efektiivselt jahvatada erinevaid materjale kutsutakse materjaliteadlasteks. Inimesi, kes loovad vastavaid masinaid kutsutakse masinate projekteerijateks. See programm näitab sulle väikest tükikest nende tööst.</p>
            </Accordion>
            <Accordion title={'Kuidas programmi kasutada?'}>
                <div className={classes['step-with-img']}>
                    <div className={classes['step-inner']}>
                        <span className={classes.numeration}>1.</span>
                        <p className={classes.paragraph}>Programmis on ülesanne, kus pead välja arvutama elektrienergia maksumuse ja valemi, mille järgi see arvutus käib (vt. <span className={classes.red}>"Ülesanne"</span>). Loe ülesanne läbi, arvuta välja elektrienergia maksumus ja sisesta see programmi. Seejärel vajuta "OK" ja "START". </p>
                    </div>
                    <img className={classes['price-img']} src={price} alt="price input" />
                </div>
                <div className={`${classes.step} ${isDataReseted && classes.filter}`}>
                    <span className={classes.numeration}>2.</span>
                    <p className={classes.paragraph}>Järgmise ülesande jaoks vajuta "RESET" ning seejärel "Uus ülesanne".</p>
                </div>
            </Accordion>
        </Fragment>
    );
}
 
export default QuickStar;
