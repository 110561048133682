const SNA23_reducer = (state, action) => {
    if (action.type === 'NEW_VARIANT') {
        return { ...state, data: action.payload.data, taskIndex: action.payload.random };
    }
    if (action.type === 'START') {
        return { 
            ...state, 
            isTimerOn: state.isTimerOn ? false : true, 
        };
    }
    if (action.type === 'RESET') {
        return { 
            ...state, 
            electricityCost: null,
            isTimerOn: false, 
            isDataReseted: true, 
            canStart: false
        };
    }
    if (action.type === 'SET_COST') {
        return {
            ...state,
            electricityCost: action.payload,
            isDataReseted: false, 
            canStart: true
        };
    }
    if (action.type === 'ADJUST_SPEED') {
        return { ...state, isAnimationSpeedSlowed: state.isAnimationSpeedSlowed ? false : true };
    }
    if (action.type === 'STOP_ANIMATION') {
        return { ...state, isTimerOn: false, canStart: false };
    }
}

export default SNA23_reducer;
