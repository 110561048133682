import React from 'react';
import classes from './Animation.module.css';
import runningMan from '../../../assets/sprites/running_man.png';
import walkingWoman from '../../../assets/sprites/walking_woman.png';

const animation = (props) => {
    const { actionType, activityType, isToggled } = props;
    const manStyle = {
        backgroundImage: `url(${runningMan})`,
        '--duration': isToggled ? '2.4s' : '0.8s',
    };

    const womanStyle = {
        backgroundImage: `url(${walkingWoman})`,
        '--duration': isToggled ? '4.8s' : '1.6s',
    };

    const backGroundFast = {
        '--backgroundDuration': isToggled ? '4.6s' : '2.8s'
    };

    const backGroundSlow = {
        '--backgroundDuration': isToggled ? '12.6s' : '6.3s'
    };
    
    return (
        <div className={classes.Container}>
            {activityType.toLowerCase().startsWith('r') || activityType.toLowerCase().startsWith('j') ? <div 
                className={[classes.Man, classes[actionType]].join(' ')} 
                style={manStyle}/> : 
            <div 
                className={[classes.Woman, classes[actionType]].join(' ')} 
                style={womanStyle}/> }
            <div 
                className={[classes.Background, classes[actionType]].join(' ')} 
                style={activityType.toLowerCase().startsWith('r') || activityType.toLowerCase().startsWith('j') ? backGroundFast : backGroundSlow}/>
        </div>
    );
}
 
export default animation;
