import React, { Component } from 'react';
import Layout from './components/Layout/Layout';
import HomePage from './pages/HomePage/HomePage';
import { Route, Switch } from 'react-router-dom';
import SNA1a from './containers/SNA1/SNA1a';
import SNA1b from './containers/SNA1/SNA1b';
import SNA1c from './containers/SNA1/SNA1c';
import SNA2a from './containers/SNA2/SNA2a/SNA2a';
import SNA2b from './containers/SNA2/SNA2b/SNA2b';
import SNA2c from './containers/SNA2/SNA2c/SNA2c';
import SNA3a from './containers/SNA3/SNA3a/SNA3a';
import SNA3b from './containers/SNA3/SNA3b/SNA3b';
import SNA3c from './containers/SNA3/SNA3c/SNA3c';
import SNA5a from './containers/SNA5/SNA5a';
import SNA5b from './containers/SNA5/SNA5b';
import SNA6a from './containers/SNA6/SNA6a/SNA6a';
import SNA6b from './containers/SNA6/SNA6b/SNA6b';
import SNA9 from './containers/SNA9/SNA9';
import SNA10a from './containers/SNA10/SNA10a/SNA10a';
import SNA10b from './containers/SNA10/SNA10b/SNA10b';
import SNA10c from './containers/SNA10/SNA10c/SNA10c';
import SNA11a from './containers/SNA11/SNA11a/SNA11a';
import SNA11b from './containers/SNA11/SNA11b/SNA11b';
import SNA11c from './containers/SNA11/SNA11c/SNA11c';
import SNA12 from './containers/SNA12/SNA12';
import SNA15a from './containers/SNA15/SNA15a';
import SNA15b from './containers/SNA15/SNA15b';
import SNA19 from './pages/SNA19/SNA19';
import SNA22 from './pages/SNA22/SNA22';
import SNA23 from './pages/SNA23/SNA23';
import ErrorPage from './pages/ErrorPage/ErrorPage';
import SNA24a from './pages/SNA24/SNA24a';
import SNA24b from './pages/SNA24/SNA24b';
import SNA25 from './pages/SNA25/SNA25';
import SNA26 from './pages/SNA26/SNA26';
// import Light from './containers/BSI3/Light';
// import BSI4 from './containers/BSI4/BSI4';
// import BSI1 from './containers/BSI1/BSI1';

class App extends Component {
  
  render() { 
    return (
      <div>
        <Layout>
          <Switch>
            <Route path="/" exact component={HomePage} />
            {/* <Route path="/BSI_1" component={BSI1} />
            <Route path="/BSI_3" component={Light} /> 
            <Route path="/BSI_4" component={BSI4} />  */}
            <Route path="/SNA_1" component={SNA1a} />
            <Route path="/SNA_1a" component={SNA1a} />
            <Route path="/SNA_1b" component={SNA1b} />
            <Route path="/SNA_1c" component={SNA1c} />
            <Route path="/SNA_2a" component={SNA2a} />
            <Route path="/SNA_2b" component={SNA2b} />
            <Route path="/SNA_2c" component={SNA2c} />
            <Route path="/SNA_3a" component={SNA3a} />
            <Route path="/SNA_3b" component={SNA3b} />
            <Route path="/SNA_3c" component={SNA3c} /> 
            <Route path="/SNA_5a" component={SNA5a} />
            <Route path="/SNA_5b" component={SNA5b} />
            <Route path="/SNA_6a" component={SNA6a} />
            <Route path="/SNA_6b" component={SNA6b} />
            <Route path="/SNA_9a" component={SNA9} /> 
            <Route path="/SNA_10a" component={SNA10a} /> 
            <Route path="/SNA_10b" component={SNA10b} /> 
            <Route path="/SNA_10c" component={SNA10c} />  
            <Route path="/SNA_11a" component={SNA11a} />
            <Route path="/SNA_11b" component={SNA11b} />
            <Route path="/SNA_11c" component={SNA11c} />  
            <Route path="/SNA_12a" component={SNA12} />
            <Route path="/SNA_15a" component={SNA15a} />
            <Route path="/SNA_15b" component={SNA15b} />
            <Route path="/SNA_19a" component={SNA19} />
            <Route path="/SNA_22a" component={SNA22} />
            <Route path="/SNA_23a" component={SNA23} />
            <Route path="/SNA_24a" component={SNA24a} />
            <Route path="/SNA_24b" component={SNA24b} />
            <Route path="/SNA_25a" component={SNA25} />
            <Route path="/SNA_26a" component={SNA26} />
            <Route path='*'>
              <ErrorPage />
            </Route>
          </Switch>
        </Layout>
      </div>
    );
  }
}
 
export default App;
