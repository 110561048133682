import React, { Component } from 'react';
import classes from './Form.module.css';
import ToggleSwitch from '../../../../components/UI/ToggleSwitch/ToggleSwitch';
import parabola_1 from '../../../../assets/icons/SNA6/1.png';
import parabola_2 from '../../../../assets/icons/SNA6/2.png';
import parabola_3 from '../../../../assets/icons/SNA6/3.png';
import parabola_4 from '../../../../assets/icons/SNA6/4.png';
import parabola_5 from '../../../../assets/icons/SNA6/5.png';
import parabola_6 from '../../../../assets/icons/SNA6/6.png';
import Input from '../../../../components/UI/Input/Input';
import Button from '../../../../components/UI/Button/Button';
import { withTranslation } from 'react-i18next';

const REGEX_FLOAT = /^\d*\.?\d$/;  // one digit after decimal

class Form extends Component {
    state = {
        startPoints: [
            {sliderColor: "SliderWhite", sliderType: "Rounded", coords: 'A(0,0)', switchOn: true},
            {sliderColor: "SliderWhite", sliderType: "Rounded", coords: 'B(3,9)', switchOn: false},
            {sliderColor: "SliderWhite", sliderType: "Rounded", coords: 'C(4,8)', switchOn: false},
            {sliderColor: "SliderWhite", sliderType: "Rounded", coords: 'D(5,5)', switchOn: false},
        ],
        explorerPaths: [
            {sliderColor: "SliderWhite", sliderType: "Rounded", func: 'f(x)=x^2', img: parabola_1, switchOn: true, validStartPoints: [0, 1], finishPoint: 24.313},
            {sliderColor: "SliderWhite", sliderType: "Rounded", func: 'f(x)=0.5x^2', img: parabola_2, switchOn: false, validStartPoints: [0, 2],finishPoint: 34.133},
            {sliderColor: "SliderWhite", sliderType: "Rounded", func: 'f(x)=11/25x^2-6', img: parabola_3, switchOn: false, validStartPoints: [3], finishPoint: 36.495},
            {sliderColor: "SliderWhite", sliderType: "Rounded", func: 'f(x)=0.4x^2-5', img: parabola_4, switchOn: false, validStartPoints: [3], finishPoint: 38.182},
            {sliderColor: "SliderWhite", sliderType: "Rounded", func: 'f(x)=2/3x^2+3', img: parabola_5, switchOn: false, validStartPoints: [1], finishPoint: 29.594},
            {sliderColor: "SliderWhite", sliderType: "Rounded", func: 'f(x)=3/16x^2+5', img: parabola_6, switchOn: false, validStartPoints: [2], finishPoint: 54.206},
        ],
        speed: {
            elementType: 'input',
            elementConfig: {
                type: 'number',
            },
            validation: {
                required: true,
                minSpeed: 0.1,
                maxSpeed: 15,
            },
            value: '',
            valid: false,
            touched: false,
        },
        startPointIsValid: true,
        randomNum: 0,
    }

    handleNewVariantClick = (min, max) => {
        let random = this.randomNum(min, max);
        while (this.state.randomNum === random) {
            random = this.randomNum(min, max);
        }
        this.setState({ randomNum: random });

        const updatedValues = [...this.state.explorerPaths]
        updatedValues.forEach((v, i) => v.switchOn = i !== random ? false : true);
        this.setState({ explorerPaths: updatedValues });

        const parabola = {};
        parabola['explorerPath'] = this.state.explorerPaths[random].func;
        this.props.parabolaData(parabola);
    }

    randomNum = (min, max) => Math.trunc(Math.random() * (max - min + 1)) + min;

    onToggle = (index) => { 
        const updatedValues = [...this.state.startPoints]
        updatedValues.forEach((v, i) => v.switchOn = i !== index ? false : true);
        this.setState({ startPoints: updatedValues });
    }

    checkValidity(value, rules) {
        let isValid = true;
        isValid = value.trim() !== '' && isValid;
        isValid = REGEX_FLOAT.test(value.trim());
        isValid = (value >= rules.minSpeed && value <= rules.maxSpeed) && isValid;
        return isValid;
    }

    inputChangedHandler = (event) => {
        this.setState({ 
            speed: { 
                ...this.state.speed, 
                value: event.target.value,
                touched: true,
                valid: this.checkValidity(event.target.value, this.state.speed.validation),
            } 
        });     
    }

    verifyCoordsAndTrajectory = () => {
        for (const v of this.state.explorerPaths) {
            if (v.switchOn) {
                let startPointIsValid = false;
                for (const validIndex of v.validStartPoints) {
                    startPointIsValid = startPointIsValid || this.state.startPoints[validIndex].switchOn; 
                }
                this.setState({ startPointIsValid }, () => this.state.startPointIsValid && this.setCoordsAndTrajectory());
            }
        }
    }

    setCoordsAndTrajectory = () => {
        const { startPoints, explorerPaths } = this.state;
        const coordsAndTrajectoryData = {};
        for (const point of startPoints) point.switchOn && (coordsAndTrajectoryData['startPoint'] = +point.coords.slice(2, -1).split(',')[0]);
        for (const path of explorerPaths) path.switchOn && (coordsAndTrajectoryData['explorerPath'] = path.func) && (coordsAndTrajectoryData['finishPoint'] = path.finishPoint);
        this.props.coordsAndTrajectoryData(coordsAndTrajectoryData);
    }

    dataHandler = (event) => {
        event.preventDefault();
        const { speed } = this.state;
        const formData = { speed: +speed.value };
        this.props.formData(formData);
    }

    render() { 
        const { t } = this.props;
        const { startPoints, explorerPaths, startPointIsValid } = this.state;
        const { elementType, elementConfig, validation: { minSpeed, maxSpeed }, value, valid, touched } = this.state.speed;
        const { isDataReseted, isCoordsDataReseted } = this.props;

        const startCoords = startPoints.map((point, i) => (
            <div key={i} className={classes.StartPointControl}>
                <p>{point.coords}</p>
                <ToggleSwitch
                    sliderColor={point.sliderColor}
                    sliderType={point.sliderType}
                    disabled={!isCoordsDataReseted}
                    isToggled={point.switchOn}
                    onToggle={() => this.onToggle(i)}/>
            </div>
        ));

        const startFuncs = explorerPaths.map((path, i) => (
            <div key={i} className={classes.ExplorerPath}>
                {path.switchOn && <img width='120' height='42' alt='parabola' src={path.img}/>}
            </div>
        ));
        
        return (
            <form className={classes.Form} onSubmit={this.dataHandler}>
                <Button 
                    type='button'
                    btnType='Success' 
                    clicked={() => this.handleNewVariantClick(0, 5)}
                    disabled={!isCoordsDataReseted}
                    style={{width: '100%', marginBottom: '15px'}}
                >
                    {t('buttons.new-variant')}
                </Button>
                <p>{t('SNA6.SNA6a.form.paragraphs.trajectory')}</p>
                <div className={classes.ExplorerPaths}>
                    {startFuncs}
                </div>
                <hr />
                <p>{t('SNA6.SNA6a.form.paragraphs.start-point')}</p>
                <div className={classes.StartPointControls}>
                    {startCoords}
                </div>
                {startPointIsValid || <p className={classes.CoordsError}>{t('SNA6.SNA6a.coords-error')}</p>}
                <Button 
                    type='button'
                    btnType='Success' 
                    clicked={this.verifyCoordsAndTrajectory}
                    disabled={!isCoordsDataReseted}
                    style={{width: '100%', margin: '15px 0'}}
                >
                    {t('SNA6.form.verify-btn')}
                </Button>
                <Input 
                    elementType={elementType}
                    elementConfig={elementConfig}
                    placeholder={t('SNA6.form.placeholders.speed')}
                    value={value}
                    invalid={!valid}
                    errorMessage={t('SNA6.form.error', {minValue: minSpeed, maxValue: maxSpeed})}
                    touched={touched}
                    disabled={!isDataReseted || isCoordsDataReseted}
                    changed={(event) => this.inputChangedHandler(event)}/>
                <Button 
                    btnType='Success' 
                    disabled={!(valid && isDataReseted && !isCoordsDataReseted)}
                    style={{width: '100%', marginBottom: '15px'}}
                >
                    {t('buttons.submit')}
                </Button>
            </form>
        );
    }
}
 
export default withTranslation() (Form);
