import React, { useState } from 'react';
import { addStyles, EditableMathField } from 'react-mathquill';
import classes from './EditableField.module.css';
import { useSNA24Context } from '../../../context/SNA24-context';
import { useTranslation } from 'react-i18next';
import Keypad from '../Keypad/Keypad';
import KeypadButtons from '../KeypadButtons/KeypadButtons';

addStyles();

const EditableField = () => {
    const [latex, setLatex] = useState('');
    const [field, setField] = useState();
    const { setExpression, calculationError, zeroError } = useSNA24Context();
    const { t } = useTranslation();

    const handleClearClick = () => {
        setLatex('');
        setExpression('');
    }

    const handleKeypadClick = (command, keystroke) => {
        field.focus();
        field.write(command);
        keystroke && field.keystroke(keystroke);
    }

 
    return (
        <div className={classes.wrapper}>
            <div className={classes['label-wrapper']}>
                <label className={classes.label} htmlFor="editable-field">
                    {t('SNA24.expression-control.input.label')}
                </label>
                <button onClick={handleClearClick}>
                    {t('buttons.clear')}
                </button>
            </div>
            <EditableMathField
                id='editable-field'  
                className={classes['editable-field']}
                latex={latex}
                onChange={(mathField) => {
                    setLatex(mathField.latex())
                    setExpression(mathField.text())
                }}
                mathquillDidMount={(mathField) => {
                    setField(mathField);
                }}  
            />
            {calculationError && <p className={classes.error}>{t('SNA24.expression-control.input.error')}</p>}
            {zeroError && <p className={classes.error}>{t('SNA24.expression-control.input.zero-error')}</p>}
            <Keypad>
                <KeypadButtons
                    clicked={handleKeypadClick}/>
            </Keypad>
        </div>
    );
}
 
export default EditableField;
