import React, { Component } from 'react';
import Input from '../../../../components/UI/Input/Input';
import Button from '../../../../components/UI/Button/Button';
import classes from '../../common_components/Form.module.css';
import { create, all } from 'mathjs';
import { withTranslation } from 'react-i18next';

const MATH = create(all);

const REGEX_INTEGERS_FRACTIONS = /^[-]?[1-9]+([+]+[0-9]+\/[0-9]+)$/;  
const REGEX_DIGITS = /^([-]?\d)*$/;
const REGEX_FRACTIONS = /^[-]?[1-9][0-9]*\/[1-9][0-9]*$/;
const REGEX_FLOAT = /^[+-]?\d+(\.\d+)?$/;

const MAX_FOUNTAIN_HEIGHT = 6; // in abstract meters - to prevent fountains going over img top

class Form extends Component {
    state = {
        heights: [
            {
                elementType: 'input',
                elementConfig: {
                    type: 'text',
                },
                value: '', 
                validation: {
                    required: true,
                    minValue: 0,
                    maxValue: 4,
                },
                valid: false, 
                touched: false
            },
            {
                elementType: 'input',
                elementConfig: {
                    type: 'text',
                },
                value: '', 
                validation: {
                    required: true,
                    minValue: 0,
                    maxValue: 4,
                },
                valid: false, 
                touched: false
            },
            {
                elementType: 'input',
                elementConfig: {
                    type: 'text',
                },
                value: '', 
                validation: {
                    required: true,
                    minValue: 0,
                    maxValue: 4,
                },
                valid: false, 
                touched: false
            },
            {
                elementType: 'input',
                elementConfig: {
                    type: 'text',
                },
                value: '', 
                validation: {
                    required: true,
                    minValue: 0,
                    maxValue: 4,
                },
                valid: false, 
                touched: false
            },
        ],
        operations: [
            {
                elementType: 'select',
                elementConfig: {
                    options: [
                        {value: '+', displayValue: '+'},
                        {value: '-', displayValue: '-'}
                    ]
                },
                value: '+',
                validation: {},
                valid: true,
            },
            {
                elementType: 'select',
                elementConfig: {
                    options: [
                        {value: '+', displayValue: '+'},
                        {value: '-', displayValue: '-'}
                    ]
                },
                value: '+',
                validation: {},
                valid: true,
            },
            {
                elementType: 'select',
                elementConfig: {
                    options: [
                        {value: '+', displayValue: '+'},
                        {value: '-', displayValue: '-'}
                    ]
                },
                value: '+',
                validation: {},
                valid: true,
            },
            {
                elementType: 'select',
                elementConfig: {
                    options: [
                        {value: '+', displayValue: '+'},
                        {value: '-', displayValue: '-'},
                    ]
                },
                value: '+',
                validation: {},
                valid: true,
            },
        ],
        fractions: [
            {
                elementType: 'input',
                elementConfig: {
                    type: 'text',
                },
                value: 0, 
                validation: {
                    required: true,
                    minValue: 0,
                    maxValue: 4,    
                },
                valid: true, 
                validHeight: true,
                touched: false
            },
            {
                elementType: 'input',
                elementConfig: {
                    type: 'text',
                },
                value: 0, 
                validation: {
                    required: true,
                    minValue: 0,
                    maxValue: 4,
                },
                valid: true, 
                validHeight: true,
                touched: false
            },
            {
                elementType: 'input',
                elementConfig: {
                    type: 'text',
                },
                value: 0, 
                validation: {
                    required: true,
                    minValue: 0,
                    maxValue: 4,
                },
                valid: true, 
                validHeight: true,
                touched: false
            },
            {
                elementType: 'input',
                elementConfig: {
                    type: 'text',
                },
                value: 0, 
                validation: {
                    required: true,
                    minValue: 0,
                    maxValue: 4,
                },
                valid: true, 
                validHeight: true,
                touched: false
            },
        ],
        heightsIsValid: false,
        fractionsIsValid: true,
        formIsValid: false
    }

    checkValidity = (value, rules) => {
        let isValid = false;
        isValid = value.trim() !== '' && isValid;
        isValid = REGEX_INTEGERS_FRACTIONS.test(value.trim()) || REGEX_DIGITS.test(value.trim()) || REGEX_FRACTIONS.test(value.trim()) || REGEX_FLOAT.test(value.trim());
        if (isValid) 
            isValid = (MATH.compile(value).evaluate() >= rules.minValue && MATH.compile(value).evaluate() <= rules.maxValue) && isValid;
        return isValid;
    }

    checkMaxHeightValidityOnHeightChange = (value, index) => {
        const str = `${value}${this.state.operations[index].value}(${this.state.fractions[index].value}*6)`;
        const lastFountainHeight = MATH.compile(str).evaluate();
        const updatedFractions = [...this.state.fractions];
        const updatedFraction = {...updatedFractions[index]}
        updatedFraction.validHeight = lastFountainHeight > 6 ? false : true;
        updatedFractions[index] = updatedFraction;
        this.setState({ fractions: updatedFractions }, () => this.checkMaxHeights());  
    }

    // additional check for overalk form validity
    checkMaxHeights = () => {
        let fractionsIsValid = true;
        this.state.fractions.forEach(fraction => fractionsIsValid = fraction.valid && fraction.validHeight && fractionsIsValid);
        this.setState({formIsValid: fractionsIsValid && this.state.heightsIsValid});
    }

    checkMaxHeightValidityOnFractionChange = (value, index) => {
        let isHeightValid = false;
        isHeightValid = value.trim() !== '' && isHeightValid;
        const str = `${this.state.heights[index].value}${this.state.operations[index].value}(${value}*6)`;
        const lastFountainHeight = MATH.compile(str).evaluate();
        isHeightValid = lastFountainHeight <= MAX_FOUNTAIN_HEIGHT;
        return isHeightValid;
    }

    checkMaxHeightValidityOnOperationChange = (value, index) => {
        const str = `${this.state.heights[index].value}${value}(${this.state.fractions[index].value}*6)`;
        const lastFountainHeight = MATH.compile(str).evaluate();
        const updatedFractions = [...this.state.fractions];
        const updatedFraction = {...updatedFractions[index]}
        updatedFraction.validHeight = lastFountainHeight > 6 ? false : true;
        updatedFractions[index] = updatedFraction;

        let fractionsIsValid = true;
        updatedFractions.forEach(fraction => fractionsIsValid = fraction.valid && fraction.validHeight && fractionsIsValid);
        this.setState({ fractions: updatedFractions, formIsValid: fractionsIsValid && this.state.heightsIsValid});   
    }

    heightInputChangedHandler = (event, index) => {
        const updatedHeights = [...this.state.heights];
        const updatedHeight = {...updatedHeights[index]}
        updatedHeight.value = event.target.value;
        updatedHeight.valid = this.checkValidity(updatedHeight.value, updatedHeight.validation);
        (updatedHeight.valid && this.state.fractions[index].valid) && this.checkMaxHeightValidityOnHeightChange(updatedHeight.value, index);
        updatedHeight.touched = true;
        updatedHeights[index] = updatedHeight;

        let heightsIsValid = true;
        updatedHeights.forEach(height => heightsIsValid = height.valid && heightsIsValid);

        this.setState({ heights: updatedHeights, heightsIsValid, formIsValid: heightsIsValid && this.state.fractionsIsValid}); 
    }

    operationInputChangedHandler = (event, index) => {
        const updatedOperations = [...this.state.operations];
        const updatedOperation = {...updatedOperations[index]}
        updatedOperation.value = event.target.value;
        (this.state.heights[index].valid && this.state.fractions[index].valid) && this.checkMaxHeightValidityOnOperationChange(updatedOperation.value, index);
        updatedOperations[index] = updatedOperation;
        this.setState({ operations: updatedOperations });
    }

    fractionInputChangedHandler = (event, index) => {
        const updatedFractions = [...this.state.fractions];
        const updatedFraction = {...updatedFractions[index]}
        updatedFraction.value = event.target.value;
        updatedFraction.valid = this.checkValidity(updatedFraction.value, updatedFraction.validation);
        updatedFraction.validHeight = updatedFraction.valid && this.checkMaxHeightValidityOnFractionChange(updatedFraction.value, index);
        updatedFraction.touched = true;
        updatedFractions[index] = updatedFraction;

        let fractionsIsValid = true;
        updatedFractions.forEach(fraction => fractionsIsValid = fraction.valid && fraction.validHeight && fractionsIsValid);
        this.setState({ fractions: updatedFractions, fractionsIsValid, formIsValid: fractionsIsValid && this.state.heightsIsValid });
    }

    dataHandler = (event) => {
        event.preventDefault();
        const { heights, operations, fractions } = this.state;
        const targetHeights = [];
        for (let i = 0; i < heights.length; i++) {
            let height = MATH.compile(heights[i].value).evaluate();
            const operation = operations[i].value;
            const fraction = MATH.compile(fractions[i].value).evaluate();
            targetHeights.push(height);
            let n = 0;
            while (n < 6) {
                height = MATH.compile(`${height}${operation}${fraction}`).evaluate();
                targetHeights.push(height)
                n++;
            }
        }
        this.props.formData(targetHeights);
    }

    render() { 
        const { heights, operations, fractions, formIsValid } = this.state;
        const { isDataReseted, t } = this.props;
        return (
            <form onSubmit={this.dataHandler}>
                {heights.map((height, index) => (
                    <div key={index}>
                        {index === 0 && <div className={classes.Header}>
                            <p>{t('SNA11.SNA11b&c.form.inputs.input-1.label')}</p>
                            <p></p>
                            <p>{t('SNA11.SNA11b&c.form.inputs.input-3.label')}</p>
                        </div>}
                         <div className={classes.Inputs}>
                            <div style={{flex: '2.5', display: 'flex', marginRight: '10px'}}>
                                <p className={classes.InputNumber}>{`${index + 1}a`}</p>
                                <Input 
                                    elementType={height.elementType}
                                    elementConfig={height.elementConfig}
                                    placeholder={t('SNA11.SNA11b&c.form.inputs.input-1.placeholder')}
                                    value={height.value}
                                    validation={height.validation}
                                    errorMessage={t('SNA11.SNA11b&c.form.errors.minMaxError', {minValue: height.validation.minValue, maxValue: height.validation.maxValue})}
                                    invalid={!height.valid}
                                    touched={height.touched}
                                    disabled={!isDataReseted}
                                    changed={(event) => this.heightInputChangedHandler(event, index)}/>
                            </div>
                            <Input 
                                style={{flex: '1', marginRight: '10px'}}
                                elementType={operations[index].elementType}
                                elementConfig={operations[index].elementConfig}
                                value={operations[index].value}
                                validation={operations[index].validation}
                                invalid={!operations[index].valid}
                                disabled={!isDataReseted}
                                changed={(event) => this.operationInputChangedHandler(event, index)}/>
                            <Input 
                                style={{flex: '2'}}
                                id={'section'}
                                elementType={fractions[index].elementType}
                                elementConfig={fractions[index].elementConfig}
                                placeholder={t('SNA11.SNA11b&c.form.inputs.input-3.placeholder')}
                                value={fractions[index].value}
                                validation={fractions[index].validation}
                                errorMessage={t('SNA11.SNA11b&c.form.errors.minMaxError', {minValue: height.validation.minValue, maxValue: height.validation.maxValue})}
                                maxHeightError={t('SNA11.SNA11b&c.form.errors.maxHeightError')}
                                invalid={!fractions[index].valid}
                                validHeight={!fractions[index].validHeight}
                                touched={fractions[index].touched}
                                disabled={!isDataReseted}
                                changed={(event) => this.fractionInputChangedHandler(event, index)}/>
                        </div>
                    </div>
                ))}
                <Button 
                    btnType='Success'
                    style={{width: '100%', marginBottom: '15px'}}
                    disabled={!(formIsValid  && isDataReseted)}
                >
                    {t('buttons.submit')}
                </Button>
            </form>
        );
    }
}
 
export default withTranslation() (Form);
