import React, { useState } from 'react';
import Checkbox from '../../UI/Checkbox/Checkbox';
import Button from '../../UI/Button/Button';
import classes from './Controls.module.css';
import { useSNA24Context } from '../../../context/SNA24-context';
import { useTranslation } from 'react-i18next';
import { IoCalculator, IoReload } from "react-icons/io5";
import { data } from '../../../data/SNA24data';

const Controls = () => {
    const checkboxesArr = Array.from({length: data.length}, (_, i) => ({ 
        id: data[i].id, 
        label: data[i].decade, 
        checked: i === data.length - 1 ? true : false 
    }));

    const { t } = useTranslation();
    const [checkboxes, setCheckboxes] = useState(checkboxesArr);
    const { 
        handleCheckboxChange, 
        handleCalculationClick, 
        handleResetClick, 
        expressionIsValid, 
        fieldsAreValid, 
        isCalculated 
    } = useSNA24Context();

    const checkboxChangeHandler = (event) => {
        const tempCheckboxes = [...checkboxes];
        for (const checkbox of tempCheckboxes) {
            checkbox.id === +event.target.id ? checkbox.checked = true : checkbox.checked = false;
        }
        setCheckboxes(tempCheckboxes);
        handleCheckboxChange(+event.target.id);
    }

    return (
        <div className={classes.wrapper}>
            <p>{t('SNA24.controls.p')}</p>
            <div className={classes.checkboxes}>
                {checkboxes.map(cb => 
                    <Checkbox 
                        key={cb.id} 
                        id={cb.id} 
                        checked={cb.checked}
                        label={cb.label} 
                        disabled={isCalculated}
                        changed={checkboxChangeHandler}
                    />
                )}
            </div>
            <Button
                btnType='Success'
                clicked={handleCalculationClick}
                disabled={!expressionIsValid || !fieldsAreValid || isCalculated}
            >
                <IoCalculator/> {t('buttons.calculation')}
            </Button>
            <Button 
                btnType='Primary' 
                clicked={handleResetClick}
            >
                <IoReload/> {t('buttons.reset')}
            </Button>
        </div>
    );
}
 
export default Controls;
