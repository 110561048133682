import React from 'react';
import { data } from '../../../data/SNA24data';
import classes from './Computer.module.css';
import { useSNA24Context } from '../../../context/SNA24-context';
import useImagesLoaded from '../../../hooks/useImagesLoaded';
import ImageWithLoading from '../../UI/ImageWithLoading/ImageWithLoading';

const Computer = () => {
    const { computerIndex } = useSNA24Context();
    const [ref, imagesLoaded] = useImagesLoaded();
    return (
        <div className={classes.wrapper} ref={ref}>
            <img src={data[computerIndex].src} alt="pc" />
            {!imagesLoaded && <ImageWithLoading />}
        </div>
    );
}
 
export default Computer;