import React, { useState } from 'react';
import Input from '../../UI/Input/Input';
import Button from '../../UI/Button/Button';
import { useSNA19Context } from '../../../context/SNA19-context';
import { useTranslation } from 'react-i18next';

const REGEX_FLOAT = /^\d*\.?\d\d$/;  //two digits after decimal

const Form = () => {
    const { isDataReseted, setSpacecraftSpeed, trajectoryIndex } = useSNA19Context();
    const { t } = useTranslation();
    
    const [speed, setSpeed] = useState({
        elementType: 'input',
        elementConfig: {
            type: 'number',
        },
        value: '',
        validation: {
            minSpeed: 11.19,
            maxSpeed: 35
        },
        valid: false,
        touched: false,
    });

    const inputChangedHandler = (event) => {
        setSpeed({
            ...speed, 
            value: event.target.value,
            touched: true,
            valid: checkValidity(event.target.value)
        });
    }

    const checkValidity = (value) => {
        let isValid = true;
        isValid = REGEX_FLOAT.test(value.trim());
        isValid = (value >= speed.validation.minSpeed && value <= speed.validation.maxSpeed) && isValid;
        return isValid;
    }

    const formSubmissionHandler = (event) => {
        event.preventDefault();
        setSpacecraftSpeed(+speed.value);
    }

    const { elementType, elementConfig, value, validation: { minSpeed, maxSpeed }, valid, touched } = speed;

    return (
        <form onSubmit={formSubmissionHandler}>
            <Input
                elementType={elementType}
                elementConfig={elementConfig}
                placeholder={t('SNA19.form.placeholder')}
                value={value}
                invalid={!valid}
                touched={touched}
                errorMessage={t('SNA19.form.error', {minValue: minSpeed, maxValue: maxSpeed})}
                disabled={trajectoryIndex === '' || !isDataReseted}
                changed={inputChangedHandler}
            /> 
            <Button 
                btnType='Success'
                disabled={!(valid && isDataReseted && trajectoryIndex !== '')}
            >
                {t('buttons.submit')}
            </Button>
        </form>
    );
}
 
export default Form;
