import React from 'react';
import classes from './Grinder.module.css';
import grinder from '../../../assets/SNA23/grinder/grinder_v2.png';
import grinder_wheel from '../../../assets/SNA23/grinder/grinder_wheel_v3.png';
import grain from '../../../assets/SNA23/grinder/grain_v1.png';
import flour from '../../../assets/SNA23/grinder/flour_v1.png';
import { useSNA23Context } from '../../../context/SNA23-context';
import useImagesLoaded from '../../../hooks/useImagesLoaded';
import ImageWithLoading from '../../UI/ImageWithLoading/ImageWithLoading';

const Grinder = () => {
    const { isTimerOn, isAnimationSpeedSlowed } = useSNA23Context();
    const [ref, imagesLoaded] = useImagesLoaded();
    return (
        <div 
            ref={ref}
            className={classes.wrapper} 
            style={{
                '--play-state': isTimerOn ? 'running' : 'paused', 
                '--duration-wheel': isAnimationSpeedSlowed ? '8s' : '4s',
                '--duration-grain': isAnimationSpeedSlowed ? '2s' : '1.3s',
                '--duration-flour': isAnimationSpeedSlowed ? '0.35s' : '0.2s',
            }}
        >
            {isTimerOn && <div className={classes.grain} style={{backgroundImage: `url(${grain})`}}></div>}
            {isTimerOn && <div className={classes.flour} style={{backgroundImage: `url(${flour})`}}></div>}  
            <img className={classes.grinder} src={grinder} alt='grinder'/>
            <img className={classes.wheel} src={grinder_wheel} alt='grinder-wheel'/>
            {!imagesLoaded && <ImageWithLoading />}
        </div>
        
    );
}
 
export default Grinder;
