import React from 'react';
import classes from '../../common_components/Controls/Controls.module.css';
import Button from '../../../../components/UI/Button/Button';
import Form from '../../../../containers/SNA10/SNA10c/Form/Form';
import Animation from '../../Animation/Animation';
import { useTranslation } from 'react-i18next';

const Controls = (props) => {
    const { activityType, actionType, isDataReseted, canStart, isRunning, isToggled } = props;
    const { t } = useTranslation();
    return (
        <div className={classes.Container}>
            <div className={classes.Controls}>
                <Form 
                    isDataReseted={isDataReseted}
                    formData={formData  => props.formData(formData)}/> 
                <div className={classes.Buttons}>
                    <Button 
                        btnType="Danger" 
                        style={{width: '50%', marginRight: '5px'}}
                        disabled={!canStart}
                        clicked={() => props.start()}
                    >
                        {isRunning ? t('buttons.pause') : t('buttons.start')}
                    </Button>
                    <Button 
                        btnType="Primary" 
                        style={{width: '50%', marginLeft: '5px'}}
                        clicked={() => props.reset()}
                    >
                        {t('buttons.reset')}
                    </Button>
                </div>
            </div>
            <Animation 
                activityType={activityType}
                actionType={actionType} 
                isToggled={isToggled}/> 
        </div>
    );
}
 
export default Controls;
