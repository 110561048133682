import React, { useContext, useReducer } from 'react';
import reducer from '../reducers/SNA24-reducer';

const SNA24Context = React.createContext();

export const SNA24Provider = (props) => {
    const initialState = {
        computerIndex: 6,
        expression: '',
        calculationTime: null,
        zeroError: false,
        calculationError: false,
        expressionVariables: [],
        expressionIsValid: false,
        fieldsAreValid: false,
        isCalculated: false
    };

    const [state, dispatch] = useReducer(reducer, initialState);

    const handleCheckboxChange = (id) => {
        dispatch({ type: 'CHANGE_COMPUTER', payload: id });
    }

    const setExpression = (exp) => {
        dispatch({ type: 'SET_EXPRESSION', payload: exp });
    }

    const setCalculationError = () => {
        dispatch({ type: 'ERROR' });
    }

    const setZeroDivisionError = () => {
        dispatch({ type: 'ZERO_ERROR' });
    }

    const setCalculationTime = (time) => {
        dispatch({ type: 'SET_TIME', payload: time });
    }

    const inputChangeHandler = (data) => {
        dispatch({ type: 'UPDATE_INPUT', payload: data})
    }

    const increase = (index) => {
        dispatch({ type: 'INCREASE', payload: index});
    }

    const decrease = (index) => {
        dispatch({ type: 'DECREASE', payload: index });
    }

    const handleCalculationClick = () => {
        dispatch({ type: 'CALCULATE' });
    }

    const handleResetClick = () => {
        dispatch({ type: 'RESET' });
    }

    return (
        <SNA24Context.Provider
            value={{
                ...state,
                handleCheckboxChange,
                setExpression,
                setCalculationError,
                setZeroDivisionError,
                setCalculationTime,
                inputChangeHandler,
                increase,
                decrease,
                handleCalculationClick,
                handleResetClick
            }}
        >
            {props.children}
        </SNA24Context.Provider>
    );
}

export const useSNA24Context = () => {
    return useContext(SNA24Context);
};
 