import React from 'react';
import classes from './Sun.module.css';

const Sun = (props) => {
    return (
        <ul className={classes.sun} style={props.style}>
            <li/>
            <li/>
            <li/>
            <li/>
        </ul>
    );
}
 
export default Sun;
