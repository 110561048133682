import React from 'react';
import { Fragment } from 'react';
import classes from './Modal.module.css';
import Backdrop from '../Backdrop/Backdrop';

const modal = (props) => (
    <Fragment>
        <Backdrop 
            show={props.show}
            clicked={props.modalClosed}/>
        <div 
            className={classes.Modal}
            style={{
                transform: props.show ? 'translate(-50%, -50%)' : 'translate(-50%, -100vh)',
                opacity: props.show ? '1' : '0'
            }}>
            <button className={classes.Close} onClick={props.modalClosed}>&times;</button>
            <div className={classes.Header}>
                <div className={classes.Title}>{props.title}</div>
            </div>
            <div className={classes.Body}>
                {props.children}
            </div>
        </div>
    </Fragment>
);
 
export default modal;