import React, { useState } from 'react';
import Button from '../../../../components/UI/Button/Button';
import classes from './Digits.module.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBackspace } from '@fortawesome/free-solid-svg-icons';

const DIGITS = [7, 8, 9, 4, 5, 6, 1, 2, 3, 0];

const Digits = (props) => {

    const [value, setValue] = useState('');

    return (
        <section className={classes.Digits}>
            <div className={classes.Display}>
                <button className={classes.CloseButton} onClick={props.closeDigitsPanel}>&times;</button>
                {value}
            </div>
            {DIGITS.map((digit, index) => (
                <Button
                    btnType='Secondary'
                    key={index}
                    style={{ width: '100%', margin: '0', borderRadius: index !== 9 ? '0' : '0 0 0 9px', border: '1px solid #6d6d6d' }}
                    clicked={() => setValue(digit)}>{digit}
                </Button>
            ))}
            <Button
                btnType='Secondary'
                style={{ width: '100%', margin: '0', borderRadius: '0', border: '1px solid #6d6d6d' }}
                clicked={() => setValue('')}>
                <FontAwesomeIcon
                    className={classes.BackspaceIcon}
                    icon={faBackspace}
                    size="2x" />
            </Button>
            <Button
                btnType='Success'
                disabled={value.length === 0}
                style={{ width: '100%', margin: '0', borderRadius: '0 0 9px 0', border: '1px solid #6d6d6d' }}
                clicked={() => props.setInputBlockDigitValue(Number(value))}>OK
            </Button>
        </section>
    );
}

export default Digits;
