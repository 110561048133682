import React, { useRef, useEffect, useCallback } from 'react';
import useWindowSize from '../../../hooks/useWindowSize';
import classes from './Chart.module.css';

const WIDTH = 0.22; // vw
const HEIGHT = 200;
const DPI_WIDTH = WIDTH * 2;
const DPI_HEIGHT = HEIGHT * 2;
const PADDING = 40;
const MEDIA_PROPORTION = 3.65;
const VIEW_HEIGHT = DPI_HEIGHT - PADDING * 2;
const VIEW_WIDTH = DPI_WIDTH;
const ROWS_COUNT = 5;
const COLUMNS_COUNT = 5;
let ctx;

const Chart = (props) => {
    const { labels = {x: 'x', y: 'y'}, axisData = [[0, 0]], drawingData = [[0, 0]] } = props;

    const canvasRef = useRef();
    let { width: windowWidth } = useWindowSize();
    if (windowWidth <= 1000) windowWidth = windowWidth * MEDIA_PROPORTION;

    const computeBoundaries = (data) => {
        let xMin, xMax, yMin, yMax;
        for (const [x, y] of data) {
            if (typeof xMin !== 'number') xMin = x;
            if (typeof xMax !== 'number') xMax = x
            if (typeof yMin !== 'number') yMin = y;
            if (typeof yMax !== 'number') yMax = y;

            if (xMin > x) xMin = x;
            if (xMax < x) xMax = x;
            if (yMin > y) yMin = y;
            if (yMax < y) yMax = y;
        }
        return [xMin, xMax, yMin, yMax];
    }

    const xAxis = useCallback((ctx, xMin, xMax) => {
        const step = VIEW_WIDTH * windowWidth / COLUMNS_COUNT;
        const textStep = (xMax - xMin) / COLUMNS_COUNT;
        ctx.beginPath();
        ctx.strokeStyle = '#aaa';
        ctx.font = 'normal 16px Helvetica, sans-serif';
        for (let i = 0; i <= COLUMNS_COUNT; i++) {
            const x = step * i;
            const text = Math.round(xMin + textStep * i);
            i !== COLUMNS_COUNT && ctx.fillText(text === 0 && i > 0 ? '' : text.toString(), x - (text === 0 ? 0 : 20), DPI_HEIGHT - PADDING / 3);
            ctx.moveTo(x + (i === COLUMNS_COUNT ? -1 : 0), DPI_HEIGHT - PADDING);
            ctx.lineTo(x + (i === COLUMNS_COUNT ? -1 : 0), PADDING);
            ctx.stroke();
        }
        ctx.closePath();
    }, [windowWidth]);

    const yAxis = useCallback((ctx, yMin, yMax) => {
        const step = VIEW_HEIGHT / ROWS_COUNT;
        const textStep = (yMax - yMin) / ROWS_COUNT;
        ctx.beginPath();
        ctx.strokeStyle = '#aaa';
        ctx.font = 'normal 16px Helvetica, sans-serif';
        for (let i = 0; i <= ROWS_COUNT; i++) {
            const y = step * i;
            const text = Math.round(yMax - textStep * i);
            ctx.fillText(text === 0 ? '' : text.toString(), 5, y + PADDING - 5)
            ctx.moveTo(0, y + PADDING);
            ctx.lineTo(DPI_WIDTH * windowWidth, y + PADDING);
            ctx.stroke();
        }
        ctx.closePath();
    }, [windowWidth]);

    const axisLabels = useCallback((ctx, xAxisLabel, yAxisLabel) => {
        ctx.font = 'bold 22px Helvetica, sans-serif';
        ctx.fillText(xAxisLabel.toString(), DPI_WIDTH * windowWidth - 20, DPI_HEIGHT - PADDING / 3);
        ctx.fillText(yAxisLabel.toString(), 0, 20);
    }, [windowWidth]);

    const toCoords = (xRatio, yRatio) => {
        return ([x, y]) => [
            Math.floor(x * xRatio), 
            Math.floor(DPI_HEIGHT - PADDING - y * yRatio)
        ]
    }

    const drawLine = (ctx, coords) => {
        ctx.beginPath();
        ctx.lineWidth = 5;
        ctx.strokeStyle = 'green';
        for (const [x, y] of coords) {
            ctx.lineTo(x, y);
        }
        ctx.stroke();
        ctx.closePath();
    }

    useEffect(() => {
        const canvas = canvasRef.current;
        ctx = canvas.getContext('2d');
        canvas.style.width = WIDTH  * windowWidth + 'px';
        canvas.style.height = HEIGHT + 'px';
        canvas.width = DPI_WIDTH  * windowWidth;
        canvas.height = DPI_HEIGHT;

        const [xMin, xMax, yMin, yMax] = computeBoundaries(axisData);
        const xRatio = VIEW_WIDTH  * windowWidth / (xMax - xMin);
        const yRatio = VIEW_HEIGHT / (yMax - yMin);
        
        xAxis(ctx, xMin, xMax);
        yAxis(ctx, yMin, yMax);
        axisLabels(ctx, labels.x, labels.y)

        const coords = drawingData.map(toCoords(xRatio, yRatio));
        drawLine(ctx, coords);
    }, [windowWidth, axisLabels, xAxis, yAxis, labels, axisData, drawingData]);
    
    return (
        <canvas className={classes.canvas} ref={canvasRef} />
    );
}
 
export default Chart;
