import React, { Component } from 'react';
import classes from './SNA12.module.css';
import Map from '../../components/SNA12/Map/Map';
import Sidebar from '../../components/SNA12/Sidebar/Sidebar';
import Modal from '../../components/UI/Modal/Modal';
import AddLocationForm from './Forms/LocationForm/AddLocationForm';
import EditLocationForm from './Forms/LocationForm/EditLocationForm';
import nextId from "react-id-generator";
import scale from '../../assets/unit_v4.png';

class SNA12 extends Component {
    state = {
        coords: {
            latitude: 58.5,
            longitude: 25,
            zoom: 8
        },
        settings: {
            size: 1,
            population: 10000
        },
        selectedLocation: {},
        selectedLocationIndex: null,
        locationPoints: [],
        isAddLocationModalOpen: false,
        isEditLocationModalOpen: false,
        tempLatitude: 0,
        tempLongitude: 0,
    }

    locationPoint(id, coords, location, population) {
        return { id, coords, location, population }
    }

    getFormData = (formData) => {
        const location = this.locationPoint(nextId(), [this.state.tempLatitude, this.state.tempLongitude], formData.place, +formData.population)
        this.closeAddLocationModal();
        this.setState({ locationPoints: [...this.state.locationPoints, location] });
    }

    updateFormData = (updatedData) => {
        this.closeEditLocationModal();
        const updatedLocationPoints = [...this.state.locationPoints];
        updatedLocationPoints[this.state.selectedLocationIndex] = updatedData;
        this.setState({ locationPoints: updatedLocationPoints });
    }

    getSettingsFormData = (settings) => {
        this.setState({ settings });
    }

    openAddLocationModal = (coords) => {
        const [lat, lng] = coords;
        this.setState({ isAddLocationModalOpen: true, tempLatitude: lat, tempLongitude: lng });
    }

    closeAddLocationModal = () => {
        this.setState({ isAddLocationModalOpen: false });
    }

    openEditLocationModal = () => {
        this.setState({ isEditLocationModalOpen: true });
    }

    closeEditLocationModal = () => {
        this.setState({ isEditLocationModalOpen: false });
    }

    deleteLocation = (index) => {
        const updatedLocationPoints = [...this.state.locationPoints];
        updatedLocationPoints.splice(index, 1);
        this.setState({ locationPoints: updatedLocationPoints });
    }

    editLocation = (selectedLocation, selectedLocationIndex) => {
        this.setState({ selectedLocation, selectedLocationIndex }, () => this.openEditLocationModal());
    }

    render() { 
        const { latitude, longitude, zoom } = this.state.coords;
        const { isAddLocationModalOpen, isEditLocationModalOpen, locationPoints, selectedLocation, settings } = this.state;
        return (
            <div className={classes.SNA12}>
                <Map
                    latitude={latitude}
                    longitude={longitude}
                    center={[latitude, longitude]}
                    zoom={zoom}
                    circles={locationPoints}
                    settings={settings}
                    openAddLocationModal={this.openAddLocationModal} />
                <Sidebar 
                    formDataCallback={this.getSettingsFormData}
                    deleteLocation={this.deleteLocation}
                    editLocation={this.editLocation}
                    locationPoints={locationPoints}
                    settings={settings}/>
                <Modal 
                    show={isAddLocationModalOpen}
                    modalClosed={() => this.closeAddLocationModal()}>
                    <AddLocationForm formDataCallback={this.getFormData} /> 
                </Modal>
                <Modal 
                    show={isEditLocationModalOpen}
                    modalClosed={() => this.closeEditLocationModal()}>
                    <EditLocationForm 
                        selectedLocation={selectedLocation}
                        formDataCallback={this.updateFormData} /> 
                </Modal>
                <img className={classes.Scale} alt='scale' src={scale}/> 
            </div>
        );
    }
}
 
export default SNA12;
 