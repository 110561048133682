import React, { Fragment } from 'react';
import Accordion from '../../UI/Accordion/Accordion';
import classes from './QuickStart.module.css';
import expression from '../../../assets/SNA1/expression1.png';

const QuickStartB = () => {
    const exp1 = <span className={classes.expression}>
        x<span className={classes.exponent}>2</span>
    </span>

    const exp2 = <span className={classes.expression}>x * x</span>
    return (  
        <Fragment>
            <Accordion title={'Mida me teeme selles programmis?'}>
                <p className={classes.paragraph}>Tänapäeva teatri- või kontserdilaval on palju erinevaid valgusteid. Paljud neist peavad töötama kindlate algoritmide järgi. See programm näitab sulle, kuidas juhitakse selliseid valgustusi. Saad kasutada oma teadmisi kolme tundmatuga ruutvõrrandite süsteemi lahendamisest.</p>
            </Accordion>
            <Accordion title={'Kelle tööd see programm tutvustab?'}>
                <p className={classes.paragraph}>Inimesi, kes töötavad teatri-, kontserdi- ja näitustesaalides valgustitega, kutsutakse valgusinsenerideks või valgustehnikuteks. Insenerid tihti loovad uusi seadmeid ja uusi algoritme. Nendel on tavaliselt kõrgharidus. Tehnikud tavaliselt kasutavad, häälestavad ja hooldavad olemasolevaid seadmeid ja seadistavad neid etendusteks. Tehnikutel võib olla nii kõrg- kui ka keskeriharidus.</p>
            </Accordion>
            <Accordion title={'Kuidas programmi kasutada?'}>
                <div className={classes.step}>
                    <span className={classes.numeration}>1.</span>
                    <div>
                        <p className={classes.paragraph}>Leia õpetaja ülesande järgi leitud funktsioon ja sisesta see programmi. Näiteks: </p>
                        <img className={classes['expression-img']} src={expression} alt='expression' />
                        <p className={classes.paragraph}>Astendamiseks kasuta korrutamist (s.t. {exp1} kirjuta nagu {exp2}). Seejärel vajuta "OK" ja "START".</p>
                    </div>
                </div>
                <div className={classes.step}>
                    <span className={classes.numeration}>2.</span>
                    <p className={classes.paragraph}>Vaata, kas valgustuse käitumine vastab õpetaja poolt antud algoritmile. Uue funktsiooni sisestamiseks vajuta "RESET".</p>
                </div>
            </Accordion>
        </Fragment>
    );
}
 
export default QuickStartB;
