import React, { Component } from 'react';
import classes from './Form.module.css';
import ToggleSwitch from '../../../../components/UI/ToggleSwitch/ToggleSwitch';
import ast_1 from '../../../../assets/icons/SNA6/1_ast.png';
import ast_2 from '../../../../assets/icons/SNA6/2_ast.png';
import ast_3 from '../../../../assets/icons/SNA6/3_ast.png';
import ast_4 from '../../../../assets/icons/SNA6/4_ast.png';
import ast_5 from '../../../../assets/icons/SNA6/5_ast.png';
import exp_1_comp from '../../../../assets/icons/SNA6/1_exp_complete.png';
import exp_2_comp from '../../../../assets/icons/SNA6/2_exp_complete.png';
import exp_3_comp from '../../../../assets/icons/SNA6/3_exp_complete.png';
import exp_4_comp from '../../../../assets/icons/SNA6/4_exp_complete.png';
import exp_5_comp from '../../../../assets/icons/SNA6/5_exp_complete.png';
import exp_1_incomp from '../../../../assets/icons/SNA6/1_exp_incomplete.png';
import exp_2_incomp from '../../../../assets/icons/SNA6/2_exp_incomplete.png';
import exp_3_incomp from '../../../../assets/icons/SNA6/3_exp_incomplete.png';
import exp_4_incomp from '../../../../assets/icons/SNA6/4_exp_incomplete.png';
import exp_5_incomp from '../../../../assets/icons/SNA6/5_exp_incomplete.png';
import Input from '../../../../components/UI/Input/Input';
import Button from '../../../../components/UI/Button/Button';
import { withTranslation } from 'react-i18next';

const REGEX_POINT = /^[(][+-]?([0-9]*[.])?[0-9]+[,][+-]?([0-9]*[.])?[0-9]+[)]$/; // (x,y)
const REGEX_FLOAT = /^\d*\.?\d$/;  // one digit after decimal

class Form extends Component {
    state = {
        parabolasPairs: [
            {
                sliderColor: "SliderWhite", 
                sliderType: "Rounded", 
                explorerPath: 'f(x)=x^2/57-175x/57-300/19', 
                asteroidPath: 'g(x)=-x^2/81+400',
                explorerImg: exp_3_comp, 
                asteroidImg: ast_3, 
                switchOn: true, 
                validStartPoint: 2, 
                intersectionPoint: '(180,0)'
            },
            {
                sliderColor: "SliderWhite", 
                sliderType: "Rounded", 
                explorerPath: 'f(x)=-x^2/435+61x/87-100/29', 
                asteroidPath: 'g(x)=-x^2/180+500', 
                explorerImg: exp_2_comp, 
                asteroidImg: ast_2,
                switchOn: false, 
                validStartPoint: 1, 
                intersectionPoint: '(300,0)'
            },
            {
                sliderColor: "SliderWhite", 
                sliderType: "Rounded", 
                explorerPath: 'f(x)=25x^2/5194-50x/53-10000/2597', 
                asteroidPath: 'g(x)=-0.015x^2+600', 
                explorerImg: exp_1_comp, 
                asteroidImg: ast_1,
                switchOn: false, 
                validStartPoint: 3, 
                intersectionPoint: '(200,0)'
            },
            {
                sliderColor: "SliderWhite", 
                sliderType: "Rounded", 
                explorerPath: 'f(x)=x^2/129-82x/43-1000/129', 
                asteroidPath: 'g(x)=-9x^2/1250+450', 
                explorerImg: exp_4_comp, 
                asteroidImg: ast_4, 
                switchOn: false, 
                validStartPoint: 3, 
                intersectionPoint: '(250,0)'
            },
            {
                sliderColor: "SliderWhite", 
                sliderType: "Rounded", 
                explorerPath: 'f(x)=(4x^2-1208x-4896)/961', 
                asteroidPath: 'g(x)=(-600x^2-44400x+69768000)/117613', 
                explorerImg: exp_5_comp, 
                asteroidImg: ast_5, 
                switchOn: false, 
                validStartPoint: 3, 
                intersectionPoint: '(306,0)'
            },
            {
                sliderColor: "SliderWhite", 
                sliderType: "Rounded", 
                explorerPath: 'f(x)=-5x^2/81+100x/9', 
                asteroidPath: 'g(x)=-x^2/81+400',
                explorerImg: exp_3_incomp, 
                asteroidImg: ast_3, 
                switchOn: false, 
                validStartPoint: 0, 
                intersectionPoint: '(180,0)'
            },
            {
                sliderColor: "SliderWhite", 
                sliderType: "Rounded", 
                explorerPath: 'f(x)=-2x^2/225+8x/3', 
                asteroidPath: 'g(x)=-x^2/180+500', 
                explorerImg: exp_2_incomp, 
                asteroidImg: ast_2,
                switchOn: false, 
                validStartPoint: 0, 
                intersectionPoint: '(300,0)'
            },
            {
                sliderColor: "SliderWhite", 
                sliderType: "Rounded", 
                explorerPath: 'f(x)=-x^2/100+2x', 
                asteroidPath: 'g(x)=-0.015x^2+600', 
                explorerImg: exp_1_incomp, 
                asteroidImg: ast_1,
                switchOn: false, 
                validStartPoint: 0, 
                intersectionPoint: '(200,0)'
            }, 
            {
                sliderColor: "SliderWhite", 
                sliderType: "Rounded", 
                explorerPath: 'f(x)=2x^2/625-4x/5', 
                asteroidPath: 'g(x)=-9x^2/1250+450', 
                explorerImg: exp_4_incomp, 
                asteroidImg: ast_4, 
                switchOn: false, 
                validStartPoint: 0, 
                intersectionPoint: '(250,0)'
            },
            {
                sliderColor: "SliderWhite", 
                sliderType: "Rounded", 
                explorerPath: 'f(x)=-100x^2/23409+200x/153', 
                asteroidPath: 'g(x)=(-600x^2-44400x+69768000)/117613', 
                explorerImg: exp_5_incomp, 
                asteroidImg: ast_5, 
                switchOn: false, 
                validStartPoint: 0, 
                intersectionPoint: '(306,0)'
            }, 
        ],
        startPoints: [
            {sliderColor: "SliderWhite", sliderType: "Rounded", coords: 'A(0,0)', switchOn: true},
            {sliderColor: "SliderWhite", sliderType: "Rounded", coords: 'B(5,0)', switchOn: false},
            {sliderColor: "SliderWhite", sliderType: "Rounded", coords: 'C(-5,0)', switchOn: false},
            {sliderColor: "SliderWhite", sliderType: "Rounded", coords: 'D(-4,0)', switchOn: false},
        ],
        intersection: {
            elementType: 'input',
            elementConfig: {
                type: 'text',
                placeholder: '(x,y)'
            },
            value: '',
            validation: {
                required: true,
                coords: 1,
            },
            valid: false,
            touched: false
        },
        speed: {
            elementType: 'input',
            elementConfig: {
                type: 'number',
            },
            validation: {
                required: true,
                minSpeed: 0.1,
                maxSpeed: 15,
            },
            value: '',
            valid: false,
            touched: false,
        },
        startAndFinishPointsAreValid: true,
        randomNum: 0,
        settingsSwitchOn: false
    }

    handleNewVariantClick = ({min, max}) => {
        let random = this.randomNum(min, max);
        while (this.state.randomNum === random) {
            random = this.randomNum(min, max);
        }
        this.setState({ randomNum: random });

        const updatedValues = [...this.state.parabolasPairs]
        updatedValues.forEach((v, i) => v.switchOn = i !== random ? false : true);

        const parabolas = {};
        parabolas['explorerPath'] = this.state.parabolasPairs[random].explorerPath;
        parabolas['asteroidPath'] = this.state.parabolasPairs[random].asteroidPath;
        parabolas['intersection'] = this.state.parabolasPairs[random].intersectionPoint.slice(1, -1).split(',')[0];
        this.props.parabolasData(parabolas);
    }

    randomNum = (min, max) => Math.trunc(Math.random() * (max - min + 1)) + min;

    onSettingsSwitchToggle = () => {
        this.handleNewVariantClick(this.state.settingsSwitchOn ? {min: 0, max: 4} : {min: 5, max: 9});
        this.setState(prevState => ({ settingsSwitchOn: !prevState.settingsSwitchOn }));
    }

    onToggle = (index) => { 
        const updatedValues = [...this.state.startPoints]
        updatedValues.forEach((v, i) => v.switchOn = i !== index ? false : true);
        this.setState({ startPoints: updatedValues });
    }

    checkValidity(value, rules) {
        let isValid = true;
        isValid = value.trim() !== '' && isValid;
        if (rules.maxSpeed) {
            isValid = REGEX_FLOAT.test(value.trim());
            isValid = (value >= rules.minSpeed && value <= rules.maxSpeed) && isValid;
        }
        if (rules.coords) {
            isValid = REGEX_POINT.test(value.trim());
        }
        return isValid;
    }

    inputChangedHandler = (type, event) => {
        this.setState({ 
            [type]: { 
                ...this.state[type], 
                value: event.target.value,
                touched: true,
                valid: this.checkValidity(event.target.value, this.state[type].validation),
            } 
        });     
    }

    verifyCoordsAndTrajectory = () => {
        for (const v of this.state.parabolasPairs) {
            if (v.switchOn) {
                let startAndFinishPointsAreValid = false;
                startAndFinishPointsAreValid = startAndFinishPointsAreValid || 
                    (this.state.startPoints[v.validStartPoint].switchOn && this.state.intersection.value === v.intersectionPoint); 
                this.setState({ startAndFinishPointsAreValid }, () => this.state.startAndFinishPointsAreValid && this.setCoords());
            }
        }
    }

    setCoords = () => {
        const { startPoints } = this.state;
        const coordsData = {};
        for (const point of startPoints) point.switchOn && 
            (coordsData['explorerStartPoint'] = +point.coords.slice(2, -1).split(',')[0]);
        this.props.coordsData(coordsData);
    }

    dataHandler = (event) => {
        event.preventDefault();
        const { speed } = this.state;
        const formData = { speed: +speed.value };
        this.props.formData(formData);
    }

    render() { 
        const { t } = this.props;
        const { parabolasPairs, startPoints, startAndFinishPointsAreValid, speed: { validation: { minSpeed, maxSpeed }} } = this.state;
        const { isDataReseted, isCoordsDataReseted } = this.props;

        const parabolasFuncs = parabolasPairs.map((pair, i) => (
            <div key={i} className={classes.ParabolasFuncs}>
                {pair.switchOn && 
                <div>
                    <p>{t('SNA6.SNA6b.form.paragraphs.comet-trajectory')}</p>
                    <img width='200' height='45' alt='parabola' src={pair.asteroidImg}/>
                </div>} 
                {pair.switchOn && 
                <div>
                    <p>{t('SNA6.SNA6b.form.paragraphs.spacecraft-trjectory')}</p>
                    <img width='200' height='45' alt='parabola' src={pair.explorerImg}/>
                </div>}
            </div>
        ));

        const startCoords = startPoints.map((point, i) => (
            <div key={i} className={classes.StartPointControl}>
                <p>{point.coords}</p>
                <ToggleSwitch
                    sliderColor={point.sliderColor}
                    sliderType={point.sliderType}
                    disabled={!isCoordsDataReseted}
                    isToggled={point.switchOn}
                    onToggle={() => this.onToggle(i)}/>
            </div>
        ));

        return (
            <form className={classes.Form} onSubmit={this.dataHandler}>
                <div className={classes.Settings}>
                    <p>{t('SNA6.SNA6b.form.settings.complete')}</p>
                    <ToggleSwitch 
                        sliderColor={'SliderWhite'}
                        sliderType={'Rounded'}
                        disabled={!isCoordsDataReseted}
                        isToggled={this.state.settingsSwitchOn}
                        onToggle={this.onSettingsSwitchToggle}/>
                    <p>{t('SNA6.SNA6b.form.settings.incomplete')}</p>
                </div>
                <Button 
                    type='button'
                    btnType='Success' 
                    clicked={() => this.handleNewVariantClick(this.state.settingsSwitchOn ? {min: 5, max: 9} : {min: 0, max: 4})}
                    disabled={!isCoordsDataReseted}
                    style={{width: '100%', margin: '15px 0'}}
                >
                    {t('buttons.new-variant')}
                </Button>
                <div className={classes.FuncsContainer}>
                    {parabolasFuncs}
                </div>
                <p>{t('SNA6.SNA6b.form.paragraphs.start-point')}</p>
                <div className={classes.StartPointControls}>
                    {startCoords}
                </div>
                <Input 
                    elementType={this.state.intersection.elementType}
                    elementConfig={this.state.intersection.elementConfig}
                    value={this.state.intersection.value}
                    label={t('SNA6.SNA6b.form.input.label')}
                    invalid={!this.state.intersection.valid}
                    validation={this.state.intersection.validation}
                    touched={this.state.intersection.touched}
                    errorMessage={t('SNA6.SNA6b.form.input.error-1')}
                    disabled={!isCoordsDataReseted}
                    changed={(event) => this.inputChangedHandler('intersection', event)}/>
                {startAndFinishPointsAreValid || <p className={classes.CoordsError}>{t('SNA6.SNA6b.form.input.error-2')}</p>}
                <Button 
                    type='button'
                    btnType='Success' 
                    clicked={this.verifyCoordsAndTrajectory}
                    disabled={!isCoordsDataReseted}
                    style={{width: '100%', marginBottom: '15px'}}
                >
                    {t('SNA6.form.verify-btn')}
                </Button>
                <Input 
                    elementType={this.state.speed.elementType}
                    elementConfig={this.state.speed.elementConfig}
                    placeholder={t('SNA6.form.placeholders.speed')}
                    value={this.state.speed.value}
                    invalid={!this.state.speed.valid}
                    touched={this.state.speed.touched}
                    errorMessage={t('SNA6.form.error', {minValue: minSpeed, maxValue: maxSpeed})}
                    disabled={!isDataReseted || isCoordsDataReseted}
                    changed={(event) => this.inputChangedHandler('speed', event)}/>
                <Button 
                    btnType='Success' 
                    disabled={!(this.state.speed.valid && isDataReseted && !isCoordsDataReseted)}
                    style={{width: '100%', marginBottom: '15px'}}
                >
                    {t('buttons.submit')}
                </Button>
            </form>
        );
    }
}
 
export default withTranslation() (Form);
