import React from 'react';
import Content from '../../components/SNA25/Content/Content';
import { SNA25Provider } from '../../context/SNA25-context';

const SNA25 = () => {
    return (
        <SNA25Provider>
            <Content />
        </SNA25Provider>
    );
}
 
export default SNA25;
