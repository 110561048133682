import React, { Fragment } from 'react';
import Accordion from '../../UI/Accordion/Accordion';
import classes from './QuickStart.module.css';
import monomial1 from '../../../assets/SNA15/monomial-1.png';
import monomial2 from '../../../assets/SNA15/monomial-2.png';
import monomial3 from '../../../assets/SNA15/monomial-3.png';
import area from '../../../assets/SNA15/area.png';
import volume from '../../../assets/SNA15/volume.png';
import water_tank from '../../../assets/SNA15/water-tank.png';

const QuickStartB = ({monomial}) => {
    const { coeficient, variable_1, variable_2 } = monomial;
    
    return (
        <Fragment>
            <Accordion title={'Mida me teeme selles programmis?'}>
                <p className={classes.paragraph}>Tänapäeval üha rohkem taimi kasvatatakse nn. „sisefarmides“. Neid sisefarme üritatakse võimalikult palju automatiseerida. See programm näitab sulle, kuidas saab automatiseerida taimede kahjuritõrjet. Siin saad kasutada oma teadmisi üksliikmetest.</p>
            </Accordion>
            <Accordion title={'Kelle tööd see programm tutvustab?'}>
                <p className={classes.paragraph}>Inimesi, kes automatiseerivad erinevaid protsesse nimetatakse automaatikuteks või automaatikainsenerideks. Neid inimesi, kes arendavad tarku algoritme põllumajanduse jaoks kutsutakse täppispõllumajanduse spetsialistideks. Tulevikus kasvab vajadus nende inimeste järele, kellel on head teadmised nii digitaalsest tehnoloogiast – kui ka põllumajandusest.</p>
            </Accordion>
            <Accordion title={'Kuidas programmi kasutada?'}>
                <div className={classes.step}>
                    <span className={classes.numeration}>1.</span>
                    <p className={classes.paragraph}>Programm näitab konveierlinti, mille peal kastid istikutega sõidavad kahjuritõrje pritsimistoru alt. Paneme sõitma 2 kasti rediseistikutega.</p>
                </div>
                <div className={classes.step}>
                    <span className={classes.numeration}>2.</span>
                    <p className={classes.paragraph}>Eelkõige sisesta 2 (kasti): </p>
                    <img className={classes['monomial-img']} src={monomial1} alt='monomial 1' />
                </div>
                <div className={`${classes['step-with-img']} ${!coeficient && classes.filter}`}>
                    <div className={classes['step-inner']}>
                        <span className={classes.numeration}>3.</span>
                        <p className={classes.paragraph}>Muutuja <span className={classes.expression}>a</span> tähistab redise lehtede pindala. Sisesta <span className={classes.expression}>a</span> programmi ja määra selle väärtus (näiteks 2 <span>&#13216;</span>).</p>
                    </div>
                    <div className={classes['img-wrapper']}>
                        <img className={classes['monomial-img']} src={monomial2} alt='monomial 2' />
                        <img className={classes['area-img']} src={area} alt='area' />
                    </div>
                </div>
                <div className={`${classes['step-with-img']} ${!variable_1.value && classes.filter}`}>
                    <div className={classes['step-inner']}>
                        <span className={classes.numeration}>4.</span>
                        <p className={classes.paragraph}>Muutuja <span className={classes.expression}>x</span> tähistab vedeliku kogust, mida on vaja pritsida ühele lehe ruutsentimeetrile. Sisesta <span className={classes.expression}>x</span> programmi ja määra selle väärtus (näiteks 10ml <span>&#13216;</span> kohta).</p>
                    </div>
                    <div className={classes['img-wrapper']}>
                        <img className={classes['monomial-img']} src={monomial3} alt='monomial 3' />
                        <img className={classes['volume-img']} src={volume} alt='volume' />
                    </div>
                </div>
                <div className={`${classes['step-with-img']} ${!variable_2.value && classes.filter}`}>
                    <div className={classes['step-inner']}>
                        <span className={classes.numeration}>5.</span>
                        <p className={classes.paragraph}>Vajuta "START" ja vaata animatsiooni. Üksliige <span className={classes.expression}>2ax</span> tähendab, et konveierlindil sõidab kaks kasti, igas kastis on rediseistikud kogupindalaga 2 <span>&#13216;</span>, ja igale ruutsentimeetrile kulub 10ml kahjuritõrje vedelikku (kokku 40ml).</p>
                    </div>
                    <img className={classes['water-tank-img']} src={water_tank} alt='water tank' />
                </div>
            </Accordion>
        </Fragment>
    );
}
 
export default QuickStartB;
