import React from 'react';
import classes from './ImageControl.module.css';
import ToggleSwitch from '../../UI/ToggleSwitch/ToggleSwitch';
import { useSNA22Context } from '../../../context/SNA22-context';
import { useTranslation } from 'react-i18next';

const ImageControl = () => {
    const { t } = useTranslation();
    const { isLinesVisible, toggleImage } = useSNA22Context();
    
    return (
        <div className={classes.wrapper}>
            <p>{t('SNA22.overlay-settings')}</p>
            <ToggleSwitch 
                sliderColor='SliderWhite'
                sliderType='Rounded'
                isToggled={isLinesVisible}
                onToggle={toggleImage}
            />
        </div>
    );
}
 
export default ImageControl;
