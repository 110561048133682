import f1 from '../assets/SNA23/formulas/1.png';
import f2 from '../assets/SNA23/formulas/2.png';
import f3 from '../assets/SNA23/formulas/3.png';
import f4 from '../assets/SNA23/formulas/4.png';
import f5 from '../assets/SNA23/formulas/5.png';
import f6 from '../assets/SNA23/formulas/6.png';
import f7 from '../assets/SNA23/formulas/7.png';
import f8 from '../assets/SNA23/formulas/8.png';
import f9 from '../assets/SNA23/formulas/9.png';
import f10 from '../assets/SNA23/formulas/10.png';
import f11 from '../assets/SNA23/formulas/11.png';
import f12 from '../assets/SNA23/formulas/12.png';


export const DATA = [
    {
        formula: f1,
        grainAmount: 1000,
        correctCost: 0.9,
        higherDifficulty: false,
        hint: false,
        yMax: 2
    },
    {
        formula: f2,
        grainAmount: 20000,
        correctCost: 10.8,
        higherDifficulty: false,
        hint: false,
        yMax: 20
    },
    {
        formula: f3,
        grainAmount: 100000,
        correctCost: 36,
        higherDifficulty: true,
        hint: true,
        yMax: 50
    },
    {
        formula: f4,
        grainAmount: 10000,
        correctCost: 10.8,
        higherDifficulty: false,
        hint: false,
        yMax: 20
    },
    {
        formula: f5,
        grainAmount: 1000000,
        correctCost: 125,
        higherDifficulty: true,
        hint: false,
        yMax: 200
    },
    {
        formula: f6,
        grainAmount: 20000,
        correctCost: 2.5,
        higherDifficulty: false,
        hint: false,
        yMax: 5
    },
    {
        formula: f7,
        grainAmount: 100000 ,
        correctCost: 51.84,
        higherDifficulty: false,
        hint: false,
        yMax: 70
    },
    {
        formula: f8,
        grainAmount: 100000,
        correctCost: 46.08,
        higherDifficulty: false,
        hint: false,
        yMax: 70
    },
    {
        formula: f9,
        grainAmount: 100000,
        correctCost: 40.32,
        higherDifficulty: false,
        hint: false,
        yMax: 70
    },
    {
        formula: f10,
        grainAmount: 1000000,
        correctCost: 144,
        higherDifficulty: false,
        hint: false,
        yMax: 200
    },
    {
        formula: f11,
        grainAmount: 1000000,
        correctCost: 125,
        higherDifficulty: false,
        hint: false,
        yMax: 200
    },
    {
        formula: f12,
        grainAmount: 100000,
        correctCost: 57.6,
        higherDifficulty: false,
        hint: false,
        yMax: 70
    },
];
