import React from 'react';
import { Fragment } from 'react';
import classes from './ConveyorBelt.module.css';
import waterPipe from '../../../assets/Mod4.png';
import WaterSpray from '../WaterSpray/WaterSpray';
import Box from '../Cube/Box';

const conveyorBelt = (props) => {
    const { cubePositions, waterVolumesPerBox, plantsAreas, waterSpray, conveyorBeltPosition } = props;
    return (
        <Fragment>
            <div className={classes.Belt} style={{'--conveyorBeltPosition': conveyorBeltPosition}}>
                {cubePositions.map((position, index) => (
                    <Box 
                        key={index} 
                        cubePosition={position}
                        waterVolume={waterVolumesPerBox[index]}
                        plantsArea={plantsAreas[index]}/>
                ))}  
            </div>
            <div className={classes.BeltBottom}></div>
            <img className={classes.WaterPipe} src={waterPipe} alt='pipe' />
            <WaterSpray waterSpray={waterSpray} />
        </Fragment>
    );
}
 
export default conveyorBelt;
