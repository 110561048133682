import React from 'react';
import classes from './SmartWatchImg.module.css';
import smart_watch from '../../../../../assets/smart_watch.png';
import smart_watch_scale from '../../../../../assets/smart_watch_scale.png';
import ImageWithLoading from '../../../../UI/ImageWithLoading/ImageWithLoading';
import useImagesLoaded from '../../../../../hooks/useImagesLoaded';

const SmartWatchImg = () => {
    const [ref, imagesLoaded] = useImagesLoaded();

    return (
        <section ref={ref}>
            {!imagesLoaded && <ImageWithLoading />}
            <img width='700' height='700' className={classes.SmartWatch} alt="smart-watch" src={smart_watch}/>
            <img className={classes.SmartWatchScale} alt="scale" src={smart_watch_scale}/>    
        </section> 
    );
}
 
export default SmartWatchImg;
