import React from 'react';
import classes from './Input.module.css';

const Input = ({ 
    elementType,
    type,
    value,
    placeholder,
    hasError,  
    label, 
    errorMessage, 
    disabled, 
    changed, 
    blured 
}) => {
    let inputElement = null;
    let validationError = null;
    const inputClasses = [classes['input-element']];

    if (hasError) {
        inputClasses.push(classes.invalid);
        validationError = <p className={classes['validation-error']}>{errorMessage}</p>;
    }

    if (disabled) {
        inputClasses.push(classes.disabled);
    }

    switch (elementType) {
        case ('input'):
            inputElement = <input
                className={inputClasses.join(' ')}
                type={type}
                placeholder={placeholder}
                value={value}
                onChange={changed} 
                onBlur={blured}
            />;
            break;
        // TODO select, textarea ...
        default:
            inputElement = <input 
                className={inputClasses}
            />
    }
    return (
        <div className={classes.input}>
            <label className={classes.label}>{label}</label>
            {inputElement}
            {validationError}
        </div>
    );
}
 
export default Input;
