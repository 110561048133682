import React from 'react';
import classes from './Form.module.css';
import Button from '../../UI/Button/Button';
import useInput from '../../../hooks/useInput';
import Input from '../../UI/Input/NewInput/Input';
import { useTranslation } from 'react-i18next';
import { useSNA25Context } from '../../../context/SNA25-context';

const REGEX_FLOAT = /^[+-]?\d*\.?\d{1,2}$/; // max two digits after decimal

const isNotEmpty = (value) => value.trim() !== '';
const checkRegex = (value) => !REGEX_FLOAT.test(+value);

const Form = () => {
    const { t } = useTranslation();
    const { setFormData, crossMarkCoords, currentData: {id} } = useSNA25Context();
    
    const {
        value: parabolaX,
        isValid: parabolaX_isValid,
        hasError: parabolaX_HasError,
        hasSpecificError: parabolaX_HasSpecificError,
        valueChangeHandler: parabolaX_ChangeHandler,
        inputBlurHandler: parabolaX_BlurHandler,
        reset: resetParabolaX,
    } = useInput(isNotEmpty, checkRegex);

    const {
        value: parabolaY,
        isValid: parabolaY_isValid,
        hasError: parabolaY_HasError,
        hasSpecificError: parabolaY_HasSpecificError,
        valueChangeHandler: parabolaY_ChangeHandler,
        inputBlurHandler: parabolaY_BlurHandler,
        reset: resetParabolaY,
    } = useInput(isNotEmpty, checkRegex);

    const {
        value: deviationX,
        isValid: deviationX_isValid,
        hasError: deviationX_HasError,
        hasSpecificError: deviationX_HasSpecificError,
        valueChangeHandler: deviationX_ChangeHandler,
        inputBlurHandler: deviationX_BlurHandler,
        reset: resetDeviationX,
    } = useInput(isNotEmpty, checkRegex);

    const {
        value: deviationY,
        isValid: deviationY_isValid,
        hasError: deviationY_HasError,
        hasSpecificError: deviationY_HasSpecificError,
        valueChangeHandler: deviationY_ChangeHandler,
        inputBlurHandler: deviationY_BlurHandler,
        reset: resetDeviationY,
    } = useInput(isNotEmpty, checkRegex);

    let formIsValid = false;

    if (parabolaX_isValid && 
        parabolaY_isValid && 
        deviationX_isValid && 
        deviationY_isValid &&
        !parabolaX_HasSpecificError &&
        !parabolaY_HasSpecificError &&
        !deviationX_HasSpecificError &&
        !deviationY_HasSpecificError) {
        formIsValid = true;
    }
    
    const formSubmissionHandler = (event) => {
        event.preventDefault();
        if (!formIsValid) return;
        setFormData(
            {
                userParabolaX: +parabolaX,
                userParabolaY: +parabolaY,
                userDeviationX: +deviationX,
                userDeviationY: +deviationY
            },
            event.nativeEvent.submitter.name
        );
        resetParabolaX();
        resetParabolaY();
        resetDeviationX();
        resetDeviationY();
    }
    return (
        <form className={classes.grid} onSubmit={formSubmissionHandler}>
            <Input
                elementType='input'
                type='number'
                label={t('SNA25.controls.form.input-1.label')}
                value={parabolaX}
                hasError={parabolaX_HasError || parabolaX_HasSpecificError}
                placeholder={t('SNA25.controls.form.common.placeholder-1')}
                errorMessage={parabolaX_HasError ? t('SNA25.controls.form.common.error-1') : t('SNA25.controls.form.common.error-3')}
                changed={parabolaX_ChangeHandler}
                blured={parabolaX_BlurHandler}
            />
            <Input
                elementType='input'
                type='number'
                label={t('SNA25.controls.form.input-2.label')}
                value={parabolaY}
                hasError={parabolaY_HasError || parabolaY_HasSpecificError}
                placeholder={t('SNA25.controls.form.common.placeholder-1')}
                errorMessage={parabolaY_HasError ? t('SNA25.controls.form.common.error-1') : t('SNA25.controls.form.common.error-3')}
                changed={parabolaY_ChangeHandler}
                blured={parabolaY_BlurHandler}
            />
            <Input
                elementType='input'
                type='number'
                label={t('SNA25.controls.form.input-3.label')}
                value={deviationX}
                hasError={deviationX_HasError || deviationX_HasSpecificError}
                placeholder={t('SNA25.controls.form.common.placeholder-2')}
                errorMessage={deviationX_HasError ? t('SNA25.controls.form.common.error-2') : t('SNA25.controls.form.common.error-3')}
                changed={deviationX_ChangeHandler}
                blured={deviationX_BlurHandler}
            />
            <Input
                elementType='input'
                type='number'
                label={t('SNA25.controls.form.input-4.label')}
                value={deviationY}
                hasError={deviationY_HasError || deviationY_HasSpecificError}
                placeholder={t('SNA25.controls.form.common.placeholder-2')}
                errorMessage={deviationY_HasError ? t('SNA25.controls.form.common.error-2') : t('SNA25.controls.form.common.error-3')}
                changed={deviationY_ChangeHandler}
                blured={deviationY_BlurHandler}
            />
            <Button 
                btnType='Success'
                name='correct'
                disabled={!formIsValid || crossMarkCoords.x === null || !id}
            >
                {t('SNA25.controls.form.buttons.correct')} 
            </Button>
            <Button 
                btnType='Danger'
                name='defect'
                disabled={!formIsValid || crossMarkCoords.x === null || !id}
            >
                {t('SNA25.controls.form.buttons.defect')}  
            </Button>
        </form>
    );
}
 
export default Form;
