import React from 'react';
import classes from './Display.module.css';

const Display = (props) => {
    return (
        <div className={classes.wrapper}>
            <div className={classes.header}>{props.header}</div>
            <div className={classes.body}>{props.value}</div>
            <div className={classes.footer}>{props.footer}</div>
        </div>
    );
}
 
export default Display;
