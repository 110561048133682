import React, { useState, useEffect } from 'react';
import classes from './Theater.module.css';
import theater from '../../assets/sna_1.png';
import LightBlock from './LightBlock';
import ImageWithLoading from '../UI/ImageWithLoading/ImageWithLoading';

const THEATER_IMG_WIDTH = 720;
const THEATER_IMG_HEIGHT = 405;
const CONTAINER_WIDTH = 0.557;
const MEDIA_COEFICIENT = 1.616

const Theater = (props) => {
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        let isMounted = false;
        const image = new Image();
        image.onload = () => !isMounted && setIsLoading(false);
        image.src = theater;
        return () => isMounted = true;
    }, []);

    return (
        <div className={classes.theater}>
            {isLoading ? 
                <ImageWithLoading
                    containerWidth={CONTAINER_WIDTH} 
                    coedicient={MEDIA_COEFICIENT}
                    width={THEATER_IMG_WIDTH} 
                    height={THEATER_IMG_HEIGHT}/> :
                <img width={THEATER_IMG_WIDTH} height={THEATER_IMG_HEIGHT} alt='theater' src={theater}/>
            }
            <LightBlock style={{backgroundColor: props.rgb}} />
        </div>
    );
}

export default Theater;
