import React from 'react';
import classes from '../../common_components/SmartWatch/SmartWatch.module.css';
import ProgressBar from '../ProgressBar/ProgressBar';
import SpeedControl from '../../../common_components/SpeedControl/SpeedControl';
import SmartWatchImg from '../../common_components/SmartWatch/SmartWatchImg/SmartWatchImg';

const PROGRESS_MAX = 100;

const smartWatch = (props) => {
    const { activityUnit, activityType, actionValue, isToggled, isRunning } = props;
    return (
        <div className={classes.Container}>
            <div className={classes.WatchContainer}>
                <SmartWatchImg />
                <ProgressBar 
                    style={{position: 'absolute', top: `calc(50% - ${props.progressBar.size / 2}px - 21px)`, left: `calc(50% - ${props.progressBar.size / 2}px)`}}
                    {...props.progressBar}
                    activityType={activityType}
                    actionValue={actionValue}
                    activityUnit={activityUnit}
                    maxValue={PROGRESS_MAX}/>    
            </div>
            <SpeedControl 
                isRunning={isRunning}
                isToggled={isToggled}
                toggle={() => props.toggle()}
            />
        </div>
    );
}
 
export default smartWatch;
