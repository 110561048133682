import React, { useState, useEffect, useRef } from 'react';
import classes from './Controls.module.css';
import Button from '../../UI/Button/Button';
import { 
    IoPlay, 
    IoPause, 
    IoVolumeHigh, 
    IoVolumeMedium, 
    IoVolumeLow, 
    IoVolumeMute, 
    IoSpeedometer 
} from "react-icons/io5";
import { useTranslation } from 'react-i18next';
import { useSNA26Context } from '../../../context/SNA26-context';
import Radio from '../../UI/Radio/Radio';
import { tracksMap } from '../../../data/SNA26data';

const DELAY = 50;

const Controls = () => {
    const { t } = useTranslation();
    const audioRef = useRef();

    const [currentTime, setCurrentTime] = useState(0);
    const [duration, setDuration] = useState(0);
    const [volume, setVolume] = useState(50);
    const [tempVolume, setTempVolume] = useState(0);
    const [playbackSpeed, setPlaybackSpeed] = useState(100);

    const {
        handleStartClick, 
        isPlaying, 
        track,
        onChangeRadioHandler, 
        setTimelineData, 
        handleEnd,
    } = useSNA26Context();

    useEffect(() => {
        audioRef.current.volume = 0.5;
    }, []);

    const fmtMSS = (s) => { return (s - (s %= 60)) / 60 + (10 < s ? ':' : ':0') + ~~(s) }

    const handleVolumeUpdate = (e) => {
        const volume = e.target.value;
        setVolume(+volume);
        audioRef.current.volume = volume / 100;
    }

    const handleVolumeClick = () => {
        if (volume > 0) {
            setTempVolume(volume);
            setVolume(0);
            audioRef.current.volume = 0;
            return;
        }
        setVolume(tempVolume);
        audioRef.current.volume = tempVolume / 100;
    }

    const handlePlaybackSpeedUpdate = (e) => {
        const playbackSpeed = e.target.value;
        audioRef.current.playbackRate = playbackSpeed / 100;
        setPlaybackSpeed(playbackSpeed);
    }

    useEffect(() => {
        let intervalId = null;
        const audio = audioRef.current;
        if (isPlaying) {
            audio.play();
            audio.playbackRate = playbackSpeed / 100;
            intervalId = setInterval(() => {
                const percent = ((audioRef.current.currentTime / audioRef.current.duration) * 100).toFixed(2);
                setCurrentTime(audioRef.current.currentTime);

                const entries = Object.entries(tracksMap.get(track).coords);
                for (const [, {x, y}] of entries) {
                    if (x >= audioRef.current.currentTime) {
                        setTimelineData(y, +percent);
                        return;
                    } 
                }
            }, DELAY);
        } else {
            audio.pause();
            clearInterval(intervalId);
        }
        return () => clearInterval(intervalId);
    }, [setTimelineData, isPlaying, track, playbackSpeed]);

    return (
        <div className={classes.wrapper}>
            <div className={classes['radio-buttons']} onChange={onChangeRadioHandler}>
                <Radio 
                    label={t('SNA26.controls.tracks.octave')} 
                    value={'Octave'} 
                    defaultChecked 
                />
                <Radio 
                    label={t('SNA26.controls.tracks.classic')} 
                    value={'Classic'} 
                />
                <Radio 
                    label={t('SNA26.controls.tracks.pop')} 
                    value={'Pop'} 
                />
            </div>
            <audio 
                ref={audioRef}
                onLoadedData={(e) => setDuration(e.currentTarget.duration)}
                onEnded={() => handleEnd()}
                src={tracksMap.get(track).source} 
            />
            <div className={classes.info}>
                <div className={classes['volume-control']}>
                    <span onClick={handleVolumeClick}>
                        {volume === 0 && <IoVolumeMute />}
                        {volume > 70 && <IoVolumeHigh />}
                        {(volume > 40 && volume <= 70) && <IoVolumeMedium />}
                        {(volume > 0 && volume <= 40) && <IoVolumeLow />}
                    </span>
                    <input 
                        type='range' 
                        name='volume'
                        onChange={handleVolumeUpdate}
                        value={volume}
                    />
                </div>
                <div className={classes['speed-control']}>
                    <span>
                        <IoSpeedometer />
                    </span>
                    <input 
                        type='range' 
                        name='speed'
                        min='30'
                        onChange={handlePlaybackSpeedUpdate}
                        value={playbackSpeed}
                    />
                </div>
                <div className={classes['time-display']}>
                    <span className={classes['current-time']}>{fmtMSS(currentTime)}</span>
                    <span className={classes['time-separator']}>/</span>
                    <span className={classes['total-time']}>{fmtMSS(duration)}</span>
                </div>
            </div>
            <Button 
                btnType='Danger' 
                disabled={false}
                clicked={handleStartClick}
            >
                {!isPlaying ? <IoPlay/> : <IoPause/>} {!isPlaying ? t('buttons.play') : t('buttons.pause')}
             </Button>
        </div>
    );
}
 
export default Controls;
