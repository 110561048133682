import React from 'react';
import classes from './Controls.module.css';
import Button from '../../UI/Button/Button';
import { useTranslation, Trans } from 'react-i18next';
import { IoDocuments, IoReload } from "react-icons/io5";
import ToggleSwitch from '../../UI/ToggleSwitch/ToggleSwitch';
import Form from '../Form/Form';
import { useSNA25Context } from '../../../context/SNA25-context';

const Controls = () => {
    const { t } = useTranslation();
    const { 
        handleNewTaskClick, 
        handleToggleClick, 
        handleResetClick, 
        crossMarkCoords,
        currentData: { parabola: {src: parabola_src} },
        isParabolaHidden 
    } = useSNA25Context();
    let { x, y } = crossMarkCoords;
    if (x === null) x = 'x';
    if (y === null) y = 'y';
    return (
        <div className={classes.wrapper}>
            <Button 
                btnType='Info' 
                disabled={crossMarkCoords.x !== null}
                clicked={handleNewTaskClick}
            >
                <IoDocuments/> {t('buttons.new-variant')}
            </Button>
            {parabola_src && <div className={classes.parabola}>
                <img src={parabola_src} alt="parabola" />
            </div>}
            <ToggleSwitch 
                onToggle={handleToggleClick}
                disabled={crossMarkCoords.x === null}
                isToggled={!isParabolaHidden}
                label={t('SNA25.controls.toggle-label')}
            />
            <hr/>
            <Trans>
                <p className={classes.paragraph}>{t('SNA25.controls.p', {x, y})}</p>
            </Trans>
            <Form />
            <Button 
                btnType='Primary' 
                clicked={handleResetClick}
            >
                <IoReload/> {t('buttons.reset')}
            </Button>
        </div>
    );
}
 
export default Controls;
