import React, { Component, Fragment } from 'react';
import classes from './SNA5.module.css';
import Grass from '../../components/common_components/Grass/Grass';
import Road from '../../components/common_components/Road/Road';
import Vehicle from '../../components/common_components/Vehicle/Vehicle';
import car from '../../assets/vehicles/car.png';
import Controls from '../../components/SNA5/Controls/Controls';
import GraphingCalculator from '../../components/SNA5/GraphingCalculator/GraphingCalculator';
import { withTranslation } from 'react-i18next';
import Notification from '../../components/UI/Notification/Notification';
import positive_notification from '../../assets/audio/positive_notification.wav';
import negative_notification from '../../assets/audio/negative_notification.wav';

const ROAD_LENGTH = 1310;
const OPPOSITE_LANE = 83; 
const SPEED_PROPORTION = 0.0289;   // 0.0289 -> 1 km/h
const DISTANCE_PROPORTION = 5.2;   // 1m = 5.2px
const START_POINT = 240;           // Start point - blue car initial position (actual point is 260px - vehicle length delta: 3/4 of vehicle length)
const SAFE_POINT = 55;            // in abstract meters
const DANGER_POINT = 10;           // in abstract meters
const SIGN_POSITION = 1275;        //px 
const OVERTAKE_RELATIVE_DISTANCE = 130;  //px
const INTERVAL_FREQUENCY = 20;

const successSound = new Audio(positive_notification);
const failSound = new Audio(negative_notification);

class SNA5a extends Component {
    state = {
        roadSectionCoordinates: [0, 50, 100, 150, 200, 250, 300, 350, 400, 450, 500, 550, 600, 650, 
            700, 750, 800, 850, 900, 950, 1000, 1050, 1100, 1150, 1200, 1250],
        overtakingCar_x: 136,
        overtakingCar_y: 123,
        inFrontCar_x: START_POINT,
        inFrontCar_y: 123,
        inFrontCarDistance: 0,
        overtakingCarDistance: (136 - START_POINT) /DISTANCE_PROPORTION,
        overtakingCarSpeed: null,
        perfomingOvertake: false,
        inFrontCarSpeed: null,
        distanceBetweenCars: null,
        canStart: false,
        isDataReseted: true,
        inputDisabled: false,
        time: 0,
        isTimerOn: false,
        start: 0,
        notifications: [],
        settings: {
            autosize: true,  
            settingsMenu: false, 
            zoomButtons: false, 
            lockViewport: false,
            expressionsCollapsed: true,
            projectorMode: true, 
            expressions: false,
        },
        mathBounds: {
            left: -4,
            right: 30,
            bottom: -20,
            top: 250
        },
    }

    getNotification = (identifier) => {
        const { t } = this.props;
        if (identifier === 'SUCCESS') {
            return {
                id: 1,
                type: 'Success',
                title: t('SNA5.notifications.titles.success'),
                description: t('SNA5.notifications.descriptions.success')
            };
        }
        if (identifier === 'DANGER') {
            return {
                id: 2,
                type: 'Danger',
                title: t('SNA5.notifications.titles.fail'),
                description: t('SNA5.notifications.descriptions.danger')
            };
        }
        if (identifier === 'WARNING') {
            return {
                id: 3,
                type: 'Warning',
                title: t('SNA5.notifications.titles.warning'),
                description: t('SNA5.notifications.descriptions.warning')
            };
        }
    }

    getFormData = (formData) => {
        this.setState({ 
            overtakingCar_x: START_POINT - formData.distanceBetweenCars * DISTANCE_PROPORTION,
            overtakingCar_y: formData.distanceBetweenCars < 10 ? OPPOSITE_LANE : 123,
            overtakingCarSpeed: formData.overtakingCarSpeed,
            inFrontCarSpeed: formData.inFrontCarSpeed,
            distanceBetweenCars: formData.distanceBetweenCars,
            overtakingCarDistance: formData.distanceBetweenCars * -1,
            canStart: true,
            isDataReseted: false,
            inputDisabled: true,
        });
    }

    handleStartClick = () => {
        this.state.isTimerOn ? this.stopTimer() : this.startTimer(); 
    }

    handleResetClick = () => {
        clearInterval(this.timer);
        this.setState({ 
            overtakingCar_x: 136,
            overtakingCar_y: 123,
            inFrontCar_x: START_POINT,
            inFrontCarDistance: 0,
            overtakingCarDistance: (136 - START_POINT) /DISTANCE_PROPORTION,
            overtakingCarSpeed: null,
            perfomingOvertake: false,
            inFrontCarSpeed: null,
            distanceBetweenCars: null,
            canStart: false,
            isDataReseted: true,
            inputDisabled: false,
            time: 0,
            isTimerOn: false,
            start: 0,
            notifications: [],
        });
    }

    startTimer = () => {
        this.setState({
            isTimerOn: true,
            time: this.state.time,
            start: Date.now() - this.state.time,
        });
        this.timer = setInterval(() => {
            this.setState({time: Date.now() - this.state.start});
            this.checkBounds();
            this.moveInFrontCar();
            this.moveOvertakingCar();
        }, INTERVAL_FREQUENCY);
    }

    stopTimer = () => {
        this.setState({ 
            isTimerOn: false, 
            timerIterator: 0 
        });
        clearInterval(this.timer);
    }

    moveInFrontCar() {
        const updatedInFrontCar_x = this.state.inFrontCar_x + this.state.inFrontCarSpeed * SPEED_PROPORTION;
        const distance = (this.state.inFrontCar_x - START_POINT) / DISTANCE_PROPORTION; 
        this.setState({ inFrontCar_x: updatedInFrontCar_x, inFrontCarDistance: distance });
    }

    moveOvertakingCar() {
        const updatedOvertakingCar_x = this.state.overtakingCar_x + this.state.overtakingCarSpeed * SPEED_PROPORTION;
        const distance = (this.state.overtakingCar_x - START_POINT) / DISTANCE_PROPORTION;

        if (this.state.inFrontCar_x - this.state.overtakingCar_x < 70 && this.state.inFrontCar_x - this.state.overtakingCar_x > 0 && this.state.overtakingCar_y > OPPOSITE_LANE) {
            const updatedOvertakingCar_y = this.state.overtakingCar_y - this.state.overtakingCarSpeed * SPEED_PROPORTION;
            this.setState({ overtakingCar_y: updatedOvertakingCar_y});
            this.state.perfomingOvertake || this.setState({ perfomingOvertake: true }, () => this.addNotification());
        }
        if (this.state.inFrontCar_x - this.state.overtakingCar_x < -40 && this.state.overtakingCar_y < this.state.inFrontCar_y) {
            const updatedOvertakingCar_y = this.state.overtakingCar_y + this.state.overtakingCarSpeed * SPEED_PROPORTION;
            this.setState({ overtakingCar_y: updatedOvertakingCar_y });
        }
        this.setState({ overtakingCar_x: updatedOvertakingCar_x, overtakingCarDistance: distance });
    }

    addNotification() {
        const {overtakingCarSpeed, inFrontCarSpeed, overtakingCar_x} = this.state;
        if (overtakingCarSpeed > inFrontCarSpeed) {
            const overtakeTime = (OVERTAKE_RELATIVE_DISTANCE / DISTANCE_PROPORTION) / (overtakingCarSpeed - inFrontCarSpeed) * 3.6; // 3.6 -> converting speed to m/s
            const overtakeDistance = overtakeTime * overtakingCarSpeed / 3.6;
            const distanceToSign = SIGN_POSITION / DISTANCE_PROPORTION - overtakeDistance - overtakingCar_x / DISTANCE_PROPORTION;
            if (distanceToSign > SAFE_POINT) {
                this.playSound('SUCCESS');
                this.setState({ notifications: [this.getNotification('SUCCESS')] });
            }
            if (distanceToSign < DANGER_POINT) {
                this.playSound('FAIL');
                this.setState({ notifications: [this.getNotification('DANGER')] });
            }
            if (distanceToSign > DANGER_POINT && distanceToSign < SAFE_POINT) {
                this.playSound('FAIL');
                this.setState({ notifications: [this.getNotification('WARNING')] });
            }
        }
    }

    playSound(identifier) {
        identifier === 'SUCCESS' ? successSound.play() : failSound.play();
    }

    deleteNotificationHandler = () => {
        this.setState({ notifications: [] });
    }

    checkBounds() {
        if (this.state.overtakingCar_x > ROAD_LENGTH && this.state.inFrontCar_x > ROAD_LENGTH) {
            this.setState({ isTimerOn: false, canStart: false });
            clearInterval(this.timer);
        }
    }

    componentWillUnmount() {
        clearInterval(this.timer);
    }

    render() { 
        return (
            <Fragment>
                <div className={classes.RoadContainer}>
                    <Grass />
                    <Road roadSectionCoordinates={this.state.roadSectionCoordinates} />
                    <img src="/images/ruler.png" alt="ruler" />
                    <Vehicle 
                        ref={this.overtakingCar}
                        position={{
                            left: this.state.overtakingCar_x,
                            top: this.state.overtakingCar_y,
                            width: '13px',
                            height: '26px',
                            borderRadius: '3px',
                            transform: 'rotate(90deg)',
                            backgroundColor: 'rgb(240, 49, 49)',
                            backgroundImage: `url(${car})`
                        }}/>
                    <Vehicle 
                        position={{
                            left: this.state.inFrontCar_x,
                            top: this.state.inFrontCar_y,
                            width: '13px',
                            height: '26px',
                            borderRadius: '3px',
                            transform: 'rotate(90deg)',
                            backgroundColor: 'rgb(12, 134, 216)',
                            backgroundImage: `url(${car})`
                        }}/>
                </div>
                <div className={classes.ControlsContainer}>
                    <Controls 
                        formDataCallback={this.getFormData} 
                        start={this.handleStartClick}
                        reset={this.handleResetClick}
                        canStart={this.state.canStart}
                        isDataReseted={this.state.isDataReseted}
                        inputDisabled={this.state.inputDisabled}
                        isTimerOn={this.state.isTimerOn}
                        stopwatch={(this.state.time / 1000).toFixed(2)}
                        inFrontCarDistance={(this.state.inFrontCarDistance).toFixed(0)}
                        overtakingCarDistance={(this.state.overtakingCarDistance).toFixed(0)}/>
                    <GraphingCalculator 
                        settings={this.state.settings} 
                        mathBounds={this.state.mathBounds}
                        overtakingCarSpeed={this.state.overtakingCarSpeed !== null ? (this.state.overtakingCarSpeed / 3.6).toFixed(2) : null}
                        inFrontCarSpeed={this.state.inFrontCarSpeed !== null ? (this.state.inFrontCarSpeed / 3.6).toFixed(2) : null}
                        distanceBetweenCars={this.state.distanceBetweenCars}
                        time={this.state.time / 1000}/>
                </div>
                <Notification 
                    notifications={this.state.notifications}
                    notificationDelay={10000}
                    deleteNotification={this.deleteNotificationHandler}/>
            </Fragment>
        );
    }
}
 
export default withTranslation()(SNA5a);
