const DISTANCE_PROPORTION = 1_495_982; // equals to 0.01 on coordinate system

const PLANETS_DATA = {
    mercury: {
        'orbitRadius': 0.38,
        'circulationPeriod': 88,
        'orbitalSpeed': 149598261 * 0.38 * 2 * Math.PI / 88 / 24 / 3600
    },
    venus: {
        'orbitRadius': 0.72,
        'circulationPeriod': 225,
        'orbitalSpeed': 149598261 * 0.72 * 2 * Math.PI / 225 / 24 / 3600
    },
    earth: {
        'orbitRadius': 1,
        'circulationPeriod': 365,
        'orbitalSpeed': 149598261 * 2 * Math.PI / 365 / 24 / 3600
    },
    mars: {
        'orbitRadius': 1.52,
        'circulationPeriod': 687,
        'orbitalSpeed': 149598261 * 1.52 * 2 * Math.PI / 687 / 24 / 3600
    },
    jupiter: {
        'orbitRadius': 5.21,
        'circulationPeriod': 4333,
        'orbitalSpeed': 149598261 * 5.21 * 2 * Math.PI / 4333 / 24 / 3600
    }
};

const calculateDistance = (arcLength, DISTANCE_PROPORTION) => {
    return arcLength * DISTANCE_PROPORTION;
}

export const PLANET_TRAJECTORIES = [
    {
        trajectory: '(x-0.3)^2+(y+0.1)^2=1.7',
        expressions: {
            arc1: 'y1=\\sqrt{1.7-(x-0.3)^2}-0.1\\left\\{-1.1<=x<=-1\\right\\}',
            arc2: 'y1=-\\sqrt{1.7-(x-0.3)^2}-0.1\\left\\{-1.1<=x<=0.7418\\right\\}'
        },
        coeficients: {h: 0.3, k: -0.1, r: Math.sqrt(1.7), x: 360 / (PLANETS_DATA.earth.circulationPeriod * PLANETS_DATA.earth.orbitalSpeed * Math.sqrt(1.7))}, 
        earthOrbitalSpeed: PLANETS_DATA.earth.orbitalSpeed,
        destinationPlanetOrbitalSpeed: PLANETS_DATA.mars.orbitalSpeed,
        destinationPlanet: 'Mars',
        timerValue: 0,                 // timer value for planets              
        startPointTimerValue: 3.0648,  // timer value for circular orbit of spacecraft
        finishPointTimerValue: 5.0581, // timer value for circular orbit of spacecraft
        spacecraftArcLength: 260, 
        planetArcLength: 316, 
        correctSpeed: 19.80,
        chartValues: {
            tMax: 10_000,
            sMax: 500_000_000,
            vMax: 50
        },
        mathBounds: {
            left: -1.7,
            right: 1.7,
            bottom: -1.7,
            top: 1.7
        },
        init: function () {
            this.spacecraftFlightDistance = calculateDistance(this.spacecraftArcLength, DISTANCE_PROPORTION);
            this.planetOrbitDistance = calculateDistance(this.planetArcLength, DISTANCE_PROPORTION);
            delete this.init;
            return this;
        }
    }.init(),
    {
        trajectory: '(x+0.26)^2+y^2=1.26^2',
        expressions: {
            arc1: '(x+0.26)^2+y^2=1.26^2\\left\\{y>0\\right\\}',
            arc2: ''
        },
        coeficients: {h: -0.26, k: 0, r: 1.26, x: 360 / (PLANETS_DATA.earth.circulationPeriod * PLANETS_DATA.earth.orbitalSpeed * 1.26)}, 
        earthOrbitalSpeed: PLANETS_DATA.earth.orbitalSpeed,
        destinationPlanetOrbitalSpeed: PLANETS_DATA.mars.orbitalSpeed,
        destinationPlanet: 'Mars',
        timerValue: 3.1851,                              
        startPointTimerValue: 0,  
        finishPointTimerValue: 3.1415,
        spacecraftArcLength: 396, 
        planetArcLength: 701, 
        correctSpeed: 13.60,
        chartValues: {
            tMax: 15_000,
            sMax: 1_000_000_000,
            vMax: 50
        },
        mathBounds: {
            left: -1.7,
            right: 1.7,
            bottom: -1.7,
            top: 1.7
        },
        init: function () {
            this.spacecraftFlightDistance = calculateDistance(this.spacecraftArcLength, DISTANCE_PROPORTION);
            this.planetOrbitDistance = calculateDistance(this.planetArcLength, DISTANCE_PROPORTION);
            delete this.init;
            return this;
        }
    }.init(),
    {
        trajectory: '(x-0.31)^2+(y+0.5)^2=1.7', 
        expressions: {
            arc1: 'y1=-\\sqrt{1.7-(x-0.31)^2}-0.5',
            arc2: 'y1=\\sqrt{1.7-(x-0.31)^2}-0.5\\left\\{x<-0.969,x>-0.273\\right\\}'
        },
        coeficients: {h: 0.31, k: -0.5, r: Math.sqrt(1.7), x: 360 / (PLANETS_DATA.earth.circulationPeriod * PLANETS_DATA.earth.orbitalSpeed * Math.sqrt(1.7))}, 
        earthOrbitalSpeed: PLANETS_DATA.earth.orbitalSpeed,
        destinationPlanetOrbitalSpeed: PLANETS_DATA.venus.orbitalSpeed,
        destinationPlanet: 'Venus',
        timerValue: 0.2531, 
        startPointTimerValue: 2.9462,     
        finishPointTimerValue: 8.3175,      
        spacecraftArcLength: 700,  
        planetArcLength: 790, 
        correctSpeed: 30.85,
        chartValues: {
            tMax: 15_000,
            sMax: 1_500_000_000,
            vMax: 50
        },
        mathBounds: {
            left: -1.2,
            right: 1.7,
            bottom: -1.9,
            top: 1
        },
        init: function () {
            this.spacecraftFlightDistance = calculateDistance(this.spacecraftArcLength, DISTANCE_PROPORTION);
            this.planetOrbitDistance = calculateDistance(this.planetArcLength, DISTANCE_PROPORTION);
            delete this.init;
            return this;
        }
    }.init(),
    {
        trajectory: '(x+0.31)^2+(y-0.5)^2=1.7', 
        expressions: {
            arc1: 'y1=\\sqrt{1.7-(x+0.31)^2}+0.5',
            arc2: 'y1=-\\sqrt{1.7-(x+0.31)^2}+0.5\\left\\{x>0.969,x<0.273\\right\\}'
        },
        coeficients: {h: -0.31, k: 0.5, r: Math.sqrt(1.7), x: 360 / (PLANETS_DATA.earth.circulationPeriod * PLANETS_DATA.earth.orbitalSpeed * Math.sqrt(1.7))}, 
        earthOrbitalSpeed: PLANETS_DATA.earth.orbitalSpeed,
        destinationPlanetOrbitalSpeed: PLANETS_DATA.venus.orbitalSpeed,
        destinationPlanet: 'Venus',
        timerValue: -2.9322, 
        startPointTimerValue: -0.1953,     
        finishPointTimerValue: 5.1759,      
        spacecraftArcLength: 700,  
        planetArcLength: 931, 
        correctSpeed: 26.18,
        chartValues: {
            tMax: 15_000,
            sMax: 1_500_000_000,
            vMax: 50
        },
        mathBounds: {
            left: -1.7,
            right: 1.1,
            bottom: -0.9,
            top: 1.9
        },
        init: function () {
            this.spacecraftFlightDistance = calculateDistance(this.spacecraftArcLength, DISTANCE_PROPORTION);
            this.planetOrbitDistance = calculateDistance(this.planetArcLength, DISTANCE_PROPORTION);
            delete this.init;
            return this;
        }
    }.init(),
    {
        trajectory: '(x+1)^2+y^2=1.72^2', 
        expressions: {
            arc1: '(x+1)^2+y^2=1.72^2\\left\\{y<0\\right\\}',
            arc2: '(x+1)^2+y^2=1.72^2\\left\\{x<0.4792\\right\\}'
        },
        coeficients: {h: -1, k: 0, r: 1.72, x: 360 / (PLANETS_DATA.earth.circulationPeriod * PLANETS_DATA.earth.orbitalSpeed * 1.72)}, 
        earthOrbitalSpeed: PLANETS_DATA.earth.orbitalSpeed,
        destinationPlanetOrbitalSpeed: PLANETS_DATA.venus.orbitalSpeed,
        destinationPlanet: 'Venus',
        timerValue: 4.2711, 
        startPointTimerValue: 0.5355,     
        finishPointTimerValue: 6.2831,      
        spacecraftArcLength: 989,  
        planetArcLength: 1092, 
        correctSpeed: 31.53,
        chartValues: {
            tMax: 25_000,
            sMax: 2_000_000_000,
            vMax: 50
        },
        mathBounds: {
            left: -2.8,
            right: 0.9,
            bottom: -1.85,
            top: 1.85
        },
        init: function () {
            this.spacecraftFlightDistance = calculateDistance(this.spacecraftArcLength, DISTANCE_PROPORTION);
            this.planetOrbitDistance = calculateDistance(this.planetArcLength, DISTANCE_PROPORTION);
            delete this.init;
            return this;
        }
    }.init(),
    {
        trajectory: 'x^2+(y+2.105)^2=3.105^2', 
        expressions: {
            arc1: 'x^2+(y+2.105)^2=3.105^2\\left\\{x<0\\right\\}',
            arc2: ''
        },
        coeficients: {h: 0, k: -2.105, r: 3.105, x: 360 / (PLANETS_DATA.earth.circulationPeriod * PLANETS_DATA.earth.orbitalSpeed * 3.105)}, 
        earthOrbitalSpeed: PLANETS_DATA.earth.orbitalSpeed,
        destinationPlanetOrbitalSpeed: PLANETS_DATA.jupiter.orbitalSpeed,
        destinationPlanet: 'Jupiter',
        timerValue: 4.7778, 
        startPointTimerValue: 1.5707,       
        finishPointTimerValue: 4.7123,      
        spacecraftArcLength: 975, 
        planetArcLength: 611,
        correctSpeed: 20.87,
        chartValues: {
            tMax: 30_000,
            sMax: 2_000_000_000,
            vMax: 50
        },
        mathBounds: {
            left: -5.2,
            right: 2,
            bottom: -5.6,
            top: 1.6
        },
        init: function () {
            this.spacecraftFlightDistance = calculateDistance(this.spacecraftArcLength, DISTANCE_PROPORTION);
            this.planetOrbitDistance = calculateDistance(this.planetArcLength, DISTANCE_PROPORTION);
            delete this.init;
            return this;
        }
    }.init(),
    {
        trajectory: 'x^2/9+(y+0.31)^2/0.69^2=1', 
        expressions: {
            arc1: 'x^2/9+(y+0.31)^2/0.69^2=1\\left\\{x>0\\right\\}',
            arc2: ''
        },
        coeficients: {h: 0, k: -0.31, r: 1, a: 3, b: 0.69, x: 360 / (PLANETS_DATA.earth.circulationPeriod * PLANETS_DATA.earth.orbitalSpeed * 1)}, 
        earthOrbitalSpeed: PLANETS_DATA.earth.orbitalSpeed,
        destinationPlanetOrbitalSpeed: PLANETS_DATA.mercury.orbitalSpeed,
        destinationPlanet: 'Mercury',
        timerValue: 1.59261294244, 
        startPointTimerValue: 4.712388,   
        finishPointTimerValue: 7.853981,      
        spacecraftArcLength: 663, 
        planetArcLength: 1364,
        correctSpeed: 22.83,
        chartValues: {
            tMax: 20_000,
            sMax: 1_500_000_000,
            vMax: 50
        },
        mathBounds: {
            left: -0.9,
            right: 3.1,
            bottom: -2,
            top: 2
        },
        init: function () {
            this.spacecraftFlightDistance = calculateDistance(this.spacecraftArcLength, DISTANCE_PROPORTION);
            this.planetOrbitDistance = calculateDistance(this.planetArcLength, DISTANCE_PROPORTION);
            delete this.init;
            return this;
        }
    }.init(),
    {
        trajectory: 'x^2+(y+1)^2=1.38^2', 
        expressions: {
            arc1: 'y1=-\\sqrt{1.38^2-x^2}-1',
            arc2: 'y1=\\sqrt{1.38^2-x^2}-1\\left\\{x<-0.9989,x>0\\right\\}'
        },
        coeficients: {h: 0, k: -1, r: 1.38, x: 360 / (PLANETS_DATA.earth.circulationPeriod * PLANETS_DATA.earth.orbitalSpeed * 1.38)}, 
        earthOrbitalSpeed: PLANETS_DATA.earth.orbitalSpeed,
        destinationPlanetOrbitalSpeed: PLANETS_DATA.mercury.orbitalSpeed,
        destinationPlanet: 'Mercury',
        timerValue: 0.0484, 
        startPointTimerValue: 2.380103,       
        finishPointTimerValue: 7.85398,      
        spacecraftArcLength: 755, 
        planetArcLength: 1126, //1.71
        correctSpeed: 31.50,
        chartValues: {
            tMax: 15_000,
            sMax: 1_500_000_000,
            vMax: 50
        },
        mathBounds: {
            left: -1.5,
            right: 1.5,
            bottom: -2.5,
            top: 0.5
        },
        init: function () {
            this.spacecraftFlightDistance = calculateDistance(this.spacecraftArcLength, DISTANCE_PROPORTION);
            this.planetOrbitDistance = calculateDistance(this.planetArcLength, DISTANCE_PROPORTION);
            delete this.init;
            return this;
        }
    }.init(),
    {
        trajectory: '(x-1)^2+(y+1)^2=1.38^2', 
        expressions: {
            arc1: 'y1=-\\sqrt{1.38^2-(x-1)^2}-1',
            arc2: 'y1=\\sqrt{1.38^2-(x-1)^2}-1\\left\\{x<-0.378,x>=0.3219\\right\\}'
        },
        coeficients: {h: 1, k: -1, r: 1.38, x: 360 / (PLANETS_DATA.earth.circulationPeriod * PLANETS_DATA.earth.orbitalSpeed * 1.38)}, 
        earthOrbitalSpeed: PLANETS_DATA.earth.orbitalSpeed,
        destinationPlanetOrbitalSpeed: PLANETS_DATA.mercury.orbitalSpeed,
        destinationPlanet: 'Mercury',
        timerValue: 1.199589, 
        startPointTimerValue: 3.087801,       
        finishPointTimerValue: 8.367638,      
        spacecraftArcLength: 728, 
        planetArcLength: 1148, //1.93
        correctSpeed: 29.79,
        chartValues: {
            tMax: 15_000,
            sMax: 1_500_000_000,
            vMax: 50
        },
        mathBounds: {
            left: -0.5,
            right: 2.5,
            bottom: -2.5,
            top: 0.5
        },
        init: function () {
            this.spacecraftFlightDistance = calculateDistance(this.spacecraftArcLength, DISTANCE_PROPORTION);
            this.planetOrbitDistance = calculateDistance(this.planetArcLength, DISTANCE_PROPORTION);
            delete this.init;
            return this;
        }
    }.init(),
];
