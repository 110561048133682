import React, { useContext, useReducer} from 'react';
import reducer from '../reducers/SNA23-reducer';
import { randomNum } from '../utils/helpers';
import { DATA } from '../data/SNA23data';

const SNA23Context = React.createContext();

export const SNA23Provider = (props) => {
    const initialState = {
        taskIndex: 0,
        data: DATA[0],
        electricityCost: null,
        isTimerOn: false,
        isDataReseted: true,
        canStart: false,
        isAnimationSpeedSlowed: false,
    };

    const handleNewVariantClick = () => {
        let random = randomNum(0, DATA.length - 1);
        while (random === state.taskIndex) random = randomNum(0, DATA.length - 1);
        const data =  DATA[random];
        dispatch({ 
            type: 'NEW_VARIANT',  
            payload: {data, random}
        });
    }

    const [state, dispatch] = useReducer(reducer, initialState);

    const handleStartClick = () => {
        dispatch({ type: 'START' });
    }

    const handleResetClick = () => {
        dispatch({ type: 'RESET' });
    }

    const setElectricityCost = (cost) => {
        dispatch({ type: 'SET_COST', payload: cost })
    }

    const adjustAnimationSpeed = () => {
        dispatch({ type: 'ADJUST_SPEED' });
    }

    const stopAnimation = () => {
        dispatch({ type: 'STOP_ANIMATION' }); 
    }

    return (
        <SNA23Context.Provider
            value={{
                ...state,
                handleNewVariantClick,
                handleStartClick,
                handleResetClick,
                setElectricityCost,
                adjustAnimationSpeed,
                stopAnimation
            }}
        >
            {props.children}
        </SNA23Context.Provider>
    );
}

export const useSNA23Context = () => {
    return useContext(SNA23Context);
};
