import React from 'react';
import Button from '../../../UI/Button/Button';
import classes from './Controls.module.css';
import { useTranslation } from 'react-i18next';

const Controls = (props) => {
    const { t } = useTranslation();
    return (
        <div className={classes.Controls}>
            {props.children}
            <div className={classes.Buttons}>
                <Button 
                    btnType='Danger' 
                    style={{width: '50%', marginRight: '5px'}} 
                    disabled={props.isDataReseted || props.isCoordsDataReseted || !props.canStart}
                    clicked={() => props.start()}>{props.isTimerOn ? t('buttons.pause') : t('buttons.start')}
                </Button>
                <Button 
                    btnType='Primary'
                    style={{width: '50%', marginLeft: '5px'}} 
                    clicked={() => props.reset()}
                >
                    {t('buttons.reset')}
                </Button>
            </div>
        </div>
    );
}
 
export default Controls;
