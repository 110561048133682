import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Trans } from 'react-i18next'
import classes from './Info.module.css';
import { useSNA19Context } from '../../../context/SNA19-context';
import nextId from "react-id-generator";

let key1 = nextId();
let key2 = nextId();

const Info = () => {
    const { t } = useTranslation();
    const { flightData } = useSNA19Context();

    useEffect(() => {
        key1 = nextId();
        key2 = nextId();
    }, [flightData]);

    return (
        <article className={classes.info}>
            <Trans>
                <p>{t('SNA19.info.p3', {planet: flightData ? t('SNA19.GC.planets.earth') : `_____`})}</p>
            </Trans>
            <Trans>
                <p>{t('SNA19.info.p4', {planet: flightData ? t(`SNA19.GC.planets.${flightData.destinationPlanet.toLowerCase()}`) : `_____`})}</p>
            </Trans>
            <hr/>
            <Trans key={key1}>
                <p className={flightData && classes.number}>{t('SNA19.info.p1', 
                    {
                        planet: flightData ? t(`SNA19.GC.planets.${flightData.destinationPlanet.toLowerCase()}`) : `_____`, 
                        hours: flightData ? Math.trunc(flightData.planetOrbitDistance / flightData.destinationPlanetOrbitalSpeed / 3600) : `_____`, 
                        minutes: flightData ? Math.round((flightData.planetOrbitDistance / flightData.destinationPlanetOrbitalSpeed / 3600 % 1).toFixed(2) * 60) : `_____`
                    }
                )}</p>
            </Trans>
            <hr/>
            <Trans key={key2}>
                <p className={flightData && classes.number}>{t('SNA19.info.p2', {distance: flightData ? flightData.spacecraftFlightDistance : `_____`})}</p>
            </Trans>
        </article>
    );
}
 
export default Info;
