import React, { Component, Fragment } from 'react';
import Des from 'desmos';
import classes from './GraphingCalculator.module.css';
import { calcAppendScript } from '../../../../components/calcAppendScript';
import Vehicle from '../../../../components/common_components/Vehicle/Vehicle';
import car from '../../../../assets/vehicles/car.png';

let calculator;
const widthDelta = 13.5;
const heightDelta = 27;

class GraphingCalculator extends Component {
    state = {
        xValue: null,
        yValue: 0,
        angle: 0,
        speed: 1,
        pointIndex: 1,
        moveDirection: null,
        functionsAmount: 0,
        coefficient: 1,
        isGraphsVisible: true,
    }

    componentDidMount() {
      calcAppendScript();
      const elt = document.getElementById('graphCalcId');
      calculator = Des.GraphingCalculator(elt, this.props.settings);
      calculator.setMathBounds(this.props.mathBounds);
    }

    componentWillUnmount() {
      window.cancelAnimationFrame(this.animationID);
      calculator.destroy();
    }

    setVehicleInitialPosition(value) {
      if (value === 'graphsAndPoints') {                            // Show/Hide graphs and points
        this.setState({ isGraphsVisible: !this.state.isGraphsVisible }, 
          () => this.showHideGrafsAndPoints()
        );
      }

      else if (!this.props.isAnimationRunning && this.props.xValues.length !== 0 && this.props.isDataReseted) {      // "OK" button clicked
        calculator.updateSettings(this.props.settings);
        this.setFunctionsAndPoints();
        this.setState({ 
          xValue: calculator.mathToPixels({ x: this.props.xValues[0] }).x - widthDelta,
          yValue: calculator.mathToPixels({ y: this.props.yValues[0] }).y - heightDelta,
          angle: this.props.angleValues[0],
          functionsAmount: this.props.functions.length
        });
      } 
      else if(!this.props.isAnimationRunning && this.props.xValues.length === 0) {             // "Reset" button klicked
        calculator.updateSettings(this.props.settings);
        this.setFunctionsAndPoints();
        this.setState({
          xValue: null, 
          yValue: 0, 
          angle: 0, 
          pointIndex: 1, 
          moveDirection: null, 
          functionsAmount: 0
        });
      } else {                                                                            // Start" button clicked
          this.animationID = window.requestAnimationFrame(() => this.animate());
      }   
    }

    showHideGrafsAndPoints() {
      this.props.functions.forEach((_, index) => calculator.setExpression({ 
        id: 'line' + index, 
        lines: this.state.isGraphsVisible 
      }));
      calculator.setExpression({
        id: 'points', 
        type: 'table',
        columns: [{}, { points: this.state.isGraphsVisible }]
      });
    }

    setDirection() {
        const xValueOfCurrentPoint = this.props.xValues[this.state.pointIndex - 1];
        const yValueOfCurrentPoint = this.props.yValues[this.state.pointIndex - 1];
        const xValueOfNextPoint = this.props.xValues[this.state.pointIndex];
        const yValueOfNextPoint = this.props.yValues[this.state.pointIndex];
    
        const k = this.getCoefficient(xValueOfCurrentPoint, xValueOfNextPoint, yValueOfCurrentPoint, yValueOfNextPoint);
        this.setState({coefficient: k});
        
        if(xValueOfNextPoint >  xValueOfCurrentPoint && yValueOfNextPoint > yValueOfCurrentPoint) {
          this.setState({moveDirection: 1});
        }
        else if (xValueOfNextPoint <  xValueOfCurrentPoint && yValueOfNextPoint > yValueOfCurrentPoint) {
          this.setState({moveDirection: 2});
        }
        else if (xValueOfNextPoint <  xValueOfCurrentPoint && yValueOfNextPoint < yValueOfCurrentPoint) {
          this.setState({moveDirection: 3});
        }
        else if (xValueOfNextPoint >  xValueOfCurrentPoint && yValueOfNextPoint < yValueOfCurrentPoint) {
          this.setState({moveDirection: 4});
        }
        else if (xValueOfNextPoint >  xValueOfCurrentPoint && yValueOfNextPoint === yValueOfCurrentPoint) {
          this.setState({moveDirection: 14});
        }
        else if (xValueOfNextPoint <  xValueOfCurrentPoint && yValueOfNextPoint === yValueOfCurrentPoint) {
          this.setState({moveDirection: 23});
        }
        else if (yValueOfNextPoint >  yValueOfCurrentPoint && xValueOfNextPoint === xValueOfCurrentPoint) {
          this.setState({moveDirection: 12});
        }
        else if (yValueOfNextPoint <  yValueOfCurrentPoint && xValueOfNextPoint === xValueOfCurrentPoint) {
          this.setState({moveDirection: 34});
        }
    }

    // Calculates linear function k coefficient
    getCoefficient (x1, x2, y1, y2) {
        if (x2 - x1 === 0) {
            return 0;
        } else {
            return (y2 - y1) / (x2 - x1);
        }
    }

    resetAngle() {
        if(this.state.angle < -90) {
          this.setState({angle: 270});
        }
        if(this.state.angle >= 270) {
          this.setState({angle: -90});
        }
    }

    setFunctionsAndPoints() {
        this.props.functions.forEach((func, index) => calculator.setExpression({ 
          id: 'line' + index, 
          latex: func, 
          lines: this.state.isGraphsVisible,
          color: '#2d70b3' 
        }));

        calculator.setExpression({
            type: 'table',
            id: 'points',
            columns: [
              {
                latex: 'x',
                values: this.props.xValues,
              },
              {
                latex: 'y',
                values: this.props.yValues,
                points: this.state.isGraphsVisible,
                color: '#c74440'
              },  
            ]
        });
        if(this.props.functions.length === 0) {
            for(let i = 0; i < this.state.functionsAmount; i++) {
                calculator.removeExpression({id: 'line' + i});
                calculator.removeExpression({id: 'points'});
            }  
        }
        this.setDirection();
    }

    moveForklift() {
        if (this.state.moveDirection === 1 && 
            (this.state.xValue + widthDelta) <= calculator.mathToPixels({x: this.props.xValues[this.state.pointIndex]}).x && 
              (this.state.yValue +  heightDelta) >= calculator.mathToPixels({y: this.props.yValues[this.state.pointIndex]}).y && this.props.angleValues.length >= this.state.pointIndex) {
            if (this.state.coefficient >= 1) {
                const updatedX_value = this.state.xValue + (this.state.speed / this.state.coefficient);
                const updatedY_value = this.state.yValue - this.state.speed;
                this.setState({xValue: updatedX_value, yValue: updatedY_value});
            }
            if (this.state.coefficient < 1) {
                const updatedX_value = this.state.xValue + this.state.speed;
                const updatedY_value = this.state.yValue - (this.state.speed * this.state.coefficient);
                this.setState({xValue: updatedX_value, yValue: updatedY_value});  
            }
        }

        else if (this.state.moveDirection === 2 &&
            (this.state.xValue + widthDelta) >= calculator.mathToPixels({x: this.props.xValues[this.state.pointIndex]}).x && 
              (this.state.yValue + heightDelta) >= calculator.mathToPixels({y: this.props.yValues[this.state.pointIndex]}).y && this.props.angleValues.length >= this.state.pointIndex) {
            if (this.state.coefficient <= -1) {
                const updatedX_value = this.state.xValue + (this.state.speed / this.state.coefficient);
                const updatedY_value = this.state.yValue - this.state.speed;
                this.setState({xValue: updatedX_value, yValue: updatedY_value});
            }
            if (this.state.coefficient > -1) {
                const updatedX_value = this.state.xValue - this.state.speed;
                const updatedY_value = this.state.yValue + (this.state.speed * this.state.coefficient);
                this.setState({xValue: updatedX_value, yValue: updatedY_value});  
            }
        }

        else if (this.state.moveDirection === 3 &&
            (this.state.xValue + widthDelta) >= calculator.mathToPixels({x: this.props.xValues[this.state.pointIndex]}).x &&
              (this.state.yValue + heightDelta) <= calculator.mathToPixels({y: this.props.yValues[this.state.pointIndex]}).y && this.props.angleValues.length >= this.state.pointIndex) {
            if (this.state.coefficient >= 1) {
                const updatedX_value = this.state.xValue - (this.state.speed / this.state.coefficient);
                const updatedY_value = this.state.yValue + this.state.speed;
                this.setState({xValue: updatedX_value, yValue: updatedY_value});
            }
            if (this.state.coefficient < 1) {
                const updatedX_value = this.state.xValue - this.state.speed;
                const updatedY_value = this.state.yValue + (this.state.speed * this.state.coefficient);
                this.setState({xValue: updatedX_value, yValue: updatedY_value});  
            }
        }

        else if (this.state.moveDirection === 4 &&
            (this.state.xValue + widthDelta) <= calculator.mathToPixels({x: this.props.xValues[this.state.pointIndex]}).x &&
              (this.state.yValue + heightDelta) <= calculator.mathToPixels({y: this.props.yValues[this.state.pointIndex]}).y && this.props.angleValues.length >= this.state.pointIndex) {
            if (this.state.coefficient >= -1) {
                const updatedX_value = this.state.xValue + this.state.speed;
                const updatedY_value = this.state.yValue - (this.state.speed * this.state.coefficient);
                this.setState({xValue: updatedX_value, yValue: updatedY_value});
            }
            if (this.state.coefficient < -1) {
                const updatedX_value = this.state.xValue - (this.state.speed / this.state.coefficient);
                const updatedY_value = this.state.yValue + this.state.speed;
                this.setState({xValue: updatedX_value, yValue: updatedY_value});  
            }
        }

        else if (this.state.moveDirection === 12 &&
            this.state.yValue >= calculator.mathToPixels({y: this.props.yValues[this.state.pointIndex]}).y - heightDelta && this.props.angleValues.length >= this.state.pointIndex) {
            const updatedY_value = this.state.yValue - this.state.speed;
            this.setState({yValue: updatedY_value});
        }
      
        else if (this.state.moveDirection === 23 &&
            this.state.xValue >= calculator.mathToPixels({x: this.props.xValues[this.state.pointIndex]}).x - widthDelta && this.props.angleValues.length >= this.state.pointIndex) {
            const updatedX_value = this.state.xValue - this.state.speed;
            this.setState({xValue: updatedX_value});
        }
      
        else if (this.state.moveDirection === 34 &&
            this.state.yValue <= calculator.mathToPixels({y: this.props.yValues[this.state.pointIndex]}).y - heightDelta && this.props.angleValues.length >= this.state.pointIndex) {
            const updatedY_value = this.state.yValue + this.state.speed;
            this.setState({yValue: updatedY_value});
          }
      
        else if (this.state.moveDirection === 14 &&
            this.state.xValue <= calculator.mathToPixels({x: this.props.xValues[this.state.pointIndex]}).x - widthDelta && this.props.angleValues.length >= this.state.pointIndex) {
            const updatedX_value = this.state.xValue + this.state.speed;
            this.setState({xValue: updatedX_value});
        }

        else if(Math.round(this.state.angle) !== Math.round(this.props.angleValues[this.state.pointIndex]) && this.props.angleValues.length > this.state.pointIndex) {
            const x1 = this.props.xValues[this.state.pointIndex - 1];
            const y1 = this.props.yValues[this.state.pointIndex - 1];
            const x2 = this.props.xValues[this.state.pointIndex];
            const y2 = this.props.yValues[this.state.pointIndex];
            const x3 = this.props.xValues[this.state.pointIndex + 1];
            const y3 = this.props.yValues[this.state.pointIndex + 1];
      
            const D = (x3 - x1) * (y2 -y1) - (y3 - y1) * (x2 - x1);
      
            if(D >= 0) { this.setState({ angle: this.state.angle + 1 }, () => this.resetAngle()); }
            if(D < 0) { this.setState({ angle: this.state.angle - 1 }, () => this.resetAngle()); }
        }

        else if (this.state.pointIndex + 1 < this.props.xValues.length && this.props.angleValues.length >= this.state.pointIndex) {
            this.setState({
              xValue: calculator.mathToPixels({x: this.props.xValues[this.state.pointIndex]}).x - widthDelta,
              yValue: calculator.mathToPixels({y: this.props.yValues[this.state.pointIndex]}).y - heightDelta,
              pointIndex: this.state.pointIndex + 1, 
            });
            this.setDirection();
        }

        else {
            this.props.stopAnimation();
          }
    }

    animate() { 
        this.moveForklift();
        if (this.props.isAnimationRunning) {
          this.animationID = window.requestAnimationFrame(() => this.animate()); 
        }   
    }

    render() { 
        return (
            <Fragment>
                <div id='graphCalcId' className={classes.GraphingCalculator}></div>
                {this.state.xValue !== null && (<Vehicle 
                    position={{
                        left: this.state.xValue,
                        top: this.state.yValue,
                        transform: 'rotate(' + this.state.angle + 'deg)',
                        backgroundImage: `url(${car})`
                    }} />)}
            </Fragment>
        );
    }
}
 
export default GraphingCalculator;
