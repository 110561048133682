import React, { useEffect } from 'react';
import { calcAppendScript } from '../../../calcAppendScript';
import Des from 'desmos';
import classes from './GraphingCalculator.module.css';
import { useTranslation } from 'react-i18next';

let calculator;

const GraphingCalculator = (props) => {
    const { t } = useTranslation();

    useEffect(() => {
        calcAppendScript();
        const elt = document.getElementById('graphCalcId');
        calculator = Des.GraphingCalculator(elt, props.settings);
        calculator.setMathBounds(props.mathBounds);
        return () => {
            calculator.destroy();
        }
    }, [props.settings, props.mathBounds]);

    useEffect(() => {
        calculator.setExpression({ 
            id: 'explorer', 
            latex: props.explorerPathParabola, 
            color: '#c74440',
            hidden: props.explorerStartPoint !== null && true   
        });

        calculator.setExpression({ 
            id: 'asteroid', 
            latex: props.asteroidPathParabola, 
            color: '#000',
            hidden: true
        });

        calculator.setExpression({ 
            id: 'Earth', 
            latex: '\\left(x-h\\right)^{2}+\\left(y-k\\right)^{2}<=r^{2}', 
            color: '#5798d9', 
        });

        calculator.setExpression({ // moves circle (Earth) left/right
            id: 'h', 
            latex: `h=${props.h}`, 
        });

        calculator.setExpression({   // moves circle (Earth) up/down
            id: 'k', 
            latex: `k=${props.k}`, 
        });

        calculator.setExpression({  // circle (Earth) radius
            id: 'r', 
            latex: `r=${props.r}`, 
            hidden: true
        });
    },[props.explorerStartPoint, props.explorerPathParabola, props.asteroidPathParabola, props.h, props.k, props.r]);

    useEffect(() => {
        calculator.setExpression({ // Part of parabola
            id: 'partOfAsteroidPathDashed', 
            latex: `g\\left(x\\right)\\left\\{x<${props.asteroidStartPoint},x>${props.explorerFinishPoint === 100 ? 10000 : props.explorerFinishPoint}\\right\\}`,
            color: '#000',
            lineStyle: Des.Styles.DASHED ,
        });

        calculator.setExpression({  // Part of parabola
            id: 'partOfAsteroidPathSolid', 
            latex: `g\\left(x\\right)\\left\\{${props.asteroidStartPoint}<x<${props.explorerFinishPoint === 100 ? 10000 : props.explorerFinishPoint}\\right\\}`, 
            color: '#000', 
        });

        calculator.setExpression({ // Part of parabola
            id: 'partOfExplorerPathDashed', 
            latex: `f\\left(x\\right)\\left\\{x<${props.explorerStartPoint},x>${props.explorerFinishPoint === null ? 10000 : props.explorerFinishPoint}\\right\\}`,
            color: '#c74440',
            lineStyle: Des.Styles.DASHED,
        });

        calculator.setExpression({  // Part of parabola
            id: 'partOfExplorerPathSolid', 
            latex: `f\\left(x\\right)\\left\\{${props.explorerStartPoint}<x<${props.explorerFinishPoint}\\right\\}`, 
            color: '#c74440', 
        });

        calculator.setExpression({ 
            id: 'explorerPath', 
            latex: '(s, f(s))', 
            color: '#c74440', 
            dragMode: Des.DragModes.NONE,
            showLabel: true,
            labelSize: Des.LabelSizes.SMALL, 
        });

        calculator.setExpression({ 
            id: 'asteroidPath', 
            latex: '(d, g(d))', 
            color: '#000', 
            dragMode: Des.DragModes.NONE,
            showLabel: true,
            labelSize: Des.LabelSizes.SMALL,
        });

        calculator.setExpression({ 
            id: 'asteroidAndExplorerFinishPoint', 
            latex: '(z, f(z))', 
            color: '#329932', 
            dragMode: Des.DragModes.NONE, 
            showLabel: true,
            labelSize: Des.LabelSizes.SMALL,
        });
        
        calculator.setExpression({ 
            id: 'z',                                  
            latex: `z=${props.explorerFinishPoint}`, 
        });

        calculator.setExpression({ 
            id: 's',                                  
            latex: `s=${props.explorerDeltaPoint}`, 
        });

        calculator.setExpression({ 
            id: 'd',                                  
            latex: `d=${props.asteroidDeltaPoint}`, 
        });

    }, [props.explorerDeltaPoint, 
        props.asteroidDeltaPoint, 
        props.explorerFinishPoint, 
        props.asteroidStartPoint, 
        props.explorerStartPoint,
        props.asteroidPathLength,
        props.explorerPathLength,
        props.isDataReseted
    ]);

    useEffect(() => {
        calculator.setExpression({ 
            id: 'explorerPath', 
            label: t('SNA6.GC.objects.spacecraft'),
        });
        calculator.setExpression({ 
            id: 'asteroidPath', 
            label: t('SNA6.GC.objects.comet'),
        });
        calculator.setExpression({ 
            id: 'asteroidAndExplorerFinishPoint', 
            label: t('SNA6.GC.objects.intersection'),
        });
    }, [t]);

    return (
        <div id='graphCalcId' className={classes.GraphingCalculator} />
    );
}
 
export default GraphingCalculator;
