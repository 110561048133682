import React from 'react';
import classes from './DashBoard.module.css';
import { faClock } from '@fortawesome/free-solid-svg-icons';
import d_red from '../../../assets/icons/SNA5/distance_red.png';
import d_blue from '../../../assets/icons/SNA5/distance_blue.png';
import Display from '../../../components/UI/Display/Display';
import DisplayFA from '../../UI/Display/DisplayFA';
import { useTranslation } from 'react-i18next';

const DashBoard = (props) => {
    const { t } = useTranslation();
    return (
        <div className={classes.Dashboard}>
            <DisplayFA    
                icon={faClock}
                value={props.stopwatch}
                unit={t('SNA5.dashboard.time-unit')}/>
            <Display 
                iconType="Reqtangle-s"     
                icon={d_red}
                value={props.overtakingCarDistance}
                unit={t('SNA5.dashboard.distance-unit')}/>
            <Display 
                iconType="Reqtangle-s"
                icon={d_blue}
                value={props.inFrontCarDistance}
                unit={t('SNA5.dashboard.distance-unit')}/>
        </div>
    );
}
 
export default DashBoard;
