import React, { Component } from 'react';
import Input from '../../../../components/UI/Input/Input';
import Button from '../../../../components/UI/Button/Button';
import { create, all } from 'mathjs';
import { withTranslation } from 'react-i18next';

const MATH = create(all);

const REGEX_INTEGERS_FRACTIONS = /^[-]?[1-9]+([+]+[0-9]+\/[0-9]+)$/;  
const REGEX_DIGITS = /^([-]?\d)*$/;
const REGEX_FRACTIONS = /^[-]?[1-9][0-9]*\/[1-9][0-9]*$/;

const MAX_FOUNTAIN_HEIGHT = 6; // in abstract meters - to prevent fountains going over img top

class Form extends Component {
    state = {
        form: {
            unitHeight: {
                elementType: 'input',
                elementConfig: {
                    type: 'text',
                },
                value: '1', 
                validation: {
                    required: true,
                    minValue: '1/10',
                    maxValue: 6,
                },
                valid: true, 
                touched: false
            },
            section_1: {
                elementType: 'input',
                elementConfig: {
                    type: 'text',
                },
                value: '', 
                validation: {
                    required: true,
                    minValue: 0,
                    maxValue: 4,
                },
                valid: false, 
                validHeight: true, 
                touched: false
            },
            section_2: {
                elementType: 'input',
                elementConfig: {
                    type: 'text',
                },
                value: '', 
                validation: {
                    required: true,
                    minValue: 0,
                    maxValue: 4,
                },
                valid: false, 
                validHeight: true, 
                touched: false
            },
            section_3: {
                elementType: 'input',
                elementConfig: {
                    type: 'text',
                },
                value: '', 
                validation: {
                    required: true,
                    minValue: 0,
                    maxValue: 4,
                },
                valid: false, 
                validHeight: true, 
                touched: false
            },
            section_4: {
                elementType: 'input',
                elementConfig: {
                    type: 'text',
                },
                value: '', 
                validation: {
                    required: true,
                    minValue: 0,
                    maxValue: 4,
                },
                valid: false, 
                validHeight: true, 
                touched: false
            }
        },
        formIsValid: false 
    }

    checkValidity = (value, rules) => {
        let isValid = false;
        isValid = value.trim() !== '' && isValid;
        isValid = REGEX_INTEGERS_FRACTIONS.test(value.trim()) || REGEX_DIGITS.test(value.trim()) || REGEX_FRACTIONS.test(value.trim());
        if (isValid) 
            isValid = (MATH.compile(value).evaluate() >= MATH.compile(rules.minValue).evaluate() && MATH.compile(value).evaluate() <= rules.maxValue) && isValid;
        return isValid;
    }

    checkMaxHeightValidity = (value, id) => {
        if (id !== 'unitHeight') {
            let isHeightValid = false;
            isHeightValid = value.trim() !== '' && isHeightValid;
            const fountainHeight = MATH.compile(this.state.form.unitHeight.value).evaluate() * MATH.compile(value).evaluate();
            isHeightValid = fountainHeight <= MAX_FOUNTAIN_HEIGHT;
            return isHeightValid;
        }
    }

    checkAllHeights = (value, updatedForm) => {
        for (let key in updatedForm) {
            if (key !== 'unitHeight') {
                const fountainHeight = MATH.compile(value).evaluate() * MATH.compile(updatedForm[key].value).evaluate();
                updatedForm[key].validHeight = fountainHeight <= MAX_FOUNTAIN_HEIGHT;
            } else {
                updatedForm[key].validHeight = true;
            }
        }
    }

    inputChangedHandler = (event, id) => {
        const updatedForm = {...this.state.form};
        const updatedItem = {...updatedForm[id]}; 
        updatedItem.value = event.target.value;  

        updatedItem.valid = this.checkValidity(updatedItem.value, updatedItem.validation); 
        updatedItem.validHeight = updatedItem.valid && this.checkMaxHeightValidity(updatedItem.value, id);
        (id === 'unitHeight' && updatedItem.value.trim() !== '' && updatedItem.valid) && this.checkAllHeights(updatedItem.value, updatedForm);
        updatedItem.touched = true;
        updatedForm[id] = updatedItem;
        let formIsValid = true;
        for (let id in updatedForm) {
            formIsValid = (updatedForm[id].valid && (id === 'unitHeight' || updatedForm[id].validHeight)) && formIsValid;
        }
        this.setState({ form: updatedForm, formIsValid });
    }

    dataHandler = (event) => {
        event.preventDefault();
        const formData = {};
        for (let key in this.state.form) {
            formData[key] = MATH.compile(this.state.form[key].value).evaluate()
        }
        this.props.formData(formData);
    }

    render() { 
        const { formIsValid } = this.state;
        const { isDataReseted, t } = this.props;
        const formElementsArray = [];
        for (let key in this.state.form) {
            formElementsArray.push({
                id: key,
                config: this.state.form[key]
            });
        }
        return (
            <form onSubmit={this.dataHandler}>
                {formElementsArray.map((formElement, index) => (
                    <div key={formElement.id}>
                        <Input 
                            id={formElement.id}
                            elementType={formElement.config.elementType}
                            elementConfig={formElement.config.elementConfig}
                            label={t(`SNA11.SNA11a.form.inputs.input-${index + 1}.label`)}
                            placeholder={t('SNA11.SNA11a.form.placeholder')}
                            value={formElement.config.value}
                            invalid={!formElement.config.valid}
                            validHeight={!formElement.config.validHeight}
                            touched={formElement.config.touched}
                            validation={formElement.config.validation}
                            errorMessage={t('SNA11.SNA11a.form.errors.minMaxError', {minValue: formElement.config.validation.minValue, maxValue: formElement.config.validation.maxValue})}
                            maxHeightError={t('SNA11.SNA11a.form.errors.maxHeightError')}
                            disabled={!isDataReseted}
                            changed={(event) => this.inputChangedHandler(event, formElement.id)}/>

                        {formElement.id === 'unitHeight' && 
                        <p style={{margin: '0 0 10px', fontWeight: '600'}}>
                            {t('SNA11.SNA11a.form.paragraph')}
                        </p>}
                    </div>
                ))} 
                <Button 
                    btnType="Success" 
                    disabled={!(formIsValid  && isDataReseted)}
                    style={{width: '100%', marginBottom: '15px'}}
                >
                    {t('buttons.submit')}
                </Button>
            </form>
        );
    }
}
 
export default withTranslation() (Form);
