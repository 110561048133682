export const randomNum = (min, max) => {
    return Math.trunc(Math.random() * (max - min + 1)) + min;
}

export const round = (value, precision) => {
    const multiplier = Math.pow(10, precision || 0);
    return Math.round(value * multiplier) / multiplier;
}

export const median = (values) => {
    values.sort((a, b) => a - b);
    const half = Math.floor(values.length / 2);
    if (values.length % 2) 
        return values[half];
    return (values[half - 1] + values[half]) / 2;
}

export const average = (values) => {
    const sum = values.reduce((a, b) => a + b, 0);
    const avg = (sum / values.length) || 0;
    return avg;
}

export const alphabet = Array.from({length: 26}, (_, index) => String.fromCharCode(97 + index));

export const getArrayOfUniques = (arr, key) => {
    return [...new Map(arr.map(item => [item[key], item])).values()]
};
