import React, { Fragment } from 'react';
import classes from './QuickStart.module.css';
import Accordion from '../../UI/Accordion/Accordion';

const QuickStartC = ({index, isAnimationRan}) => {
    return (  
        <Fragment>
            <Accordion title={'Mida me teeme selles programmis?'}>
                <p className={classes.paragraph}>Põlveliigeste punktsioon (või punkteerimine) on meditsiiniline operatsioon, milles käigus süstlanõel peab sisenema liigesesse õige nurga all ja õigele sügavusele. Tulevikus, seda operatsiooni võivad teha spetsiaalsed kirurgilised robotid. Selleks, et kirurgiline robot oskaks sisestada nõela õige nurga all, kasutatakse matemaatilisi meetodeid. Üks nendest meetoditest on luua täisnurkse kolmnurga mudelit patsiendi jala peal, seejärel saab arvutada nõela nurga arkusfunktsioonide abil.</p>
                <br/>
                <p className={classes.paragraph}>Pildil on kujutatud inimese põlv. Süstalt võib sisestada erinevatest kohtadest põlve lähedalt ja nõela ots peab jõudma põlve keskpunkti (põlvekedra alla).</p>
            </Accordion>
            <Accordion title={'Kelle tööd see programm tutvustab?'}>
                <p className={classes.paragraph}>Inimesed, kes loovad erinevaid meditsiinilisi seadmeid nimetatakse biomeditsiini insenerideks või bioinsenerideks. Nendel spetsialistidel on tavaliselt kõrgharidus. Inimesi kes töötavad olemasolevate meditsiiniliste seadmetega, häälestavad ja hooldavad neid – kutsutakse laborantideks või biotehnikuteks. Nendel võib olla nii kõrg- kui ka keskeriharidus.</p>
            </Accordion>
            <Accordion title={'Kuidas programmi kasutada?'}>
                <div className={classes.step}>
                    <span className={classes.numeration}>1.</span>
                    <p className={classes.paragraph}>Alusta programmi kasutamist vajutades nuppu <strong>"Uus ülesanne"</strong>.</p>
                </div>
                <div className={`${classes.step} ${index === null && classes.filter}`}>
                    <span className={classes.numeration}>2.</span>
                    <p className={classes.paragraph}>Selleks, et modelleerida roboti tööd, sisesta arvutatud nõela kraadi väärtus (ehk nurga ∠CAB väärtus). Seejärel vajuta <strong>"OK"</strong> ja <strong>"START"</strong>.</p>
                </div>
                <div className={`${classes.step} ${!isAnimationRan && classes.filter}`}>
                    <span className={classes.numeration}>3.</span>
                    <p className={classes.paragraph}>Järgmise ülesande jaoks vajuta <strong>"RESET"</strong> ning seejärel <strong>"Uus ülesanne"</strong>.</p>
                </div>
            </Accordion>
        </Fragment>
    );
}
 
export default QuickStartC;
