const SNA22_reducer = (state, action) => {
    if (action.type === 'NEW_VARIANT') {
        return { ...state, data: action.payload.data, index: action.payload.random };
    }
    if (action.type === 'SHOW') {
        return { 
            ...state, 
            isMascotShadowVisible: true,
            canShow: false,
        }
    }
    if (action.type === 'RESET') {
        return { 
            ...state, 
            index: 0,
            mascotShadowAngle: null,
            isMascotShadowVisible: false,
            isDataReseted: true,
            canShow: false,
        };
    }
    if (action.type === 'TOGGLE') {
        return { 
            ...state, 
            isLinesVisible: !state.isLinesVisible
        }
    }
    if (action.type === 'SET_ANGLE') {
        return { 
            ...state, 
            isDataReseted: false, 
            canShow: true, 
            mascotShadowAngle: action.payload, 
            isMascotShadowVisible: false, 
        }
    }
}

export default SNA22_reducer;
