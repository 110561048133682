import React from 'react';
import classes from './Timeline.module.css';
import { tracksMap } from '../../../data/SNA26data';
import { useSNA26Context } from '../../../context/SNA26-context';
import { useTranslation } from 'react-i18next';
import useImagesLoaded from '../../../hooks/useImagesLoaded';
import ImageWithLoading from '../../UI/ImageWithLoading/ImageWithLoading';
import { yOffset } from '../../../data/SNA26data';
//import { temp } from '../../../data/SNA26data';

const FREQUENCIES = [262, 294, 330, 349, 392, 440., 494];

const Timeline = () => {
    
    const { 
        frequency, 
        percentage,
        track, 
    } = useSNA26Context();

    const { t } = useTranslation();
    const [ref, imagesLoaded] = useImagesLoaded();

    const spectrum = tracksMap.get(track).spectrum;
    const coeficient = tracksMap.get(track).frequencyCoeficient;
    const offset = track === 'octave' && yOffset;

    // const arr = [];
    // for (const coords of temp) {
    //     arr.push({x: coords[0], y: coords[1]})
    // }
    // console.log(arr)

    return (
        <div 
            className={classes.timeline} 
            style={{
                '--left': percentage, 
                '--bottom': frequency, 
                '--horizontal': percentage < 90 ? percentage : percentage - 8,
                '--vertical': frequency < 90 ? frequency : frequency - 12
            }}
            ref={ref}
        >
            {!imagesLoaded && <ImageWithLoading />}
            <img className={classes.trace} src={spectrum} alt="trace" />
            {track === 'octave' && FREQUENCIES.map((f, i) => 
                <span key={f} className={classes[`frequency-${i + 1}`]}>{`${f}Hz`}</span>
            )}
            <span className={classes['x-axis']}>
                <span className={classes['x-axis-label']}>x, {t('SNA26.timeline.x-axis-label')}</span>
            </span>
            <span className={classes['y-axis']}>
                <span className={classes['y-axis-label']}>y, {t('SNA26.timeline.y-axis-label')}</span>
            </span>
            <span className={classes.a}>{`a=${(frequency * coeficient + offset).toFixed(3)}`}</span>
        </div>   
    );
}
 
export default Timeline;
