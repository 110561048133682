import React from 'react';
import classes from './Triangle.module.css';
import triangle1 from '../../../assets/trigonometry/triangles_sna8/triangle1.png';
import triangle2 from '../../../assets/trigonometry/triangles_sna8/triangle2.png';
import triangle3 from '../../../assets/trigonometry/triangles_sna8/triangle3.png';
import triangle4 from '../../../assets/trigonometry/triangles_sna8/triangle4.png';
import targets from '../../../assets/trigonometry/targets.png';

const triangles = [triangle1, triangle2, triangle3, triangle4];

const TriangleC = (props) => (
    <div>
        {props.transparent && props.index !== null && <img className={classes.Triangle} alt="triangle" src={triangles[props.index]}/>}
        {!props.transparent && <img className={classes.Triangle} alt="triangle" src={targets}/>}
    </div>  
);

export default TriangleC;
