import React from 'react';
import Polynomial from './Polynomial/Polynomial';
import classes from './Polynomials.module.css';

const polynomials = (props) => {
    const { id: weekId, values: polynomialsArray } = props.polynomialsArray;
    const { isAnimationRunning, isDataReseted} = props;
    const polynomials = [];

    for (const key of Object.keys(polynomialsArray)) {
        polynomials.push({
            id: key,
            values: polynomialsArray[key]
        });
    }

    return (
        <div className={classes.Polynomials}>
            {polynomials.map(polynomial => (
                <div key={polynomial.id} className={classes.Polynomial}>
                    <Polynomial
                        polynomialId={polynomial.id}
                        weekId={weekId}
                        isAnimationRunning={isAnimationRunning}
                        isDataReseted={isDataReseted}
                        coeficient={polynomial.values.coeficient}
                        variable_1={polynomial.values.variable_1.image}
                        variable_2={polynomial.values.variable_2.type}
                        openCoeficientsPanel={(weekId, polynomialId) => props.openCoeficientsPanel(weekId, polynomialId)}
                        openVariables_1_Panel={(weekId, polynomialId) => props.openVariables_1_Panel(weekId, polynomialId)}
                        openVariables_2_Panel={(weekId, polynomialId) => props.openVariables_2_Panel(weekId, polynomialId)} />
                    <span>+</span>
                </div>
            ))}
        </div>
    );
}

export default polynomials;
