import React from 'react';
import classes from './Sky.module.css';

const skyColors = [
    'hsla(194, 74%, 69%, 0.55)', 
    'hsla(194, 40%, 69%, 0.55)', 
    'hsl(180, 10%, 69%)', 
    'hsl(180, 1%, 69%)', 
    'hsl(211, 20%, 35%)'
];

const Sky = ({forecastIndex}) => {
    return (
        <div className={classes.sky} style={{'--color': skyColors[forecastIndex]}}/>
    );
}
 
export default Sky;
