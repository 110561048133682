import React from 'react';
import classes from './FootballField.module.css';
import { useSNA22Context } from '../../../context/SNA22-context';
import Mascot from '../Mascot/Mascot';
import useImagesLoaded from '../../../hooks/useImagesLoaded';
import ImageWithLoading from '../../UI/ImageWithLoading/ImageWithLoading';

const FootballField = () => {
    const { isLinesVisible, data: { footballField, overlay } } = useSNA22Context();
    const [ref, imagesLoaded] = useImagesLoaded();

    return (
        <section ref={ref} className={classes.wrapper}>
            {!imagesLoaded && <ImageWithLoading />}
            <img className={classes['football-field']} src={footballField} alt='fb2' width='1200' height='800'/>
            {isLinesVisible && <img src={overlay} className={classes.lines} alt='ov2' width='1200' height='800'/>}
            <Mascot />
        </section>
    );
}
 
export default FootballField;
