const octave_coords = [
    {
        "x": 3.99,
        "y": 17.24
    },
    {
        "x": 7.99,
        "y": 23.51
    },
    {
        "x": 12.00,
        "y": 30.51
    },
    {
        "x": 15.99,
        "y": 34.26
    },
    {
        "x": 19.99,
        "y": 42.7
    },
    {
        "x": 23.99,
        "y": 52.05
    },
    {
        "x": 28.02,
        "y": 62.52
    }
];

const classic_coords = [
    {
        "x": 0.29,
        "y": 7.87
    },
    {
        "x": 0.36,
        "y": 10.41
    },
    {
        "x": 0.44,
        "y": 12.94
    },
    {
        "x": 0.51,
        "y": 15.48
    },
    {
        "x": 0.58,
        "y": 18.02
    },
    {
        "x": 0.65,
        "y": 19.42
    },
    {
        "x": 0.73,
        "y": 20.94
    },
    {
        "x": 0.8,
        "y": 22.46
    },
    {
        "x": 0.87,
        "y": 23.98
    },
    {
        "x": 0.94,
        "y": 24.37
    },
    {
        "x": 1.02,
        "y": 24.49
    },
    {
        "x": 1.09,
        "y": 24.75
    },
    {
        "x": 1.16,
        "y": 24.87
    },
    {
        "x": 1.23,
        "y": 25.13
    },
    {
        "x": 1.31,
        "y": 22.72
    },
    {
        "x": 1.38,
        "y": 20.18
    },
    {
        "x": 1.45,
        "y": 17.89
    },
    {
        "x": 1.53,
        "y": 17.89
    },
    {
        "x": 1.6,
        "y": 20.18
    },
    {
        "x": 1.67,
        "y": 24.87
    },
    {
        "x": 1.74,
        "y": 29.7
    },
    {
        "x": 1.82,
        "y": 34.39
    },
    {
        "x": 1.89,
        "y": 39.09
    },
    {
        "x": 1.96,
        "y": 43.4
    },
    {
        "x": 2.03,
        "y": 43.65
    },
    {
        "x": 2.11,
        "y": 44.16
    },
    {
        "x": 2.18,
        "y": 44.67
    },
    {
        "x": 2.25,
        "y": 44.04
    },
    {
        "x": 2.32,
        "y": 43.53
    },
    {
        "x": 2.4,
        "y": 42.89
    },
    {
        "x": 2.47,
        "y": 32.87
    },
    {
        "x": 2.54,
        "y": 18.91
    },
    {
        "x": 2.61,
        "y": 17.51
    },
    {
        "x": 2.69,
        "y": 17.64
    },
    {
        "x": 2.76,
        "y": 19.42
    },
    {
        "x": 2.83,
        "y": 21.45
    },
    {
        "x": 2.91,
        "y": 23.35
    },
    {
        "x": 2.98,
        "y": 25.25
    },
    {
        "x": 3.05,
        "y": 27.16
    },
    {
        "x": 3.12,
        "y": 29.19
    },
    {
        "x": 3.2,
        "y": 31.09
    },
    {
        "x": 3.27,
        "y": 32.87
    },
    {
        "x": 3.34,
        "y": 34.64
    },
    {
        "x": 3.41,
        "y": 36.29
    },
    {
        "x": 3.49,
        "y": 37.94
    },
    {
        "x": 3.56,
        "y": 39.59
    },
    {
        "x": 3.63,
        "y": 41.37
    },
    {
        "x": 3.7,
        "y": 43.02
    },
    {
        "x": 3.78,
        "y": 44.67
    },
    {
        "x": 3.85,
        "y": 45.05
    },
    {
        "x": 3.92,
        "y": 45.18
    },
    {
        "x": 3.99,
        "y": 45.43
    },
    {
        "x": 4.07,
        "y": 42.01
    },
    {
        "x": 4.14,
        "y": 10.66
    },
    {
        "x": 4.21,
        "y": 10.79
    },
    {
        "x": 4.29,
        "y": 10.91
    },
    {
        "x": 4.36,
        "y": 18.27
    },
    {
        "x": 4.43,
        "y": 28.81
    },
    {
        "x": 4.5,
        "y": 39.21
    },
    {
        "x": 4.58,
        "y": 44.29
    },
    {
        "x": 4.65,
        "y": 44.29
    },
    {
        "x": 4.72,
        "y": 44.29
    },
    {
        "x": 4.79,
        "y": 44.29
    },
    {
        "x": 4.87,
        "y": 38.96
    },
    {
        "x": 4.94,
        "y": 20.69
    },
    {
        "x": 5.01,
        "y": 21.45
    },
    {
        "x": 5.08,
        "y": 27.03
    },
    {
        "x": 5.16,
        "y": 29.7
    },
    {
        "x": 5.23,
        "y": 20.81
    },
    {
        "x": 5.3,
        "y": 12.06
    },
    {
        "x": 5.37,
        "y": 12.82
    },
    {
        "x": 5.45,
        "y": 14.21
    },
    {
        "x": 5.52,
        "y": 15.74
    },
    {
        "x": 5.59,
        "y": 24.24
    },
    {
        "x": 5.67,
        "y": 35.28
    },
    {
        "x": 5.74,
        "y": 46.19
    },
    {
        "x": 5.81,
        "y": 57.23
    },
    {
        "x": 5.88,
        "y": 68.27
    },
    {
        "x": 5.96,
        "y": 57.99
    },
    {
        "x": 6.03,
        "y": 17.51
    },
    {
        "x": 6.1,
        "y": 16.5
    },
    {
        "x": 6.17,
        "y": 15.61
    },
    {
        "x": 6.25,
        "y": 14.59
    },
    {
        "x": 6.32,
        "y": 13.58
    },
    {
        "x": 6.39,
        "y": 12.56
    },
    {
        "x": 6.46,
        "y": 12.31
    },
    {
        "x": 6.54,
        "y": 56.22
    },
    {
        "x": 6.61,
        "y": 60.15
    },
    {
        "x": 6.68,
        "y": 47.34
    },
    {
        "x": 6.75,
        "y": 36.42
    },
    {
        "x": 6.83,
        "y": 25.51
    },
    {
        "x": 6.9,
        "y": 19.29
    },
    {
        "x": 6.97,
        "y": 17.89
    },
    {
        "x": 7.04,
        "y": 16.5
    },
    {
        "x": 7.12,
        "y": 15.1
    },
    {
        "x": 7.19,
        "y": 13.71
    },
    {
        "x": 7.26,
        "y": 12.44
    },
    {
        "x": 7.34,
        "y": 13.32
    },
    {
        "x": 7.41,
        "y": 14.21
    },
    {
        "x": 7.48,
        "y": 15.23
    },
    {
        "x": 7.55,
        "y": 47.21
    },
    {
        "x": 7.63,
        "y": 71.95
    },
    {
        "x": 7.7,
        "y": 57.99
    },
    {
        "x": 7.77,
        "y": 42.51
    },
    {
        "x": 7.84,
        "y": 27.16
    },
    {
        "x": 7.92,
        "y": 21.7
    },
    {
        "x": 7.99,
        "y": 17.26
    },
    {
        "x": 8.06,
        "y": 13.83
    },
    {
        "x": 8.13,
        "y": 15.1
    },
    {
        "x": 8.21,
        "y": 44.16
    },
    {
        "x": 8.28,
        "y": 64.97
    },
    {
        "x": 8.35,
        "y": 57.23
    },
    {
        "x": 8.42,
        "y": 49.49
    },
    {
        "x": 8.5,
        "y": 41.75
    },
    {
        "x": 8.57,
        "y": 34.01
    },
    {
        "x": 8.64,
        "y": 26.4
    },
    {
        "x": 8.72,
        "y": 23.35
    },
    {
        "x": 8.79,
        "y": 20.81
    },
    {
        "x": 8.86,
        "y": 18.27
    },
    {
        "x": 8.93,
        "y": 15.74
    },
    {
        "x": 9.01,
        "y": 13.96
    },
    {
        "x": 9.08,
        "y": 21.7
    },
    {
        "x": 9.15,
        "y": 30.2
    },
    {
        "x": 9.22,
        "y": 33.12
    },
    {
        "x": 9.3,
        "y": 30.58
    },
    {
        "x": 9.37,
        "y": 28.05
    },
    {
        "x": 9.44,
        "y": 25.51
    },
    {
        "x": 9.51,
        "y": 26.14
    },
    {
        "x": 9.59,
        "y": 31.22
    },
    {
        "x": 9.66,
        "y": 36.17
    },
    {
        "x": 9.73,
        "y": 41.24
    },
    {
        "x": 9.8,
        "y": 41.24
    },
    {
        "x": 9.88,
        "y": 37.69
    },
    {
        "x": 9.95,
        "y": 34.26
    },
    {
        "x": 10.02,
        "y": 37.94
    },
    {
        "x": 10.1,
        "y": 46.32
    },
    {
        "x": 10.17,
        "y": 54.7
    },
    {
        "x": 10.24,
        "y": 55.71
    },
    {
        "x": 10.31,
        "y": 55.71
    },
    {
        "x": 10.39,
        "y": 55.71
    },
    {
        "x": 10.46,
        "y": 55.71
    },
    {
        "x": 10.53,
        "y": 55.58
    },
    {
        "x": 10.6,
        "y": 51.27
    },
    {
        "x": 10.68,
        "y": 46.7
    },
    {
        "x": 10.75,
        "y": 42.13
    },
    {
        "x": 10.82,
        "y": 37.69
    },
    {
        "x": 10.89,
        "y": 33.5
    },
    {
        "x": 10.97,
        "y": 30.71
    },
    {
        "x": 11.04,
        "y": 27.92
    },
    {
        "x": 11.11,
        "y": 25.25
    },
    {
        "x": 11.18,
        "y": 31.85
    },
    {
        "x": 11.26,
        "y": 41.62
    },
    {
        "x": 11.33,
        "y": 51.52
    },
    {
        "x": 11.4,
        "y": 58.5
    },
    {
        "x": 11.48,
        "y": 58.76
    },
    {
        "x": 11.55,
        "y": 59.01
    },
    {
        "x": 11.62,
        "y": 59.26
    },
    {
        "x": 11.69,
        "y": 59.52
    },
    {
        "x": 11.77,
        "y": 62.94
    },
    {
        "x": 11.84,
        "y": 67.13
    },
    {
        "x": 11.91,
        "y": 48.6
    },
    {
        "x": 11.98,
        "y": 30.08
    },
    {
        "x": 12.06,
        "y": 24.87
    },
    {
        "x": 12.13,
        "y": 24.87
    },
    {
        "x": 12.2,
        "y": 65.1
    },
    {
        "x": 12.27,
        "y": 69.16
    },
    {
        "x": 12.35,
        "y": 55.58
    },
    {
        "x": 12.42,
        "y": 42.01
    },
    {
        "x": 12.49,
        "y": 28.43
    },
    {
        "x": 12.56,
        "y": 18.65
    },
    {
        "x": 12.64,
        "y": 18.27
    },
    {
        "x": 12.71,
        "y": 17.77
    },
    {
        "x": 12.78,
        "y": 17.39
    },
    {
        "x": 12.86,
        "y": 16.88
    },
    {
        "x": 12.93,
        "y": 16.5
    },
    {
        "x": 13,
        "y": 33.38
    },
    {
        "x": 13.07,
        "y": 74.24
    },
    {
        "x": 13.15,
        "y": 60.15
    },
    {
        "x": 13.22,
        "y": 44.54
    },
    {
        "x": 13.29,
        "y": 28.93
    },
    {
        "x": 13.36,
        "y": 26.52
    },
    {
        "x": 13.44,
        "y": 45.3
    },
    {
        "x": 13.51,
        "y": 70.81
    },
    {
        "x": 13.58,
        "y": 61.17
    },
    {
        "x": 13.65,
        "y": 51.4
    },
    {
        "x": 13.73,
        "y": 41.75
    },
    {
        "x": 13.8,
        "y": 32.11
    },
    {
        "x": 13.87,
        "y": 22.34
    },
    {
        "x": 13.94,
        "y": 12.69
    },
    {
        "x": 14.02,
        "y": 11.93
    },
    {
        "x": 14.09,
        "y": 11.8
    },
    {
        "x": 14.16,
        "y": 11.68
    },
    {
        "x": 14.24,
        "y": 21.45
    },
    {
        "x": 14.31,
        "y": 31.09
    },
    {
        "x": 14.38,
        "y": 26.9
    },
    {
        "x": 14.45,
        "y": 22.59
    },
    {
        "x": 14.53,
        "y": 21.19
    },
    {
        "x": 14.6,
        "y": 21.19
    },
    {
        "x": 14.67,
        "y": 21.32
    },
    {
        "x": 14.74,
        "y": 21.83
    },
    {
        "x": 14.82,
        "y": 24.49
    },
    {
        "x": 14.89,
        "y": 28.3
    },
    {
        "x": 14.96,
        "y": 31.98
    },
    {
        "x": 15.03,
        "y": 35.41
    },
    {
        "x": 15.11,
        "y": 35.15
    },
    {
        "x": 15.18,
        "y": 35.03
    },
    {
        "x": 15.25,
        "y": 34.9
    },
    {
        "x": 15.32,
        "y": 34.64
    },
    {
        "x": 15.4,
        "y": 26.52
    },
    {
        "x": 15.47,
        "y": 26.52
    },
    {
        "x": 15.54,
        "y": 26.52
    },
    {
        "x": 15.62,
        "y": 27.28
    },
    {
        "x": 15.69,
        "y": 35.28
    },
    {
        "x": 15.76,
        "y": 43.4
    },
    {
        "x": 15.83,
        "y": 51.4
    },
    {
        "x": 15.91,
        "y": 58.76
    },
    {
        "x": 15.98,
        "y": 58.76
    },
    {
        "x": 16.05,
        "y": 58.88
    },
    {
        "x": 16.12,
        "y": 59.01
    },
    {
        "x": 16.2,
        "y": 49.87
    },
    {
        "x": 16.27,
        "y": 38.07
    },
    {
        "x": 16.34,
        "y": 26.14
    },
    {
        "x": 16.41,
        "y": 15.86
    },
    {
        "x": 16.49,
        "y": 15.86
    },
    {
        "x": 16.56,
        "y": 15.86
    },
    {
        "x": 16.63,
        "y": 67.26
    },
    {
        "x": 16.7,
        "y": 66.75
    },
    {
        "x": 16.78,
        "y": 53.43
    },
    {
        "x": 16.85,
        "y": 40.23
    },
    {
        "x": 16.92,
        "y": 26.9
    },
    {
        "x": 17,
        "y": 22.97
    },
    {
        "x": 17.07,
        "y": 67.89
    },
    {
        "x": 17.14,
        "y": 61.42
    },
    {
        "x": 17.21,
        "y": 50.76
    },
    {
        "x": 17.29,
        "y": 40.1
    },
    {
        "x": 17.36,
        "y": 29.31
    },
    {
        "x": 17.43,
        "y": 20.94
    },
    {
        "x": 17.5,
        "y": 19.67
    },
    {
        "x": 17.58,
        "y": 18.4
    },
    {
        "x": 17.65,
        "y": 17.13
    },
    {
        "x": 17.72,
        "y": 48.22
    },
    {
        "x": 17.79,
        "y": 72.59
    },
    {
        "x": 17.87,
        "y": 57.36
    },
    {
        "x": 17.94,
        "y": 40.48
    },
    {
        "x": 18.01,
        "y": 23.6
    },
    {
        "x": 18.08,
        "y": 21.45
    },
    {
        "x": 18.16,
        "y": 65.99
    },
    {
        "x": 18.23,
        "y": 64.72
    },
    {
        "x": 18.3,
        "y": 56.22
    },
    {
        "x": 18.37,
        "y": 47.72
    },
    {
        "x": 18.45,
        "y": 39.21
    },
    {
        "x": 18.52,
        "y": 30.84
    },
    {
        "x": 18.59,
        "y": 22.34
    },
    {
        "x": 18.67,
        "y": 19.54
    },
    {
        "x": 18.74,
        "y": 18.78
    },
    {
        "x": 18.81,
        "y": 18.15
    },
    {
        "x": 18.88,
        "y": 25.13
    },
    {
        "x": 18.96,
        "y": 37.69
    },
    {
        "x": 19.03,
        "y": 37.44
    },
    {
        "x": 19.1,
        "y": 37.06
    },
    {
        "x": 19.17,
        "y": 36.68
    },
    {
        "x": 19.25,
        "y": 37.56
    },
    {
        "x": 19.32,
        "y": 41.88
    },
    {
        "x": 19.39,
        "y": 46.07
    },
    {
        "x": 19.46,
        "y": 50.38
    },
    {
        "x": 19.54,
        "y": 54.7
    },
    {
        "x": 19.61,
        "y": 58.88
    },
    {
        "x": 19.68,
        "y": 59.14
    },
    {
        "x": 19.75,
        "y": 59.14
    },
    {
        "x": 19.83,
        "y": 59.14
    },
    {
        "x": 19.9,
        "y": 59.14
    },
    {
        "x": 19.97,
        "y": 59.14
    },
    {
        "x": 20.05,
        "y": 59.14
    },
    {
        "x": 20.12,
        "y": 59.14
    },
    {
        "x": 20.19,
        "y": 59.14
    },
    {
        "x": 20.26,
        "y": 59.14
    },
    {
        "x": 20.34,
        "y": 58.5
    },
    {
        "x": 20.41,
        "y": 50.76
    },
    {
        "x": 20.48,
        "y": 42.89
    },
    {
        "x": 20.55,
        "y": 37.94
    },
    {
        "x": 20.63,
        "y": 35.41
    },
    {
        "x": 20.7,
        "y": 32.23
    },
    {
        "x": 20.77,
        "y": 29.06
    },
    {
        "x": 20.84,
        "y": 25.76
    },
    {
        "x": 20.92,
        "y": 22.59
    },
    {
        "x": 20.99,
        "y": 19.8
    },
    {
        "x": 21.06,
        "y": 19.67
    },
    {
        "x": 21.13,
        "y": 43.15
    },
    {
        "x": 21.21,
        "y": 73.1
    },
    {
        "x": 21.28,
        "y": 62.18
    },
    {
        "x": 21.35,
        "y": 46.57
    },
    {
        "x": 21.43,
        "y": 30.96
    },
    {
        "x": 21.5,
        "y": 29.44
    },
    {
        "x": 21.57,
        "y": 68.65
    },
    {
        "x": 21.64,
        "y": 66.24
    },
    {
        "x": 21.72,
        "y": 56.85
    },
    {
        "x": 21.79,
        "y": 47.59
    },
    {
        "x": 21.86,
        "y": 38.2
    },
    {
        "x": 21.93,
        "y": 28.93
    },
    {
        "x": 22.01,
        "y": 25.51
    },
    {
        "x": 22.08,
        "y": 24.24
    },
    {
        "x": 22.15,
        "y": 22.97
    },
    {
        "x": 22.22,
        "y": 54.44
    },
    {
        "x": 22.3,
        "y": 72.59
    },
    {
        "x": 22.37,
        "y": 63.32
    },
    {
        "x": 22.44,
        "y": 44.67
    },
    {
        "x": 22.51,
        "y": 31.73
    },
    {
        "x": 22.59,
        "y": 36.55
    },
    {
        "x": 22.66,
        "y": 72.46
    },
    {
        "x": 22.73,
        "y": 72.46
    },
    {
        "x": 22.81,
        "y": 67.89
    },
    {
        "x": 22.88,
        "y": 58.63
    },
    {
        "x": 22.95,
        "y": 49.37
    },
    {
        "x": 23.02,
        "y": 40.1
    },
    {
        "x": 23.1,
        "y": 30.84
    },
    {
        "x": 23.17,
        "y": 21.57
    },
    {
        "x": 23.24,
        "y": 25.38
    },
    {
        "x": 23.31,
        "y": 42.01
    },
    {
        "x": 23.39,
        "y": 58.63
    },
    {
        "x": 23.46,
        "y": 62.31
    },
    {
        "x": 23.53,
        "y": 60.53
    },
    {
        "x": 23.6,
        "y": 59.77
    },
    {
        "x": 23.68,
        "y": 59.77
    },
    {
        "x": 23.75,
        "y": 59.77
    },
    {
        "x": 23.82,
        "y": 59.77
    },
    {
        "x": 23.89,
        "y": 59.77
    },
    {
        "x": 23.97,
        "y": 59.77
    },
    {
        "x": 24.04,
        "y": 59.77
    },
    {
        "x": 24.11,
        "y": 59.77
    },
    {
        "x": 24.19,
        "y": 59.77
    },
    {
        "x": 24.26,
        "y": 59.77
    },
    {
        "x": 24.33,
        "y": 59.77
    },
    {
        "x": 24.4,
        "y": 62.31
    },
    {
        "x": 24.48,
        "y": 62.31
    },
    {
        "x": 24.55,
        "y": 60.15
    },
    {
        "x": 24.62,
        "y": 60.15
    },
    {
        "x": 24.69,
        "y": 60.03
    },
    {
        "x": 24.77,
        "y": 60.03
    },
    {
        "x": 24.84,
        "y": 59.9
    },
    {
        "x": 24.91,
        "y": 59.9
    },
    {
        "x": 24.98,
        "y": 58.5
    },
    {
        "x": 25.06,
        "y": 44.16
    },
    {
        "x": 25.13,
        "y": 29.82
    },
    {
        "x": 25.2,
        "y": 19.8
    },
    {
        "x": 25.27,
        "y": 19.67
    },
    {
        "x": 25.35,
        "y": 19.54
    },
    {
        "x": 25.42,
        "y": 57.87
    },
    {
        "x": 25.49,
        "y": 73.98
    },
    {
        "x": 25.57,
        "y": 51.9
    },
    {
        "x": 25.64,
        "y": 27.54
    },
    {
        "x": 25.71,
        "y": 25.13
    },
    {
        "x": 25.78,
        "y": 61.29
    },
    {
        "x": 25.86,
        "y": 73.48
    },
    {
        "x": 25.93,
        "y": 68.91
    },
    {
        "x": 26,
        "y": 53.55
    },
    {
        "x": 26.07,
        "y": 38.2
    },
    {
        "x": 26.15,
        "y": 32.99
    },
    {
        "x": 26.22,
        "y": 31.98
    },
    {
        "x": 26.29,
        "y": 30.96
    },
    {
        "x": 26.36,
        "y": 48.22
    },
    {
        "x": 26.44,
        "y": 73.6
    },
    {
        "x": 26.51,
        "y": 72.72
    },
    {
        "x": 26.58,
        "y": 65.23
    },
    {
        "x": 26.65,
        "y": 41.88
    },
    {
        "x": 26.73,
        "y": 30.2
    },
    {
        "x": 26.8,
        "y": 56.98
    },
    {
        "x": 26.87,
        "y": 72.97
    },
    {
        "x": 26.95,
        "y": 71.19
    },
    {
        "x": 27.02,
        "y": 69.67
    },
    {
        "x": 27.09,
        "y": 59.14
    },
    {
        "x": 27.16,
        "y": 48.48
    },
    {
        "x": 27.24,
        "y": 37.82
    },
    {
        "x": 27.31,
        "y": 31.98
    },
    {
        "x": 27.38,
        "y": 42.64
    },
    {
        "x": 27.45,
        "y": 65.61
    },
    {
        "x": 27.53,
        "y": 66.24
    },
    {
        "x": 27.6,
        "y": 63.32
    },
    {
        "x": 27.67,
        "y": 60.41
    },
    {
        "x": 27.74,
        "y": 59.77
    },
    {
        "x": 27.82,
        "y": 59.77
    },
    {
        "x": 27.89,
        "y": 59.77
    },
    {
        "x": 27.96,
        "y": 59.9
    },
    {
        "x": 28.03,
        "y": 59.9
    },
    {
        "x": 28.11,
        "y": 65.1
    },
    {
        "x": 28.18,
        "y": 64.72
    },
    {
        "x": 28.25,
        "y": 59.9
    },
    {
        "x": 28.33,
        "y": 59.9
    },
    {
        "x": 28.4,
        "y": 59.9
    },
    {
        "x": 28.47,
        "y": 59.9
    },
    {
        "x": 28.54,
        "y": 59.9
    },
    {
        "x": 28.62,
        "y": 59.9
    },
    {
        "x": 28.69,
        "y": 59.9
    },
    {
        "x": 28.76,
        "y": 59.9
    },
    {
        "x": 28.83,
        "y": 61.29
    },
    {
        "x": 28.91,
        "y": 63.83
    },
    {
        "x": 28.98,
        "y": 61.68
    },
    {
        "x": 29.05,
        "y": 60.03
    },
    {
        "x": 29.12,
        "y": 59.9
    },
    {
        "x": 29.2,
        "y": 51.65
    },
    {
        "x": 29.27,
        "y": 25.76
    },
    {
        "x": 29.34,
        "y": 22.97
    },
    {
        "x": 29.41,
        "y": 40.61
    },
    {
        "x": 29.49,
        "y": 72.46
    },
    {
        "x": 29.56,
        "y": 70.18
    },
    {
        "x": 29.63,
        "y": 63.2
    },
    {
        "x": 29.7,
        "y": 49.49
    },
    {
        "x": 29.78,
        "y": 36.68
    },
    {
        "x": 29.85,
        "y": 72.97
    },
    {
        "x": 29.92,
        "y": 71.83
    },
    {
        "x": 30,
        "y": 70.81
    },
    {
        "x": 30.07,
        "y": 70.05
    },
    {
        "x": 30.14,
        "y": 54.44
    },
    {
        "x": 30.21,
        "y": 37.18
    },
    {
        "x": 30.29,
        "y": 24.24
    },
    {
        "x": 30.36,
        "y": 23.22
    },
    {
        "x": 30.43,
        "y": 22.08
    },
    {
        "x": 30.5,
        "y": 43.02
    },
    {
        "x": 30.58,
        "y": 73.35
    },
    {
        "x": 30.65,
        "y": 71.57
    },
    {
        "x": 30.72,
        "y": 58.63
    },
    {
        "x": 30.79,
        "y": 33.5
    },
    {
        "x": 30.87,
        "y": 30.71
    },
    {
        "x": 30.94,
        "y": 73.1
    },
    {
        "x": 31.01,
        "y": 72.59
    },
    {
        "x": 31.08,
        "y": 68.53
    },
    {
        "x": 31.16,
        "y": 57.49
    },
    {
        "x": 31.23,
        "y": 46.45
    },
    {
        "x": 31.3,
        "y": 35.53
    },
    {
        "x": 31.38,
        "y": 24.49
    },
    {
        "x": 31.45,
        "y": 20.69
    },
    {
        "x": 31.52,
        "y": 20.05
    },
    {
        "x": 31.59,
        "y": 20.69
    },
    {
        "x": 31.67,
        "y": 38.58
    },
    {
        "x": 31.74,
        "y": 56.6
    },
    {
        "x": 31.81,
        "y": 68.65
    },
    {
        "x": 31.88,
        "y": 65.99
    },
    {
        "x": 31.96,
        "y": 63.45
    },
    {
        "x": 32.03,
        "y": 46.07
    },
    {
        "x": 32.1,
        "y": 38.58
    },
    {
        "x": 32.17,
        "y": 50.38
    },
    {
        "x": 32.25,
        "y": 60.91
    },
    {
        "x": 32.32,
        "y": 61.04
    },
    {
        "x": 32.39,
        "y": 61.04
    },
    {
        "x": 32.46,
        "y": 71.7
    },
    {
        "x": 32.54,
        "y": 68.91
    },
    {
        "x": 32.61,
        "y": 64.21
    },
    {
        "x": 32.68,
        "y": 60.53
    },
    {
        "x": 32.76,
        "y": 62.06
    },
    {
        "x": 32.83,
        "y": 64.72
    },
    {
        "x": 32.9,
        "y": 67.39
    },
    {
        "x": 32.97,
        "y": 70.05
    },
    {
        "x": 33.05,
        "y": 72.59
    },
    {
        "x": 33.12,
        "y": 72.97
    },
    {
        "x": 33.19,
        "y": 72.84
    },
    {
        "x": 33.26,
        "y": 72.84
    },
    {
        "x": 33.34,
        "y": 59.9
    },
    {
        "x": 33.41,
        "y": 45.43
    },
    {
        "x": 33.48,
        "y": 31.09
    },
    {
        "x": 33.55,
        "y": 22.46
    },
    {
        "x": 33.63,
        "y": 19.92
    },
    {
        "x": 33.7,
        "y": 17.26
    },
    {
        "x": 33.77,
        "y": 55.71
    },
    {
        "x": 33.84,
        "y": 73.35
    },
    {
        "x": 33.92,
        "y": 73.35
    },
    {
        "x": 33.99,
        "y": 73.35
    },
    {
        "x": 34.06,
        "y": 73.35
    },
    {
        "x": 34.14,
        "y": 73.35
    },
    {
        "x": 34.21,
        "y": 73.35
    },
    {
        "x": 34.28,
        "y": 73.35
    },
    {
        "x": 34.35,
        "y": 73.35
    },
    {
        "x": 34.43,
        "y": 73.48
    },
    {
        "x": 34.5,
        "y": 73.48
    },
    {
        "x": 34.57,
        "y": 73.48
    },
    {
        "x": 34.64,
        "y": 73.48
    },
    {
        "x": 34.72,
        "y": 73.48
    },
    {
        "x": 34.79,
        "y": 73.48
    },
    {
        "x": 34.86,
        "y": 73.48
    },
    {
        "x": 34.93,
        "y": 74.62
    },
    {
        "x": 35.01,
        "y": 78.43
    },
    {
        "x": 35.08,
        "y": 82.23
    },
    {
        "x": 35.15,
        "y": 78.17
    },
    {
        "x": 35.22,
        "y": 67.89
    },
    {
        "x": 35.3,
        "y": 57.61
    },
    {
        "x": 35.37,
        "y": 47.34
    },
    {
        "x": 35.44,
        "y": 72.97
    },
    {
        "x": 35.52,
        "y": 72.97
    },
    {
        "x": 35.59,
        "y": 72.84
    },
    {
        "x": 35.66,
        "y": 63.71
    },
    {
        "x": 35.73,
        "y": 53.81
    },
    {
        "x": 35.81,
        "y": 72.72
    },
    {
        "x": 35.88,
        "y": 49.37
    },
    {
        "x": 35.95,
        "y": 51.65
    },
    {
        "x": 36.02,
        "y": 65.86
    },
    {
        "x": 36.1,
        "y": 67.13
    },
    {
        "x": 36.17,
        "y": 55.96
    },
    {
        "x": 36.24,
        "y": 81.98
    },
    {
        "x": 36.31,
        "y": 47.34
    },
    {
        "x": 36.39,
        "y": 62.31
    },
    {
        "x": 36.46,
        "y": 71.83
    },
    {
        "x": 36.53,
        "y": 56.22
    },
    {
        "x": 36.6,
        "y": 37.69
    },
    {
        "x": 36.68,
        "y": 51.27
    },
    {
        "x": 36.75,
        "y": 59.77
    },
    {
        "x": 36.82,
        "y": 59.77
    },
    {
        "x": 36.9,
        "y": 59.9
    },
    {
        "x": 36.97,
        "y": 64.21
    },
    {
        "x": 37.04,
        "y": 72.46
    },
    {
        "x": 37.11,
        "y": 65.48
    },
    {
        "x": 37.19,
        "y": 43.02
    },
    {
        "x": 37.26,
        "y": 27.16
    },
    {
        "x": 37.33,
        "y": 62.44
    },
    {
        "x": 37.4,
        "y": 66.88
    },
    {
        "x": 37.48,
        "y": 55.08
    },
    {
        "x": 37.55,
        "y": 43.4
    },
    {
        "x": 37.62,
        "y": 65.86
    },
    {
        "x": 37.69,
        "y": 83.88
    },
    {
        "x": 37.77,
        "y": 83.63
    },
    {
        "x": 37.84,
        "y": 77.41
    },
    {
        "x": 37.91,
        "y": 72.97
    },
    {
        "x": 37.98,
        "y": 72.84
    },
    {
        "x": 38.06,
        "y": 72.72
    },
    {
        "x": 38.13,
        "y": 72.59
    },
    {
        "x": 38.2,
        "y": 70.69
    },
    {
        "x": 38.28,
        "y": 65.99
    },
    {
        "x": 38.35,
        "y": 61.29
    },
    {
        "x": 38.42,
        "y": 59.64
    },
    {
        "x": 38.49,
        "y": 58.88
    },
    {
        "x": 38.57,
        "y": 61.55
    },
    {
        "x": 38.64,
        "y": 71.95
    },
    {
        "x": 38.71,
        "y": 81.35
    },
    {
        "x": 38.78,
        "y": 75.76
    },
    {
        "x": 38.86,
        "y": 72.97
    },
    {
        "x": 38.93,
        "y": 72.84
    },
    {
        "x": 39,
        "y": 72.72
    },
    {
        "x": 39.07,
        "y": 72.72
    },
    {
        "x": 39.15,
        "y": 72.59
    },
    {
        "x": 39.22,
        "y": 72.46
    },
    {
        "x": 39.29,
        "y": 72.46
    },
    {
        "x": 39.36,
        "y": 70.81
    },
    {
        "x": 39.44,
        "y": 57.99
    },
    {
        "x": 39.51,
        "y": 45.3
    },
    {
        "x": 39.58,
        "y": 34.26
    },
    {
        "x": 39.66,
        "y": 63.32
    },
    {
        "x": 39.73,
        "y": 82.49
    },
    {
        "x": 39.8,
        "y": 73.48
    },
    {
        "x": 39.87,
        "y": 64.09
    },
    {
        "x": 39.95,
        "y": 54.57
    },
    {
        "x": 40.02,
        "y": 45.18
    },
    {
        "x": 40.09,
        "y": 35.66
    },
    {
        "x": 40.16,
        "y": 26.27
    },
    {
        "x": 40.24,
        "y": 28.81
    },
    {
        "x": 40.31,
        "y": 52.16
    },
    {
        "x": 40.38,
        "y": 73.1
    },
    {
        "x": 40.45,
        "y": 68.15
    },
    {
        "x": 40.53,
        "y": 57.99
    },
    {
        "x": 40.6,
        "y": 53.05
    },
    {
        "x": 40.67,
        "y": 53.05
    },
    {
        "x": 40.74,
        "y": 63.2
    },
    {
        "x": 40.82,
        "y": 64.09
    },
    {
        "x": 40.89,
        "y": 51.65
    },
    {
        "x": 40.96,
        "y": 37.94
    },
    {
        "x": 41.03,
        "y": 24.11
    }
];

const pop_coords = [
    {
        "x": 0,
        "y": 47.46
    },
    {
        "x": 0.07,
        "y": 14.47
    },
    {
        "x": 0.15,
        "y": 3.3
    },
    {
        "x": 0.22,
        "y": 3.17
    },
    {
        "x": 0.29,
        "y": 6.98
    },
    {
        "x": 0.36,
        "y": 43.65
    },
    {
        "x": 0.44,
        "y": 2.92
    },
    {
        "x": 0.51,
        "y": 2.79
    },
    {
        "x": 0.58,
        "y": 2.66
    },
    {
        "x": 0.65,
        "y": 2.54
    },
    {
        "x": 0.73,
        "y": 38.32
    },
    {
        "x": 0.8,
        "y": 3.55
    },
    {
        "x": 0.87,
        "y": 3.43
    },
    {
        "x": 0.94,
        "y": 3.3
    },
    {
        "x": 1.02,
        "y": 3.05
    },
    {
        "x": 1.09,
        "y": 2.92
    },
    {
        "x": 1.16,
        "y": 27.79
    },
    {
        "x": 1.23,
        "y": 9.64
    },
    {
        "x": 1.31,
        "y": 2.92
    },
    {
        "x": 1.38,
        "y": 9.26
    },
    {
        "x": 1.45,
        "y": 43.02
    },
    {
        "x": 1.52,
        "y": 2.54
    },
    {
        "x": 1.6,
        "y": 2.41
    },
    {
        "x": 1.67,
        "y": 37.69
    },
    {
        "x": 1.74,
        "y": 2.41
    },
    {
        "x": 1.82,
        "y": 2.54
    },
    {
        "x": 1.89,
        "y": 12.69
    },
    {
        "x": 1.96,
        "y": 27.66
    },
    {
        "x": 2.03,
        "y": 3.05
    },
    {
        "x": 2.11,
        "y": 2.92
    },
    {
        "x": 2.18,
        "y": 2.79
    },
    {
        "x": 2.25,
        "y": 42.77
    },
    {
        "x": 2.32,
        "y": 7.87
    },
    {
        "x": 2.4,
        "y": 2.79
    },
    {
        "x": 2.47,
        "y": 2.66
    },
    {
        "x": 2.54,
        "y": 2.54
    },
    {
        "x": 2.61,
        "y": 35.91
    },
    {
        "x": 2.69,
        "y": 14.97
    },
    {
        "x": 2.76,
        "y": 3.3
    },
    {
        "x": 2.83,
        "y": 3.05
    },
    {
        "x": 2.9,
        "y": 2.92
    },
    {
        "x": 2.98,
        "y": 2.79
    },
    {
        "x": 3.05,
        "y": 2.54
    },
    {
        "x": 3.12,
        "y": 29.82
    },
    {
        "x": 3.19,
        "y": 2.16
    },
    {
        "x": 3.27,
        "y": 2.66
    },
    {
        "x": 3.34,
        "y": 36.29
    },
    {
        "x": 3.41,
        "y": 14.85
    },
    {
        "x": 3.48,
        "y": 3.3
    },
    {
        "x": 3.56,
        "y": 3.05
    },
    {
        "x": 3.63,
        "y": 2.66
    },
    {
        "x": 3.7,
        "y": 2.28
    },
    {
        "x": 3.78,
        "y": 2.03
    },
    {
        "x": 3.85,
        "y": 42.39
    },
    {
        "x": 3.92,
        "y": 3.3
    },
    {
        "x": 3.99,
        "y": 3.05
    },
    {
        "x": 4.07,
        "y": 2.79
    },
    {
        "x": 4.14,
        "y": 2.54
    },
    {
        "x": 4.21,
        "y": 47.21
    },
    {
        "x": 4.28,
        "y": 7.36
    },
    {
        "x": 4.36,
        "y": 2.54
    },
    {
        "x": 4.43,
        "y": 2.66
    },
    {
        "x": 4.5,
        "y": 9.39
    },
    {
        "x": 4.57,
        "y": 37.69
    },
    {
        "x": 4.65,
        "y": 3.3
    },
    {
        "x": 4.72,
        "y": 3.05
    },
    {
        "x": 4.79,
        "y": 2.92
    },
    {
        "x": 4.86,
        "y": 2.79
    },
    {
        "x": 4.94,
        "y": 2.66
    },
    {
        "x": 5.01,
        "y": 22.34
    },
    {
        "x": 5.08,
        "y": 17.77
    },
    {
        "x": 5.15,
        "y": 2.66
    },
    {
        "x": 5.23,
        "y": 2.54
    },
    {
        "x": 5.3,
        "y": 27.41
    },
    {
        "x": 5.37,
        "y": 3.17
    },
    {
        "x": 5.45,
        "y": 2.92
    },
    {
        "x": 5.52,
        "y": 42.64
    },
    {
        "x": 5.59,
        "y": 6.85
    },
    {
        "x": 5.66,
        "y": 2.92
    },
    {
        "x": 5.74,
        "y": 32.36
    },
    {
        "x": 5.81,
        "y": 25.89
    },
    {
        "x": 5.88,
        "y": 2.54
    },
    {
        "x": 5.95,
        "y": 2.54
    },
    {
        "x": 6.03,
        "y": 2.54
    },
    {
        "x": 6.1,
        "y": 35.79
    },
    {
        "x": 6.17,
        "y": 26.02
    },
    {
        "x": 6.24,
        "y": 2.54
    },
    {
        "x": 6.32,
        "y": 2.41
    },
    {
        "x": 6.39,
        "y": 2.28
    },
    {
        "x": 6.46,
        "y": 37.31
    },
    {
        "x": 6.53,
        "y": 15.23
    },
    {
        "x": 6.61,
        "y": 2.41
    },
    {
        "x": 6.68,
        "y": 7.87
    },
    {
        "x": 6.75,
        "y": 44.04
    },
    {
        "x": 6.82,
        "y": 12.69
    },
    {
        "x": 6.9,
        "y": 1.9
    },
    {
        "x": 6.97,
        "y": 1.65
    },
    {
        "x": 7.04,
        "y": 1.52
    },
    {
        "x": 7.11,
        "y": 1.4
    },
    {
        "x": 7.19,
        "y": 31.35
    },
    {
        "x": 7.26,
        "y": 9.26
    },
    {
        "x": 7.33,
        "y": 2.41
    },
    {
        "x": 7.41,
        "y": 2.41
    },
    {
        "x": 7.48,
        "y": 35.66
    },
    {
        "x": 7.55,
        "y": 2.79
    },
    {
        "x": 7.62,
        "y": 2.54
    },
    {
        "x": 7.7,
        "y": 45.94
    },
    {
        "x": 7.77,
        "y": 79.82
    },
    {
        "x": 7.84,
        "y": 2.92
    },
    {
        "x": 7.91,
        "y": 2.79
    },
    {
        "x": 7.99,
        "y": 7.49
    },
    {
        "x": 8.06,
        "y": 55.96
    },
    {
        "x": 8.13,
        "y": 75.38
    },
    {
        "x": 8.2,
        "y": 60.03
    },
    {
        "x": 8.28,
        "y": 44.54
    },
    {
        "x": 8.35,
        "y": 29.19
    },
    {
        "x": 8.42,
        "y": 13.71
    },
    {
        "x": 8.49,
        "y": 2.79
    },
    {
        "x": 8.57,
        "y": 2.28
    },
    {
        "x": 8.64,
        "y": 56.98
    },
    {
        "x": 8.71,
        "y": 74.87
    },
    {
        "x": 8.78,
        "y": 57.49
    },
    {
        "x": 8.86,
        "y": 39.97
    },
    {
        "x": 8.93,
        "y": 22.59
    },
    {
        "x": 9,
        "y": 5.08
    },
    {
        "x": 9.08,
        "y": 3.3
    },
    {
        "x": 9.15,
        "y": 79.95
    },
    {
        "x": 9.22,
        "y": 55.58
    },
    {
        "x": 9.29,
        "y": 36.42
    },
    {
        "x": 9.37,
        "y": 17.13
    },
    {
        "x": 9.44,
        "y": 3.55
    },
    {
        "x": 9.51,
        "y": 3.81
    },
    {
        "x": 9.58,
        "y": 80.2
    },
    {
        "x": 9.66,
        "y": 63.71
    },
    {
        "x": 9.73,
        "y": 45.43
    },
    {
        "x": 9.8,
        "y": 27.16
    },
    {
        "x": 9.87,
        "y": 9.01
    },
    {
        "x": 9.95,
        "y": 3.81
    },
    {
        "x": 10.02,
        "y": 3.68
    },
    {
        "x": 10.09,
        "y": 48.48
    },
    {
        "x": 10.16,
        "y": 77.66
    },
    {
        "x": 10.24,
        "y": 48.1
    },
    {
        "x": 10.31,
        "y": 18.53
    },
    {
        "x": 10.38,
        "y": 3.55
    },
    {
        "x": 10.45,
        "y": 3.68
    },
    {
        "x": 10.53,
        "y": 80.08
    },
    {
        "x": 10.6,
        "y": 71.83
    },
    {
        "x": 10.67,
        "y": 55.96
    },
    {
        "x": 10.75,
        "y": 40.1
    },
    {
        "x": 10.82,
        "y": 24.24
    },
    {
        "x": 10.89,
        "y": 8.38
    },
    {
        "x": 10.96,
        "y": 3.68
    },
    {
        "x": 11.04,
        "y": 3.68
    },
    {
        "x": 11.11,
        "y": 66.12
    },
    {
        "x": 11.18,
        "y": 47.08
    },
    {
        "x": 11.25,
        "y": 28.05
    },
    {
        "x": 11.33,
        "y": 9.01
    },
    {
        "x": 11.4,
        "y": 3.43
    },
    {
        "x": 11.47,
        "y": 3.55
    },
    {
        "x": 11.54,
        "y": 79.44
    },
    {
        "x": 11.62,
        "y": 60.53
    },
    {
        "x": 11.69,
        "y": 22.08
    },
    {
        "x": 11.76,
        "y": 3.55
    },
    {
        "x": 11.83,
        "y": 3.43
    },
    {
        "x": 11.91,
        "y": 10.53
    },
    {
        "x": 11.98,
        "y": 78.81
    },
    {
        "x": 12.05,
        "y": 86.04
    },
    {
        "x": 12.12,
        "y": 87.31
    },
    {
        "x": 12.2,
        "y": 88.58
    },
    {
        "x": 12.27,
        "y": 89.85
    },
    {
        "x": 12.34,
        "y": 91.12
    },
    {
        "x": 12.41,
        "y": 92.39
    },
    {
        "x": 12.49,
        "y": 93.78
    },
    {
        "x": 12.56,
        "y": 94.16
    },
    {
        "x": 12.63,
        "y": 94.29
    },
    {
        "x": 12.71,
        "y": 94.54
    },
    {
        "x": 12.78,
        "y": 94.8
    },
    {
        "x": 12.85,
        "y": 94.92
    },
    {
        "x": 12.92,
        "y": 95.18
    },
    {
        "x": 13,
        "y": 95.3
    },
    {
        "x": 13.07,
        "y": 95.56
    },
    {
        "x": 13.14,
        "y": 95.81
    },
    {
        "x": 13.21,
        "y": 95.94
    },
    {
        "x": 13.29,
        "y": 96.19
    },
    {
        "x": 13.36,
        "y": 96.45
    },
    {
        "x": 13.43,
        "y": 87.56
    },
    {
        "x": 13.5,
        "y": 3.55
    },
    {
        "x": 13.58,
        "y": 3.68
    },
    {
        "x": 13.65,
        "y": 3.81
    },
    {
        "x": 13.72,
        "y": 4.06
    },
    {
        "x": 13.79,
        "y": 29.57
    },
    {
        "x": 13.87,
        "y": 28.3
    },
    {
        "x": 13.94,
        "y": 5.2
    },
    {
        "x": 14.01,
        "y": 4.7
    },
    {
        "x": 14.08,
        "y": 4.31
    },
    {
        "x": 14.16,
        "y": 44.8
    },
    {
        "x": 14.23,
        "y": 8.12
    },
    {
        "x": 14.3,
        "y": 4.19
    },
    {
        "x": 14.38,
        "y": 10.66
    },
    {
        "x": 14.45,
        "y": 39.72
    },
    {
        "x": 14.52,
        "y": 4.44
    },
    {
        "x": 14.59,
        "y": 4.31
    },
    {
        "x": 14.67,
        "y": 4.19
    },
    {
        "x": 14.74,
        "y": 4.06
    },
    {
        "x": 14.81,
        "y": 4.06
    },
    {
        "x": 14.88,
        "y": 33.12
    },
    {
        "x": 14.96,
        "y": 82.61
    },
    {
        "x": 15.03,
        "y": 79.7
    },
    {
        "x": 15.1,
        "y": 43.15
    },
    {
        "x": 15.17,
        "y": 6.6
    },
    {
        "x": 15.25,
        "y": 3.3
    },
    {
        "x": 15.32,
        "y": 59.77
    },
    {
        "x": 15.39,
        "y": 99.87
    },
    {
        "x": 15.46,
        "y": 3.3
    },
    {
        "x": 15.54,
        "y": 3.43
    },
    {
        "x": 15.61,
        "y": 3.68
    },
    {
        "x": 15.68,
        "y": 32.74
    },
    {
        "x": 15.75,
        "y": 99.87
    },
    {
        "x": 15.83,
        "y": 99.24
    },
    {
        "x": 15.9,
        "y": 3.55
    },
    {
        "x": 15.97,
        "y": 3.68
    },
    {
        "x": 16.05,
        "y": 3.93
    },
    {
        "x": 16.12,
        "y": 99.87
    },
    {
        "x": 16.19,
        "y": 19.29
    },
    {
        "x": 16.26,
        "y": 3.17
    },
    {
        "x": 16.34,
        "y": 99.37
    },
    {
        "x": 16.41,
        "y": 12.94
    },
    {
        "x": 16.48,
        "y": 3.17
    },
    {
        "x": 16.55,
        "y": 99.11
    },
    {
        "x": 16.63,
        "y": 2.92
    },
    {
        "x": 16.7,
        "y": 2.92
    },
    {
        "x": 16.77,
        "y": 3.05
    },
    {
        "x": 16.84,
        "y": 99.87
    },
    {
        "x": 16.92,
        "y": 3.17
    },
    {
        "x": 16.99,
        "y": 15.74
    },
    {
        "x": 17.06,
        "y": 99.87
    },
    {
        "x": 17.13,
        "y": 15.86
    },
    {
        "x": 17.21,
        "y": 3.81
    },
    {
        "x": 17.28,
        "y": 99.62
    },
    {
        "x": 17.35,
        "y": 24.24
    },
    {
        "x": 17.42,
        "y": 3.55
    },
    {
        "x": 17.5,
        "y": 3.55
    },
    {
        "x": 17.57,
        "y": 3.55
    },
    {
        "x": 17.64,
        "y": 59.39
    },
    {
        "x": 17.71,
        "y": 99.37
    },
    {
        "x": 17.79,
        "y": 3.55
    },
    {
        "x": 17.86,
        "y": 3.55
    },
    {
        "x": 17.93,
        "y": 26.78
    },
    {
        "x": 18.01,
        "y": 92.26
    },
    {
        "x": 18.08,
        "y": 24.62
    },
    {
        "x": 18.15,
        "y": 4.19
    },
    {
        "x": 18.22,
        "y": 86.42
    },
    {
        "x": 18.3,
        "y": 51.9
    },
    {
        "x": 18.37,
        "y": 5.2
    },
    {
        "x": 18.44,
        "y": 5.08
    },
    {
        "x": 18.51,
        "y": 89.59
    },
    {
        "x": 18.59,
        "y": 16.12
    },
    {
        "x": 18.66,
        "y": 4.82
    },
    {
        "x": 18.73,
        "y": 99.75
    },
    {
        "x": 18.8,
        "y": 38.58
    },
    {
        "x": 18.88,
        "y": 4.7
    },
    {
        "x": 18.95,
        "y": 4.57
    },
    {
        "x": 19.02,
        "y": 4.57
    },
    {
        "x": 19.09,
        "y": 4.31
    },
    {
        "x": 19.17,
        "y": 70.56
    },
    {
        "x": 19.24,
        "y": 4.19
    },
    {
        "x": 19.31,
        "y": 4.31
    },
    {
        "x": 19.38,
        "y": 4.31
    },
    {
        "x": 19.46,
        "y": 4.44
    },
    {
        "x": 19.53,
        "y": 40.74
    },
    {
        "x": 19.6,
        "y": 92.77
    },
    {
        "x": 19.68,
        "y": 39.34
    },
    {
        "x": 19.75,
        "y": 4.31
    },
    {
        "x": 19.82,
        "y": 4.57
    },
    {
        "x": 19.89,
        "y": 69.29
    },
    {
        "x": 19.97,
        "y": 10.28
    },
    {
        "x": 20.04,
        "y": 4.82
    },
    {
        "x": 20.11,
        "y": 4.7
    },
    {
        "x": 20.18,
        "y": 96.19
    },
    {
        "x": 20.26,
        "y": 4.95
    },
    {
        "x": 20.33,
        "y": 4.7
    },
    {
        "x": 20.47,
        "y": 41.12
    },
    {
        "x": 20.55,
        "y": 4.7
    },
    {
        "x": 20.62,
        "y": 19.54
    },
    {
        "x": 20.69,
        "y": 72.84
    },
    {
        "x": 20.76,
        "y": 4.95
    },
    {
        "x": 20.84,
        "y": 4.82
    },
    {
        "x": 20.91,
        "y": 67.64
    },
    {
        "x": 20.98,
        "y": 22.46
    },
    {
        "x": 21.05,
        "y": 5.08
    },
    {
        "x": 21.13,
        "y": 99.49
    },
    {
        "x": 21.2,
        "y": 40.1
    },
    {
        "x": 21.27,
        "y": 4.44
    },
    {
        "x": 21.34,
        "y": 4.44
    },
    {
        "x": 21.42,
        "y": 4.31
    },
    {
        "x": 21.49,
        "y": 51.78
    },
    {
        "x": 21.56,
        "y": 86.93
    },
    {
        "x": 21.64,
        "y": 4.57
    },
    {
        "x": 21.71,
        "y": 4.57
    },
    {
        "x": 21.78,
        "y": 11.04
    },
    {
        "x": 21.85,
        "y": 76.27
    },
    {
        "x": 21.93,
        "y": 19.42
    },
    {
        "x": 22,
        "y": 4.44
    },
    {
        "x": 22.07,
        "y": 49.62
    },
    {
        "x": 22.14,
        "y": 49.75
    },
    {
        "x": 22.22,
        "y": 4.82
    },
    {
        "x": 22.29,
        "y": 4.95
    },
    {
        "x": 22.36,
        "y": 4.95
    },
    {
        "x": 22.43,
        "y": 4.95
    },
    {
        "x": 22.51,
        "y": 5.08
    },
    {
        "x": 22.58,
        "y": 63.32
    },
    {
        "x": 22.65,
        "y": 5.2
    },
    {
        "x": 22.72,
        "y": 5.58
    },
    {
        "x": 22.8,
        "y": 56.35
    },
    {
        "x": 22.87,
        "y": 26.78
    },
    {
        "x": 22.94,
        "y": 5.08
    },
    {
        "x": 23.01,
        "y": 39.21
    },
    {
        "x": 23.09,
        "y": 63.58
    },
    {
        "x": 23.16,
        "y": 5.08
    },
    {
        "x": 23.23,
        "y": 5.2
    },
    {
        "x": 23.31,
        "y": 5.2
    },
    {
        "x": 23.38,
        "y": 5.33
    },
    {
        "x": 23.45,
        "y": 81.47
    },
    {
        "x": 23.52,
        "y": 63.71
    },
    {
        "x": 23.6,
        "y": 4.7
    },
    {
        "x": 23.67,
        "y": 4.7
    },
    {
        "x": 23.74,
        "y": 4.57
    },
    {
        "x": 23.81,
        "y": 72.08
    },
    {
        "x": 23.89,
        "y": 45.94
    },
    {
        "x": 23.96,
        "y": 7.99
    },
    {
        "x": 24.03,
        "y": 73.1
    },
    {
        "x": 24.1,
        "y": 57.99
    },
    {
        "x": 24.18,
        "y": 4.31
    },
    {
        "x": 24.25,
        "y": 50.51
    },
    {
        "x": 24.32,
        "y": 96.95
    },
    {
        "x": 24.39,
        "y": 27.16
    },
    {
        "x": 24.47,
        "y": 27.16
    },
    {
        "x": 24.54,
        "y": 95.94
    },
    {
        "x": 24.61,
        "y": 4.31
    },
    {
        "x": 24.68,
        "y": 10.03
    },
    {
        "x": 24.76,
        "y": 66.62
    },
    {
        "x": 24.83,
        "y": 4.44
    },
    {
        "x": 24.9,
        "y": 19.42
    },
    {
        "x": 24.98,
        "y": 69.92
    },
    {
        "x": 25.05,
        "y": 4.44
    },
    {
        "x": 25.12,
        "y": 4.44
    },
    {
        "x": 25.19,
        "y": 4.44
    },
    {
        "x": 25.27,
        "y": 4.44
    },
    {
        "x": 25.34,
        "y": 29.82
    },
    {
        "x": 25.41,
        "y": 81.35
    },
    {
        "x": 25.48,
        "y": 4.7
    },
    {
        "x": 25.56,
        "y": 4.7
    },
    {
        "x": 25.63,
        "y": 4.7
    },
    {
        "x": 25.7,
        "y": 85.41
    },
    {
        "x": 25.77,
        "y": 18.78
    },
    {
        "x": 25.85,
        "y": 10.41
    },
    {
        "x": 25.92,
        "y": 76.78
    },
    {
        "x": 25.99,
        "y": 3.93
    },
    {
        "x": 26.06,
        "y": 4.19
    },
    {
        "x": 26.14,
        "y": 88.32
    },
    {
        "x": 26.21,
        "y": 64.34
    },
    {
        "x": 26.28,
        "y": 4.19
    },
    {
        "x": 26.35,
        "y": 4.44
    },
    {
        "x": 26.43,
        "y": 64.34
    },
    {
        "x": 26.5,
        "y": 65.23
    },
    {
        "x": 26.57,
        "y": 18.65
    },
    {
        "x": 26.64,
        "y": 5.46
    },
    {
        "x": 26.72,
        "y": 5.58
    },
    {
        "x": 26.79,
        "y": 12.82
    },
    {
        "x": 26.86,
        "y": 89.85
    },
    {
        "x": 26.94,
        "y": 74.11
    },
    {
        "x": 27.01,
        "y": 5.58
    },
    {
        "x": 27.08,
        "y": 5.58
    },
    {
        "x": 27.15,
        "y": 5.58
    },
    {
        "x": 27.23,
        "y": 37.18
    },
    {
        "x": 27.3,
        "y": 72.72
    },
    {
        "x": 27.37,
        "y": 97.59
    },
    {
        "x": 27.44,
        "y": 5.84
    },
    {
        "x": 27.52,
        "y": 5.58
    },
    {
        "x": 27.59,
        "y": 39.85
    },
    {
        "x": 27.66,
        "y": 51.4
    },
    {
        "x": 27.73,
        "y": 5.58
    },
    {
        "x": 27.81,
        "y": 33.25
    },
    {
        "x": 27.88,
        "y": 70.43
    },
    {
        "x": 27.95,
        "y": 5.58
    },
    {
        "x": 28.02,
        "y": 5.84
    },
    {
        "x": 28.1,
        "y": 98.6
    },
    {
        "x": 28.17,
        "y": 14.59
    },
    {
        "x": 28.24,
        "y": 5.58
    },
    {
        "x": 28.31,
        "y": 98.86
    },
    {
        "x": 28.39,
        "y": 15.1
    },
    {
        "x": 28.46,
        "y": 5.58
    },
    {
        "x": 28.53,
        "y": 72.46
    },
    {
        "x": 28.61,
        "y": 42.51
    },
    {
        "x": 28.68,
        "y": 9.26
    },
    {
        "x": 28.75,
        "y": 5.58
    },
    {
        "x": 28.82,
        "y": 72.21
    },
    {
        "x": 28.9,
        "y": 22.59
    },
    {
        "x": 28.97,
        "y": 6.09
    },
    {
        "x": 29.04,
        "y": 6.09
    },
    {
        "x": 29.11,
        "y": 38.83
    },
    {
        "x": 29.19,
        "y": 59.52
    },
    {
        "x": 29.26,
        "y": 18.15
    },
    {
        "x": 29.33,
        "y": 5.71
    },
    {
        "x": 29.4,
        "y": 5.71
    },
    {
        "x": 29.48,
        "y": 13.96
    },
    {
        "x": 29.55,
        "y": 88.2
    },
    {
        "x": 29.62,
        "y": 88.96
    },
    {
        "x": 29.69,
        "y": 89.72
    },
    {
        "x": 29.77,
        "y": 90.48
    },
    {
        "x": 29.84,
        "y": 91.12
    },
    {
        "x": 29.91,
        "y": 91.88
    },
    {
        "x": 29.98,
        "y": 92.64
    },
    {
        "x": 30.06,
        "y": 93.4
    },
    {
        "x": 30.13,
        "y": 94.16
    },
    {
        "x": 30.2,
        "y": 94.92
    },
    {
        "x": 30.28,
        "y": 95.56
    },
    {
        "x": 30.35,
        "y": 96.07
    },
    {
        "x": 30.42,
        "y": 96.57
    },
    {
        "x": 30.49,
        "y": 97.21
    },
    {
        "x": 30.57,
        "y": 97.72
    },
    {
        "x": 30.64,
        "y": 98.22
    },
    {
        "x": 30.71,
        "y": 98.73
    },
    {
        "x": 30.86,
        "y": 99.24
    },
    {
        "x": 30.93,
        "y": 99.11
    },
    {
        "x": 31,
        "y": 98.86
    },
    {
        "x": 31.07,
        "y": 98.73
    },
    {
        "x": 31.15,
        "y": 98.6
    },
    {
        "x": 31.22,
        "y": 98.35
    },
    {
        "x": 31.29,
        "y": 98.22
    },
    {
        "x": 31.36,
        "y": 98.1
    },
    {
        "x": 31.44,
        "y": 97.84
    },
    {
        "x": 31.51,
        "y": 97.72
    },
    {
        "x": 31.58,
        "y": 97.59
    },
    {
        "x": 31.65,
        "y": 78.93
    },
    {
        "x": 31.73,
        "y": 97.46
    },
    {
        "x": 31.8,
        "y": 97.72
    },
    {
        "x": 31.87,
        "y": 84.01
    },
    {
        "x": 31.94,
        "y": 91.62
    },
    {
        "x": 32.02,
        "y": 88.45
    },
    {
        "x": 32.09,
        "y": 89.72
    },
    {
        "x": 32.24,
        "y": 85.41
    },
    {
        "x": 32.31,
        "y": 94.92
    },
    {
        "x": 32.38,
        "y": 84.01
    },
    {
        "x": 32.53,
        "y": 99.62
    },
    {
        "x": 32.6,
        "y": 79.19
    },
    {
        "x": 32.67,
        "y": 99.75
    },
    {
        "x": 32.74,
        "y": 84.26
    },
    {
        "x": 32.82,
        "y": 90.36
    },
    {
        "x": 32.89,
        "y": 89.85
    },
    {
        "x": 32.96,
        "y": 97.08
    },
    {
        "x": 33.03,
        "y": 97.21
    },
    {
        "x": 33.11,
        "y": 80.84
    },
    {
        "x": 33.18,
        "y": 97.97
    },
    {
        "x": 33.25,
        "y": 90.36
    },
    {
        "x": 33.32,
        "y": 87.56
    },
    {
        "x": 33.4,
        "y": 94.04
    },
    {
        "x": 33.47,
        "y": 94.16
    },
    {
        "x": 33.54,
        "y": 95.3
    },
    {
        "x": 33.61,
        "y": 94.04
    },
    {
        "x": 33.69,
        "y": 86.04
    },
    {
        "x": 33.76,
        "y": 96.32
    },
    {
        "x": 33.83,
        "y": 95.94
    },
    {
        "x": 33.91,
        "y": 100
    },
    {
        "x": 33.98,
        "y": 94.67
    },
    {
        "x": 34.05,
        "y": 88.83
    },
    {
        "x": 34.12,
        "y": 96.45
    },
    {
        "x": 34.2,
        "y": 87.44
    },
    {
        "x": 34.27,
        "y": 91.24
    },
    {
        "x": 34.34,
        "y": 93.4
    },
    {
        "x": 34.41,
        "y": 84.39
    },
    {
        "x": 34.49,
        "y": 96.32
    },
    {
        "x": 34.56,
        "y": 94.04
    },
    {
        "x": 34.63,
        "y": 88.2
    },
    {
        "x": 34.7,
        "y": 95.94
    },
    {
        "x": 34.78,
        "y": 89.21
    },
    {
        "x": 34.85,
        "y": 93.53
    },
    {
        "x": 34.92,
        "y": 95.81
    },
    {
        "x": 34.99,
        "y": 97.46
    },
    {
        "x": 35.07,
        "y": 98.22
    },
    {
        "x": 35.14,
        "y": 88.83
    },
    {
        "x": 35.21,
        "y": 93.91
    },
    {
        "x": 35.28,
        "y": 95.69
    },
    {
        "x": 35.36,
        "y": 92.89
    },
    {
        "x": 35.43,
        "y": 95.81
    },
    {
        "x": 35.5,
        "y": 94.42
    },
    {
        "x": 35.57,
        "y": 93.4
    },
    {
        "x": 35.65,
        "y": 97.08
    },
    {
        "x": 35.72,
        "y": 89.47
    },
    {
        "x": 35.79,
        "y": 99.75
    },
    {
        "x": 35.87,
        "y": 99.87
    },
    {
        "x": 35.94,
        "y": 92.26
    },
    {
        "x": 36.01,
        "y": 98.86
    },
    {
        "x": 36.08,
        "y": 91.37
    },
    {
        "x": 36.16,
        "y": 90.74
    },
    {
        "x": 36.23,
        "y": 96.7
    },
    {
        "x": 36.37,
        "y": 97.72
    },
    {
        "x": 36.52,
        "y": 98.22
    },
    {
        "x": 36.59,
        "y": 97.84
    },
    {
        "x": 36.66,
        "y": 94.92
    },
    {
        "x": 36.74,
        "y": 94.54
    },
    {
        "x": 36.81,
        "y": 96.95
    },
    {
        "x": 36.88,
        "y": 99.37
    },
    {
        "x": 36.95,
        "y": 97.46
    },
    {
        "x": 37.03,
        "y": 93.65
    },
    {
        "x": 37.1,
        "y": 98.6
    },
    {
        "x": 37.17,
        "y": 94.67
    },
    {
        "x": 37.24,
        "y": 99.37
    },
    {
        "x": 37.32,
        "y": 97.97
    },
    {
        "x": 37.39,
        "y": 95.56
    },
    {
        "x": 37.46,
        "y": 98.6
    },
    {
        "x": 37.54,
        "y": 92.39
    },
    {
        "x": 37.61,
        "y": 95.3
    },
    {
        "x": 37.68,
        "y": 92.26
    },
    {
        "x": 37.75,
        "y": 94.54
    },
    {
        "x": 37.83,
        "y": 97.72
    },
    {
        "x": 37.9,
        "y": 92.01
    },
    {
        "x": 37.97,
        "y": 99.11
    },
    {
        "x": 38.04,
        "y": 96.83
    },
    {
        "x": 38.12,
        "y": 93.15
    },
    {
        "x": 38.19,
        "y": 97.46
    },
    {
        "x": 38.26,
        "y": 98.22
    },
    {
        "x": 38.41,
        "y": 96.95
    },
    {
        "x": 38.48,
        "y": 95.43
    },
    {
        "x": 38.55,
        "y": 99.87
    },
    {
        "x": 38.62,
        "y": 95.94
    },
    {
        "x": 38.7,
        "y": 94.54
    },
    {
        "x": 38.77,
        "y": 98.1
    },
    {
        "x": 38.84,
        "y": 99.37
    },
    {
        "x": 38.91,
        "y": 97.34
    },
    {
        "x": 38.99,
        "y": 91.88
    },
    {
        "x": 39.06,
        "y": 96.32
    },
    {
        "x": 39.13,
        "y": 93.53
    },
    {
        "x": 39.21,
        "y": 99.37
    },
    {
        "x": 39.28,
        "y": 90.86
    },
    {
        "x": 39.35,
        "y": 96.7
    },
    {
        "x": 39.42,
        "y": 95.69
    },
    {
        "x": 39.5,
        "y": 94.8
    },
    {
        "x": 39.57,
        "y": 92.01
    },
    {
        "x": 39.64,
        "y": 99.87
    },
    {
        "x": 39.71,
        "y": 99.87
    },
    {
        "x": 39.79,
        "y": 95.43
    },
    {
        "x": 39.86,
        "y": 99.11
    },
    {
        "x": 39.93,
        "y": 92.89
    },
    {
        "x": 40,
        "y": 95.81
    },
    {
        "x": 40.08,
        "y": 93.02
    },
    {
        "x": 40.22,
        "y": 97.97
    },
    {
        "x": 40.29,
        "y": 95.43
    },
    {
        "x": 40.37,
        "y": 99.37
    },
    {
        "x": 40.44,
        "y": 96.83
    },
    {
        "x": 40.51,
        "y": 93.65
    },
    {
        "x": 40.58,
        "y": 98.35
    },
    {
        "x": 40.66,
        "y": 91.24
    },
    {
        "x": 40.73,
        "y": 100
    },
    {
        "x": 40.8,
        "y": 95.94
    },
    {
        "x": 40.87,
        "y": 91.75
    },
    {
        "x": 40.95,
        "y": 98.35
    },
    {
        "x": 41.02,
        "y": 91.75
    },
    {
        "x": 41.09,
        "y": 99.87
    },
    {
        "x": 41.17,
        "y": 99.75
    },
    {
        "x": 41.24,
        "y": 92.01
    },
    {
        "x": 41.31,
        "y": 96.83
    },
    {
        "x": 41.38,
        "y": 90.48
    },
    {
        "x": 41.46,
        "y": 95.18
    },
    {
        "x": 41.53,
        "y": 96.7
    },
    {
        "x": 41.6,
        "y": 98.1
    },
    {
        "x": 41.67,
        "y": 96.32
    },
    {
        "x": 41.75,
        "y": 91.75
    },
    {
        "x": 41.82,
        "y": 99.75
    },
    {
        "x": 41.89,
        "y": 91.75
    },
    {
        "x": 41.96,
        "y": 90.61
    },
    {
        "x": 42.04,
        "y": 92.89
    },
    {
        "x": 42.11,
        "y": 87.94
    },
    {
        "x": 42.18,
        "y": 96.57
    },
    {
        "x": 42.25,
        "y": 94.29
    },
    {
        "x": 42.33,
        "y": 83.38
    },
    {
        "x": 42.4,
        "y": 96.07
    },
    {
        "x": 42.47,
        "y": 92.89
    },
    {
        "x": 42.54,
        "y": 95.3
    },
    {
        "x": 42.62,
        "y": 92.39
    },
    {
        "x": 42.69,
        "y": 99.49
    },
    {
        "x": 42.76,
        "y": 97.34
    },
    {
        "x": 42.84,
        "y": 94.92
    },
    {
        "x": 42.91,
        "y": 94.16
    },
    {
        "x": 42.98,
        "y": 97.59
    },
    {
        "x": 43.05,
        "y": 99.62
    },
    {
        "x": 43.13,
        "y": 99.62
    },
    {
        "x": 43.2,
        "y": 99.62
    },
    {
        "x": 43.27,
        "y": 98.35
    },
    {
        "x": 43.34,
        "y": 96.45
    },
    {
        "x": 43.42,
        "y": 96.45
    },
    {
        "x": 43.49,
        "y": 99.75
    },
    {
        "x": 43.56,
        "y": 99.75
    },
    {
        "x": 43.63,
        "y": 99.62
    },
    {
        "x": 43.71,
        "y": 99.62
    },
    {
        "x": 43.78,
        "y": 97.97
    },
    {
        "x": 43.85,
        "y": 95.43
    },
    {
        "x": 43.92,
        "y": 97.46
    },
    {
        "x": 44,
        "y": 99.62
    },
    {
        "x": 44.07,
        "y": 97.08
    },
    {
        "x": 44.14,
        "y": 96.19
    },
    {
        "x": 44.21,
        "y": 99.24
    },
    {
        "x": 44.29,
        "y": 96.32
    },
    {
        "x": 44.36,
        "y": 94.04
    },
    {
        "x": 44.43,
        "y": 96.45
    },
    {
        "x": 44.51,
        "y": 94.29
    },
    {
        "x": 44.58,
        "y": 98.98
    },
    {
        "x": 44.65,
        "y": 98.6
    },
    {
        "x": 44.72,
        "y": 97.08
    },
    {
        "x": 44.8,
        "y": 95.56
    },
    {
        "x": 44.87,
        "y": 94.8
    },
    {
        "x": 44.94,
        "y": 99.75
    },
    {
        "x": 45.01,
        "y": 99.62
    },
    {
        "x": 45.09,
        "y": 90.1
    },
    {
        "x": 45.16,
        "y": 98.22
    },
    {
        "x": 45.23,
        "y": 96.57
    },
    {
        "x": 45.3,
        "y": 95.56
    },
    {
        "x": 45.38,
        "y": 96.32
    },
    {
        "x": 45.45,
        "y": 96.32
    },
    {
        "x": 45.52,
        "y": 96.32
    },
    {
        "x": 45.59,
        "y": 97.72
    },
    {
        "x": 45.67,
        "y": 99.75
    },
    {
        "x": 45.74,
        "y": 95.69
    },
    {
        "x": 45.81,
        "y": 95.69
    },
    {
        "x": 45.88,
        "y": 99.62
    },
    {
        "x": 45.96,
        "y": 99.62
    },
    {
        "x": 46.03,
        "y": 98.35
    },
    {
        "x": 46.1,
        "y": 97.21
    },
    {
        "x": 46.17,
        "y": 52.03
    },
    {
        "x": 46.25,
        "y": 51.78
    }
];

const tracksArr = [
    ['octave', { 
        source: 'https://dartef.s3.eu-north-1.amazonaws.com/SNA26/tracks/octave.mp3', 
        coords: octave_coords, 
        spectrum: 'https://dartef.s3.eu-north-1.amazonaws.com/SNA26/spectrums/octave_spectrum.png',
        frequencyCoeficient: 0.0051278
    }],
    ['classic', { 
        source: 'https://dartef.s3.eu-north-1.amazonaws.com/SNA26/tracks/classic.mp3', 
        coords: classic_coords, 
        spectrum: 'https://dartef.s3.eu-north-1.amazonaws.com/SNA26/spectrums/classic_spectrum.png',
        frequencyCoeficient: 0.2
    }], 
    ['pop', { 
        source: 'https://dartef.s3.eu-north-1.amazonaws.com/SNA26/tracks/pop.mp3', 
        coords: pop_coords, 
        spectrum: 'https://dartef.s3.eu-north-1.amazonaws.com/SNA26/spectrums/pop_spectrum.png',
        frequencyCoeficient: 0.2 
    }]
];

export const tracksMap = new Map(tracksArr);

export const yOffset = 0.17322;
