import React from 'react';
import AmountControls from '../AmountControls/AmountControls';
import EditableField from '../EditableField/EditableField';
import classes from './ExpressionControl.module.css';

const ExpressionControl = () => {
    return (
        <div className={classes.wrapper}>
            <EditableField />
            <AmountControls />
        </div>
    );
}
 
export default ExpressionControl;
