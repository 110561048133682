import React from 'react';
import classes from './AirParcel.module.css';

const AirParcel = ({airParcel_y, temperature, isDataReseted}) => {
    return (
        <div 
            className={classes['air-parcel']}
            temperature={temperature !== '' ? !isDataReseted ? '' : `${temperature}°C` : ''}
            style={{'--y_position': airParcel_y, '--color': temperature <= 0 ? 'hsl(270, 100%, 30%)' : 'red'}}/>
    );
}
 
export default AirParcel;
