import React, { Component, Fragment } from 'react';
import FormBuilder from '../SNA3a/FormBuilder/FormBuilder';
import GraphingCalculator from '../SNA3a/GraphingCalculator/GraphingCalculator';
import classes from '../SNA3a/SNA3.module.css';
import { create, all } from 'mathjs';
import SideDrawer from '../../../components/UI/SideDrawer/SideDrawer';
import QuickStartB from '../../../components/SNA3/QuickStartB';

const MATH = create(all);
const PARSER = MATH.parser();
const REGEX = /[+-]?\d+(\.\d+)?/g;

class SNA3b extends Component {
    vehicleInitialPosition = React.createRef();

    state = {
        settings: {
            autosize: true,  
            settingsMenu: false, 
            zoomButtons: false, 
            lockViewport: false,
            expressionsCollapsed: true,
            projectorMode: true, 
            expressions: true
        },
        mathBounds: {
            left: -20,
            right: 20,
            bottom: -20,
            top: 20
        },
        functions: [],
        intersectionPointsX: [],
        intersectionPointsY: [],
        angleValues: [],
        canStart: false,
        isAnimationRunning: false,
        isDataReseted: true,
        inputDisabled: false,
        isSideDrawerOpen: false,
    }

    getFormData = (functionsData, pointsData) => {
        const functions = [];
        functionsData.forEach(func => functions.push(func.value));

        const [xValues, yValues, angles] = [[], [], []];
        for (let i = 0; i < functions.length - 1; i++) {
            const [arr1, arr2, arr3, arr4] = [[], [], [], []];
            // Both functions have the form of y = kx + b
            if (functions[i].indexOf('x') !== -1 && functions[i].indexOf('y') !== -1 && functions[i + 1].indexOf('x') !== -1 && functions[i + 1].indexOf('y') !== -1) {
                PARSER.evaluate('x = 1000');
                PARSER.evaluate(functions[i]);
                const y1 = PARSER.evaluate('y');
                
                PARSER.evaluate(functions[i + 1]);
                const y3 = PARSER.evaluate('y');
                
                PARSER.evaluate('x = -1000');
                PARSER.evaluate(functions[i]);
                const y2 = PARSER.evaluate('y');

                PARSER.evaluate(functions[i + 1]);
                const y4 = PARSER.evaluate('y');
                arr1.push(1000, y1);
                arr2.push(-1000, y2);
                arr3.push(1000, y3);
                arr4.push(-1000, y4);
                
                const intersection = MATH.intersect(arr1, arr2, arr3, arr4);
                if (intersection !== null) {
                    xValues.push(parseFloat(intersection[0].toFixed(2)));
                    yValues.push(parseFloat(intersection[1].toFixed(2)));
                } else {
                    xValues.push(null);
                    yValues.push(null);
                }       
            }
            // First function have form of y = kx + b and the second (next) y = b or x = b
            if (functions[i].indexOf('x') !== -1 && functions[i].indexOf('y') !== -1 && (functions[i + 1].indexOf('x') === -1 || functions[i + 1].indexOf('y') === -1)) {
                PARSER.evaluate('x = 1000');
                PARSER.evaluate(functions[i]);
                const y1 = PARSER.evaluate('y');

                PARSER.evaluate('x = -1000');
                PARSER.evaluate(functions[i]);
                const y2 = PARSER.evaluate('y');
                arr1.push(1000, y1);
                arr2.push(-1000, y2); 

                if (functions[i + 1].indexOf('y') === -1) {
                    const x3 = parseFloat(functions[i + 1].match(REGEX));
                    arr3.push(x3, 1000);
                    arr4.push(x3, -1000);
                }
                if (functions[i + 1].indexOf('x') === -1) {
                    const y3 = parseFloat(functions[i + 1].match(REGEX));
                    arr3.push(1000, y3);
                    arr4.push(-1000, y3);
                }
                 
                const intersection = MATH.intersect(arr1, arr2, arr3, arr4); 
                xValues.push(parseFloat(intersection[0].toFixed(2)));
                yValues.push(parseFloat(intersection[1].toFixed(2))); 
            }
            // First function have form of y = b or x = b and the second (next) y = kx + b
            if ((functions[i].indexOf('x') === -1 || functions[i].indexOf('y') === -1) && functions[i + 1].indexOf('x') !== -1 && functions[i + 1].indexOf('y') !== -1) {
                if (functions[i].indexOf('y') === -1) {
                    const x1 = parseFloat(functions[i].match(REGEX));
                    arr1.push(x1, 1000);
                    arr2.push(x1, -1000);
                }
                if (functions[i].indexOf('x') === -1) {
                    const y1 = parseFloat(functions[i].match(REGEX));
                    arr1.push(1000, y1);
                    arr2.push(-1000, y1);
                }
                PARSER.evaluate('x = 1000');
                PARSER.evaluate(functions[i + 1]);
                const y3 = PARSER.evaluate('y');

                PARSER.evaluate('x = -1000');
                PARSER.evaluate(functions[i + 1]);
                const y4 = PARSER.evaluate('y');
                arr3.push(1000, y3);
                arr4.push(-1000, y4);
                const intersection = MATH.intersect(arr1, arr2, arr3, arr4);
                xValues.push(parseFloat(intersection[0].toFixed(2)));
                yValues.push(parseFloat(intersection[1].toFixed(2)));
            }
            // First function have form of x = b  and the second (next) y = b OR otherwise
            if ((functions[i].indexOf('x') === -1 && functions[i + 1].indexOf('y') === -1) || (functions[i].indexOf('y') === -1 && functions[i + 1].indexOf('x') === -1)) {
                if (functions[i].indexOf('y') === -1) {
                    xValues.push(parseFloat(functions[i].match(REGEX)));
                    yValues.push(parseFloat(functions[i + 1].match(REGEX)));
                }
                if (functions[i].indexOf('x') === -1) {
                    xValues.push(parseFloat(functions[i + 1].match(REGEX)));
                    yValues.push(parseFloat(functions[i ].match(REGEX)));
                }
            } 
            if ((functions[i].indexOf('x') === -1 && functions[i + 1].indexOf('x') === -1) || (functions[i].indexOf('y') === -1 && functions[i + 1].indexOf('y'))) {
                xValues.push(null);
                yValues.push(null);
            }
        }
        const startCoordinates = pointsData[0].value.slice(1, -1).split(',');
        const finishCoordinates = pointsData[1].value.slice(1, -1).split(',');
        xValues.unshift(Number(startCoordinates[0]));
        xValues.push(Number(finishCoordinates[0]));
        yValues.unshift(Number(startCoordinates[1]));
        yValues.push(Number(finishCoordinates[1]));

        for (let i = 0; i < xValues.length - 1; i++) {
            if(xValues[i] !== null && xValues[i + 1] !== null) {
                const angle = 90 - Math.atan2(yValues[i + 1] - yValues[i], xValues[i + 1] - xValues[i]) * 180 / Math.PI;
                angles.push(angle);
            } 
        }

        const updatedSettings = {...this.state.settings};
        updatedSettings.lockViewport = true;
        updatedSettings.expressions = false;

        this.setState({
            settings: updatedSettings,
            functions: functions, 
            intersectionPointsX: xValues, 
            intersectionPointsY: yValues,
            angleValues: angles,
            canStart: true,
            inputDisabled: true,
        }, () => this.vehicleInitialPosition.current.setVehicleInitialPosition());
    }

    handleStartClick = () => {
        this.setState({isAnimationRunning: !this.state.isAnimationRunning, isDataReseted: false}, () =>
        this.vehicleInitialPosition.current.setVehicleInitialPosition());
    }

    handleResetClick = () => {
        const updatedSettings = {...this.state.settings};
        updatedSettings.lockViewport = false;
        updatedSettings.expressions = true;
        this.setState({
            settings: updatedSettings,
            functions: [],
            intersectionPointsX: [],
            intersectionPointsY: [],
            angleValues: [],
            canStart: false,
            isAnimationRunning: false,
            isDataReseted: true,
            inputDisabled: false,
        }, () => this.vehicleInitialPosition.current.setVehicleInitialPosition());    
    }

    stopAnimation = () => {
        this.setState({
            canStart: false,
            isAnimationRunning: false,
        });
    }

    showHideGraphs = () => {
        this.vehicleInitialPosition.current.setVehicleInitialPosition('graphsAndPoints');
    }

    openSideDrawerHandler = () => {
        this.setState({ isSideDrawerOpen: true });
    }

    closeSideDrawerHandler = () => {
        this.setState({ isSideDrawerOpen: false });
    }

    render() { 
        const { isSideDrawerOpen } = this.state;

        return (
            <Fragment>
                <div className={classes.SNA3}>
                    <GraphingCalculator 
                        settings={this.state.settings} 
                        mathBounds={this.state.mathBounds}
                        xValues={this.state.intersectionPointsX}
                        yValues={this.state.intersectionPointsY} 
                        angleValues={this.state.angleValues}
                        functions={this.state.functions}
                        ref={this.vehicleInitialPosition}
                        isAnimationRunning={this.state.isAnimationRunning}
                        isDataReseted={this.state.isDataReseted}
                        stopAnimation={this.stopAnimation}/>
                    <FormBuilder 
                        className={classes.FormBuilder} 
                        canStart={this.state.canStart}
                        isDataReseted={this.state.isDataReseted}
                        isAnimationRunning={this.state.isAnimationRunning}
                        inputDisabled={this.state.inputDisabled}
                        formDataCallback={this.getFormData}
                        start={this.handleStartClick}
                        reset={this.handleResetClick}
                        onToggle={this.showHideGraphs}/>
                </div>
                <SideDrawer 
                    isSideDrawerOpen={isSideDrawerOpen}
                    openSideDrawer={this.openSideDrawerHandler}
                    closeSideDrawer={this.closeSideDrawerHandler}
                >
                    <QuickStartB/>
                </SideDrawer>
            </Fragment>
        );
    }
}
 
export default SNA3b;
