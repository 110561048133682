import React from 'react';
import Content from '../../components/SNA19/Content/Content';
import { SNA19Provider} from '../../context/SNA19-context';

const SNA19withProvider = () => {
    return (
        <SNA19Provider>
            <Content />
        </SNA19Provider>
    );
}
 
export default SNA19withProvider;
