import { useState } from 'react';
const def = () => false;
const useInput = (validateValue, validateSpecificError = def) => {
    const [enteredValue, setEnteredValue] = useState('');
    const [isTouched, setIsTouched] = useState(false);

    const valueIsValid = validateValue(enteredValue);
    const hasSpecificError = validateSpecificError(enteredValue) && isTouched;
    const hasError = !valueIsValid && isTouched;
    const valueChangeHandler = (event) => {
        setEnteredValue(event.target.value);
    }

    const inputBlurHandler = () => {
        setIsTouched(true);
    }

    const reset = () => {
        //setEnteredValue('');
        setIsTouched(false);
    }

    return {
        value: enteredValue,
        isValid: valueIsValid,
        hasError,
        hasSpecificError,
        valueChangeHandler,
        inputBlurHandler,
        reset
    };
}
 
export default useInput;
