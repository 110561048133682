import React from 'react';
import classes from './Toolbar.module.css';
import Logo from '../../Logo/Logo';
import LanguageSwitcher from '../../LanguageSwitcher/LanguageSwitcher';

const toolbar = (props) => (
    <header className={classes.Toolbar}>
        <Logo>DARTEF</Logo>
        <LanguageSwitcher />
    </header>
)

export default toolbar;