import React from 'react';
import classes from './Cloud.module.css';

const cloudColors = [
    'hsl(255, 100%, 100%)', 
    'hsl(0, 0%, 91%)', 
    'hsl(200, 23%, 51%)', 
    'hsl(210, 29%, 29%)', 
    'hsl(209, 30%, 19%)'
];

const Cloud = ({x_position, forecastIndex}) => {
    return (
        <div className={classes.wrapper} style={{'--x_position': x_position, '--coeficient' : forecastIndex < 3 ? 1 : forecastIndex < 4 ? 1.2 : 1.3}}>  
            <div className={classes.base}>
                <div className={classes.bar} style={{'--color': cloudColors[forecastIndex]}}/>
            </div>
            <div className={classes['circle-1']}>
                <div className={classes.bar} style={{'--color': cloudColors[forecastIndex]}}/>
            </div>
            <div className={classes['circle-2']}>
                <div className={classes.bar} style={{'--color': cloudColors[forecastIndex]}}/>
            </div>
        </div> 
    );
}
 
export default Cloud;
