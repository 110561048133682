import React, { useState, useEffect } from 'react';
import AirFlows from '../AirFlows/AirFlows';
import AirParcel from '../AirParcel/AirParcel';
import Cloud from '../Cloud/Cloud';
import Sky from '../Sky/Sky';
import Lightning from '../Lightning/Lightning';
import Rain from '../Rain/Rain';
import classes from './World.module.css';
import main_scene from '../../../assets/SNA9/main_scene.png';
import Sun from '../Sun/Sun';
import EnvTemperature from '../EnvTemperature/EnvTemperature';
import ImageWithLoading from '../../UI/ImageWithLoading/ImageWithLoading';

const IMG_WIDTH = 1500;
const IMG_HEIGHT = 1000;
const CONTAINER_WIDTH = 0.6;
const MEDIA_COEFICIENT = 1.5;

const World = ({
    airFlows, 
    airParcel_y, 
    environmentTemperature, 
    airParcelTemperature, 
    forecastIndex, 
    isRainImgVisible,
    cloudPositions,
    userForecastIndex,
    isDataReseted
}) => {
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        let isMounted = false;
        const image = new Image();
        image.onload = () => !isMounted && setIsLoading(false);
        image.src = main_scene;
        return () => isMounted = true;
    }, []);

    return (
        <section className={classes.wrapper}>
            {isLoading ? 
                <ImageWithLoading 
                    containerWidth={CONTAINER_WIDTH} 
                    coeficient={MEDIA_COEFICIENT}
                    width={IMG_WIDTH} 
                    height={IMG_HEIGHT}/> :
                <img width='1500' height='1000' alt='main_scene' src={main_scene}/>
            } 
            <Sky forecastIndex={forecastIndex} /> 
            {forecastIndex > 2 && <Lightning />}
            {forecastIndex > 2 && isRainImgVisible && <Rain />}
            <EnvTemperature
                isDataReseted={isDataReseted} 
                temperature={environmentTemperature}/>
            <Sun 
                style={{
                    '--x_position': forecastIndex < 2 && userForecastIndex !== '' ? 3 : -10,  // vw from left 
                    '--color': 'hsl(56, 92%, 53%)', 
                    '--status': forecastIndex <= 2 && userForecastIndex !== '' ? 'running' : 'paused'}} />
            <Cloud 
                x_position={cloudPositions[forecastIndex][0]}  // vw from left 
                forecastIndex={forecastIndex}/>
            <Cloud 
                x_position={cloudPositions[forecastIndex][1]} // vw from left 
                forecastIndex={forecastIndex}/>
            <Cloud 
                x_position={cloudPositions[forecastIndex][2]} // vw from left 
                forecastIndex={forecastIndex}/>
            <AirFlows airFlows={airFlows}/>
            <AirParcel 
                isDataReseted={isDataReseted}
                airParcel_y={airParcel_y}
                temperature={airParcelTemperature}/>
        </section>
    );
}
 
export default World;
