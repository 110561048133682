import React from 'react';
import KeypadButton from './KeypadButton.js/KeypadButton';
import classes from './KeypadButtons.module.css';
import { keypadButtons1 } from '../../../data/SNA24data';
import { keypadButtons2 } from '../../../data/SNA24data';

const KeypadButtons = ({ clicked }) => {
    return (
        <section className={classes.wrapper}>
            <div className={classes['keypad-buttons-1-grid']}>
                {keypadButtons1.map(({ value, command, keystroke }) => 
                    <KeypadButton 
                        key={value}
                        clicked={() => clicked(command, keystroke)}
                    >
                        {value}
                    </KeypadButton>)}
            </div>
            <div className={classes['keypad-buttons-2-grid']}>
                {keypadButtons2.map(({ value, command, keystroke }) => 
                    <KeypadButton 
                        key={value}
                        clicked={() => clicked(command, keystroke)}
                    >
                        {value}
                    </KeypadButton>)}
            </div>
       </section>
    );
}
 
export default KeypadButtons;
