import React from 'react';
import classes from './Display.module.css';

const display = (props) => (
    <div className={classes.Display} style={props.style}>
        <img className={[classes.DisplayIcon, classes[props.iconType]].join(' ')} src={props.icon} alt='display icon'></img>
        <div className={classes.DisplayValue}>{props.value}</div>
        <div className={classes.DisplayUnit}>{props.unit}</div>
    </div>
);

export default display;
