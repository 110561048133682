const SNA19_reducer = (state, action) => {
    if (action.type === 'START') {
        return { 
            ...state, 
            isTimerOn: state.isTimerOn ? false : true, 
            isStarcraftAnimationRunning: state.isStarcraftAnimationRunning ? false : true
        }
    }
    if (action.type === 'RESET') {
        return { 
            ...state, 
            spacecraftSpeed: 0,
            trajectoryIndex: '',
            flightData: '',
            isTimerOn: true, 
            isDataReseted: true, 
            isStarcraftAnimationRunning: false,
            isAnimationSpeedSlowed: false,
            timerAcc: 0
        };
    }
    if (action.type === 'NEW_VARIANT') {
        return { ...state, isTimerOn: false, flightData: action.payload.flightData, trajectoryIndex: action.payload.random };
    }
    if (action.type === 'SET_SPEED') {
        return { ...state, isDataReseted: false, canStart: true, spacecraftSpeed: action.payload };
    }
    if (action.type === 'STOP_SPACECRAFT') {
        return { ...state, canStart: false, isStarcraftAnimationRunning: false };
    }
    if (action.type === 'ADJUST_SPEED') {
        return { ...state, isAnimationSpeedSlowed: state.isAnimationSpeedSlowed ? false : true };
    }
    if (action.type === 'ADD_DELTA') {
        return { ...state, timerAcc: state.timerAcc + action.payload };
    }
    if (action.type === 'STOP_ANIMATION') {
        return { ...state, isTimerOn: false, isStarcraftAnimationRunning: false };
    }
    if (action.type === 'DISABLE_START') {
        return { ...state, canStart: false };
    }

};

export default SNA19_reducer;
 