import React from 'react';
import classes from './Lightning.module.css';
import lightning from '../../../assets/SNA9/lightning.png';

const Lightning = () => {
    return (
        <div 
            className={classes.lightning} 
            style={{backgroundImage: `url(${lightning})`}}/>
    );
}
 
export default Lightning;
