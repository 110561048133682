export const triangles = [
    {
        triangle: 'https://dartef.s3.eu-north-1.amazonaws.com/SNA-2-7-8/SNA2/Triangles/1.png',
        hypotenuse: 6,
        direction: 1,
        syringeAngle: 238,
        yCoeficient: 0.625,
        aPoint: {
            x: 0.492,
            y: 0.477
        },
        cPoint: {
            x: 0.672,
            y: 0.365
        },
    },
    {
        triangle: 'https://dartef.s3.eu-north-1.amazonaws.com/SNA-2-7-8/SNA2/Triangles/2.png',
        hypotenuse: 5.7,
        direction: 2,
        syringeAngle: -41,
        yCoeficient: 1.15,
        aPoint: {
            x: 0.492,
            y: 0.479
        },
        cPoint: {
            x: 0.625,
            y: 0.631
        },
    },
    {
        triangle: 'https://dartef.s3.eu-north-1.amazonaws.com/SNA-2-7-8/SNA2/Triangles/3.png',
        hypotenuse: 4.3,
        direction: 4,
        syringeAngle: 119,
        yCoeficient: 0.554,
        aPoint: {
            x: 0.49,
            y: 0.481
        },
        cPoint: {
            x: 0.357,
            y: 0.406
        },
    },
    {
        triangle: 'https://dartef.s3.eu-north-1.amazonaws.com/SNA-2-7-8/SNA2/Triangles/4.png',
        hypotenuse: 4.9,
        direction: 4,
        syringeAngle: 134,
        yCoeficient: 0.966,
        aPoint: {
            x: 0.49,
            y: 0.477
        },
        cPoint: {
            x: 0.365,
            y: 0.359
        },
    },
    {
        triangle: 'https://dartef.s3.eu-north-1.amazonaws.com/SNA-2-7-8/SNA2/Triangles/5.png',
        hypotenuse: 6.7,
        direction: 4,
        syringeAngle: 142,
        yCoeficient: 1.28,
        aPoint: {
            x: 0.49,
            y: 0.478
        },
        cPoint: {
            x: 0.345,
            y: 0.291
        },
    },
    {
        triangle: 'https://dartef.s3.eu-north-1.amazonaws.com/SNA-2-7-8/SNA2/Triangles/6.png',
        hypotenuse: 5.7,
        direction: 2,
        syringeAngle: -32,
        yCoeficient: 1.6,
        aPoint: {
            x: 0.4925,
            y: 0.48
        },
        cPoint: {
            x: 0.599,
            y: 0.651
        },
    },
    {
        triangle: 'https://dartef.s3.eu-north-1.amazonaws.com/SNA-2-7-8/SNA2/Triangles/7.png',
        hypotenuse: 5.7,
        direction: 3,
        syringeAngle: 49,
        yCoeficient: 0.869,
        aPoint: {
            x: 0.491,
            y: 0.48
        },
        cPoint: {
            x: 0.341,
            y: 0.61
        },
    },
    {
        triangle: 'https://dartef.s3.eu-north-1.amazonaws.com/SNA-2-7-8/SNA2/Triangles/8.png',
        hypotenuse: 5,
        direction: 3,
        syringeAngle: 60,
        yCoeficient: 0.577,
        aPoint: {
            x: 0.492,
            y: 0.477
        },
        cPoint: {
            x: 0.341,
            y: 0.566
        },
    },
    {
        triangle: 'https://dartef.s3.eu-north-1.amazonaws.com/SNA-2-7-8/SNA2/Triangles/9.png',
        hypotenuse: 6.2,
        direction: 2,
        syringeAngle: -30,
        yCoeficient: 1.732,
        aPoint: {
            x: 0.491,
            y: 0.478
        },
        cPoint: {
            x: 0.598,
            y: 0.665
        },
    },
    {
        triangle: 'https://dartef.s3.eu-north-1.amazonaws.com/SNA-2-7-8/SNA2/Triangles/10.png',
        hypotenuse: 4,
        direction: 3,
        syringeAngle: 60,
        yCoeficient: 0.577,
        aPoint: {
            x: 0.491,
            y: 0.479
        },
        cPoint: {
            x: 0.37,
            y: 0.55
        },
    },
    {
        triangle: 'https://dartef.s3.eu-north-1.amazonaws.com/SNA-2-7-8/SNA2/Triangles/11.png',
        hypotenuse: 4.2,
        direction: 3,
        syringeAngle: 30,
        yCoeficient: 1.732,
        aPoint: {
            x: 0.4923,
            y: 0.477
        },
        cPoint: {
            x: 0.42,
            y: 0.605
        },
    },
    {
        triangle: 'https://dartef.s3.eu-north-1.amazonaws.com/SNA-2-7-8/SNA2/Triangles/12.png',
        hypotenuse: 5.1,
        direction: 1,
        syringeAngle: 225,
        yCoeficient: 1,
        aPoint: {
            x: 0.494,
            y: 0.477
        },
        cPoint: {
            x: 0.619,
            y: 0.355
        },
    }
];

export const target = 'https://dartef.s3.eu-north-1.amazonaws.com/SNA-2-7-8/Target/target.png';
