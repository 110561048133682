import React from 'react';
import classes from './Checkbox.module.css';

const Checkbox = (props) => {
    const { id = 1, disabled = false, checked = false, label = 'Checkbox' } = props;
    return (
        <div className={classes.wrapper}>
            <input 
                type='checkbox' 
                id={id} 
                disabled={disabled}
                checked={checked}
                onChange={props.changed}
            /> 
            <label htmlFor={id}>{label}</label>
        </div>
    );
}
 
export default Checkbox;
