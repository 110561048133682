import React, { Fragment } from 'react';
import classes from './EnvTemperature.module.css';

const EnvTemperature = ({temperature, isDataReseted}) => {
    return (
        <Fragment>
            <div className={classes['temperature-1']}>{temperature !== '' ? !isDataReseted ? '' : `${temperature}°C` : ''}</div>
            <div className={classes['temperature-2']}>{temperature !== '' ? !isDataReseted ? '' : `${temperature}°C` : ''}</div>
            <div className={classes['temperature-3']}>{temperature !== '' ? !isDataReseted ? '' : `${temperature}°C` : ''}</div>
        </Fragment>
    );
}
 
export default EnvTemperature;