import React from 'react';
import classes from './SNA24.module.css';
import Controls from '../../components/SNA24/Controls/Controls';
import Computer from '../../components/SNA24/Computer/Computer';
import { SNA24Provider } from '../../context/SNA24-context';
import ExpressionControl from '../../components/SNA24/ExpressionControl/ExpressionControl';
import Info from '../../components/SNA24/Info/Info';
import SideDrawer from '../../components/UI/SideDrawer/SideDrawer';
import useToggle from '../../hooks/useToggle';
import QuickStartA from '../../components/SNA24/QuickStart/QuickStartA';

const SNA24 = () => {
    const [isOpen, setIsOpen] = useToggle(false);

    const openSideDrawerHandler = () => {
        setIsOpen(true);
    }

    const closeSideDrawerHandler = () => {
        setIsOpen(false);
    }

    return (
        <SNA24Provider>
            <main className={classes.wrapper}>
                <section className={classes.content}>
                    <Computer />
                    <ExpressionControl />
                </section>
                <section className={classes.controls}>
                    <Controls />
                    <Info />
                </section>  
            </main>
            <SideDrawer 
                isSideDrawerOpen={isOpen}
                openSideDrawer={openSideDrawerHandler}
                closeSideDrawer={closeSideDrawerHandler}
            >
                <QuickStartA />
            </SideDrawer> 
        </SNA24Provider>
    );
}
 
export default SNA24;
