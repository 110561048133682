import React, { useEffect } from 'react';
import classes from './Notification.module.css';
import { AiOutlineCheckCircle, AiOutlineWarning, AiOutlineExclamationCircle, AiOutlineInfoCircle } from 'react-icons/ai';

const Notification = (props) => {
    const { notifications = [], position = 'TopRight', notificationDelay = 3000, deleteNotification } = props;

    useEffect(() => {
        const interval = setInterval(() => {
            if (notifications.length) {
                deleteNotification();
            }
        }, notificationDelay);
        return () => clearInterval(interval);
    }, [deleteNotification, notifications, notificationDelay]);

    return (
        <div className={`${classes.Container} ${classes[position]}`}>
            {
                notifications.map(notification =>
                    <div key={notification.id} className={`${classes.Notification} ${classes[position]} ${classes[notification.type]}`}>
                        <button className={classes.CloseButton} onClick={props.deleteNotification}>&times;</button>
                        <div className={classes.Content}>
                            <div>
                                {notification.type === 'Success' && <AiOutlineCheckCircle />}
                                {notification.type === 'Danger' && <AiOutlineExclamationCircle />}
                                {notification.type === 'Warning' && <AiOutlineWarning />}
                                {notification.type === 'Info' && <AiOutlineInfoCircle />}
                            </div>
                            <div>
                                <p className={classes.Title}>{notification.title}</p>
                                <p className={classes.Message}>{notification.description}</p>
                            </div>
                        </div>
                    </div>
                )
            }
        </div>
    );
}

export default Notification;
