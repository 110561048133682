export const getCards = (t) => [
    {
        name: t('homePage.cards.SNA1a.title'), 
        subtitle: t('homePage.cards.SNA1a.subtitle'), 
        path: '/SNA_1a', 
        image: 'images/sna_1.png'
    },
    {
        name: t('homePage.cards.SNA1b.title'), 
        subtitle: t('homePage.cards.SNA1b.subtitle'), 
        path: '/SNA_1b', 
        image: 'images/sna_1.png'
    },
    {
        name: t('homePage.cards.SNA1c.title'), 
        subtitle: t('homePage.cards.SNA1c.subtitle'), 
        path: '/SNA_1c', 
        image: 'images/sna_1.png'
    },
    {
        name: t('homePage.cards.SNA2a.title'), 
        subtitle: t('homePage.cards.SNA2a.subtitle'), 
        path: '/SNA_2a', 
        image: 'images/sna_7.png'
    },
    {
        name: t('homePage.cards.SNA2b.title'), 
        subtitle: t('homePage.cards.SNA2b.subtitle'), 
        path: '/SNA_2b', 
        image: 'images/sna_2.png'
    },
    {
        name: t('homePage.cards.SNA2c.title'), 
        subtitle: t('homePage.cards.SNA2c.subtitle'), 
        path: '/SNA_2c', 
        image: 'images/sna_8.png'
    },
    {
        name: t('homePage.cards.SNA3a.title'), 
        subtitle: t('homePage.cards.SNA3a.subtitle'), 
        path: '/SNA_3a', 
        image: 'images/sna_3.png'
    },
    {
        name: t('homePage.cards.SNA3b.title'), 
        subtitle: t('homePage.cards.SNA3b.subtitle'), 
        path: '/SNA_3b', 
        image: 'images/sna_3.png'
    },
    {
        name: t('homePage.cards.SNA3c.title'), 
        subtitle: t('homePage.cards.SNA3c.subtitle'), 
        path: '/SNA_3c', 
        image: 'images/sna_4.png'
    },
    {
        name: t('homePage.cards.SNA5a.title'), 
        subtitle: t('homePage.cards.SNA5a.subtitle'), 
        path: '/SNA_5a', 
        image: 'images/sna_5.png'
    },
    {
        name: t('homePage.cards.SNA5b.title'), 
        subtitle: t('homePage.cards.SNA5b.subtitle'), 
        path: '/SNA_5b', 
        image: 'images/sna_5.png'
    },
    {
        name: t('homePage.cards.SNA6a.title'), 
        subtitle: t('homePage.cards.SNA6a.subtitle'), 
        path: '/SNA_6a', 
        image: 'images/sna_6a.png'
    },
    {
        name: t('homePage.cards.SNA6b.title'), 
        subtitle: t('homePage.cards.SNA6b.subtitle'), 
        path: '/SNA_6b', 
        image: 'images/sna_6b.png'
    },
    {
        name: t('homePage.cards.SNA9.title'), 
        subtitle: t('homePage.cards.SNA9.subtitle'), 
        path: '/SNA_9a', 
        image: 'images/sna_9.png'
    },
    {
        name: t('homePage.cards.SNA10a.title'), 
        subtitle: t('homePage.cards.SNA10a.subtitle'), 
        path: '/SNA_10a', 
        image: 'images/sna_10a.png'
    },
    {
        name: t('homePage.cards.SNA10b.title'), 
        subtitle: t('homePage.cards.SNA10b.subtitle'), 
        path: '/SNA_10b', 
        image: 'images/sna_10b.png'
    },
    {
        name: t('homePage.cards.SNA10c.title'), 
        subtitle: t('homePage.cards.SNA10c.subtitle'), 
        path: '/SNA_10c', 
        image: 'images/sna_10b.png'
    },
    {
        name: t('homePage.cards.SNA11a.title'), 
        subtitle: t('homePage.cards.SNA11a.subtitle'), 
        path: '/SNA_11a', 
        image: 'images/sna_11a.png'
    },
    {
        name: t('homePage.cards.SNA11b.title'), 
        subtitle: t('homePage.cards.SNA11b.subtitle'), 
        path: '/SNA_11b', 
        image: 'images/sna_11b.png'
    },
    {
        name: t('homePage.cards.SNA11c.title'), 
        subtitle: t('homePage.cards.SNA11c.subtitle'), 
        path: '/SNA_11c', 
        image: 'images/sna_11c.png'
    },
    {
        name: t('homePage.cards.SNA12a.title'), 
        subtitle: t('homePage.cards.SNA12a.subtitle'), 
        path: '/SNA_12a', 
        image: 'images/sna_12.png'
    },
    {
        name: t('homePage.cards.SNA15a.title'), 
        subtitle: t('homePage.cards.SNA15a.subtitle'), 
        path: '/SNA_15a', 
        image: 'images/sna_15a.png'
    },
    {
        name: t('homePage.cards.SNA15b.title'), 
        subtitle: t('homePage.cards.SNA15b.subtitle'), 
        path: '/SNA_15b', 
        image: 'images/sna_15a.png'
    },
    {
        name: t('homePage.cards.SNA19.title'), 
        subtitle: t('homePage.cards.SNA19.subtitle'), 
        path: '/SNA_19a', 
        image: 'images/sna_19.png'
    },
    {
        name: t('homePage.cards.SNA22.title'), 
        subtitle: t('homePage.cards.SNA22.subtitle'), 
        path: '/SNA_22a', 
        image: 'images/sna_22.png'
    },
    {
        name: t('homePage.cards.SNA23.title'), 
        subtitle: t('homePage.cards.SNA23.subtitle'), 
        path: '/SNA_23a', 
        image: 'images/sna_23.png'
    },
    {
        name: t('homePage.cards.SNA24a.title'), 
        subtitle: t('homePage.cards.SNA24a.subtitle'), 
        path: '/SNA_24a', 
        image: 'https://dartef.s3.eu-north-1.amazonaws.com/SNA24/computers/10.png'
    },
    {
        name: t('homePage.cards.SNA24b.title'), 
        subtitle: t('homePage.cards.SNA24b.subtitle'), 
        path: '/SNA_24b', 
        image: 'https://dartef.s3.eu-north-1.amazonaws.com/SNA24/computers/10.png'
    },
    {
        name: t('homePage.cards.SNA25.title'),  
        subtitle: t('homePage.cards.SNA25.subtitle'),  
        path: '/SNA_25a', 
        image: 'https://dartef.s3.eu-north-1.amazonaws.com/cards/sna_25.png'
    },
    {
        name: t('homePage.cards.SNA26.title'),  
        subtitle: t('homePage.cards.SNA26.subtitle'),  
        path: '/SNA_26a', 
        image: 'https://dartef.s3.eu-north-1.amazonaws.com/cards/sna_26.png'
    },
    // {name: t('homePage.cards.BSI1.title'), subtitle: '', path: '/BSI_1', image: 'images/bsi_1.png'},
    // {name: t('homePage.cards.BSI3.title'), subtitle: '', path: '/BSI_3', image: 'images/theater.png'},
    // {name: t('homePage.cards.BSI4.title'), subtitle: '', path: '/BSI_4', image: 'images/bsi_4.png'},
];
