import React from 'react';
import classes from './Polynomial.module.css';

const Polinomial = (props) => {
    const { weekId, polynomialId, isAnimationRunning, isDataReseted } = props;
    return (
        <section className={classes.Container} >
            <div
                className={classes.Variable}
                disabled={isAnimationRunning || !isDataReseted}       // attribute SELECTOR => div does't have a disabled attribute
                onClick={() => props.openCoeficientsPanel(weekId, polynomialId)}>{props.coeficient}
            </div>
            <div
                className={classes.Variable}
                disabled={isAnimationRunning || !isDataReseted}
                onClick={() => props.openVariables_1_Panel(weekId, polynomialId)}>
                <div className={classes.Img}>
                    {props.variable_1 && <img width='30px' height='30px' src={props.variable_1} alt='icon' />}
                </div>
            </div>
            <div
                className={classes.Variable}
                disabled={isAnimationRunning || !isDataReseted}
                onClick={() => props.openVariables_2_Panel(weekId, polynomialId)}>{props.variable_2}
            </div>
        </section>
    );
}

export default Polinomial;
