import React, { useEffect, useState, Fragment } from 'react';
import classes from '../../common_components/ProgressBar/ProgressBar.module.css';
import { useTranslation } from 'react-i18next';

const ProgressBar = (props) => {
    const { t } = useTranslation();
    const [offset, setOffset] = useState(0);
    const { 
        size, 
        progress, 
        strokeWidth, 
        firstCircleStroke, 
        secondCircleStroke,
        circleFillColor,
        activityType,
        actionValue,
        activityUnit
    } = props;
    const center = size / 2;
    const radius = size / 2 - strokeWidth / 2;
    const circumference = 2 * Math.PI * radius;
    useEffect(() => {
        const progressOffset = ((props.maxValue - progress) / props.maxValue) * circumference ;
        setOffset(progressOffset);
    }, [circumference, progress, props.maxValue]);
    return (
        <Fragment>
            <svg className={classes.ProgressBar} style={props.style} width={size} height={size} > 
                <circle 
                    className={classes.FirstCircleBackground}
                    stroke={firstCircleStroke}
                    style={{fill: circleFillColor}}
                    cx={center}
                    cy={center}
                    r={radius}
                    strokeWidth={strokeWidth} />
                <circle 
                    className={classes.SecondCircleBackground}
                    transform={`rotate(-90, ${center}, ${center})`}
                    stroke={secondCircleStroke}
                    cx={center}
                    cy={center}
                    r={radius}
                    strokeWidth={strokeWidth}
                    strokeDasharray={circumference} 
                    strokeDashoffset={offset} />
                <text 
                    className={classes.ProgressValue}
                    dominantBaseline='middle'
                    x={center}
                    y={center - 70}>{t('SNA10b.smartwatch.text.line-1')}
                </text>
                <text 
                    className={classes.ProgressValue}
                    dominantBaseline='middle'
                    x={center}
                    y={center - 50}>{t('SNA10b.smartwatch.text.line-2', {target: Math.trunc(progress.toFixed(2))})}
                </text>
                <text 
                    className={classes.ProgressValue}
                    dominantBaseline='middle'
                    x={center}
                    y={center - 10}
                >
                    {
                        t(`SNA10b.smartwatch.text.line-3.${activityUnit === 'steps' ? `step` : 'minute'}`, 
                        {type: t(`SNA10b.smartwatch.text.line-3.types.${activityType === 'walk' ? 'walk' : 'run'}`), count: actionValue})
                    }
                </text>
                <text 
                    className={classes.ProgressValue}
                    dominantBaseline='middle'
                    x={center}
                    y={center + 10}>{t('SNA10b.smartwatch.text.line-4')}
                </text>
            </svg>
        </Fragment>
    );
}
 
export default ProgressBar;
