import React, { useContext, useReducer } from 'react';
import reducer from '../reducers/SNA25-reducer';
import { randomNum } from '../utils/helpers';
import { data, defaultData } from '../data/SNA25data';

const SNA25Context = React.createContext();

const dx = 2;
const dy = 1;

const crossmarkIsValid = (crossMarkCoords, parabolaVetrexCoords) => {
    const {x: crossMarkX, y: crossMarkY} = crossMarkCoords;
    const {correctParabolaX, correctParabolaY} = parabolaVetrexCoords;
    
    const x1 = correctParabolaX - dx / 2;
    const x2 = x1 + dx;
    const y1 = correctParabolaY - dy / 3;
    const y2 = correctParabolaY + dy;

    const crossmarkXisValid = crossMarkX >= x1 && crossMarkX <= x2;
    const crossmarkYisValid = crossMarkY >= y1 && crossMarkY <= y2;
    return crossmarkXisValid && crossmarkYisValid;
}

export const SNA25Provider = (props) => {
    const initialState = {
        currentData: defaultData,
        isParabolaHidden: true,
        crossMarkCoords: { x: null, y: null },
        crossMarkIsValid: false,
        formData: null,
        submitterName: '',
        isDataReseted: true
    };

    const [state, dispatch] = useReducer(reducer, initialState);
    
    const handleNewTaskClick = () => {
        let random = randomNum(0, data.length - 1);
        while (random === state.currentData.id) random = randomNum(0, data.length - 1);
        const currentData =  data[random];
        dispatch({ 
            type: 'NEW_TASK',  
            payload: { currentData }
        });
    }

    const handleToggleClick = () => {
        dispatch({ type: 'TOGGLE' });
    }

    const setCrossMarkCoords = (coords) => {
        const valid = crossmarkIsValid(coords, state.currentData.parabola.vertex)
        dispatch({ type: 'COORDS', payload: { coords, valid } });
    }

    const setFormData = (data, submitterName) => {
        dispatch({ type: 'FORM', payload: { data, submitterName } });
    }

    const handleResetClick = () => {
        dispatch({ type: 'RESET' });
    }

    return (
        <SNA25Context.Provider
            value={{
                ...state,
                handleNewTaskClick,
                handleToggleClick,
                setCrossMarkCoords,
                setFormData,
                handleResetClick
            }}
        >
            {props.children}
        </SNA25Context.Provider>
    );
}

export const useSNA25Context = () => {
    return useContext(SNA25Context);
};
