import React from 'react';
import classes from './Map.module.css';
import { MapContainer, TileLayer, CircleMarker, Popup, useMapEvent } from 'react-leaflet';
import { useTranslation } from 'react-i18next';

const UNIT = 120; // one abstract unit in pixels

const Map = ( props ) => {
    const { t} = useTranslation();
    const { latitude, longitude, zoom, circles, settings } = props;
    const center = [latitude, longitude];

    const MyComponent = () => {
        useMapEvent('click', (mapEvent) => {
            const { lat, lng } = mapEvent.latlng;
            props.openAddLocationModal([lat, lng]); 
        })
        return null;
    }
    return (
        <MapContainer 
            className={classes.Map} 
            center={center} 
            zoom={zoom} 
            scrollWheelZoom={true}    
        >
            <MyComponent />
            <TileLayer
                attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a>'
                url='https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png'
            />
            {circles.map(circle => 
                <CircleMarker key={circle.id} center={circle.coords} pathOptions={{ color: 'red' }} radius={((circle.population * settings.size * UNIT) / settings.population) / 2}>
                    <Popup>
                        {`${circle.location}: ${circle.population}`} <br/>
                        {t('SNA12.map', {diameter: (circle.population / settings.population * settings.size).toFixed(2)})}
                    </Popup>
                </CircleMarker>
            )} 
        </MapContainer>
    );
}
 
export default Map;
