import React from 'react';
import { SNA23Provider } from '../../context/SNA23-context';
import Content from '../../components/SNA23/Content/Content';

const SNA23 = () => {
    return (
        <SNA23Provider>
            <Content/>
        </SNA23Provider>
    );
}
 
export default SNA23;
