import React from 'react';
import SettingsForm from '../../../containers/SNA12/Forms/SettingsForm/SettingsForm';
import LocationsList from '../LocationsList/LocationsList';
import classes from './Sidebar.module.css';

const sidebar = (props) => {
    const { locationPoints, settings } = props;
    return (
        <div className={classes.Sidebar}>
            <SettingsForm 
                settings={settings}
                formDataCallback={formData => props.formDataCallback(formData)}/>
            <LocationsList
                deleteLocation={index => props.deleteLocation(index)}
                editLocation={(location, index) => props.editLocation(location, index)}
                locationPoints={locationPoints} />
        </div>
    );
}
 
export default sidebar;
