import React from 'react';
import classes from './Radio.module.css';

const Radio = ({label= 'Radio button', value, disabled, checked, defaultChecked}) => {
    return (
       <label className={classes.radio}>
           <input 
                className={classes['radio-input']} 
                type='radio' 
                name='radio-button' 
                value={value}
                disabled={disabled} 
                checked={checked}
                defaultChecked={defaultChecked}
            />
           <span className={classes['radio-button']} ></span>
           <span className={classes['radio-label']}>{label}</span>
       </label>
    );
}
 
export default Radio;
