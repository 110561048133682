import React from 'react';
import classes from './Logo.module.css';
import { Link } from 'react-router-dom';

const logo = (props) => (
    <Link className={classes.LogoLink}to='/'>
        {props.children}  
    </Link>
);

export default logo;