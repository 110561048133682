import React, { Fragment } from 'react';
import Accordion from '../UI/Accordion/Accordion';
import classes from './QuickStart.module.css';
import button from '../../assets/SNA26/show-list.png';
import form from '../../assets/SNA3/form.png';

const QuickStartB = () => {
    const open = <img className={classes['show-list-img']} src={button} alt="show-list" />;
    const close = <img className={classes['hide-list-img']} src={button} alt="hide-list" />;

    return (
        <Fragment>
            <Accordion title={'Mida me teeme selles programmis?'}>
                <p className={classes.paragraph}>Isejuhtiv auto koostab oma teekonda kasutades matemaatilisi meetodeid. See programm näitab sulle, kuidas saab panna autot sõitma kasutades koordinaatteljestikku.</p>
            </Accordion>
            <Accordion title={'Kelle tööd see programm tutvustab?'}>
                <p className={classes.paragraph}>Isejuhtivate sõidukite algoritme loovad arvuti- ja elektroonikainsenerid, programmeerijad, andmeanalüütikud ja teised spetsialistid.  Valdaval osal nendest on kõrgharidus ja isegi doktorikraadid.</p>
            </Accordion>
            <Accordion title={'Kuidas programmi kasutada?'}>
                <div className={classes.step}>
                    <span className={classes.numeration}>1.</span>
                    <p className={classes.paragraph}>Vajuta koordinaattasandi vasakus nurgas noolekest {open}, seejärel nuppu "+" ja <strong>"Image"</strong>, ja lisa koordinaattasandile pilt kaardiga (kaardi saad õpetajalt). Kui kaart on üles laetud, sulge aken vajutades nuppu {close}. Tee kaardi suurus parajaks (reguleerides pilti nurgast).</p>
                </div>
                <div className={classes['step-with-img']}>
                    <div className={classes['step-inner']}>
                        <span className={classes.numeration}>2.</span>
                        <p className={classes.paragraph}>Sisesta koordinaate (-14,4), (-10,10), seejärel vajuta <strong>"OK"</strong> ja <strong>"START"</strong>.</p>
                    </div>
                    <img className={classes['form-img']} src={form} alt="form" />
                </div>
                <div className={classes.step}>
                    <span className={classes.numeration}>3.</span>
                    <p className={classes.paragraph}>Vaata, kas need koordinaadid kirjeldavad auto teekonda korrektselt, või sooviksid seda muuta?</p>
                </div>
            </Accordion>
        </Fragment>
    );
}
 
export default QuickStartB;
