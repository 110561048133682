import React, { Component } from 'react';
import { calcAppendScript } from '../../../components/calcAppendScript';
import Des from 'desmos';
import classes from './GraphingCalculator.module.css';

let calculator;

class GraphingCalculator extends Component {

    state = {
    }

    componentDidMount() {
        calcAppendScript();
        const elt = document.getElementById('graphCalcId');
        calculator = Des.GraphingCalculator(elt, this.props.settings);
        calculator.setMathBounds(this.props.mathBounds);
        calculator.setExpression({ 
            id: 'point', 
            latex: '(m,f(m))', 
            color: '#ff0000', 
            dragMode: Des.DragModes.NONE 
        });

        calculator.setExpression({ 
            id: 'line1', 
            color: '#388c46' 
        });

        calculator.setExpression({ 
            id: 'm', 
            latex: `m=${this.props.pointX}`, 
        });
    }

    componentDidUpdate() { 
        if (Number(this.props.pointX) === 0) {
            calculator.setExpression({ id: 'line1', latex: this.props.func });
        }  
        calculator.setExpression({ id: 'm', latex: `m=${this.props.pointX}` });
    }

    componentWillUnmount() {
        calculator.destroy();
    }

    render() {
        return (  
            <div id='graphCalcId' className={classes.GraphingCalculator} />
        );
    }
}
 
export default GraphingCalculator;
