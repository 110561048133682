import React from 'react';
import classes from './LocationsList.module.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrashAlt, faEdit } from '@fortawesome/free-solid-svg-icons';

const locationsList = (props) => {
    const { locationPoints } = props;
    return (
        <div className={classes.Locations}>
            <ul>
                {locationPoints.map((location, index) => 
                    <li key={location.id}>
                        <div className={classes.LocationDetails}>
                            <span className={classes.Icon} role='img' aria-labelledby='city'>🏙</span>
                            <span className={classes.LocationName}>{location.location}</span>
                        </div>
                        <div className={classes.LocationDetails}>
                            <span className={classes.Icon} role='img' aria-labelledby='person'>👤</span>
                            <span className={classes.PopulationValue}>{location.population}</span>
                        </div>
                        <div>
                            <FontAwesomeIcon 
                                className={classes.EditIcon}  
                                icon={faEdit} 
                                size="2x" 
                                onClick={() => props.editLocation(location, index)}/>
                            <FontAwesomeIcon 
                                className={classes.TrashIcon} 
                                icon={faTrashAlt} 
                                size="2x" 
                                onClick={() => props.deleteLocation(index)}/>
                        </div>
                    </li>)}
            </ul> 
        </div>
    );
}
 
export default locationsList ;
