import React from 'react';
import classes from './SNA26.module.css';
import GraphingCalculator from '../../components/SNA26/GraphingCalculator/GraphingCalculator';
import Controls from '../../components/SNA26/Controls/Controls';
import { SNA26Provider } from '../../context/SNA26-context';
import Timeline from '../../components/SNA26/Timeline/Timeline';
import SideDrawer from '../../components/UI/SideDrawer/SideDrawer';
import useToggle from '../../hooks/useToggle';
import QuickStart from '../../components/SNA26/QuickStart/QuickStart';

const SNA26 = () => {
    const [isOpen, setIsOpen] = useToggle(false);

    const openSideDrawerHandler = () => {
        setIsOpen(true);
    }

    const closeSideDrawerHandler = () => {
        setIsOpen(false);
    }
    return (
        <SNA26Provider>
            <main className={classes.wrapper}>
                <Timeline />
                <section className={classes.row}>
                    <div className={classes.gc}>
                        <GraphingCalculator />
                    </div>
                    <div className={classes.controls}>
                        <Controls />
                    </div>
                </section>
            </main>
            <SideDrawer
                isSideDrawerOpen={isOpen}
                openSideDrawer={openSideDrawerHandler}
                closeSideDrawer={closeSideDrawerHandler}
            >
                <QuickStart />
            </SideDrawer>
        </SNA26Provider>
    );
}
 
export default SNA26;
