import React from 'react';
import classes from './Skeleton.module.css';

const Skeleton = () => {
    return (
        <div className={classes['skeleton-wrapper']}>
            <div className={classes['skeleton-img']}/>
            <div className={classes['skeleton-info']}>
                <div className={classes['skeleton-title']}/>
                <div className={classes['skeleton-text']}/>
                <div className={classes['skeleton-text']}/>
            </div>
        </div>
    );
}
 
export default Skeleton;
