import React, { Component } from 'react';
import Input from '../../../components/UI/Input/Input';
import Button from '../../../components/UI/Button/Button';
import { withTranslation } from 'react-i18next';

class Form extends Component {
    state = {
        function: {
            elementType: 'input',
            elementConfig: {
                type: 'text',
            },
            value: ''
        },
    }

    inputChangedHandler = (event) => {
        const updatedFunction = {...this.state.function};
        updatedFunction.value = event.target.value;
        this.setState({function: updatedFunction});     
    }

    dataHandler = (event) => {
        event.preventDefault();
        const func = this.state.function.value;
        this.props.formDataCallback(func);
    }

    render() { 
        const { t } = this.props;
        return (
            <div onSubmit={this.dataHandler}>
                <form> 
                    <Input 
                        elementType={this.state.function.elementType}
                        elementConfig={this.state.function.elementConfig}
                        placeholder={t('SNA1.form.input-placeholder')}
                        value={this.state.function.value}
                        error={this.props.error}
                        errorMessage={t('SNA1.form.input-error')}
                        disabled={this.props.inputDisabled && !this.props.error}
                        changed={(event) => this.inputChangedHandler(event)}/>  
                    <Button
                        btnType="Success" 
                        disabled={!(this.props.canStart && this.props.isDataReseted)}
                        style={{width: '100%', marginBottom: '15px'}}
                    >
                        {t('buttons.submit')}
                    </Button>
                </form>    
            </div>
        );
    }
}
 
export default withTranslation() (Form);
