import React from 'react';
import classes from './AirFlow.module.css';

const AirFlow = ({airFlow, flowNumber}) => {
    return (
        <div>
            {airFlow.map((circle, index) => (
                <div 
                    key={index} 
                    className={classes[`circle-${flowNumber}`]} 
                    style={{
                        '--x_position': circle.x,
                        '--y_position': circle.y, 
                        '--size': circle.size, 
                        '--opacity': circle.opacity, 
                        display: circle.y === 8 ? 'none' : null
                    }}  
                />
            ))}
        </div>
    );
}
 
export default AirFlow;
