import React, { useEffect, useState, useCallback } from 'react';
import { calcAppendScript } from '../../calcAppendScript';
import Des from 'desmos';
import classes from './GraphingCalculator.module.css';
import { useSNA19Context } from '../../../context/SNA19-context';
import { useTranslation } from 'react-i18next';

let calculator;

const SETTINGS = {
    autosize: true,  
    settingsMenu: false, 
    zoomButtons: false, 
    lockViewport: false,
    expressionsCollapsed: true,
    projectorMode: false, 
    expressions: false,
};

const MERCURY_RADIUS = 0.38;
const VENUS_RADIUS = 0.72;
const EARTH_RADIUS = 1;
const MARS_RADIUS = 1.52;
const JUPITER_RADIUS = 5.21;
const SATURN_RADIUS = 9.56;
const URANUS_RADIUS = 19.19;
const NEPTUNE_RADIUS = 30.08;

const DELTA_STEP = 0.01;        
const SLOWED_DELAY = 80;
const DELAY = 40;

const GraphingCalculator = () => {
    const { t } = useTranslation();
    const [bounds, setBounds] = useState({left: -6, right: 6, bottom: -6, top: 6});
    const [timer, setTimer] = useState(3);
    const [spacecraftPosition, setSpacecraftPosition] = useState(0);

    const {
        isTimerOn, 
        isStarcraftAnimationRunning, 
        flightData, 
        spacecraftSpeed, 
        stopSpacecraft,
        isAnimationSpeedSlowed,
        setTimerAcc
    } = useSNA19Context();

    const expressions = [
        {id: 'Sun', latex: '(0, 0)', color: '#fa7e19', labelData: {label: '', showLabel: false}},
        {id: 'Mercury-orbit', latex: `x^2+y^2=${MERCURY_RADIUS}^2`, color: '#ccc', labelData: {}},
        {id: 'Mercury', latex: `(${-MERCURY_RADIUS}\\cos(360/88c),${-MERCURY_RADIUS}\\sin(360/88c))`, color: '#c74440', labelData: {label: t('SNA19.GC.planets.mercury'), showLabel: true}},
        {id: 'Venus-orbit', latex: `x^2+y^2=${VENUS_RADIUS}^2`, color: '#ccc', labelData: {}},
        {id: 'Venus', latex: `(${-VENUS_RADIUS}\\cos(360/225c),${-VENUS_RADIUS}\\sin(360/225c))`, color: '#388c46', labelData: {label: t('SNA19.GC.planets.venus'), showLabel: true}},
        {id: 'Earth-orbit', latex: `x^2+y^2=${EARTH_RADIUS}^2`, color: '#ccc', labelData: {}},
        {id: 'Earth', latex: `(${-EARTH_RADIUS}\\cos(360/365c),${-EARTH_RADIUS}\\sin(360/365c))`, color: '#2d70b3', labelData: {label: t('SNA19.GC.planets.earth'), showLabel: true}},
        {id: 'Mars-orbit', latex: `x^2+y^2=${MARS_RADIUS}^2`, color: '#ccc', labelData: {}},
        {id: 'Mars', latex: `(${-MARS_RADIUS}\\cos(360/687c),${-MARS_RADIUS}\\sin(360/687c))`, color: '#c74440', labelData: {label: t('SNA19.GC.planets.mars'), showLabel: true}},
        {id: 'Jupiter-orbit', latex: `x^2+y^2=${JUPITER_RADIUS}^2`, color: '#ccc', labelData: {}},
        {id: 'Jupiter', latex: `(${-JUPITER_RADIUS}\\cos(360/4333c),${-JUPITER_RADIUS}\\sin(360/4333c))`, color: '#fa7e19', labelData: {label: t('SNA19.GC.planets.jupiter'), showLabel: true}},
        {id: 'Saturn-orbit', latex: `x^2+y^2=${SATURN_RADIUS}^2`, color: '#ccc', labelData: {}},
        {id: 'Saturn', latex: `(${-SATURN_RADIUS}\\cos(360/10759c),${-SATURN_RADIUS}\\sin(360/10759c))`, color: '#000000', labelData: {label: t('SNA19.GC.planets.saturn'), showLabel: true}},
        {id: 'Uranus-orbit', latex: `x^2+y^2=${URANUS_RADIUS}^2`, color: '#ccc', labelData: {}},
        {id: 'Uranus', latex: `(${-URANUS_RADIUS}\\cos(360/30685c),${-URANUS_RADIUS}\\sin(360/30685c))`, color: '#2d70b3', labelData: {label: t('SNA19.GC.planets.uranus'), showLabel: true}},
        {id: 'Neptune-orbit', latex: `x^2+y^2=${NEPTUNE_RADIUS}^2`, color: '#ccc', labelData: {}},
        {id: 'Neptune', latex: `(${-NEPTUNE_RADIUS}\\cos(360/60190c),${-NEPTUNE_RADIUS}\\sin(360/60190c))`, color: '#6042a6', labelData: {label: t('SNA19.GC.planets.neptune'), showLabel: true}},
    ];

    const getValueOfNextPoint = useCallback(() => {
        const delta = DELTA_STEP * flightData.coeficients.x * spacecraftSpeed;
        setTimerAcc(delta);
        setSpacecraftPosition(prev => prev + delta < flightData.finishPointTimerValue ? prev + delta : flightData.correctSpeed === spacecraftSpeed ? flightData.finishPointTimerValue : prev + delta);
    }, [flightData, spacecraftSpeed, setTimerAcc]);

    const checkBounds = useCallback(() => {
        if (spacecraftPosition >= flightData.finishPointTimerValue && flightData.correctSpeed === spacecraftSpeed) {
            stopSpacecraft();
        }
    }, [spacecraftPosition, flightData, spacecraftSpeed, stopSpacecraft]);
 
    useEffect(() => {
        calcAppendScript();
        const elt = document.getElementById('graphCalcId');
        calculator = Des.GraphingCalculator(elt, SETTINGS);
        return () => {
            calculator.destroy();
        }
    }, []);

    useEffect(() => {
        calculator.setMathBounds(bounds);
    }, [bounds]);

    useEffect(() => {
        let intervalId = null;
        if (isTimerOn) {
            intervalId = setInterval(() => {
                setTimer(prev => prev + DELTA_STEP);
                if (isStarcraftAnimationRunning) {
                    getValueOfNextPoint();
                    checkBounds();
                }
                if (!isStarcraftAnimationRunning && flightData) {
                    setTimerAcc(DELTA_STEP * flightData.coeficients.x * spacecraftSpeed);
                }   
            }, isAnimationSpeedSlowed ? SLOWED_DELAY : DELAY);
        } else {
            clearInterval(intervalId);
        }
        return () => clearInterval(intervalId);
    }, [isStarcraftAnimationRunning, isTimerOn, flightData, getValueOfNextPoint, checkBounds, setTimerAcc, spacecraftSpeed, isAnimationSpeedSlowed, timer]);

    useEffect(() => {
        for (const exp of expressions) {
            calculator.setExpression({
                id: exp.id,
                latex: exp.latex,
                color: exp.color,
                label: spacecraftPosition === flightData.finishPointTimerValue && flightData.correctSpeed === spacecraftSpeed && exp.id === flightData.destinationPlanet ? 
                    `${exp.labelData.label}/${t('SNA19.GC.spacecraft')}` : 
                    exp.labelData.label,
                showLabel: exp.labelData.showLabel,
                labelSize: Des.LabelSizes.SMALL,
            });
        }

        calculator.setExpression({ 
            id: 'c',                                  
            latex: `c=${timer}`, 
        });
    }, [timer, expressions, flightData, t, spacecraftPosition, spacecraftSpeed]);

    useEffect(() => {
        setSpacecraftPosition(flightData.startPointTimerValue);
        flightData && setBounds(flightData.mathBounds);
        flightData && setTimer(flightData.timerValue);
        for (const key in flightData?.expressions) {
            calculator.setExpression({ 
                id: key, 
                latex: flightData.expressions[key], 
                color: '#000',
            });
        }
        if (flightData === '') {
            calculator.removeExpression({ id: 'arc1' });
            calculator.removeExpression({ id: 'arc2' });
        }
    }, [flightData]);

    useEffect(() => {
        const { h, k, r, a = 1, b = 1 } = flightData.coeficients || {};

        calculator.setExpression({
            id: 'start',
            latex: `(${r * a * Math.cos(flightData.startPointTimerValue) + h}, ${r * b *  Math.sin(flightData.startPointTimerValue) + k})`, 
            color: '#000', 
            label: t('SNA19.GC.start'),
            showLabel: true,
            labelSize: Des.LabelSizes.LARGE,
        });

        calculator.setExpression({
            id: 'landing',
            latex: `(${r * a * Math.cos(flightData.finishPointTimerValue) + h}, ${r * b *  Math.sin(flightData.finishPointTimerValue) + k})`, 
            color: '#000', 
            label: t('SNA19.GC.landing'),
            showLabel: true,
            labelSize: Des.LabelSizes.LARGE,
        });

        calculator.setExpression({ 
            id: 'spacecraft', 
            latex: `(${r * a * Math.cos(spacecraftPosition) + h}, ${r * b *  Math.sin(spacecraftPosition) + k})`, 
            //latex: `m=(${r*a}\\cos(d)+${h},${r*b}\\sin(d)+${k})`,
            color: '#000', 
            label: t('SNA19.GC.spacecraft'),
            showLabel: spacecraftPosition < flightData.finishPointTimerValue || spacecraftSpeed !== flightData.correctSpeed,
            labelSize: Des.LabelSizes.SMALL,
        });

        // calculator.setExpression({ 
        //     id: 'd',                                  
        //     latex: `d=${spacecraftPosition}`, 
        // });
    }, [spacecraftPosition, flightData, spacecraftSpeed, t]);

    return (
        <section id='graphCalcId' className={classes.graphingCalculator} />
    );
}
 
export default GraphingCalculator;
