import React from 'react';
import AirFlow from './AirFlow/AirFlow';

const Air = ({airFlows}) => {
    return (
        <div>
            {airFlows.map((airFlow, index) => (
                <AirFlow 
                    key={index} 
                    airFlow={airFlow}
                    flowNumber={index + 1}
                />  
            ))} 
        </div>
    );
}
 
export default Air;
