import React, { Fragment } from 'react';
import classes from './SideDrawer.module.css';
import { BsQuestion } from 'react-icons/bs';
import Backdrop from '../Backdrop/Backdrop';

const SideDrawer = (props) => {
    return (
        <Fragment>
            <Backdrop 
                show={props.isSideDrawerOpen}
                clicked={props.closeSideDrawer}/>
            <div className={`${classes.sidedrawer} ${classes[props.isSideDrawerOpen ? 'open' : 'close']}`}>
                <div className={classes.wrapper}>
                    <button className={classes['close-btn']} onClick={props.closeSideDrawer}>&times;</button>
                    <div className={classes.content}>
                        {props.children}
                    </div>
                </div>
                <div className={`${classes.marker} ${classes[props.isSideDrawerOpen ? 'marker-non-visible' : 'marker-visible']}`} onClick={props.openSideDrawer}>
                    <BsQuestion />
                </div>
            </div>
        </Fragment>
    );
}
 
export default SideDrawer;
