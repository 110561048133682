import React from 'react';
import classes from './Footer.module.css';
import { useTranslation } from 'react-i18next';

const Footer = () => {
    const { t } = useTranslation();
    return (
        <footer className={classes.footer}>
            <div className={classes.sponsor}>
                <div className={classes.logos}>
                    <div className={classes.flag} /> 
                    <div className={classes.edTech} />
                </div>
                <p className={classes.description}>
                    {t('homePage.footer.description')}
                </p>
            </div>
            <div className={classes['logo-box']}>
                <img src={t('homePage.footer.logo')} alt="logo" className={classes.logo} />
            </div>
            <p className={classes.copyright}>&copy; 2020-{new Date().getFullYear()}, dartef.com</p>
        </footer>
    );
}
 
export default Footer;
