import React, { Fragment } from 'react';
import Accordion from '../../UI/Accordion/Accordion';
import classes from './QuickStart.module.css';
import form from '../../../assets/SNA19/form.png';
import { useSNA19Context } from '../../../context/SNA19-context';

const QuickStart = () => {
    const { trajectoryIndex, isDataReseted } = useSNA19Context();

    return (
        <Fragment>
            <Accordion title={'Sissejuhatus'}>
                <p className={classes.paragraph}>Oled kindlasti kuulnud, et inimkond on saatnud spetsiaalseid kosmoseaparaate teistele planeetidele (näiteks Marsile). Selleks, et kosmoseaparaat jõuaks õigel ajal õigesse kohta, on vaja õigesti arvutada kiirust, millega peaks liikuma kosmoseaparaat. See programm näitab põhimõtet, millel need arvutused põhinevad. Selles programmis saad rakendada on teadmisi ühtlasest liikumisest.</p>
            </Accordion>
            <Accordion title={'Kelle tööd see programm simuleerib?'}>
                <p className={classes.paragraph}>See programm näitab sulle väikest tükikest kosmoseteadlase või kosmoseinseneri tööst. Need spetsialistid ennustavad kosmoseobjektide liikumist selleks, et liikumine kosmoses oleks võimalikult efektiivne ja ohutu. Pea meeles, et programm näitab vaid ühte väikest osa nende spetsialistide töös!</p>
            </Accordion>
            <Accordion title={'Kuidas programmi kasutada?'}>
                 <div className={classes.step}>
                    <span className={classes.numeration}>1.</span>
                    <p className={classes.paragraph}>Alusta programmi kasutamist vajutades nuppu "Uus ülesanne".</p>
                </div>
                <div className={`${classes['step-with-img']} ${!trajectoryIndex && classes.filter}`}>
                    <div className={classes['step-inner']}>
                        <span className={classes.numeration}>2.</span>
                        <p className={classes.paragraph}>Programm näitab sulle, mis planeedile kosmoseaparaat peab lendama, kui palju aega selleks kulub ja mis on aparaadi teekonna pikkus. Arvuta välja mis kiirusega peaks saatma aparaadi, et see jõuaks õigel ajal planeedile. Sisesta kiirus programmi, seejärel vajuta "OK" ja "START".</p>
                    </div>
                    <img className={classes['form-img']} src={form} alt='form' />
                </div>
                <div className={`${classes.step} ${isDataReseted && classes.filter}`}>
                    <span className={classes.numeration}>3.</span>
                    <p className={classes.paragraph}>Järgmise ülesande jaoks vajuta "RESET" ning seejärel nuppu "Uus ülesanne".</p>
                </div>
            </Accordion>
        </Fragment>
    );
}
 
export default QuickStart;
