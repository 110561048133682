import React, { useState } from 'react';
import classes from './LanguageSwitcher.module.css';
import { FaGlobe, FaCaretDown } from 'react-icons/fa';
import { useTranslation } from "react-i18next";
import cookies from 'js-cookie';
import { Link } from 'react-router-dom';

const LANGUAGES = [
    {
        code: 'et',
        name: 'Eesti'
    },
    {
        code: 'en',
        name: 'English'
    },
    {
        code: 'ru',
        name: 'Русский'
    }
];

const LanguageSwitcher = () => {
    let currentLanguageCode = cookies.get('i18next') || navigator.language || 'en';
    if (currentLanguageCode.length > 2) currentLanguageCode = currentLanguageCode.slice(0, 2);
    const currentLanguage = LANGUAGES.find((l) => l.code === currentLanguageCode);

    const [language, setLanguage] = useState(currentLanguage?.name || 'English');
    const { i18n } = useTranslation();

    const changeLanguage = (language, code) => {
        setLanguage(language);
        i18n.changeLanguage(code);
    }

    const languagesList = LANGUAGES.map(({code, name}) => 
        <li key={code} onClick={() => changeLanguage(name, code) }>
            <Link to={'#'} >{name}</Link>
        </li>
    );

    return (
        <div className={classes['language-switcher']}>
            <button className={classes['selected-language']} >
                <FaGlobe />
                {language}
                <FaCaretDown className={classes.caret}/>
            </button>
            <ul>
                {languagesList}
            </ul>
        </div>
    );
}
 
export default LanguageSwitcher;
